import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';

const ClientProduct = props => {
  const { selSignupClientid, user, reloadNewsGridCondition } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [signupClientDatalist, setSignupClientDatalist] = useState({});
  const [clientProductData, setClientProductData] = useState([]);

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/clientsignupdata/${selSignupClientid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setSignupClientDatalist(dataArray[0]);

        const dataArrayTwo = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/getclientproducts/${user.providerid}/${selSignupClientid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setClientProductData(dataArrayTwo);
      } catch (err) {}
    };
    if (selSignupClientid) {
      fetchSignupClientData();
    }
  }, [sendRequest, selSignupClientid]);

  const stClientProductData = new ArrayStore({
    key: 'assetaccountid',
    data: clientProductData,
  });

  const dcClientProductData = new DataSource({
    store: stClientProductData,
  });

  return (
    <div id="client-reg-prod-box" >
    <div className="sd-header item">
    <div className="dx-fieldset-header">Registered Products</div>
  </div>

      <ResponsiveBox>
        <Col />
        <Row />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <DataGrid
            id='client-access-grid'
            height={"300px"}
            dataSource={dcClientProductData}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            focusedRowEnabled={true}
            showBorders={true}
            showColumnLines={true}
            showRowLines={true}
            autoNavigateToFocusedRow={true}
            // focusedRowKey={selectedClientid}
            onFocusedRowChanged={e => {
              if (e.row) {
                //    setSelectedClientid(e.row.key.clientid);
                //    passToParent(e.row.key.clientid);
              }
            }}
          >
            <Column dataField='assetaccountid' visible={false}></Column>
            <Column dataField='providerid' visible={false}></Column>
            <Column dataField='productname' caption='Product' width={150} />
            <Column dataField='servicename' caption='Service' width={150} />
            <Column dataField='providername' caption='Provider' width={120} />
            <Column dataField='provider_approved' caption='Approved' width={80} />
            <Column dataField='access_state_txt' caption='Access State' width={120} />
          </DataGrid>
        </Item>
      </ResponsiveBox>
   </div>
  );
};

export default ClientProduct;
