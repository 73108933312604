import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { ArgumentAxis, Chart, Series, ValueAxis } from 'devextreme-react/chart';

const DashProvider = props => {
  const { selStartDate, selEndDate, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [dashProviderData, setDashProviderData] = useState([]);

  useEffect(() => {
    const fetchDashRev = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/rep/dashprovider/${selStartDate}/${selEndDate}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setDashProviderData(dataArray);
      } catch (err) {}
    };
    fetchDashRev();
  }, [sendRequest]);

  const stDashProviderData = new ArrayStore({
    key: 'providername',
    data: dashProviderData,
  });

  const dcDashProviderData = new DataSource({
    store: stDashProviderData,
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />

        <Item></Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='dash-area-provider'>
            <Chart id='dash-provider-chart' dataSource={dcDashProviderData} rotated={true}>
              <Series
                valueField='revenue'
                argumentField='providername'
                name='Transactions'
                type='bar'
                color='#0e9c2d'
              />
              <ArgumentAxis title='Service-Provider' />
              <ValueAxis title='EUR' />
            </Chart>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default DashProvider;
