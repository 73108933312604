import DataSource from "devextreme/data/data_source";

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

function calculateCurrentPageNumber(loadOptions) {
  return loadOptions.skip / loadOptions.take + 1;
}

export default function paginator(
  callback,
  callbackData,
  key,
  pageLimit,
  additionalParams
) {
  if (!additionalParams) {
    additionalParams = {}
  }
  return new DataSource({
    key: key,
    paginate: true,
    pageSize: pageLimit,
    load: function(loadOptions) {
      loadOptions.page = calculateCurrentPageNumber(loadOptions); // номер страницы
      loadOptions.limit = loadOptions.take; // лимит
      let queryStringData = { ...additionalParams };

      [
        // 'skip',
        // 'take',
        "page",
        "limit"
        // 'requireTotalCount',
        // 'requireGroupCount',
        // 'sort',
        // 'filter',
        // 'totalSummary',
        // 'group',
        // 'groupSummary',
      ].forEach(function(i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          // params += `${i}=${JSON.stringify(loadOptions[i])}&`;
          queryStringData[i] = loadOptions[i];
        }
      });

      //Getting filter options
      if (loadOptions.filter) {
        queryStringData.filter = JSON.stringify(loadOptions.filter);
      }
      //Getting sort options
      if (loadOptions.sort) {
        queryStringData.sort = JSON.stringify(loadOptions.sort);
      }
      //Getting group options
      if (loadOptions.group) {
        queryStringData.group = JSON.stringify(loadOptions.group);
      }

      let result = null;

      if (callbackData.length > 0) {
        result = callback(...callbackData, queryStringData);
      } else {
        result = callback(queryStringData);
      }

      return result
        .then((data) => {
          return {
            data: data.items,
            totalCount: data.meta.totalItems
          };
        })
        .catch((e) => {
          console.log(e);
          throw "Data Loading Error";
        });
    }
  });
}
