import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ThreadMessages from "./internalMessaging/ThreadMessages";
import Button from "devextreme-react/button";
import InternalMessagingHelper from "./internalMessaging/InternalMessagingHelper";

function ThreadMessagesPage(props) {
  const [clientInfo, setClientInfo] = useState(null);

  const handleBackButton = () => {
    if (clientInfo && clientInfo.customerId) {
      props.history.push({
        pathname: `/clients/${clientInfo.customerId}`,
        state: { openTabIndex: 7 }
      });
    } else {
      props.history.push({ pathname: "/messages" });
    }
  };

  useEffect(() => {
    InternalMessagingHelper.getThreadDetails(props.match.params.threadId).then(
      response => {
        if (response?.persons) {
          const clientInfo = response.persons.find(
            p => p.customerType === "CLIENT"
          );
          if (clientInfo) {
            setClientInfo(clientInfo);
          }
        }
      }
    );
  }, []);

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card wide-card p-4"}>
          <Button
            width={180}
            icon={"back"}
            type={"default"}
            className={"dx-shape-standard mb-4"}
            text={"Back to Threads"}
            stylingMode="outlined"
            onClick={handleBackButton}
          />
          <ThreadMessages threadId={props.match.params.threadId} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(ThreadMessagesPage);
