import React, { useEffect, useState } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import MultiView from "devextreme-react/multi-view";
import { Item } from "devextreme-react/responsive-box";
import { useHttpClient } from "../../hooks/http-hook";

import { useAuth } from "../../contexts/auth";

import ServiceFeaturesTab from "./ServiceFeaturesTab";
import Tabs from "devextreme-react/tabs";

const ServiceFeaturesPage = () => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [serviceCatDatalist, setServiceCatDatalist] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedID, setSelctedID] = useState(1);

  useEffect(() => {
    const fetchServiceCategories = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + "/service/servicecat", "GET", null, {
          Authorization: "Bearer " + user.token,
        });
        setServiceCatDatalist(dataArray);
      } catch (err) {}
    };
    fetchServiceCategories();
  }, [sendRequest]);

  return (
    <div className="sd-header item">
      <div className="dx-fieldset-header">Service Features</div>
      <Tabs
        dataSource={serviceCatDatalist}
        selectedIndex={tabIndex}
        onItemClick={(e) => {
          setTabIndex(e.itemIndex);
          setSelctedID(e.itemData.id);
          // console.log(e.itemData);
        }}
      ></Tabs>
      <MultiView height={500} loop={false}>
        <Item title="Service Features">
          <ServiceFeaturesTab serviceSectionID={selectedID} user={user} />
        </Item>
      </MultiView>
    </div>
  );
};

export default ServiceFeaturesPage;
