import React, { Component, createRef, useEffect, useRef, useState } from 'react';
import InternalMessagingHelper from './InternalMessagingHelper';
import FileUploader from 'devextreme-react/file-uploader';
import { Button } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import MessagesList from './MessagesList';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import TextArea from 'devextreme-react/text-area';
import moment from 'moment';

function ThreadMessages(props) {
  let messageListRef = useRef();
  let ALLOWED_DOCUMENT_TYPES;

  if (process.env.REACT_APP_ALLOWED_DOCUMENT_TYPES) {
    ALLOWED_DOCUMENT_TYPES = process.env.REACT_APP_ALLOWED_DOCUMENT_TYPES.split(',');
  } else {
    ALLOWED_DOCUMENT_TYPES = ['.doc', '.jpg', '.jpeg', '.jfif', '.bmp'];
  }

  let MAX_DOCUMENT_SIZE;
  if (process.env.REACT_APP_MAX_DOCUMENT_SIZE) {
    MAX_DOCUMENT_SIZE = Number(process.env.REACT_APP_MAX_DOCUMENT_SIZE) * 1024 * 1024;
  } else {
    MAX_DOCUMENT_SIZE = 1024 * 1024;
  }

  const [messagesDataList, setMessagesDataList] = useState([]);
  const [messageDataCurrentPage, setMessageDataCurrentPage] = useState(1);
  const [messagesDataTotalPages, setMessagesDataTotalPages] = useState(1);
  const [textToSend, setTextToSend] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [attaches, setAttaches] = useState([]);
  const [thread, setThread] = useState({});

  useEffect(() => {
    initList(messageDataCurrentPage);
    if (null != messageListRef.current) {
      messageListRef.current.onscroll = handleLoadMore;
    }
  }, []);

  const initList = page => {
    setIsLoading(true);
    InternalMessagingHelper.getThreadMessages(props.threadId, {
      page: page,
      limit: 10,
    }).then(data => {
      const reverseArray = data.items.reverse();
      setMessagesDataList([...reverseArray, ...messagesDataList]);
      setMessagesDataTotalPages(data.meta.totalPages);
      setMessageDataCurrentPage(page);
      setIsLoading(false);
      setThread(data.thread);
      if (page === 1) {
        scrollTo();
      }
    });
  };

  const reloadList = () => {
    InternalMessagingHelper.getThreadMessages(props.threadId, {
      page: 1,
      limit: 10,
    }).then(data => {
      const reverseArray = data.items.reverse();
      setMessagesDataList([...reverseArray]);
      setMessagesDataTotalPages(data.meta.totalPages);
      setMessageDataCurrentPage(1);
      scrollTo();
    });
  };

  // const scrollTo = (scrollHeight = messageListRef.current.scrollHeight) => {
  const scrollTo = () => {
    console.log(messageListRef);
    if (null != messageListRef.current) {
      let scrollHeight = messageListRef.current.scrollHeight;
      messageListRef.current.scroll({ top: scrollHeight });
    }
  };

  const handleLoadMore = () => {
    if (messageListRef.current.scrollTop === 0) {
      const nextPage = messageDataCurrentPage + 1;
      if (nextPage <= messagesDataTotalPages) {
        initList(nextPage);
      }
    }
  };

  const handleValidateForm = data => {
    const validate = data.validationGroup.validate();
    if (validate.isValid) {
      handleSubmitMessage();
      data.validationGroup.reset();
    } else {
      notify('Please write a message', 'error', 2000);
    }
  };

  const handleSubmitMessage = () => {
    const formData = new FormData();
    formData.append('threadId', String(props.threadId));
    formData.append('message', textToSend);
    if (attaches && attaches.length > 0) {
      attaches.forEach(file => {
        formData.append('files', file, file.name);
      });
    }

    InternalMessagingHelper.addThreadMessage(formData)
      .then(res => {
        notify('Success', 'success', 2000);
        setTextToSend('');
        setAttaches([]);
        reloadList();
      })
      .catch(error => {
        if (error.response) {
          notify(error.response.data.message, 'error', 2000);
        } else {
          notify('An error occurred while sending the message.', 'error', 2000);
        }
      });
  };

  return (
    <React.Fragment>
      <div className='content'>
        <div className={'mt-4 mb-4 thread-messages-wrapper'}>
          <div className='thread-title text-center'>
            <h3>{thread?.theme}</h3>
            <div className={'mb-4'}>Created: {moment(thread.createdAt).format('DD.MM.YYYY HH:mm')}</div>
          </div>
          <MessagesList
            threadInfo={thread}
            isLoading={isLoading}
            className={'p-4 mb-4'}
            messageListRef={messageListRef}
            height={500}
            messageslist={messagesDataList}
          />
          <p>Write a message</p>

          <TextArea onValueChanged={e => setTextToSend(e.value)} value={textToSend} className={'message-text-input'}>
            <Validator>
              <RequiredRule message='Required field' />
            </Validator>
          </TextArea>
        </div>
        <FileUploader
          style={{
            padding: 0,
          }}
          value={attaches}
          onValueChanged={e => {
            setAttaches(e.value);
          }}
          allowCanceling={true}
          labelText={''}
          selectButtonText='Attach files'
          uploadMode='useForm'
          showFileList={true}
          multiple={true}
          allowedFileExtensions={ALLOWED_DOCUMENT_TYPES}
          maxFileSize={MAX_DOCUMENT_SIZE}
          invalidFileExtensionMessage={`Unsupported file type, allowed only ${ALLOWED_DOCUMENT_TYPES.join(', ')}`}
          invalidMaxFileSizeMessage={`File is too large. Max filesize is ${process.env.REACT_APP_MAX_DOCUMENT_SIZE} MB`}
        />
        <div style={{ textAlign: 'end' }}>
          <Button onClick={handleValidateForm} text={'Send'} width={'auto'} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ThreadMessages;
