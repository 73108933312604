import React, { useState } from 'react';

import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import Box, { Item } from 'devextreme-react/box';

const SelectTemplatePop = props => {
  const { showPopup, hidePopup, popTitle, popMessage } = props;
  const [featureChanged, setFeatureChanged] = useState(false);

  return (
    <Popup
      title={popTitle}
      width={740}
      height={370}
      className="popup-template"
      visible={showPopup}
      resizeEnabled={false}
      showCloseButton={true}
      onHiding={() => {
         if (!featureChanged){
            hidePopup(featureChanged);
         }
      }}
      onShowing={() => {
        setFeatureChanged(false);
      }}
    >
      <Box id="template-form" direction='col' height='100%'>
        <Item ratio={1} width='90%'>
          <h5 className="pop-message select-template-scroll" dangerouslySetInnerHTML={{__html: popMessage}}/>
        </Item>
        <Item ratio={1}>
          <div id={'closebutton-select'}>
          <p className="warning-text">The contents of the email will be overwritten.</p>
            <div className="button-wrapper">
              <Button
                id='savebutton-right'
                width={120}
                height={50}
                text='Continue'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  setFeatureChanged(true);
                  hidePopup(true);
                }}
              />
              <Button
                width={120}
                height={50}
                text='Cancel'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  setFeatureChanged(true);
                  hidePopup(false);
                }}
              />
            </div>
          </div>
        </Item>
      </Box>
    </Popup>
  );
};

export default SelectTemplatePop;
