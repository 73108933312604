import React, { useRef, useState} from 'react';
import {Button} from "devextreme-react";
import DataGrid, {Column, Pager, SearchPanel} from 'devextreme-react/data-grid';
import paginator from "../../../modules/paginator/Paginator";
import {withRouter} from "react-router-dom";
import notify from "devextreme/ui/notify";
import {MailingGetAccounts, MailingSetDefaultAccount} from "./MailingHelper";


function AccountsList(props) {
  let dataGridRef = useRef();

  const initGrid = () => {
    return paginator(MailingGetAccounts, [], "id", 10);
  };

  const [accountsDataList, setAccountsDataList] = useState(initGrid())

  const addNewAccount = () => {
    props.history.push("/mailing/account");
  };

  const renderActionCell = (cellData) => {
    return (
      <>
        <Button
          hint={"Set default"}
          icon="check"
          height={35}
          onClick={() => {
            setDefaultAccount(cellData.data.id);
          }}
        />
        <Button
          icon="edit"
          height={35}
          onClick={() => {
            editAccount(cellData.data.id);
          }}
        />
      </>
    );
  };

  const setDefaultAccount = (accountId) => {
    MailingSetDefaultAccount(accountId)
      .then(() => {
        notify("The default account is set", "success", 2000);
        setAccountsDataList(initGrid())
      })
      .catch(() => {
        notify("An error occurred while sending data.", "error", 2000);
      });
  };

  const editAccount = (accountId) => {
    props.history.push(`/mailing/account/${accountId}`);
  };




    return (
      <React.Fragment>
        <div className="content">
          <div className={"row row-flex-space-between mt-4"}>
            <Button
              text="New Account"
              type="normal"
              stylingMode="outlined"
              height={35}
              onClick={addNewAccount}
            />
          </div>
          <div className={"mt-4"}>
            <DataGrid
              ref={dataGridRef}
              id="rep-transactions-notes-grid"
              dataSource={accountsDataList}
              columnAutoWidth={true}
              allowColumnReordering={true}
              hoverStateEnabled={true}
              allowColumnResizing={true}
              focusedRowEnabled={false}
              showBorders={true}
              wordWrapEnabled={true}
              showRowLines={true}
              showColumnHeaders={true}
              remoteOperations={true}
            >
              <SearchPanel visible={false} placeholder="Search..." />
              <Pager showPageSizeSelector={true} showInfo={true} />
              <Column dataField="id" />
              <Column
                dataField="fromName"
                encodeHtml={false}
                caption="From name"
                dataType="string"
                width={"100%"}
              />

              <Column
                dataField="fromEmail"
                encodeHtml={false}
                caption="From email"
                dataType="string"
                width={"100%"}
              />
              <Column
                dataField="isActive"
                caption="Active"
                dataType="boolean"
              />
              <Column
                dataField="isDefault"
                caption="Default"
                dataType="boolean"
              />
              <Column
                width={100}
                caption="Action"
                cellRender={renderActionCell}
              />
            </DataGrid>
          </div>
        </div>
      </React.Fragment>
    );

}

export default withRouter(AccountsList);