import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import './AdminDash';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

const AdminDashProvider = (props) => {
  const { user, selCompanyID } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [serviceProviderList, setServiceProviderList] = useState([]);
  const [serviceProviderId, setServiceProviderId] = useState(-1);
  const [serviceProvContactId, setServiceProvContactId] = useState(-1);

  useEffect(() => {
    const fetchServiceProvider = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/provider/serviceprovider',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setServiceProviderList(dataArray);
      } catch (err) {}
    };
    fetchServiceProvider();
  }, [sendRequest]);

  const stServiceProvData = new ArrayStore({
    key: 'providerid',
    data: serviceProviderList,
  });

  const dcServiceProvData = new DataSource({
    store: stServiceProvData,
  });

  const openContactEdit = () => {
    openServiceProviderEdit();
  };

  const openContactAdd = () => {
    setServiceProviderId(-1);
    setServiceProvContactId(-1);
    openServiceProviderInsert();
  };

  const openServiceProviderEdit = () => {
    user.serviceProvider = {
      svProviderId: serviceProviderId,
      svPersonId: serviceProvContactId,
      user: user,
      modeInsert: false,
    };
    history.push({
      pathname: '/serviceproviderdata',
    });
  };

  const openServiceProviderInsert = () => {
    user.serviceProvider = {
      svProviderId: -1,
      svPersonId: -1,
      user: user,
      modeInsert: true,
    };
    history.push({
      pathname: '/serviceprovideredit',
    });
  };

  return (
    <div className='sd-header item'>
      <div className='dx-fieldset-header'>Service Provider</div>
      <ResponsiveBox>
        <Col ratio={0.3} screen='md lg sm' />
        <Col ratio={3} />
        <Row />

        <Item>
          <Location screen='md lg sm' row={0} col={0} />
          <div id='hm-btn-section-11'>
            <Button
              width={120}
              height={36}
              text='Open'
              type='success'
              stylingMode='outlined'
              onClick={openContactEdit}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div>
            <DataGrid
              id='serv-provider-grid'
              key='providerid'
              height={350}
              dataSource={dcServiceProvData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  setServiceProviderId(e.row.key);
                  setServiceProvContactId(e.row.data.personid);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='providerid' visible={false}></Column>
              <Column dataField='personid' visible={false}></Column>
              <Column dataField='categoryid' visible={false}></Column>
              <Column dataField='serviceid' visible={false}></Column>
              <Column
                dataField='providername'
                caption='Service Provider'
                width={300}
                // cellRender={renderGridCell}
              />
              <Column dataField='category' caption='Category' width={200} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

export default AdminDashProvider;
