import React, {useEffect, useRef, useState} from 'react';
import {useHttpClient} from '../../hooks/http-hook';
import {Popup} from 'devextreme-react/popup';
import ResponsiveBox, {Col, Item, Location, Row} from "devextreme-react/responsive-box";
import {
    ButtonItem,
    ButtonOptions,
    Form,
    Item as FormItem,
    Label,
    RequiredRule,
    SimpleItem
} from "devextreme-react/form";
import FileUploader from 'devextreme-react/file-uploader';
import {Validator} from "devextreme-react";
import notify from "devextreme/ui/notify";
import DocExchangeHelper from "../../modules/docexchange/DocExchangeHelper";
import {toSqlDateFormat} from "../../utils/dateFormat";

const CompReqDocPop = (props) => {
    const {showPopup, hidePopup, selReqDocId, popInsert, user} = props;
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const popupRef = useRef(null);
    const noteFormRef = useRef(null);
    const uploadFileRef = useRef(null);

    const [docDetails, setDocDetails] = useState({});

    const docTemplateData = {
        doc_title: docDetails?.doc_title || "",
        docComment: docDetails?.comment || "",
        validOn: docDetails?.validon || new Date(),
        validUntil: docDetails?.validuntil || "",
        file: null
    };

    const saveRequiredDoc = async () => {
        try {
            const docData = new FormData();

            if (docTemplateData.docComment)
                docData.append("comment", docTemplateData.docComment);
            if (docTemplateData.doc_title)
                docData.append("usage_advice", docTemplateData.doc_title);
            if (docTemplateData.file) docData.append("file", docTemplateData.file);

            await DocExchangeHelper.uploadDocumentGlobal(docData);

            notify("New document was successfully added", "success", 2000);

        } catch (err) {
            notify("Error sending data", "error", 2000);
        }
    };

    const validateForm = async noteFormRef => {
        console.log(noteFormRef)
        const result = noteFormRef.validationGroup.validate();
        
        if (result.isValid) {
            await saveRequiredDoc();
            hidePopup(true);            
        } else {
            notify("Please complete all required fields", "error", 2000);
        }
    };

    const renderFileUploader = () => {
        return (
            <FileUploader
                id={"serviceCustomerFileUploader"}
                selectButtonText="Select a file"
                uploadMode="instantly"
                uploadFile={file => {
                    docTemplateData.file = file;
                }}
                ref={uploadFileRef}
            >
                <Validator validationGroup="RequiredDocData">
                    <RequiredRule message="Select a file"/>
                </Validator>
            </FileUploader>
        );
    };

    return (
        <Popup
            title="Required Document"
            ref={popupRef}
            visible={showPopup}
            height={"auto"}
            width={"650px"}
            closeOnOutsideClick={true}
            showCloseButton={true}
            resizeEnabled={true}
            onHiding={() => {
                hidePopup(true);
            }}
        >
            <ResponsiveBox>
                <Row ratio={7}/>
                <Row ratio={2}/>
                <Col ratio={1}/>
                <Item>
                    <Location screen="md lg sm xs" row={0} col={0}/>
                    <Form
                        ref={noteFormRef}
                        formData={docTemplateData}
                        labelLocation={"left"}
                        validationGroup="RequiredDocData"
                    >
                        <SimpleItem dataField='doc_title' isRequired={true} colSpan={2}>
                            <Label text='Document Title'/>
                        </SimpleItem>
                        <FormItem itemType="group" colCount={1}>
                            <SimpleItem
                                dataField="docComment"
                                editorType="dxTextArea"
                                isRequired={false}
                                editorOptions={{height: 100}}
                            >
                                <Label text="Description"/>
                            </SimpleItem>
                        </FormItem>
                        <FormItem itemType="group" colCount={1}>
                            <SimpleItem dataField="file" render={renderFileUploader}>
                                <Label text="Upload document"/>
                            </SimpleItem>
                        </FormItem>

                        <ButtonItem>
                            <ButtonOptions
                                type={"success"}
                                height={35}
                                useSubmitBehavior={false}
                                onClick={validateForm}
                            >
                                <span className="dx-button-text">Save</span>
                            </ButtonOptions>
                        </ButtonItem>
                        <ButtonItem>
                            <ButtonOptions
                                type={"default"}
                                height={35}
                                onClick={() => hidePopup(true)}
                            >
                                <span className="dx-button-text">Close</span>
                            </ButtonOptions>
                        </ButtonItem>
                    </Form>
                </Item>
            </ResponsiveBox>
        </Popup>
    );
};

export default CompReqDocPop;