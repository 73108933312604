import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./servicesedit.css";
import { useHttpClient } from "../../hooks/http-hook";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";
import { Button } from "devextreme-react/button";

import DataGrid, { Column, Editing } from "devextreme-react/data-grid";

const ServicesEditFeatures = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const { selServiceId, user } = props;

  const [serviceFeaturesData, setServiceFeaturesData] = useState([]);

  useEffect(() => {
    const fetchServiceFeatures = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/systemservicefeatures/${selServiceId}`,
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setServiceFeaturesData(dataArray);
      } catch (err) {console.log(err)}
    };
    if (selServiceId) {
      fetchServiceFeatures();
    }
  }, [sendRequest, selServiceId]);

  const stServiceFeaturesData = new ArrayStore({
    key: ["serviceid", "group_assetid", "featureid"],
    data: serviceFeaturesData,
  });

  const dcServiceFeaturesData = new DataSource({
    store: stServiceFeaturesData,
  });

  const saveFeatureUse = async (anFeatureUseData) => {
    try {
      sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/service/modsystemservicefeature",
        "POST",
        JSON.stringify({
          operation: 2,
          serviceid: anFeatureUseData.serviceid,
          featureid: anFeatureUseData.featureid,
          usefeature: anFeatureUseData.usefeature,
        }),
        { "Content-Type": "application/json", Authorization: "Bearer " + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.2} />

        <Col />

        <Item>
          <Location screen="md lg sm xs" row={0} col={0} />
          <div id="form-edit">
            <DataGrid
              id="partner-service-edit-featureGrid"
              dataSource={dcServiceFeaturesData}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              columnAutoWidth={true}
              focusedRowEnabled={true}
              onSaved={(e) => {
                if (e.changes[0]) {
                  saveFeatureUse(e.changes[0].data);
                }
              }}
            >
              <Column dataField="servicefeature_id" visible={false}></Column>
              <Column dataField="category_id" visible={false}></Column>
              <Column dataField="buysellasset" visible={false}></Column>
              <Column dataField="sendreceiveasset" visible={false}></Column>
              <Column dataField="feature" caption="Feature" width="250"></Column>
              <Column dataField="groupasset" caption="Group" width="100"></Column>
              <Column dataField="buysellasset_txt" caption="Asset" width="100px" visible={true} />
              <Column dataField="main_feature" dataType="boolean" caption="Service" width="70"></Column>
              <Column dataField="account_feature" dataType="boolean" caption="Account" width="80"></Column>
              <Column dataField="buy" dataType="boolean" caption="Buy" width="60" visible={true} />
              <Column dataField="sell" dataType="boolean" caption="Sell" width="60" visible={true} />
              <Column dataField="list" dataType="boolean" caption="List" width="60" visible={true} />
              <Column dataField="send" dataType="boolean" caption="Send" width="60" visible={true} />
              <Column dataField="receive" dataType="boolean" caption="Receive" width="70" visible={true} />
            </DataGrid>
          </div>
        </Item>
        <Item>
          <Location screen="md lg sm xs" row={1} col={0} />
          <div id={"services-edit-button"}>
            <div style={{ flexDirection: "row" }}>
              <Button
                id="services-edit_close-button"
                width={120}
                height={50}
                text="Close"
                type="normal"
                stylingMode="contained"
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ServicesEditFeatures;
