import React, { useEffect, useRef, useState, useCallback } from 'react';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import { useHttpClient } from '../../hooks/http-hook';
import './services.css';
import ArrayStore from 'devextreme/data/array_store';

import ServiceProviderEditPop from '../servicesData/ServiceProviderEditPop';
import BankaccountCreatePop from '../servicesData/BankaccountCreatePop';

const ServicesCreatePop = props => {
  const { showPopup, hidePopup, systemServiceId, user, selcontactid, selucompany, showTabs, modeInsert } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const dataFormRef = useRef(null);
  const [lpCategories, setLpCategories] = useState([]);
  const [lpProvider, setLpProvider] = useState([]);
  const [lpBankAccounts, setLpBankAccounts] = useState([]);
  const [lpCurrencies, setLpCurrencies] = useState([]);
  const [showProviderPopup, setShowProviderPopup] = useState(false);
  const [showBankPopup, setShowBankPopup] = useState(false);
  const [reloadLookupData, setReloadLookupData] = useState(false);

  const [edServiceName, setEdServiceName] = useState('');
  const [edCategoryid, setEdCategoryid] = useState(-1);
  const [edProviderid, setEdProviderid] = useState(-1);
  const [edLiquidityAcctid, setEdLiquidityAcctid] = useState(-1);
  const [edServiceAcctid, setEdServiceAcctid] = useState(-1);
  const [edCurrency, setEdCurrency] = useState('EUR');
  const [edSpcAccountid, setEdSpcAccountid] = useState(-1);

  const [spcAccounts,setSpcAccounts] = useState([]);

  const fetchCategories = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/service/servicecat', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpCategories(dataArray);
    } catch (err) {}
  };

  const fetchProvider = async () => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/provider/serviceproviderlp',
        'GET',
        null,
        { Authorization: 'Bearer ' + user.token }
      );
      setLpProvider(dataArray);
    } catch (err) {}
  };

  const fetchBankAccounts = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/bankaccountslp', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpBankAccounts(dataArray);
    } catch (err) {}
  };

  const fetchCurrencies = async () => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/21',
        'GET',
        null,
        { Authorization: 'Bearer ' + user.token }
      );
      setLpCurrencies(dataArray);
    } catch (err) {}
  };

  const fetchSpecAccounts = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/forex/forexbaseaccounts', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setSpcAccounts(dataArray);
    } catch (err) {}
  };

  useEffect(() => {
    if (showPopup) {
      fetchCategories();
      fetchProvider();
      fetchBankAccounts();
      fetchCurrencies();
      fetchSpecAccounts();
    }
  }, [sendRequest, showPopup, reloadLookupData]);

  const stLpCategoriesData = new ArrayStore({
    key: 'category_id',
    data: lpCategories,
  });

  const stLpProviderData = new ArrayStore({
    key: 'providerid',
    data: lpProvider,
  });

  const stlpBankAccountsData = new ArrayStore({
    key: 'bankaccountid',
    data: lpBankAccounts,
  });

  const stlpCurrencies = new ArrayStore({
    key: 'short_id',
    data: lpCurrencies,
  });

  const stSpcAccounts = new ArrayStore({
    key: 'baseaccountid',
    data: spcAccounts,
  });


  const system_service_FormData = {
    serviceid: -1,
    categoryid: edCategoryid,
    providerid: edProviderid,
    servicename: edServiceName,
    account_liquidity_id: edLiquidityAcctid,
    account_service_provider_id: edServiceAcctid,
    currency: edCurrency,
    spc_accountid: edSpcAccountid,
  };

  const save_temp_data = () => {
    setEdServiceName(system_service_FormData.servicename);
    setEdCategoryid(system_service_FormData.categoryid);
    setEdProviderid(system_service_FormData.providerid);
    setEdLiquidityAcctid(system_service_FormData.account_liquidity_id);
    setEdServiceAcctid(system_service_FormData.account_service_provider_id);
    setEdCurrency(system_service_FormData.currency);
    setEdSpcAccountid(system_service_FormData.spc_accountid);
  };

  const CreateService = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/service/systemservice',
        'POST',
        JSON.stringify({
          operation: 1,
          serviceid: system_service_FormData.serviceid,
          categoryid: system_service_FormData.categoryid,
          providerid: system_service_FormData.providerid,
          servicename: system_service_FormData.servicename,
          account_liquidity_id: system_service_FormData.account_liquidity_id,
          account_service_provider_id: system_service_FormData.account_service_provider_id,
          currency: system_service_FormData.currency,
          spc_accountid: system_service_FormData.spc_accountid,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async dataFormRef => {
    const result = dataFormRef.validationGroup.validate();
    if (result.isValid) {
      await CreateService();
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  function navigateToHome() {
    hidePopup(true);
  }

  const hideBankAccountPopup = useCallback(doReload => {
    setShowBankPopup(false);
    if (doReload) {
      setReloadLookupData(!reloadLookupData);
    }
  });

  const showServiceProviderAdd = () => {
    save_temp_data();
    setShowProviderPopup(true);
  };

  const showBankAccountPopup = () => {
    save_temp_data();
    setShowBankPopup(true);
  };

  const hideProviderPopup = () => {
    setShowProviderPopup(false);
    setReloadLookupData(!reloadLookupData);
  };

  return (
    <React.Fragment>
      <ServiceProviderEditPop
        showPopup={showProviderPopup}
        hidePopup={hideProviderPopup}
        svProviderId={-1}
        svPersonId={-1}
        modeInsert={true}
      />
      <BankaccountCreatePop
        showPopup={showBankPopup}
        hidePopup={hideBankAccountPopup}
        svProviderId={-1}
        svPersonId={-1}
        modeInsert={true}
      />

      <Popup
        title={'Add System Service'}
        ref={popupRef}
        width='1024'
        height='600'
        visible={showPopup}
        resizeEnabled={true}
        showCloseButton={true}
        onHiding={() => {
          hidePopup(true);
        }}
        //    onShowing={() => {
        //      setContactsChanged(false);
        //    }}
      >
        <ResponsiveBox>
          <Row ratio={10} />
          <Row ratio={0.2} />
          <Col />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div id='form-edit'>
              <Form
                id={'form'}
                ref={dataFormRef}
                formData={system_service_FormData}
                labelLocation={'top'}
                colCountByScreen={colCountByScreen}
                validationGroup='Service_Add_Data'
              >
                <Item itemType='group' colCount={3}>
                  <SimpleItem dataField='servicename' isRequired={true} editorOptions={baseEditorOptions}>
                    <RequiredRule message='Please enter a Service Name' />
                    <Label text='Name' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='categoryid'
                    editorType='dxSelectBox'
                    name='category_listbox'
                    isRequired={true}
                    editorOptions={{
                      dataSource: stLpCategoriesData,
                      valueExpr: 'category_id',
                      displayExpr: 'category_name',
                      searchEnabled: true,
                      
                    }}
                  >
                    <RequiredRule message='Please select the Service Category' />
                    <Label text='Category' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='spc_accountid'
                    editorType='dxSelectBox'
                    name='spc_accpuntid_listbox'
                    isRequired={false}
                    editorOptions={{
                      dataSource: stSpcAccounts,
                      valueExpr: 'baseaccountid',
                      displayExpr: 'baseaccountname',
                      searchEnabled: true,
                      
                    }}
                  >
                    <Label text='Extension' />
                  </SimpleItem>
                </Item>
                <EmptyItem />

                <Item itemType='group' colCount={6}>
                  <SimpleItem
                    dataField='providerid'
                    editorType='dxSelectBox'
                    colSpan={2}
                    name='provider_listbox'
                    isRequired={true}
                    editorOptions={{
                      dataSource: stLpProviderData,
                      valueExpr: 'providerid',
                      displayExpr: 'providername',
                      searchEnabled: true,
                      
                    }}
                  >
                    <RequiredRule message='Please select the Service Service Provider' />
                    <Label text='Service Provider' />
                  </SimpleItem>
                  <ButtonItem
                    horizontalAlignment='left'
                    verticalAlignment='center'
                    colSpan={1}
                    buttonOptions={{
                      text: 'Add',
                      width: 100,
                      height: 36,
                      type: 'default',
                      stylingMode: 'outlined',
                      onClick: () => {
                        showServiceProviderAdd();
                      },
                    }}
                  />
                </Item>
                <EmptyItem />

                <Item itemType='group' colCount={6}>
                  <SimpleItem
                    dataField='account_liquidity_id'
                    editorType='dxSelectBox'
                    colSpan={2}
                    name='LqAccount_listbox'
                    isRequired={true}
                    editorOptions={{
                      dataSource: stlpBankAccountsData,
                      valueExpr: 'bankaccountid',
                      displayExpr: 'accountname',
                      searchEnabled: true,
                      
                    }}
                  >
                    <RequiredRule message='Please select the Service Liquidity Bank Account' />
                    <Label text='Liquidity Account' />
                  </SimpleItem>
                  <ButtonItem
                    horizontalAlignment='left'
                    verticalAlignment='center'
                    colSpan={1}
                    buttonOptions={{
                      text: 'Add',
                      width: 100,
                      height: 36,
                      type: 'default',
                      stylingMode: 'outlined',
                      onClick: () => {
                        showBankAccountPopup();
                      },
                    }}
                  />

                  <SimpleItem
                    dataField='account_service_provider_id'
                    editorType='dxSelectBox'
                    colSpan={2}
                    name='SrvAccount_listbox'
                    isRequired={true}
                    editorOptions={{
                      dataSource: stlpBankAccountsData,
                      valueExpr: 'bankaccountid',
                      displayExpr: 'accountname',
                      searchEnabled: true,
                      
                    }}
                  >
                    <RequiredRule message='Please select the Service Bank Account' />
                    <Label text='Service Account' />
                  </SimpleItem>
                  <ButtonItem
                    horizontalAlignment='left'
                    verticalAlignment='center'
                    colSpan={1}
                    buttonOptions={{
                      text: 'Add',
                      width: 100,
                      height: 36,
                      type: 'default',
                      stylingMode: 'outlined',
                      onClick: () => {
                        showBankAccountPopup();
                      },
                    }}
                  />
                </Item>
                <EmptyItem />
                <Item itemType='group' colCount={6}>
                  <SimpleItem
                    dataField='currency'
                    editorType='dxSelectBox'
                    colSpan={1}
                    name='currency_listbox'
                    isRequired={true}
                    editorOptions={{
                      dataSource: stlpCurrencies,
                      valueExpr: 'short_id',
                      displayExpr: 'lp_value',
                      searchEnabled: true,
                      
                    }}
                  >
                    <RequiredRule message='Please select the Service Base Currency' />
                    <Label text='Service Base Currency' />
                  </SimpleItem>
                </Item>
              </Form>
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={1} col={0} />
            <div id={'contactpopbutton'}>
              <div style={{ flexDirection: 'row' }}>
                <Button
                  id='savebutton'
                  width={120}
                  height={50}
                  text='Save'
                  type='default'
                  stylingMode='contained'
                  validationGroup='Service_Add_Data'
                  useSubmitBehavior={true}
                  onClick={validateForm}
                />
                <Button
                  width={120}
                  height={50}
                  text='Close'
                  type='normal'
                  stylingMode='contained'
                  onClick={navigateToHome}
                />
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      </Popup>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ServicesCreatePop;
