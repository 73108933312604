import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';

import './ServicesData.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ArrayStore from 'devextreme/data/array_store';

import ServiceProviderEditPop from './ServiceProviderEditPop';
import BankaccountCreatePop from './BankaccountCreatePop';

const ServicesDataSetup = (props) => {
  const history = useHistory();
  const { systemServiceId, modeInsert, user } = props;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const dataFormRef = useRef(null);
  const [serviceData, setServiceData] = useState({});
  const [lpCategories, setLpCategories] = useState([]);
  const [lpProvider, setLpProvider] = useState([]);
  const [lpBankAccounts, setLpBankAccounts] = useState([]);
  const [lpCurrencies, setLpCurrencies] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [reloadLookupData, setReloadLookupData] = useState(false);
  const [showBankPopup, setShowBankPopup] = useState(false);
  const [extAccounts, setExtAccounts] = useState([]);

  const fetchSystemService = async () => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/service/systemservice/${systemServiceId}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + user.token }
      );
      setServiceData(dataArray[0]);
    } catch (err) {}
  };

  const fetchCategories = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/service/servicecat', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpCategories(dataArray);
    } catch (err) {}
  };

  const fetchProvider = async () => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/provider/serviceproviderlp',
        'GET',
        null,
        { Authorization: 'Bearer ' + user.token }
      );
      setLpProvider(dataArray);
    } catch (err) {}
  };

  const fetchBankAccounts = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/bankaccountslp', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpBankAccounts(dataArray);
    } catch (err) {}
  };

  const fetchCurrencies = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/21', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpCurrencies(dataArray);
    } catch (err) {}
  };

  const fetchExtAccounts = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/forex/forexbaseaccounts', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setExtAccounts(dataArray);
    } catch (err) {}
  };

  useEffect(() => {
    if (systemServiceId) {
      fetchSystemService();
    }
  }, [sendRequest, systemServiceId]);

  useEffect(() => {
    fetchCategories();
    fetchProvider();
    fetchBankAccounts();
    fetchCurrencies();
    fetchExtAccounts();
  }, [sendRequest, reloadLookupData]);

  const stLpCategoriesData = new ArrayStore({
    key: 'category_id',
    data: lpCategories,
  });

  const stLpProviderData = new ArrayStore({
    key: 'providerid',
    data: lpProvider,
  });

  const stlpBankAccountsData = new ArrayStore({
    key: 'bankaccountid',
    data: lpBankAccounts,
  });

  const stlpCurrencies = new ArrayStore({
    key: 'short_id',
    data: lpCurrencies,
  });

  const stExtAccounts = new ArrayStore({
    key: 'baseaccountid',
    data: extAccounts,
  });

  const system_service_FormData = {
    serviceid: (serviceData && serviceData.serviceid) || -1,
    categoryid: (serviceData && serviceData.categoryid) || -1,
    providerid: (serviceData && serviceData.providerid) || -1,
    servicename: (serviceData && serviceData.servicename) || '',
    account_liquidity_id: (serviceData && serviceData.account_liquidity_id) || -1,
    account_service_provider_id: (serviceData && serviceData.account_service_provider_id) || -1,
    currency: (serviceData && serviceData.currency) || 'EUR',
    spc_accountid: (serviceData && serviceData.spc_accountid) || -1,
  };

  function navigateToHome() {
    history.goBack();
  }

  const modifyService = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/service/systemservice',
        'POST',
        JSON.stringify({
          operation: modeInsert ? 1 : 2,
          serviceid: system_service_FormData.serviceid,
          categoryid: system_service_FormData.categoryid,
          providerid: system_service_FormData.providerid,
          servicename: system_service_FormData.servicename,
          account_liquidity_id: system_service_FormData.account_liquidity_id,
          account_service_provider_id: system_service_FormData.account_service_provider_id,
          currency: system_service_FormData.currency,
          spc_accountid: system_service_FormData.spc_accountid,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async (dataFormRef) => {
    const result = dataFormRef.validationGroup.validate();
    if (result.isValid) {
      await modifyService();
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  const showServiceProviderAdd = () => {
    setShowPopup(true);
  };

  const hidePopup = useCallback((doReload) => {
    setShowPopup(false);
    if (doReload) {
      setReloadLookupData(!reloadLookupData);
    }
  });

  const showBankAccountPopup = () => {
    setShowBankPopup(true);
  };

  const hideBankAccountPopup = useCallback((doReload) => {
    setShowBankPopup(false);
    if (doReload) {
      setReloadLookupData(!reloadLookupData);
    }
  });

  return (
    <React.Fragment>
      <ServiceProviderEditPop
        showPopup={showPopup}
        hidePopup={hidePopup}
        svProviderId={-1}
        svPersonId={-1}
        modeInsert={true}
        user={user}
      />
      <BankaccountCreatePop
        showPopup={showBankPopup}
        hidePopup={hideBankAccountPopup}
        svProviderId={-1}
        svPersonId={-1}
        modeInsert={true}
        user={user}
      />
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Service</div>

        <ResponsiveBox>
          <Row ratio={10} />
          <Row ratio={0.2} />
          <Col />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div>
              <Form
                ref={dataFormRef}
                formData={system_service_FormData}
                labelLocation={'top'}
                colCountByScreen={colCountByScreen}
                validationGroup='Service_Edit_Data'
              >
                <Item itemType='group' colCount={12}>
                  <SimpleItem dataField='servicename' colSpan={6} isRequired={true} editorOptions={baseEditorOptions}>
                    <RequiredRule message='Please enter a Service Name' />
                    <Label text='Name' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='categoryid'
                    editorType='dxSelectBox'
                    name='category_listbox'
                    colSpan={4}
                    isRequired={true}
                    editorOptions={{
                      dataSource: stLpCategoriesData,
                      valueExpr: 'category_id',
                      displayExpr: 'category_name',
                      searchEnabled: true,
                    }}
                  >
                    <RequiredRule message='Please select the Service Category' />
                    <Label text='Category' />
                  </SimpleItem>
                </Item>

                <Item itemType='group' colCount={12}>
                  <SimpleItem
                    dataField='providerid'
                    editorType='dxSelectBox'
                    colSpan={6}
                    name='provider_listbox'
                    isRequired={true}
                    editorOptions={{
                      dataSource: stLpProviderData,
                      valueExpr: 'providerid',
                      displayExpr: 'providername',
                      searchEnabled: true,
                    }}
                  >
                    <RequiredRule message='Please select the Service Service Provider' />
                    <Label text='Provider' />
                  </SimpleItem>
                  <ButtonItem
                    horizontalAlignment='left'
                    verticalAlignment='bottom'
                    colSpan={3}
                    buttonOptions={{
                      text: 'Add',
                      width: 100,
                      height: 36,
                      type: 'normal',
                      stylingMode: 'outlined',
                      onClick: () => {
                        showServiceProviderAdd();
                      },
                    }}
                  />
                  <SimpleItem
                    dataField='currency'
                    editorType='dxSelectBox'
                    colSpan={3}
                    name='currency_listbox'
                    isRequired={true}
                    editorOptions={{
                      dataSource: stlpCurrencies,
                      valueExpr: 'short_id',
                      displayExpr: 'lp_value',
                      searchEnabled: true,
                    }}
                  >
                    <RequiredRule message='Please select the Service Base Currency' />
                    <Label text='Currency' />
                  </SimpleItem>
                </Item>
                <Item itemType='group' colCount={12}>
                  <SimpleItem
                    dataField='spc_accountid'
                    editorType='dxSelectBox'
                    colSpan={6}
                    name='SpcAccount_listbox'
                    editorOptions={{
                      dataSource: stExtAccounts,
                      valueExpr: 'baseaccountid',
                      displayExpr: 'baseaccountname',
                      searchEnabled: false,
                    }}
                  >
                    <Label text='Extended Settings' />
                  </SimpleItem>
                </Item>
                <EmptyItem />

                <Item itemType='group' colCount={12}>
                  <SimpleItem
                    dataField='account_liquidity_id'
                    editorType='dxSelectBox'
                    colSpan={6}
                    name='LqAccount_listbox'
                    isRequired={true}
                    editorOptions={{
                      dataSource: stlpBankAccountsData,
                      valueExpr: 'bankaccountid',
                      displayExpr: 'accountname',
                      searchEnabled: true,
                    }}
                  >
                    <RequiredRule message='Please select the Service Liquidity Bank Account' />
                    <Label text='Liquidity Account' />
                  </SimpleItem>
                  <ButtonItem
                    horizontalAlignment='left'
                    verticalAlignment='bottom'
                    colSpan={3}
                    buttonOptions={{
                      text: 'Add',
                      width: 100,
                      height: 36,
                      type: 'normal',
                      stylingMode: 'outlined',
                      onClick: () => {
                        showBankAccountPopup();
                      },
                    }}
                  />
                </Item>
                <Item itemType='group' colCount={12}>
                  <SimpleItem
                    dataField='account_service_provider_id'
                    editorType='dxSelectBox'
                    colSpan={6}
                    name='SrvAccount_listbox'
                    isRequired={true}
                    editorOptions={{
                      dataSource: stlpBankAccountsData,
                      valueExpr: 'bankaccountid',
                      displayExpr: 'accountname',
                      searchEnabled: true,
                    }}
                  >
                    <RequiredRule message='Please select the Service Bank Account' />
                    <Label text='Service Account' />
                  </SimpleItem>
                  <ButtonItem
                    horizontalAlignment='left'
                    verticalAlignment='bottom'
                    colSpan={3}
                    buttonOptions={{
                      text: 'Add',
                      width: 100,
                      height: 36,
                      type: 'normal',
                      stylingMode: 'outlined',
                      onClick: () => {
                        showBankAccountPopup();
                      },
                    }}
                  />
                </Item>
              </Form>
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={1} col={0} />
            <div id={'services-edit-button'}>
              <div style={{ flexDirection: 'row' }}>
                <Button
                  id='savebutton'
                  width={100}
                  height={36}
                  text='Save'
                  type='default'
                  stylingMode='outlined'
                  validationGroup='Service_Edit_Data'
                  useSubmitBehavior={true}
                  onClick={validateForm}
                />
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ServicesDataSetup;
