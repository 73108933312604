import React, { useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';

import DataGrid, { Column, Pager, Scrolling } from 'devextreme-react/data-grid';

import InquiryDocRequestPopup from './InquiryDocRequestPopup';
import DocExchangeHelper from '../docexchange/DocExchangeHelper';
import paginator from '../paginator/Paginator';
import notify from 'devextreme/ui/notify';

function InquiryDocRequest(props) {
  const { selServiceId } = props;

  const [serviceReqDocData, setServiceReqDocData] = useState();
  const [serviceRequiredDocsId, setServiceRequiredDocsId] = useState(-1);
  const [showPopup, setShowPopup] = useState(false);
  const [reloadDocData, setReloadDocData] = useState(false);
  const [isDetails, setIsDetails] = useState(false);

  useEffect(() => {
    try {
      setServiceReqDocData(paginator(DocExchangeHelper.getServiceDocuments, [selServiceId], 'id', 5));
    } catch (err) {
      notify('Data loading error', 'error', 2000);
    }
  }, [selServiceId, reloadDocData]);

  const addCustomerDoc = () => {
    setReloadDocData(false);
    setIsDetails(false);
    setShowPopup(true);
  };

  const hidePopup = () => {
    setShowPopup(false);
  };

  const renderEditCell = cellData => {
    return (
      <Button
        id='btn-doc'
        text={'Details'}
        width={'auto'}
        onClick={() => {
          setReloadDocData(false);
          setIsDetails(true);
          setServiceRequiredDocsId(cellData.data.id);
          setShowPopup(true);
        }}
      />
    );
  };

  return (
    <React.Fragment>
      {showPopup && (
        <InquiryDocRequestPopup
          hidePopup={hidePopup}
          selServiceId={selServiceId}
          selReqDocId={serviceRequiredDocsId}
          isDetails={isDetails}
          doReload={setReloadDocData}
        />
      )}
      <div className={'text-left ml-2 mt-4 mb-1'}>
        <Button
          id='btn-addNewDoc'
          text='Add new document'
          type='normal'
          stylingMode='outlined'
          onClick={addCustomerDoc}
        />
      </div>
      <DataGrid
        id='grid-serviceReqDoc'
        dataSource={serviceReqDocData}
        showBorders={true}
        showRowLines={true}
        showColumnLines={true}
        columnAutoWidth={true}
        hoverStateEnabled={true}
        focusedRowEnabled={false}
        remoteOperations={true}
      >
        <Pager showPageSizeSelector={true} showInfo={true} />
        <Scrolling showScrollbar={true} />
        <Column dataField='serviceid' visible={false} />
        <Column dataField='id' visible={false} />
        <Column dataField='document_type_description' caption='Doc type' width='100%' />
        <Column dataField='usage_advice' caption='Usage advice' width='200px' />
        <Column dataField='comment' caption='Comment' width='200px' />
        <Column dataField='file_name' caption='File name' width='200px' />
        <Column dataField='validon' dataType='date' width='100px' format='dd.MM.yyyy' />
        <Column dataField='validuntil' dataType='date' width='100px' format='dd.MM.yyyy' />
        <Column width={150} alignment='center' cellRender={renderEditCell} />
      </DataGrid>
    </React.Fragment>
  );
}

export default InquiryDocRequest;
