import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useAuth } from '../../contexts/auth';
import './services.css';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import {
  Form,
  SimpleItem,
  EmptyItem,
  RequiredRule,
  ButtonItem,
} from 'devextreme-react/form';

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from 'devextreme-react/responsive-box';

import { Tabs, MultiView, SelectBox } from 'devextreme-react';
import { Label } from 'devextreme-react/data-grid';

import ProductsTab from './ProductsTab';
import ProductServices from './ProductServices';
import ProductRatePlan from './ProductRatePlan';
import ProductRegions from './ProductRegions';
import ProductCompliance from './ProductCompliance';
import ProductStatus from './ProductStatus';

const ProductsPage = () => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [contactPersonid, setContactPersonid] = useState();
  const [userCompanies, setUserCompanies] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const getPersonid = useCallback(fPersonid => {
    setContactPersonid(fPersonid);
  });

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={2} />
            <Row ratio={0.7} />
            <Col />

            <Item>
              <Location screen='md lg' row={0} col={0} colspan={2} />
              <Location screen='sm' row={0} col={0} colspan={1} />
              <Location screen='xs' row={0} col={0} />
              <div className='rb-header item'>
                <ResponsiveBox>
                  <Row />
                  <Col ratio={3} />
                  <Col ration={1} />
                  <Item>
                    <Location screen='md lg sm' row={0} col={0} />
                    <div className='dx-fieldset-header' id='cap_item'>
                      Products of "{user.managedpartner_name}"
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={1} />
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg sm' row={1} col={0} />
              <Location screen='xs' row={1} col={0} />
              <div className='rb-content item'>
                <ResponsiveBox>
                  <Row ratio={1} />
                  <Row ratio={1} />
                  <Col ratio={1} />
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={0} />
                    <div>
                      <MultiView height={370} animationEnabled={false}>
                        <Item title='Products'>
                          <ProductsTab
                            passToParent={getPersonid}
                            user={user}
                            partnerid={user.managedpartner}
                          />
                        </Item>
                      </MultiView>
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={1} col={0} />
                    <ResponsiveBox>
                      <Col ratio={0.33} screen='md lg sm' />
                      <Col ratio={3} />
                      <Row />
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={0} />
                      </Item>
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={1} />
                        <Tabs
                          selectedIndex={tabIndex}
                          width='99%'
                          // height='20px'
                          visible={true}
                          onItemClick={e => {
                            setTabIndex(e.itemIndex);
                          }}
                        >
                          <Item text='Details' />
                          <Item text='Rate Plan' />
                          <Item text='Regions' />
                          <Item text='Compliance Requirements' />
                          <Item text='Status' />
                        </Tabs>
                        <MultiView
                          height={370}
                          animationEnabled={false}
                          selectedIndex={tabIndex}
                        >
                          <Item title='Details'>
                            <ProductServices
                              selTabIndex={tabIndex}
                              selProductID={contactPersonid}
                              user={user}
                            />
                          </Item>
                          <Item title='Rate Plan'>
                            <ProductRatePlan
                              tabVisibleIndex={tabIndex}
                              caseDetails={{ contactid: contactPersonid }}
                              user={user}
                            />
                          </Item>
                          <Item title='Regions'>
                            <ProductRegions
                              tabVisibleIndex={tabIndex}
                              caseDetails={{ contactid: contactPersonid }}
                              user={user}
                            />
                          </Item>
                          <Item title='Compliance Requirements'>
                            <ProductCompliance
                              tabVisibleIndex={tabIndex}
                              caseDetails={{ contactid: contactPersonid }}
                              user={user}
                            />
                          </Item>
                          <Item title='Status'>
                            <ProductStatus
                              tabVisibleIndex={tabIndex}
                              caseDetails={{ contactid: contactPersonid }}
                              user={user}
                            />
                          </Item>
                        </MultiView>
                      </Item>
                    </ResponsiveBox>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg' row={2} col={0} colspan={2} />
              <Location screen='sm' row={2} col={0} colspan={1} />
              <Location screen='xs' row={2} col={0} />
              <div className='rb-footer item' id='cap_item'></div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductsPage;
