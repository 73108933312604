import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import './PartnerData.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

const PartnerDataServices = props => {
  const { user, selPartnerId, selRoutingId, partnername } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [servicesList, setServicesList] = useState([]);
  const [sysServiceID, setSysServiceID] = useState(-1);
  const [selServiceData, setSelServiceData] = useState({});

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/partnersystemservices/${selPartnerId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setServicesList(dataArray);
      } catch (err) {}
    };
    if (selPartnerId) {
      fetchServices();
    }
  }, [sendRequest, selPartnerId]);

  const stServicesData = new ArrayStore({
    key: 'serviceid',
    data: servicesList,
  });

  const dcServicesData = new DataSource({
    store: stServicesData,
  });

  const openServicesEdit = () => {
    user.service = {
      partnerId: selPartnerId,
      partnerName: partnername,
      routingId: selRoutingId,
      serviceId: sysServiceID,
      serviceName: selServiceData.servicename,
      providerId: selServiceData.providerid,
      categoryId: selServiceData.categoryid,
      account_Liquidity_Id: selServiceData.account_liquidity_id,
      account_Service_Provider_Id: selServiceData.account_service_provider_id,
      user: user,
      modeInsert: false,
    };
    history.push({
      pathname: '/partnereditservices_edit',
    });
  };

  return (
    <div className='sd-header item'>
    <div className='dx-fieldset-header'>Services</div>

      <ResponsiveBox>
        <Col ratio={2}  />
        <Col ratio={9} />
        <Row />

        <Item>
          <Location screen='md lg sm' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <Button width={120} height={36} text='Open' type='default' stylingMode='outlined' onClick={openServicesEdit} />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div>
            <DataGrid
              id='service-serviceoverview-grid'
              height={450}
              dataSource={dcServicesData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={e => {
                if (e.row) {
                  setSysServiceID(e.row.key);
                  setSelServiceData(e.row.data);
                  //  console.log(e.row.key);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='serviceid' visible={false}></Column>
              <Column dataField='categoryid' visible={false}></Column>
              <Column dataField='providerid' visible={false}></Column>
              <Column dataField='servicename' caption='Service' width={180} />
              <Column dataField='category' caption='Category' width={120} />
              <Column dataField='providername' caption='Provider' width={180} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

export default PartnerDataServices;
