import React, { useState, useEffect, useCallback } from "react";
import { useHttpClient } from "../../hooks/http-hook";

import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";

const ClientsBase = (props) => {
  const { tabVisibleIndex, selSignupClientid, user, reloadNewsGridCondition } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [signupClientDatalist, setSignupClientDatalist] = useState({});

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/clientsignupdata/${selSignupClientid}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setSignupClientDatalist(dataArray[0]);
      } catch (err) {}
    };
    if (selSignupClientid) {
      fetchSignupClientData();
    }
  }, [sendRequest, selSignupClientid]);

  return (
    <React.Fragment>
      <div id="client-base-box">
        <ResponsiveBox>
          <Row ratio={1} />
          <Col ratio={1} />

          <Item>
            <Location screen="md lg sm xs" row={0} col={0} />
            
            <div id="client-data-name">
            <div className="rb-header item">
              <div className="dx-fieldset-header" id="cap_item">
                <span>
                  {" "}
                  {signupClientDatalist && signupClientDatalist.cl_salutation}{" "}
                  {signupClientDatalist && signupClientDatalist.cl_firstname}{" "}
                  {signupClientDatalist && signupClientDatalist.cl_lastname}
                </span>
              </div>
            </div>
            </div>

            <div className="note-details-wrapper">
              <div className="note-details-personally">
                <div className="note-details-personally-colorcode">
                  <div className="note-details-personally-block">
                    <div className="note-details-personally-block-infoText">Residence:</div>
                    <div className="note-details-personally-block-outputText">
                      <span>{signupClientDatalist && signupClientDatalist.cl_street}</span>
                      <br />
                      <span>{signupClientDatalist && signupClientDatalist.cl_plc}</span>{" "}
                      <span>{signupClientDatalist && signupClientDatalist.cl_city}</span>
                      <br />
                      <span>{signupClientDatalist && signupClientDatalist.cl_countryofresidence}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="note-details-wrapper">
              <div className="note-details-personally">
                <div className="note-details-personally-colorcode">
                  <div className="note-details-personally-block">
                    <div className="note-details-personally-block-infoText">E-Mail:</div>
                    <div className="note-details-personally-block-outputText-verified">
                      {signupClientDatalist && signupClientDatalist.cl_email_verified ? "verified" : "not verified"}
                    </div>
                    <div className="note-details-personally-block-outputText">
                      {signupClientDatalist && signupClientDatalist.cl_email}
                    </div>
                  </div>

                  <div className="note-details-personally-block">
                    <div className="note-details-personally-block-infoText">Phone:</div>
                    <div className="note-details-personally-block-outputText-verified">
                      {signupClientDatalist && signupClientDatalist.cl_mobilephone_verified
                        ? "verified"
                        : "not verified"}
                    </div>
                    <div className="note-details-personally-block-outputText">
                      {signupClientDatalist && signupClientDatalist.cl_mobilephone}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="note-details-wrapper">
              <div className="note-details-personally">
                <div className="note-details-personally-colorcode">
                  <div className="note-details-personally-block">
                    <div className="note-details-personally-block-infoText">Company:</div>
                    <div className="note-details-personally-block-outputText">
                      {signupClientDatalist && signupClientDatalist.cy_name}
                    </div>
                  </div>

                  <div className="note-details-personally-block">
                    <div className="note-details-personally-block-infoText">Registered in:</div>
                    <div className="note-details-personally-block-outputText">
                      {signupClientDatalist && signupClientDatalist.cy_regin_country}
                    </div>
                  </div>

                  <div className="note-details-personally-block">
                    <div className="note-details-personally-block-infoText">Address:</div>
                    <div className="note-details-personally-block-outputText">
                      <span>{signupClientDatalist && signupClientDatalist.cy_street}</span>
                      <br />
                      <span>{signupClientDatalist && signupClientDatalist.cy_plc}</span>{" "}
                      <span>{signupClientDatalist && signupClientDatalist.cy_city}</span>
                      <br />
                      <span>{signupClientDatalist && signupClientDatalist.cy_country}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="note-details-wrapper">
              <div className="note-details-account">
                <div className="note-details-account-colorcode">
                  <div className="note-details-account-block">
                    <div className="note-details-account-block-infoText">Signup Started:</div>
                    <div className="note-details-account-block-outputText">
                      {signupClientDatalist &&
                        signupClientDatalist.signupdate &&
                        signupClientDatalist &&
                        signupClientDatalist.signupdate.slice(0, 10)}
                      {", "}
                      {signupClientDatalist &&
                        signupClientDatalist.signupdate &&
                        signupClientDatalist &&
                        signupClientDatalist.signupdate.slice(11, 16)}
                    </div>
                  </div>

                  <div className="note-details-account-block">
                    <div className="note-details-account-block-infoText">KYC:</div>
                    <div className="note-details-account-block-outputText">
                      {signupClientDatalist && signupClientDatalist.gp_passed ? "Passed " : ""}
                      {signupClientDatalist && signupClientDatalist.gp_failed
                        ? "Failed " + signupClientDatalist && signupClientDatalist.gp_failed_reason
                        : ""}
                    </div>
                  </div>
                  <div className="note-details-account-block">
                    <div className="note-details-account-block-infoText">Risk Level:</div>
                    <div className="note-details-account-block-outputText">
                      {signupClientDatalist && signupClientDatalist.risklevel}
                    </div>
                  </div>
                  <div className="note-details-account-block">
                    <div className="note-details-account-block-infoText">Product:</div>
                    <div className="note-details-account-block-outputText">
                      {signupClientDatalist && signupClientDatalist.productname}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default ClientsBase;
