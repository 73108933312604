import React, { useState, useEffect, useCallback } from "react";
import { useHttpClient } from "../../hooks/http-hook";

import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";
import Button from "devextreme-react/button";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, { Scrolling, Column, FilterRow } from "devextreme-react/data-grid";

import CasesNotesToPop from "../operations/CasesNotesToPop";
import ClientAccountUsersPop from "../clientsapproved/ClientAccountUsersPop";

const ClientUsers = (props) => {
  const { tabVisibleIndex, selSignupClientid, user, reloadNewsGridCondition } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [signupClientDatalist, setSignupClientDatalist] = useState({});

  const [showCasesNotesTo, setShowCasesNotesTo] = useState(false);
  const [noteEntryId, setNoteEntryId] = useState(-1);
  const [noteCaseEntryid, setNoteCaseEntryid] = useState(-1);
  const [noteCaseSituation, setNoteCaseSituation] = useState(-1);
  const [contactPortalid, setContactPortalid] = useState(-1);
  const [contactClient, setContactClient] = useState(false);
  const [contactClientid, setContactClientid] = useState(-1);
  const [contactClientMail, setContactClientMail] = useState("");
  const [clientInvitedData, setClientInvitedData] = useState([]);
  const [clientConnectedData, setClientConnectedData] = useState([]);
  const [showClientAccountUsersPop, setShowClientAccountUsersPop] = useState(false);
  const [caseSituationAction, setCaseSituationAction] = useState(false);

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/clientsignupdata/${selSignupClientid}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setSignupClientDatalist(dataArray[0]);

        const dataArrayTwo = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/invitedusers/${selSignupClientid}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setClientInvitedData(dataArrayTwo);

        const dataArrayThree = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/connectedusers/${selSignupClientid}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setClientConnectedData(dataArrayThree);
      } catch (err) {}
    };
    if (selSignupClientid) {
      fetchSignupClientData();
    }
  }, [sendRequest, selSignupClientid]);

  const stClientInvitedData = new ArrayStore({
    key: "inviteid",
    data: clientInvitedData,
  });

  const dcClientInvitedData = new DataSource({
    store: stClientInvitedData,
  });

  const stClientConnectedData = new ArrayStore({
    key: "inviteid",
    data: clientConnectedData,
  });

  const dcClientConnectedData = new DataSource({
    store: stClientConnectedData,
  });

  const contactSelectedClient = async () => {
    setContactClientid(signupClientDatalist.signupid);
    setCaseSituationAction(false);
    setNoteCaseSituation(16);
    setContactPortalid(signupClientDatalist.portalid);
    setContactClient(true);
    setShowCasesNotesTo(true);
  };

  const contactClientMerchant = () => {
    setContactClient(false);
    setContactClientid(signupClientDatalist.clientid);
    setContactPortalid(signupClientDatalist.portalid);
    setNoteCaseSituation(17);
    setShowCasesNotesTo(true);
  };

  const contactClientProvider = async () => {
    setContactClient(false);
    setContactClientid(signupClientDatalist.signupid);
    setContactPortalid(-10);
    setNoteCaseSituation(18);
    setShowCasesNotesTo(true);
  };

  const hideCasesNotesTo = async () => {
    await setShowCasesNotesTo(false);
    await setNoteEntryId(-1);
    await setNoteCaseEntryid(-1);
    await setContactPortalid(-1);
    await setContactClient(false);
    await setContactClientid(-1);
    await setContactPortalid(-1);
    await setNoteCaseSituation(-1);
    await reloadNewsGridCondition(true);
  };

  const showClientAccessDialog = () => {
    setShowClientAccountUsersPop(true);
  };

  const hideClientHideAccountUsersPop = () => {
    setShowClientAccountUsersPop(false);
  };

  return (
    <React.Fragment>
      <CasesNotesToPop
        showPopup={showCasesNotesTo}
        hidePopup={hideCasesNotesTo}
        user={user}
        entryid={noteEntryId}
        caseEntryid={noteCaseEntryid}
        caseSituation={noteCaseSituation}
        contactPortalid={contactPortalid}
        contactClient={contactClient}
        contactClientid={contactClientid}
        contactClientMail={contactClientMail}
        clientData={signupClientDatalist}
        caseSituationAction={caseSituationAction}
      />
      <ClientAccountUsersPop
        showPopup={showClientAccountUsersPop}
        hidePopup={hideClientHideAccountUsersPop}
        user={user}
      />
      <div id="client-users-box">
        <div className="sd-header item">
          <div className="dx-fieldset-header">Account Users</div>
        </div>
        <ResponsiveBox>
          <Row ratio={1} />
          <Col ratio={2.3} />
          <Col ratio={9} />

          <Item>
            <Location screen="md lg sm xs" row={0} col={0} />
            <div id="hm-btn-section-0-1">
              <Button width={120} height={36} text="Access" type="default" stylingMode="outlined" onClick={showClientAccessDialog} />
            </div>
          </Item>

          <Item>
            <Location screen="md lg sm xs" row={0} col={1} />
            <div>
              <table>
                <tbody>
                  <tr>
                    <td width="100px">Main User:</td>
                    <td width="300px">
                      {" "}
                      {signupClientDatalist && signupClientDatalist.cl_salutation}{" "}
                      {signupClientDatalist && signupClientDatalist.cl_firstname}{" "}
                      {signupClientDatalist && signupClientDatalist.cl_lastname}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <p>Invited:</p>
            </div>
            <DataGrid
              id="client-invited-user-grid"
              dataSource={dcClientInvitedData}
              defaultFocusedRowIndex={0}
              focusedRowEnabled={true}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              showColumnHeaders={false}
              // focusedRowKey={selectedClientid}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  //    setSelectedClientid(e.row.key.clientid);
                  //    passToParent(e.row.key.clientid);
                }
              }}
            >
              <Column dataField="inviteid" visible={false}></Column>
              <Column dataField="u_cl_email" caption="E-Mail" width={200} />
              <Column dataField="invitedate" caption="E-Invited" dataType="date" width={120} />
              <Column dataField="accountname" caption="to Account" width={360} />
            </DataGrid>
            <div>
              <p>Connected Users:</p>
            </div>
            <DataGrid
              id="client-connected-user-grid"
              dataSource={dcClientConnectedData}
              defaultFocusedRowIndex={0}
              focusedRowEnabled={true}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              showColumnHeaders={true}
              // focusedRowKey={selectedClientid}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  //    setSelectedClientid(e.row.key.clientid);
                  //    passToParent(e.row.key.clientid);
                }
              }}
            >
              <Column dataField="inviteid" visible={false}></Column>
              <Column dataField="u_clientid" visible={false}></Column>
              <Column dataField="assetaccountid" visible={false}></Column>
              <Column dataField="cl_firstname" caption="First Name" width={120} />
              <Column dataField="cl_lastname" caption="Name" width={180} />
              <Column dataField="accountname" caption="Account" width={300} />
              <Column dataField="approved" caption="Access" width={120} />
            </DataGrid>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default ClientUsers;
