import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';

import './Client.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ArrayStore from 'devextreme/data/array_store';
import DataGrid, { Column, Selection, FilterRow, Summary, TotalItem } from 'devextreme-react/data-grid';

const ForexTrades = (props) => {
  const history = useHistory();
  const { selClientid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [forexTrades, setForexTrades] = useState([]);

  useEffect(() => {
    if (selClientid) {
      fetchForexTrades();
    }
    //  console.log(selClientid);
  }, [sendRequest, selClientid]);

  const fetchForexTrades = async () => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/forex/forextradesclient/${selClientid}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + user.token }
      );
      setForexTrades(dataArray);
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Forex Trades</div>

        <ResponsiveBox>
          <Row ratio={1} />
          <Col ratio={1} />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div>
              <DataGrid
                height={700}
                dataSource={forexTrades}
                keyExpr='fxdrid'
                columnAutoWidth={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
              >
                <Selection mode='single' selectAllMode='page' />
                <FilterRow visible={true} />
                <Column dataField='fxdrid' caption='ID' visible={true} dataType='number' width={80}></Column>
                <Column dataField='clientid' visible={false}></Column>
                <Column dataField='orderid' visible={false}></Column>
                <Column dataField='order_datetime' caption='Order Date' dataType='datetime' width={120}></Column>
                <Column dataField='close_datetime' caption='Close Date' dataType='datetime' width={120}></Column>
                <Column dataField='cpair' caption='Symbol' visible={true} width={100} />
                <Column dataField='long_short_text' caption='long/short' visible={true} width={100} />
                <Column
                  dataField='cpair_ask'
                  caption='Start Ask'
                  visible={true}
                  width={90}
                  format={{ type: 'fixedPoint', precision: 4 }}
                />
                <Column
                  dataField='cpair_entered'
                  caption='Start Bid'
                  visible={true}
                  width={90}
                  format={{ type: 'fixedPoint', precision: 4 }}
                />
                <Column dataField='order_units' caption='Units' visible={true} width={50} />
                <Column dataField='leverage' caption='Leverage' visible={true} width={70} />
                <Column dataField='order_margin' caption='Margin' visible={true} width={60} />
                <Column
                  dataField='cpair_close_ask'
                  caption='Close Ask'
                  visible={true}
                  width={90}
                  format={{ type: 'fixedPoint', precision: 4 }}
                />
                <Column
                  dataField='cpair_close_done'
                  caption='Close Bid'
                  visible={true}
                  width={90}
                  format={{ type: 'fixedPoint', precision: 4 }}
                />
                <Column
                  dataField='trade_profit_loss'
                  caption='P/L'
                  visible={true}
                  width={120}
                  format={{ type: 'fixedPoint', precision: 2 }}
                />
                <Column dataField='trade_currency' caption='Currency' visible={true} width={90} />

                <Summary>
                  <TotalItem column='fxdrid' summaryType='count' />
                  <TotalItem
                    column='trade_profit_loss'
                    summaryType='sum'
                    valueFormat={{ type: 'fixedPoint', precision: 2 }}
                  />
                </Summary>
              </DataGrid>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default ForexTrades;
