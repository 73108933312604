import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ThreadMessages from './internalMessaging/ThreadMessagesInquiry';
import Button from 'devextreme-react/button';

import InternalMessagingHelper from './internalMessaging/InternalMessagingHelper';

function ThreadMessagesPage(props) {
  const { messageid, openedBy } = props;
  const [clientInfo, setClientInfo] = useState(null);

  const handleBackButton = () => {
    if (clientInfo && clientInfo.customerId) {
      props.history.push({
        pathname: `/clients/${clientInfo.customerId}`,
        state: { openTabIndex: 7 },
      });
    } else {
      props.history.push({ pathname: '/messages' });
    }
  };

  useEffect(() => {
    InternalMessagingHelper.getThreadDetails(messageid).then(response => {
      if (response?.persons) {
        const clientInfo = response.persons.find(p => p.customerType === 'CLIENT');
        if (clientInfo) {
          setClientInfo(clientInfo);
        }
      }
    });
  }, [messageid]);

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div>
          <ThreadMessages threadId={messageid} openedBy={openedBy} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(ThreadMessagesPage);
