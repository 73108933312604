import { useState, useCallback, useRef, useEffect } from 'react';
import { useHttpClient } from './http-hook';

export const useInquiry = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const createInquiry = useCallback(async (user, clientid, subject, comment) => {
    console.log(2009, user, clientid, subject, comment);
    try {
      const response = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/inquiries/createClientRequests',
        'POST',
        JSON.stringify({
          userId: user.id,
          clientId: clientid,
          serviceId: -1,
          portalId: user.portalid,
          title: subject,
          description: comment,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }, []);

  return {
    createInquiry: createInquiry,
  };
};
