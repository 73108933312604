import React, { useState, useEffect } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import './services.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

const ProductServices = props => {
  const { selTabIndex, selProductID, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    const fetchProductService = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/product/systemservices/${selProductID}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setServiceData(dataArray);
      } catch (err) {}
    };

    if (selProductID && selTabIndex === 0) {
      fetchProductService();
    }
  }, [sendRequest, selProductID, selTabIndex]);

  return (
    <ResponsiveBox>
      <Col />
      <Row />
      <Item>
        <Location screen='md lg sm xs' row={0} col={0} />
        <div>
          <p>Contained Services</p>
          <DataGrid
            id='product-system-servicesGrid'
            // ref={userDataGrid}
            keyExpr={'serviceid'}
            dataSource={serviceData}
            showColumnHeaders={false}
            showBorders={false}
            showRowLines={false}
            showColumnLines={false}
            columnAutoWidth={true}
            //  defaultFocusedRowIndex={0}
            // focusedRowEnabled={true}
            // onFocusedRowChanged={e => {
            //   setCmpGrid(e.row.data);
            // }}
          >
            <Column dataField='serviceid' visible={false}></Column>
            <Column dataField='servicename' caption='Service'></Column>
          </DataGrid>
        </div>
      </Item>
    </ResponsiveBox>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ProductServices;
