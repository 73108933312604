/**
 *
 * @param minutes number
 * @param d Date object
 * @returns {Date}
 */
export const getRoundedDateNearMinutes = (minutes, d) => {
  if(!d) { d =new Date() }
  let ms = 1000 * 60 * minutes;
  return new Date(Math.ceil(d.getTime() / ms) * ms);
};
