import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import Button from 'devextreme-react/button';

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';

const ProductRatePlan = props => {
  const { tabVisibleIndex, caseDetails } = props;
  const fkey = caseDetails && caseDetails.contactid;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showPopup, setShowPopup] = useState(false);

  const navigatetoCasesOverview = () => {
    setShowPopup(true);
  };

  const hidePopup = useCallback(doContactReload => {
    setShowPopup(false);
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <p>Product Rate Plan</p>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ProductRatePlan;
