import React, { useState, useEffect } from 'react';
import './Extensions.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import Button from 'devextreme-react/button';

import FxAccountPop from './ExtensionsPop';
import MessagePop from '../../ui_elements/MessagePop';

const FxAccounts = (props) => {
  const { user } = props;
  const [forexAccountsDatalist, setForexAccountsDatalist] = useState([]);

  const [ForexAccountRowData, setForexAccountRowData] = useState();

  const [showPortalPopup, setShowPortalPopup] = useState(false);
  const [addPortal, setAddPortal] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [reloadPortalData, setReloadPortalData] = useState(false);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();


  const fetchForexAccounts = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/extensions/extensionaccounts', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setForexAccountsDatalist(dataArray);
    } catch (err) {}
  };


  useEffect(() => {
    fetchForexAccounts();
  }, [sendRequest, reloadPortalData]);

  const stForexAccountsData = new ArrayStore({
    key: ['extaccountid','partnerid','providerid'],
    data: forexAccountsDatalist,
  });

  const delForexAccount = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/extensions/modextensionaccount',
        'POST',
        JSON.stringify({
          extaccountid: ForexAccountRowData.extaccountid,
          operation: 3,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const addNewPortal = () => {
    setAddPortal(true);
    setShowPortalPopup(true);
  };

  const editExistPortal = () => {
    // console.log(1234, ForexAccountRowData)
    setAddPortal(false);
    setShowPortalPopup(true);
  };

  const removePortal = () => {
    setShowMessagePop(true);
  };

  const hidePortalPopup = () => {
    setShowPortalPopup(false);
    setReloadPortalData(!reloadPortalData);
  };

  const hideMessagePop = (doRemove) => {
    setShowMessagePop(false);
    if (doRemove) {
      delForexAccount();
      setReloadPortalData(!reloadPortalData);
    }
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected Feature?'
      />
      <FxAccountPop
        showPopup={showPortalPopup}
        hidePopup={hidePortalPopup}
        ForexAccountRowData={ForexAccountRowData}
        addAccount={addPortal}
        user={user}
      />
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Extensions</div>
        <ResponsiveBox>
          <Row />
          <Col ratio={2} />
          <Col ratio={8} />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div id='hm-btn-section-0-1'>
              <Button width={100} height={36} text='Add' type='default' stylingMode='outlined' onClick={addNewPortal} />
            </div>
            <div id='hm-btn-section-0-11'>
              <Button
                width={100}
                height={36}
                text='Edit'
                type='success'
                stylingMode='outlined'
                onClick={editExistPortal}
              />
            </div>
            <div id='hm-btn-section-0-11'>
              <Button width={100} height={36} text='Rem' type='danger' stylingMode='outlined' onClick={removePortal} />
            </div>
          </Item>

          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <DataGrid
              // id='portal-data-grid'
              height={440}
              dataSource={stForexAccountsData}
              showRowLines={true}
              showBorders={true}
              showColumnLines={true}
              columnAutoWidth={true}
              defaultFocusedRowIndex={0}
              focusedRowEnabled={true}
              showColumnHeaders={true}
              onFocusedRowChanged={(e) => {
                setForexAccountRowData(e.row.data);
              }}
            >
              <Column dataField='extaccountid' visible={true} caption='ID' width='50'/>
              <Column dataField='partnerid' visible={false}/>
              <Column dataField='providerid' visible={false}/>
              <Column dataField='categoryid' visible={false} />
              <Column dataField='category_name' caption='Category' width='100'/>
              <Column dataField='baseaccountname' caption='Extension' width='200' />
              <Column dataField='partnername' caption='Merchant' width='120' />
              <Column dataField='providername' caption='Provider' width='110' />

            </DataGrid>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default FxAccounts;
