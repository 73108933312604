import React, { useRef, useState, useEffect } from 'react';
import './systemSectionSetup.css';

import { useHttpClient } from '../../hooks/http-hook';

import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';

import Box from 'devextreme-react/box';
import { Form, SimpleItem, Label, ButtonItem } from 'devextreme-react/form';

const SystemSectionTab = props => {
  const { user } = props;
  const departmentDataGrid = useRef(null);

  const [departmentDatalist, setDepartmentDatalist] = useState([]);
  const [departmentGridData, setDepartmentGridData] = useState();
  const [departmentDataMod, setDeparmentDataMod] = useState(false);
  const [lpCategoryType, setLpCategoryType] = useState([]);
  const [lpCategoryOps, setLpCategoryOps] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const departmentData = {
    category_name: departmentGridData && departmentGridData.category_name,
    category_id: departmentGridData && departmentGridData.category_id,
    category_type: departmentGridData && departmentGridData.category_type,
    category_ops: departmentGridData && departmentGridData.category_ops,
    category_ident: departmentGridData && departmentGridData.category_ident,
  };

  useEffect(() => {
    const fetchServiceCategories = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/service/servicecat', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setDepartmentDatalist(dataArray);
      } catch (err) {}
    };

    const fetchCategoryType = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/14',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpCategoryType(dataArray);
      } catch (err) {}
    };

    const fetchCategoryOps = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/17',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpCategoryOps(dataArray);
      } catch (err) {}
    };

    fetchServiceCategories();
    fetchCategoryType();
    fetchCategoryOps();
  }, [sendRequest, departmentDataMod]);

  const stDepartmentData = new ArrayStore({
    key: 'category_id',
    data: departmentDatalist,
  });

  const stCategoryTypeData = new ArrayStore({
    key: 'lp_id',
    data: lpCategoryType,
  });

  const stCategoryOps = new ArrayStore({
    key: 'lp_id',
    data: lpCategoryOps,
  });

  const modServiceCat = async (modify, servicedata) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/service/servicecat',
        'POST',
        JSON.stringify({
          category_id: servicedata.category_id,
          category_name: servicedata.category_name,
          category_type: servicedata.category_type,
          category_ops: servicedata.category_ops,
          category_ident: servicedata.category_ident,
          operation: modify,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setDeparmentDataMod(!departmentDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ResponsiveBox>
      <Row ratio={1} />
      <Row ratio={4} />
      <Item>
        <Location screen='md lg sm xs' row={0} col={0} colspan={3} />
        <div id='companytab-box'>
          <Box direction='row' height={40} width='95%'>
            <Item ratio={3}>
              <Form id={'form'} formData={departmentData} labelLocation={'left'}>
                <Item itemType='group' colCount={4}>
                  <SimpleItem dataField='category_name' colSpan={3}>
                    <Label location='left' text='Section' />
                  </SimpleItem>
                  <SimpleItem dataField='category_ident' colSpan={1}>
                    <Label location='left' text='ID' />
                  </SimpleItem>
                </Item>
              </Form>
            </Item>
            <Item ratio={2}>
              <Form id={'form'} formData={departmentData} labelLocation={'left'}>
                <SimpleItem
                  dataField='category_type'
                  editorType='dxSelectBox'
                  name='type_listbox'
                  editorOptions={{
                    dataSource: stCategoryTypeData,
                    valueExpr: 'lp_id',
                    displayExpr: 'lp_value',
                    searchEnabled: false,
                  }}
                >
                  <Label text='Type' />
                </SimpleItem>
              </Form>
            </Item>
            <Item ratio={2}>
              <Form id={'form'} formData={departmentData} labelLocation={'left'}>
                <SimpleItem
                  dataField='category_ops'
                  editorType='dxSelectBox'
                  name='ops_listbox'
                  editorOptions={{
                    dataSource: stCategoryOps,
                    valueExpr: 'lp_id',
                    displayExpr: 'lp_value',
                    searchEnabled: false,
                  }}
                >
                  <Label text='Ops' />
                </SimpleItem>
              </Form>
            </Item>
            <Item ratio={1}>
              <Button
                id='companytab-button'
                width={98}
                height={'36px'}
                text='Add'
                type='default'
                onClick={() => {
                  modServiceCat(1, departmentData);
                }}
              />
            </Item>
            <Item ratio={1}>
              <Button
                id='companytab-button'
                width={98}
                height={'36px'}
                text='Change'
                type='success'
                onClick={() => {
                  modServiceCat(2, departmentData);
                }}
              />
            </Item>
            <Item ratio={1}>
              <Button
                id='companytab-button'
                width={98}
                height={'36px'}
                text='Remove'
                type='danger'
                onClick={() => {
                  modServiceCat(3, departmentData);
                }}
              />
            </Item>
          </Box>
        </div>
      </Item>
      <Item>
        <Location screen='md lg sm xs' row={1} col={0} />
        <DataGrid
          id='service-sections-grid'
          ref={departmentDataGrid}
          dataSource={stDepartmentData}
          showRowLines={true}
          showBorders={true}
          showColumnLines={true}
          columnAutoWidth={true}
          defaultFocusedRowIndex={0}
          focusedRowEnabled={true}
          showColumnHeaders={true}
          onFocusedRowChanged={e => {
            setDepartmentGridData(e.row.data);
          }}
          onSaved={e => {
            if (e.changes[0]) {
              modServiceCat(2, e.changes[0].data);
            }
          }}
        >
          <Column dataField='category_id' visible={false}></Column>
          <Column dataField='category_name' caption='Service Category'></Column>
          <Column dataField='category_ident' caption='ID' width='50'></Column>
          <Column dataField='category_type' caption='Category Type' width='120'>
            <Lookup dataSource={stCategoryTypeData} displayExpr='lp_value' valueExpr='lp_id' />
          </Column>
          <Column dataField='category_ops' caption='Category Operations' width='150'>
            <Lookup dataSource={stCategoryOps} displayExpr='lp_value' valueExpr='lp_id' />
          </Column>
          <Editing mode='row' allowUpdating={true} />
        </DataGrid>
      </Item>
    </ResponsiveBox>
  );
};

export default SystemSectionTab;
