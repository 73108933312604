import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule } from 'devextreme-react/form';

const CaseEditPop = props => {
  const {
    showPopup,
    hidePopup,
    user,
    selLfdNr,
    openedToEdit,
    dstCaseType,
    dstCaseStates,
    dstStDevPrios,
    usedCompanyid,
  } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const caseDataFormRef = useRef(null);
  const [caseData, setCaseData] = useState({});
  const [caseDataModified, setCaseDataModified] = useState(false);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + `/cases/${selLfdNr}`, 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setCaseData(dataArray[0]);
      } catch (err) {}
    };
    if (selLfdNr && showPopup && openedToEdit === 2) {
      fetchCases();
    } else if (selLfdNr && showPopup && openedToEdit === 1) {
      setCaseData({});
    }
  }, [sendRequest, selLfdNr, showPopup]);

  const correct_company_id = (anucompanyid, anselcompanyid, usercompanyid) => {
    if (anucompanyid !== -1) {
      return anucompanyid;
    } else {
      if (anselcompanyid !== -1) {
        return anselcompanyid;
      } else {
        return usercompanyid;
      }
    }
  };

  const caseEditData = {
    LfdNr: caseData.LfdNr || -1,
    Base_LfdNr: caseData.Base_LfdNr || -1,
    Contact: caseData.BaseContact || '',
    contactid: caseData.BaseAnruferid || -1,
    casetypeid: caseData.Case_Type || 1,
    casedate: caseData.created || new Date(),
    subject: caseData.Gesprgegen || '',
    priorityid: caseData.CasePrio || 1,
    casestateid: caseData.Case_State || 1,
    content: caseData.Gespraechsinhalt || '',
    userId: caseData.Mitarbeiterid || user.userId,
    username: caseData.user || user.lastname,
    ucompanyid: caseData.ucompanyid || usedCompanyid,
    selucompany: correct_company_id(caseData.ucompanyid || -1, usedCompanyid, user.companyid),
  };

  const saveCase = async an_operation => {
    try {
      /*  await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/cases/case',
        'POST',
        JSON.stringify({
          operation: an_operation,
          lfdnr: caseEditData.LfdNr,
          base_lfdnr: caseEditData.Base_LfdNr,
          contact: caseEditData.Contact,
          contactid: caseEditData.contactid,
          casetypeid: caseEditData.casetypeid,
          subject: caseEditData.subject,
          priorityid: caseEditData.priorityid,
          casestateid: caseEditData.casestateid,
          content: caseEditData.content,
          userId: caseEditData.userId,
          ucompanyid: caseEditData.ucompanyid,
          selucompany: caseEditData.selucompany,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token  }
      );
    */
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async caseDataFormRef => {
    const result = caseDataFormRef.validationGroup.validate();
    // console.log(result);
    if (result.isValid) {
      setCaseDataModified(true);
      saveCase(openedToEdit);
      hidePopup(true);
    } else {
      alert('Please complete all required fields');
    }
  };

  return (
    <React.Fragment>
      <Popup
        title={'Compliance Case'}
        width={900}
        height={700}
        ref={popupRef}
        visible={showPopup}
        resizeEnabled={true}
        showCloseButton={true}
        onHiding={() => {
          hidePopup(caseDataModified);
        }}
      >
        <ResponsiveBox>
          <Row ratio={10} />
          <Row ratio={0.1} />
          <Col />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div>
              <Form
                id={'form'}
                formData={caseEditData}
                labelLocation={'left'}
                colCountByScreen={colCountByScreen}
                ref={caseDataFormRef}
                validationGroup='CasesData'
              >
                <Item itemType='group' colCount={3}>
                  <SimpleItem
                    dataField='casetypeid'
                    editorType='dxSelectBox'
                    colSpan={2}
                    editorOptions={{
                      dataSource: dstCaseType,
                      valueExpr: 'lp_id',
                      displayExpr: 'lp_value',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='Section' />
                  </SimpleItem>
                </Item>

                <Item itemType='group' colCount={3}>
                  <SimpleItem
                    dataField='contactid'
                    colSpan={1}
                    editorType='dxSelectBox'
                    editorOptions={{
                      //  dataSource: stContacts,
                      valueExpr: 'personid',
                      displayExpr: 'nachname',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='Cust in Signup' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='contactid'
                    colSpan={1}
                    editorType='dxSelectBox'
                    editorOptions={{
                      //  dataSource: stContacts,
                      valueExpr: 'personid',
                      displayExpr: 'nachname',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='Active Customer' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='contactid'
                    colSpan={1}
                    editorType='dxSelectBox'
                    editorOptions={{
                      //  dataSource: stContacts,
                      valueExpr: 'personid',
                      displayExpr: 'nachname',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='Transaction' />
                  </SimpleItem>
                </Item>

                <Item itemType='group' colCount={3}>
                  <SimpleItem
                    dataField='contactid'
                    colSpan={1}
                    editorType='dxSelectBox'
                    editorOptions={{
                      //  dataSource: stContacts,
                      valueExpr: 'personid',
                      displayExpr: 'nachname',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='Merchant' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='contactid'
                    colSpan={1}
                    editorType='dxSelectBox'
                    editorOptions={{
                      //  dataSource: stContacts,
                      valueExpr: 'personid',
                      displayExpr: 'nachname',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='Service' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='contactid'
                    colSpan={1}
                    editorType='dxSelectBox'
                    editorOptions={{
                      //  dataSource: stContacts,
                      valueExpr: 'personid',
                      displayExpr: 'nachname',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='Service Provider' />
                  </SimpleItem>
                </Item>

                <Item itemType='group' colCount={1}>
                  <SimpleItem dataField='subject' isRequired={true}>
                    <RequiredRule message='Please enter a Subject' />
                    <Label text='Case' />
                  </SimpleItem>
                </Item>

                <Item itemType='group' colCount={1}>
                  <SimpleItem
                    dataField='content'
                    editorType='dxTextArea'
                    isRequired={true}
                    editorOptions={{ height: 160 }}
                  >
                    <RequiredRule message='Please enter Case Content' />
                    <Label text='Content' />
                  </SimpleItem>
                </Item>

                <Item itemType='group' colCount={3}>
                  <SimpleItem dataField='casedate' editorType='dxDateBox' editorOptions={{ type: 'datetime' }}>
                    <Label text='Date' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='priorityid'
                    editorType='dxSelectBox'
                    editorOptions={{
                      dataSource: dstStDevPrios,
                      valueExpr: 'lp_id',
                      displayExpr: 'lp_value',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='Priotity' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='casestateid'
                    editorType='dxSelectBox'
                    editorOptions={{
                      dataSource: dstCaseStates,
                      valueExpr: 'lp_id',
                      displayExpr: 'lp_value',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='State' />
                  </SimpleItem>
                </Item>

                <EmptyItem />

                <Item itemType='group' colCount={4}>
                  <SimpleItem dataField='username' colSpan={2} editorOptions={{ readOnly: true }}>
                    <Label text='Created by' />
                  </SimpleItem>
                </Item>
              </Form>
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={1} col={0} />
            <div id={'closebutton'}>
              <div style={{ flexDirection: 'row' }}>
                <Button
                  id='savebutton'
                  disabled={true}
                  width={120}
                  height={40}
                  text='Save'
                  type='normal'
                  stylingMode='contained'
                  validationGroup='CasesData'
                  useSubmitBehavior={true}
                  onClick={validateForm}
                />
                <Button
                  width={120}
                  height={40}
                  text='close'
                  type='normal'
                  stylingMode='contained'
                  onClick={() => {
                    hidePopup(false);
                  }}
                />
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      </Popup>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default CaseEditPop;
