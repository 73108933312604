import React, { useEffect, useState, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';

import './NetworkData.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';
import NetworkDataSetup from './NetworkDataSetup';
import NetworkDataServices from './NetworkDataServices';
import NetworkDataPartner from './NetworkDataPartner';
import NetworkDataConfig from './NetworkDataConfig';

const PartnerDataPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();

  const selRoutingid = user.routing && user.routing.selRoutingid;
  const modeInsert = user.routing && user.routing.modeInsert;

  useEffect(() => {
    if (!user.routing) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='network-data-box'>
              <NetworkDataSetup selRoutingid={selRoutingid} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='network-data-box'>
              <NetworkDataServices selRoutingid={selRoutingid} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={1} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='network-data-box'>
              <NetworkDataPartner selRoutingid={selRoutingid} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='network-data-line'>
                <NetworkDataConfig selRoutingid={selRoutingid} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={3} col={1} />
          <div id={'client-close-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default PartnerDataPage;
