import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import './PartnerPage.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import PartnerEditPop from './PartnerEditPop';

const getGridHeight = () => {
  const anValue = window.innerHeight - 730;
  // console.log(5678, anValue);
  return anValue;
};

const PartnerTab = (props) => {
  const { passToParent, user, selCompanyID } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [partnerList, setPartnerList] = useState([]);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [popupTabsVisible, setPopupTabsVisible] = useState(false);
  const [popModeInsert, setpopModeInsert] = useState(false);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [serviceProviderId, setServiceProviderId] = useState(-1);
  const [serviceProvContactId, setServiceProvContactId] = useState(-1);
  const [partnerRecord, setPartnerRecord] = useState({});

  useEffect(() => {
    const fetchPartner = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/partner/partners', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setPartnerList(dataArray);
      } catch (err) {}
    };
    fetchPartner();
  }, [sendRequest, reloadContacts]);

  const stPartnerData = new ArrayStore({
    key: 'partnerid',
    data: partnerList,
  });

  const dcPartnerData = new DataSource({
    store: stPartnerData,
  });

  const openContactEdit = () => {
    user.partner = {
      partnerid: partnerRecord.partnerid || -1,
      routingid: partnerRecord.routingid || -1,
      partnername: partnerRecord.partnername || '',
      personid: partnerRecord.personid || -1,
      routingname: partnerRecord.routingname || '',
    };
    history.push({
      pathname: '/partneredit',
    });
  };

  const openPartnerData = () => {
    user.partner = {
      partnerid: partnerRecord.partnerid || -1,
      routingid: partnerRecord.routingid || -1,
      partnername: partnerRecord.partnername || '',
      personid: partnerRecord.personid || -1,
      routingname: partnerRecord.routingname || '',
    };
    history.push({
      pathname: '/partnerdata',
    });
  };

  const openContactAdd = () => {
    setServiceProviderId(-1);
    setServiceProvContactId(-1);
    setpopModeInsert(true);
    setPopupTabsVisible(true);
    setShowContactPopup(true);
  };

  const renderGridCell = (cellData) => {
    return <div style={{ color: 'blue' }}>{cellData.text}</div>;
  };

  const hideContactPopup = useCallback((doContactReload) => {
    setShowContactPopup(false);
    if (doContactReload) {
      setReloadContacts(!reloadContacts);
    }
  });

  return (
    <React.Fragment>
      <PartnerEditPop
        showPopup={showContactPopup}
        hidePopup={hideContactPopup}
        svProviderId={serviceProviderId}
        svPersonId={serviceProvContactId}
        user={user}
        selucompany={selCompanyID}
        showTabs={popupTabsVisible}
        modeInsert={popModeInsert}
      />
      <ResponsiveBox>
        <Col ratio={0.3} screen='md lg sm xs' />
        <Col ratio={3} />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <Button width={120} height={36} text='Add' type='default' stylingMode='outlined' onClick={openContactAdd} />
          </div>
          <div id='hm-btn-section-11'>
            <Button
              width={120}
              height={36}
              text='Open'
              type='success'
              stylingMode='outlined'
              onClick={openPartnerData}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div>
            <DataGrid
              id='partner-grid'
              height={getGridHeight}
              key='partnerid'
              dataSource={dcPartnerData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  setServiceProviderId(e.row.key);
                  setServiceProvContactId(e.row.data.personid);
                  passToParent(e.row.key, e.row.data.personid, e.row.data.routingid);
                  setPartnerRecord(e.row.data);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='partnerid' visible={false}></Column>
              <Column dataField='routingid' visible={false}></Column>
              <Column dataField='partnername' caption='Merchant' width={200}></Column>
              <Column dataField='personid' visible={false}></Column>
              <Column dataField='routingname' caption='Financial Network' width={300} />
              <Column dataField='activedate' dataType='date' caption='Active since' width={130} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default PartnerTab;
