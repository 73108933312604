import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import './PartnerData.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import ProductsEditPop from './ProductsEditPop';
import ProductsInfoRemovePop from './ProductsInfoRemovePop';
import MessagePop from '../../ui_elements/MessagePop';

const ProductsTab = props => {
  const { user, selCompanyID, partnerid } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [productsList, setProductsList] = useState([]);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [popupTabsVisible, setPopupTabsVisible] = useState(false);
  const [popModeInsert, setpopModeInsert] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [selectedProductid, setSelectedProductid] = useState();
  const [selectedRoutingid, setSelectedRoutingid] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/product/products/${partnerid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProductsList(dataArray);
      } catch (err) {}
    };
    fetchProducts();
  }, [sendRequest, reloadData]);

  const stProductData = new ArrayStore({
    key: 'productid',
    data: productsList,
  });

  const dcProductData = new DataSource({
    store: stProductData,
  });

  const openProductAdd = () => {
    setSelectedProductid(-1);
    setpopModeInsert(true);
    setPopupTabsVisible(true);
    setShowContactPopup(true);
  };

  const openProductEdit = () => {
    user.product = {
      partnerid: partnerid || -1,
      productid: selectedProductid || -1,
      routingid: selectedRoutingid || -1,
      modeInsert: false,
    };
    history.push({
      pathname: '/partnerproductedit',
    });
  };

  const delProduct = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/product/delproduct',
        'POST',
        JSON.stringify({
          productid: selectedProductid,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      ).then(() => setReloadData(!reloadData));
    } catch (err) {
      console.log(err);
    }
  };

  const hideContactPopup = useCallback(doContactReload => {
    setShowContactPopup(false);
    if (doContactReload) {
      setReloadData(!reloadData);
    }
  });

  const checkIsProductActiv = () => {
    if (!selectedStatus) {
      setShowMessagePop(true);
    } else {
      setpopModeInsert(true);
      setPopupTabsVisible(true);
      setShowRemovePopup(true);
    }
  };

  const hideRemovePopup = () => {
    setShowRemovePopup(false);
  };

  const hideMessagePop = doRemove => {
    setShowMessagePop(false);
    if (doRemove) {
      delProduct();
    }
  };

  return (
    <React.Fragment>
      <ProductsEditPop showPopup={showContactPopup} hidePopup={hideContactPopup} selPartnerId={partnerid} user={user} />
      <ProductsInfoRemovePop
        showPopup={showRemovePopup}
        hidePopup={hideRemovePopup}
        productid={selectedProductid}
        selectedProduct={selectedProduct}
      />
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected Product?'
      />

<div className='sd-header item'>
    <div className='dx-fieldset-header'>Products</div>

      <ResponsiveBox>
        <Col ratio={3} />
        <Row />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <DataGrid
              id='partner-products-edit-Grid'
              height={400}
              dataSource={dcProductData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={e => {
                if (e.row) {
                  setSelectedProductid(e.row.key);
                  setSelectedRoutingid(e.row.data.routingid);
                  setSelectedStatus(e.row.data.productactive);
                  setSelectedProduct(e.row.data.productname);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='productid' visible={false}></Column>
              <Column dataField='partnerid' visible={false}></Column>
              <Column dataField='routingid' visible={false}></Column>
              <Column dataField='productname' caption='Product' width={300}></Column>
              <Column dataField='producttype' caption='Product Type' width={200}></Column>
              <Column dataField='productactive' caption='Active' width={90}></Column>
              <Column dataField='startdate' dataType='date' caption='Start Date' width={130} />
              <Column dataField='enddate' dataType='date' caption='End Date' width={130} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </div>
    </React.Fragment>
  );
};

export default ProductsTab;
