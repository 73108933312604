import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './NetworkData.css';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import MultiView from 'devextreme-react/multi-view';
import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';

import { SelectBox } from 'devextreme-react/select-box';
import Box from 'devextreme-react/box';
import Tabs from 'devextreme-react/tabs';
import ArrayStore from 'devextreme/data/array_store';

const NetworkDataServices = (props) => {
  const { selRoutingid, modeInsert, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const dataFormRef = useRef(null);
  const [lpBankAccounts, setLpBankAccounts] = useState([]);
  const [lpSystemServices, setLpSystemServices] = useState([]);
  const [routingData, setRoutingData] = useState({});
  const [routingServicesData, setRoutingServicesData] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [routingServiceDataMod, setRoutingServiceDataMod] = useState();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/bankaccountslp', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setLpBankAccounts(dataArray);
      } catch (err) {}
    };

    const fetchSystemServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/service/lpsystemservice',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpSystemServices(dataArray);
      } catch (err) {}
    };

    fetchBankAccounts();
    fetchSystemServices();
  }, [sendRequest]);

  useEffect(() => {
    const fetchSingleRouting = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/routing/financialrouting/${selRoutingid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setRoutingData(dataArray[0]);
      } catch (err) {}
    };
    if (selRoutingid) {
      fetchSingleRouting();
    }
  }, [sendRequest, selRoutingid]);

  useEffect(() => {
    const fetchRoutingServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/routing/routingsystemserviceslp/${selRoutingid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setRoutingServicesData(dataArray);
      } catch (err) {}
    };
    if (selRoutingid) {
      fetchRoutingServices();
    }
  }, [sendRequest, selRoutingid, routingServiceDataMod]);

  const stlpBankAccountsData = new ArrayStore({
    key: 'bankaccountid',
    data: lpBankAccounts,
  });

  const stlpSystemServicesData = new ArrayStore({
    key: 'serviceid',
    data: lpSystemServices,
  });

  const stRoutingServicesData = new ArrayStore({
    key: 'serviceid',
    data: routingServicesData,
  });

  const fnce_Routing_FormData = {
    routingid: (routingData && routingData.routingid) || -1,
    routingname: (routingData && routingData.routingname) || '',
    ops_accountid: (routingData && routingData.ops_accountid) || -1,
    qua_accountid: (routingData && routingData.qua_accountid) || -1,
    mrg_accountid: (routingData && routingData.mrg_accountid) || -1,
  };

  const service_FormData = {
    serviceid: (selectedService && selectedService.serviceid) || -1,
  };

  const ModifyRouting = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/routing/modfinancialrouting',
        'POST',
        JSON.stringify({
          operation: modeInsert ? 1 : 2,
          routingid: fnce_Routing_FormData.routingid,
          routingname: fnce_Routing_FormData.routingname,
          ops_accountid: fnce_Routing_FormData.ops_accountid,
          qua_accountid: fnce_Routing_FormData.qua_accountid,
          mrg_accountid: fnce_Routing_FormData.mrg_accountid,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    history.goBack();
  }

  const validateForm = async (dataFormRef) => {
    const result = dataFormRef.validationGroup.validate();
    if (result.isValid) {
      await ModifyRouting();
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  const modRoutingService = async (modify) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/routing/modroutingservices',
        'POST',
        JSON.stringify({
          routingid: fnce_Routing_FormData.routingid,
          serviceid: service_FormData.serviceid,
          operation: modify,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setRoutingServiceDataMod(!routingServiceDataMod);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className='sd-header item'>
      <div className='dx-fieldset-header'>Associated Services</div>
      <ResponsiveBox>
        <Row />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} colspan={3} />
          <div>
            <Form formData={service_FormData} labelLocation={'left'}>
              <SimpleItem
                dataField='serviceid'
                editorType='dxSelectBox'
                colSpan={1}
                name='service_listbox'
                editorOptions={{
                  dataSource: stlpSystemServicesData,
                  valueExpr: 'serviceid',
                  displayExpr: 'servicename',
                  searchEnabled: true,
                }}
              >
                <Label location='left' text='Service' />
              </SimpleItem>
            </Form>

            <Button
              id='assoc-btn-1'
              width={120}
              height={'36px'}
              text='Add'
              type='default'
              stylingMode='outlined'
              onClick={() => {
                modRoutingService(1);
              }}
            />

            <Button
              id='assoc-btn-2'
              stylingMode='outlined'
              width={120}
              height={'36px'}
              text='Remove'
              type='danger'
              onClick={() => {
                modRoutingService(3);
              }}
            />
          </div>
          <DataGrid
            id='network-data-assoc-services'
            height={340}
            dataSource={stRoutingServicesData}
            showRowLines={true}
            showBorders={true}
            showColumnLines={true}
            columnAutoWidth={true}
            defaultFocusedRowIndex={0}
            focusedRowEnabled={true}
            showColumnHeaders={true}
            onFocusedRowChanged={(e) => {
              setSelectedService(e.row.data);
            }}
          >
            <Column dataField='serviceid' visible={false}></Column>
            <Column dataField='servicename' caption='Associated Services'></Column>
          </DataGrid>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default NetworkDataServices;
