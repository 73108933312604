import HTTP from "../../../api/HTTP";


export const MailingGetEmails = (queryStringData) => {
    return HTTP.get(`/mailing/emails`, {
      params: queryStringData
    }).then(response => response.data);
  };

export const MailingSaveEmail = (formData) => {
    const emailId = formData.get("id");
    const url =
      emailId === "null" ? "/mailing/emails" : `/mailing/emails/${emailId}`;
    return HTTP.post(url, formData);
  };

export const MailingGetEmail = (emailId) => {
    return HTTP.get(`/mailing/emails/${emailId}`).then(
      response => response.data
    );
  };

export const MailingGetTemplates = (queryStringData) => {
    return HTTP.get(`/mailing/templates`, {
      params: queryStringData
    }).then(response => response.data);
  };

export const MailingGetTemplate = (templateId) => {
    return HTTP.get(`/mailing/templates/${templateId}`).then(
      response => response.data
    );
  };

export const MailingDeleteTemplate = (templateId) => {
    return HTTP.delete(`/mailing/templates/${templateId}`).then(
      response => response.data 
    );
  };

export const MailingSaveTemplate = (data) => {
    if (data.id) {
      return HTTP.put(`/mailing/templates/${data.id}`, data).then(
        response => response.data
      );
    } else {
      return HTTP.post(`/mailing/templates`, data).then(
        response => response.data
      );
    }
  };

export const MailingGetAccounts = (queryStringData) => {
    return HTTP.get("/mailing/accounts", {
      params: queryStringData
    }).then(response => response.data);
  };

export const MailingSaveAccount = (account) => {
    const url =
      null == account.id
        ? "/mailing/account"
        : `/mailing/accounts/${account.id}`;

    return HTTP.post(url, account).then(response => response.data);
  };

export const MailingGetAccount = (accountId) => {
    return HTTP.get(`/mailing/accounts/${accountId}`).then(
      response => response.data
    );
  };

export const MailingSetDefaultAccount = (accountId) => {
    return HTTP.put(`/mailing/accounts/${accountId}/default`,).then(response => response.data);
  };