import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';
import 'devextreme/dist/css/dx.light.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Form, Container, Card } from 'devextreme-react/form';
import ArrayStore from 'devextreme/data/array_store';

import { SimpleItem, EmptyItem, Label, ButtonItem, ButtonOptions, GroupItem } from 'devextreme-react/form';

export default function NeuronalNet(props) {
  const { modelid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [matrixDataEntries, setMatrixDataEntries] = useState([]);
  const [reloadMatrixDataEntries, setReloadMatrixDataEntries] = useState(true);

  const [neuroNetworkConfigData, setNeuroNetworkConfigData] = useState({});

  const [neuroTrainRuns, setNeuroTrainRuns] = useState(50);
  const [selectedQueryEntry, setSelectedQueryEntry] = useState();

  useEffect(() => {
    const fetchMatrixDataEntries = async () => {
      const response = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/ai/matrix-data/matrix-data-entries/${modelid}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + user.token,
        }
      );
      setMatrixDataEntries(response);
      setReloadMatrixDataEntries(false);
    };
    if (modelid || reloadMatrixDataEntries) {
      fetchMatrixDataEntries();
    }
  }, [reloadMatrixDataEntries, modelid]);

  const stMatrixDataEntries = new ArrayStore({
    key: 'rownumer',
    data: matrixDataEntries,
  });

  const initNeuronalNetwork = async () => {
    // console.log('1---1');
    // console.log(process.env.REACT_APP_NN_SERVER_URL);

    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + `/ai/nnetwork/init/${modelid}`,
      'GET',
      null,
      {
        Authorization: 'Bearer ' + user.token,
      }
    );
    console.log(response);
  };

  const loadTrainDataNeuronalNetwork = async () => {
    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + `/ai/nnetwork/loadtrainingdata/${modelid}`,
      'GET',
      null,
      {
        Authorization: 'Bearer ' + user.token,
      }
    );
    console.log(response);
  };

  const trainNeuronalNetwork = async () => {
    console.log(neuroTrainRuns);

    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + `/ai/nnetwork/trainnetwork/${modelid}/${neuroTrainRuns}`,
      'GET',
      null,
      {
        Authorization: 'Bearer ' + user.token,
      }
    );
    console.log(response);
  };

  const requestNeuronalNetwork = async () => {
    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + `/ai/nnetwork/querynetwork/${modelid}/${neuroNetworkNodes.selectedRowNumber}`,
      'GET',
      null,
      {
        Authorization: 'Bearer ' + user.token,
      }
    );
    console.log(response);
  };

  const saveWeightsNeuronalNetwork = async () => {
    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + `/ai/nnetwork/saveweigths/${modelid}`,
      'GET',
      null,
      {
        Authorization: 'Bearer ' + user.token,
      }
    );
    console.log(response);
  };

  const loadWeightsNeuronalNetwork = async () => {
    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + `/ai/nnetwork/loadweigths/${modelid}`,
      'GET',
      null,
      {
        Authorization: 'Bearer ' + user.token,
      }
    );
    console.log(response);
  };

  useEffect(() => {
    const fetchNeuroNetworkConfig = async () => {
      const response = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/ai/neuronal/nodes/${modelid}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + user.token,
        }
      );
      setNeuroNetworkConfigData(response[0]);
    };
    fetchNeuroNetworkConfig();
  }, [modelid]);

  const neuroNetworkNodes = {
    modelid: neuroNetworkConfigData && neuroNetworkConfigData.modelid ? neuroNetworkConfigData.modelid : -1,
    modelname: neuroNetworkConfigData && neuroNetworkConfigData.modelname ? neuroNetworkConfigData.modelname : '',
    input_nodes: neuroNetworkConfigData && neuroNetworkConfigData.input_nodes ? neuroNetworkConfigData.input_nodes : 0,
    hidden_nodes:
      neuroNetworkConfigData && neuroNetworkConfigData.hidden_nodes ? neuroNetworkConfigData.hidden_nodes : 0,
    output_nodes:
      neuroNetworkConfigData && neuroNetworkConfigData.output_nodes ? neuroNetworkConfigData.output_nodes : 0,
    learn_rate: neuroNetworkConfigData && neuroNetworkConfigData.learn_rate ? neuroNetworkConfigData.learn_rate : 0.3,
    train_runs: 100,
    selectedRowNumber: -1,
  };

  const modNeuronalNetworkConfig = async (anmode) => {
    // console.log(neuroNetworkNodes);
    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + '/ai/neuronal/mod_neuronal_config',
      'POST',
      JSON.stringify({
        operation: anmode,
        modelid: modelid,
        modelname: neuroNetworkNodes.modelname,
        input_nodes: neuroNetworkNodes.input_nodes,
        hidden_nodes: neuroNetworkNodes.hidden_nodes,
        output_nodes: neuroNetworkNodes.output_nodes,
        learn_rate: neuroNetworkNodes.learn_rate,
        train_runs: neuroNetworkNodes.train_runs,
      }),
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    );
    console.log(response);
  };

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Col ratio={8} />
        <Col ratio={4} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <h4 className='card-heading'>Neuronal Network Setup</h4>
          <ResponsiveBox>
            <Row ratio={1} />
            <Row ratio={1} />
            <Row ratio={1} />
            <Row ratio={1} />
            <Col ratio={1} />
            <Item>
              <Location screen='md lg xs sm' row={0} col={0} />
              <Form formData={neuroNetworkNodes} labelLocation={'left'}>
                <Item itemType='group' colCount={12}>
                  <SimpleItem
                    dataField='input_nodes'
                    colSpan={2}
                    editorOptions={
                      {
                        //
                      }
                    }
                  >
                    <Label location='left' text='In N.' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='hidden_nodes'
                    colSpan={2}
                    editorOptions={
                      {
                        //
                      }
                    }
                  >
                    <Label location='left' text='Hd N.' />
                  </SimpleItem>

                  <SimpleItem
                    dataField='output_nodes'
                    colSpan={2}
                    editorOptions={
                      {
                        //
                      }
                    }
                  >
                    <Label location='left' text='Out N.' />
                  </SimpleItem>

                  <SimpleItem
                    dataField='learn_rate'
                    colSpan={3}
                    editorOptions={
                      {
                        //
                      }
                    }
                  >
                    <Label location='left' text='Learn Rate' />
                  </SimpleItem>

                  <ButtonItem colSpan={2} horizontalAlignment='left'>
                    <ButtonOptions
                      width={100}
                      height={36}
                      type='success'
                      text='Save'
                      colSpan={2}
                      stylingMode='outlined'
                      onClick={() => {
                        modNeuronalNetworkConfig(2);
                      }}
                    ></ButtonOptions>
                  </ButtonItem>
                </Item>
                <EmptyItem />
                <GroupItem colCount={10}>
                  <ButtonItem colSpan={3} horizontalAlignment='left'>
                    <ButtonOptions
                      width='200px'
                      height='100%'
                      type='default'
                      text='Initialize'
                      stylingMode='outlined'
                      onClick={initNeuronalNetwork}
                    ></ButtonOptions>
                  </ButtonItem>
                  <ButtonItem colSpan={3} horizontalAlignment='left'>
                    <ButtonOptions
                      width='200px'
                      height='100%'
                      type='default'
                      text='Load Trainingdata'
                      stylingMode='outlined'
                      onClick={loadTrainDataNeuronalNetwork}
                    ></ButtonOptions>
                  </ButtonItem>
                </GroupItem>
                <EmptyItem />
                <GroupItem colCount={10}>
                  <SimpleItem
                    dataField='train_runs'
                    colSpan={2}
                    editorOptions={
                      {
                        //
                      }
                    }
                  >
                    <Label location='left' text='Training x' />
                  </SimpleItem>
                  <ButtonItem colSpan={3} horizontalAlignment='left'>
                    <ButtonOptions
                      width='200px'
                      height='100%'
                      type='default'
                      text='Execute'
                      stylingMode='outlined'
                      onClick={trainNeuronalNetwork}
                    ></ButtonOptions>
                  </ButtonItem>
                </GroupItem>
                <EmptyItem />
                <GroupItem colCount={10}>
                  <ButtonItem colSpan={3} horizontalAlignment='left'>
                    <ButtonOptions
                      width='200px'
                      height='100%'
                      type='success'
                      text='Save Weights'
                      stylingMode='outlined'
                      horizontalAlignment='left'
                      onClick={saveWeightsNeuronalNetwork}
                    ></ButtonOptions>
                  </ButtonItem>
                  <ButtonItem colSpan={3} horizontalAlignment='left'>
                    <ButtonOptions
                      width='200px'
                      height='100%'
                      type='default'
                      text='Load Weights'
                      stylingMode='outlined'
                      onClick={loadWeightsNeuronalNetwork}
                    ></ButtonOptions>
                  </ButtonItem>
                </GroupItem>
              </Form>
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} />
          <div>
            <h4 className='card-heading'>Neuronal Network Query</h4>
          </div>
          <Form formData={neuroNetworkNodes} labelLocation={'left'}>
            <Item itemType='group' colCount={12}>
              <SimpleItem
                dataField='selectedRowNumber'
                editorType='dxSelectBox'
                colSpan={6}
                name='clienttype_listbox'
                editorOptions={{
                  dataSource: stMatrixDataEntries,
                  valueExpr: 'rownumer',
                  displayExpr: 'entryname',
                  searchEnabled: true,
                }}
              >
                <Label text='Data Entry' />
              </SimpleItem>

              <ButtonItem colSpan={6} horizontalAlignment='left'>
                <ButtonOptions
                  width='180px'
                  height='100%'
                  type='success'
                  text='Query Network'
                  stylingMode='outlined'
                  onClick={requestNeuronalNetwork}
                ></ButtonOptions>
              </ButtonItem>
            </Item>
          </Form>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
}
