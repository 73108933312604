import React, { useRef, useState, useEffect } from 'react';
import './GlobalDefinitions.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Form, SimpleItem, Label, ButtonItem } from 'devextreme-react/form';

const ServiceAssetsTab = props => {
  const { user } = props;
  const departmentDataGrid = useRef(null);
  const [serviceAssetsDatalist, setServiceAssetsDatalist] = useState([]);
  const [assetGridData, setAssetGridData] = useState();
  const [assetDataMod, setAssetDataMod] = useState(false);
  const [lpAssetCategories, setLpAssetCategories] = useState([]);
  const [serviceCatDatalist, setServiceCatDatalist] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const assetData = {
    assetid: assetGridData && assetGridData.assetid,
    assetname: assetGridData && assetGridData.assetname,
    assetshortid: assetGridData && assetGridData.assetshortid,
    assetcategory: assetGridData && assetGridData.assetcategory,
    assetcalcunit: assetGridData && assetGridData.assetcalcunit,
    assetcalcbase: assetGridData && assetGridData.assetcalcbase,
    categoryid: assetGridData && assetGridData.categoryid,
  };

  useEffect(() => {
    const fetchServiceAssets = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/service/serviceassets', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setServiceAssetsDatalist(dataArray);
      } catch (err) {}
    };

    const fetchAssetCategories = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/22',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpAssetCategories(dataArray);
      } catch (err) {}
    };

    const fetchServiceCategories = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/service/servicecat', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setServiceCatDatalist(dataArray);
      } catch (err) {}
    };

    fetchServiceAssets();
    fetchAssetCategories();
    fetchServiceCategories();
  }, [sendRequest, assetDataMod]);

  const stAssetData = new ArrayStore({
    key: 'assetid',
    data: serviceAssetsDatalist,
  });

  const stAssetCategoriesData = new ArrayStore({
    key: 'lp_id',
    data: lpAssetCategories,
  });

  const stserviceCatDatalist = new ArrayStore({
    key: 'lp_id',
    data: serviceCatDatalist,
  });

  const modAssetCategories = async (modify, servicedata) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/service/serviceassets',
        'POST',
        JSON.stringify({
          assetid: servicedata.assetid,
          assetname: servicedata.assetname,
          assetshortid: servicedata.assetshortid,
          assetcategory: servicedata.assetcategory,
          assetcalcunit: servicedata.assetcalcunit,
          assetcalcbase: servicedata.assetcalcbase,
          categoryid: servicedata.categoryid,
          operation: modify,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setAssetDataMod(!assetDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="sd-header item">
    <div className="dx-fieldset-header">Service Assets</div>

    <ResponsiveBox>
      <Row ratio={1} />
      <Row ratio={4} />
      <Item>
        <Location screen='md lg sm xs' row={0} col={0} colspan={3} />
        <div>
          <Form id={'form'} formData={assetData} labelLocation={'left'}>
            <Item itemType='group' colCount={10}>
              <SimpleItem dataField='assetid' colSpan={1}>
                <Label location='left' text='ID' />
              </SimpleItem>
              <SimpleItem dataField='assetname' colSpan={2}>
                <Label location='left' text='Name' />
              </SimpleItem>
              <SimpleItem dataField='assetshortid' colSpan={1}>
                <Label location='left' text='Short ID' />
              </SimpleItem>

              <SimpleItem
                dataField='assetcategory'
                editorType='dxSelectBox'
                name='assetcat_listbox'
                colSpan={2}
                editorOptions={{
                  dataSource: stAssetCategoriesData,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: false,
                }}
              >
                <Label text='Category' />
              </SimpleItem>

              <SimpleItem dataField='assetcalcunit' colSpan={1}>
                <Label location='left' text='Unit' />
              </SimpleItem>

              <SimpleItem dataField='assetcalcbase' colSpan={1}>
                <Label location='left' text='Unit Size' />
              </SimpleItem>
              <SimpleItem
                dataField='categoryid'
                editorType='dxSelectBox'
                name='servicecat_listbox'
                colSpan={2}
                editorOptions={{
                  dataSource: stserviceCatDatalist,
                  valueExpr: 'category_id',
                  displayExpr: 'category_name',
                  searchEnabled: false,
                }}
              >
                <Label text='Category' />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={20}>
              <ButtonItem
                horizontalAlignment='left'
                colSpan={2}
                buttonOptions={{
                  text: 'add',
                  width: 120,
                  height: 36,
                  type: 'default',
                  stylingMode: "outlined",
                  onClick: () => {
                    modAssetCategories(1, assetData);
                  },
                }}
              />
              <ButtonItem
                horizontalAlignment='left'
                colSpan={2}
                buttonOptions={{
                  text: 'Mod',
                  width: 120,
                  height: 36,
                  type: 'success',
                  stylingMode: "outlined",
                  onClick: () => {
                    modAssetCategories(2, assetData);
                  },
                }}
              />
              <ButtonItem
                horizontalAlignment='left'
                colSpan={2}
                buttonOptions={{
                  text: 'Rem',
                  width: 120,
                  height: 36,
                  type: 'danger',
                  stylingMode: "outlined",
                  onClick: () => {
                    modAssetCategories(3, assetData);
                  },
                }}
              />
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={1} col={0} />
              <DataGrid
                height={400}
                ref={departmentDataGrid}
                dataSource={stAssetData}
                showRowLines={true}
                showBorders={true}
                showColumnLines={true}
                columnAutoWidth={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                showColumnHeaders={true}
                onFocusedRowChanged={e => {
                  setAssetGridData(e.row.data);
                }}
                onSaved={e => {
                  if (e.changes[0]) {
                    modAssetCategories(2, e.changes[0].data);
                  }
                }}
              >
                <Column dataField='assetid' visible={true} caption='ID' width='50'></Column>
                <Column dataField='assetname' caption='Name' width='150'></Column>
                <Column dataField='assetshortid' caption='Short N.' width='50'></Column>
                <Column dataField='assetcategory' caption='Category' width='120'>
                  <Lookup dataSource={stAssetCategoriesData} displayExpr='lp_value' valueExpr='lp_id' />
                </Column>
                <Column dataField='assetcalcunit' caption='Unit' width='100' />
                <Column dataField='assetcalcbase' caption='Unit Size' width='100' />
                <Column dataField='categoryid' caption='Service Section' width='120'>
                  <Lookup dataSource={stserviceCatDatalist} displayExpr='category_name' valueExpr='category_id' />
                </Column>
                <Editing mode='row' allowUpdating={true} />
              </DataGrid>
            </Item>
          </Form>
        </div>
      </Item>
    </ResponsiveBox>
    </div>
  );
};

export default ServiceAssetsTab;
