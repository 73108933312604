import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { useAuth } from '../contexts/auth';
import './detailgrids.css';

import DataGrid, { Scrolling, Column, FilterRow, MasterDetail } from 'devextreme-react/data-grid';

import ProductServiceDetailGrid from './ProductServiceDetailGrid';

const ProductCostRiskServiceDetailGrid = props => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const risklevel = props.data.data;
  const [costRateDetailList, setCostRateDetailList] = useState([]);

  useEffect(() => {
    const fetchCostRateEntries = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/product/viewproductcostriskserviceentries/${risklevel.productid}/${risklevel.partnerid}/${risklevel.riskid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCostRateDetailList(dataArray);
      } catch (err) {}
    };
    fetchCostRateEntries();
  }, [sendRequest, risklevel.productid, risklevel.partnerid, risklevel.riskid]);

  const stCostDetailsData = new ArrayStore({
    key: ['serviceid'],
    data: costRateDetailList,
  });

  const dcCostDetailsData = new DataSource({
    store: stCostDetailsData,
  });

  return (
    <div>
      <DataGrid
        // id={'product-risk-detailgrid'}
        dataSource={dcCostDetailsData}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        focusedRowEnabled={true}
        showBorders={true}
        showRowLines={true}
        showColumnLines={true}
        autoNavigateToFocusedRow={true}
        // onFocusedRowChanged={e => {
        //   setRiskGridData(e.row.data);
        //   setSelRiskid(e.row.data.riskid);
        // }}
      >
        <Column dataField='serviceid' visible={false} />
        <Column dataField='costentryid' visible={false} />
        <Column dataField='riskid' visible={false} />
        <Column dataField='servicename' caption='Service' width='200px' allowEditing={false} />
        <Column dataField='risklevel' caption='Risk Level' width='150px' visible={false} allowEditing={false} />
        <Column dataField='rates_set' caption='Rates Set' width='90px' visible={false} />
        <Column dataField='not_applicable' caption='Not Applicable' width='100px' />
        <Column dataField='setupfee' caption='Setup Fee' dataType='number' width='80' />
        <Column dataField='recurringfee' caption='Service Fee' dataType='number' width='80' />
        <Column dataField='recperiod' caption='Recurring' width='80'></Column>
        <MasterDetail enabled={true} component={ProductServiceDetailGrid} />
      </DataGrid>
    </div>
  );
};
export default ProductCostRiskServiceDetailGrid;
