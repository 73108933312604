import React, { useState, useEffect } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import './Networks.css';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';
import { ConstantLine } from 'devextreme-react/chart';

const RoutingServices = props => {
  const { selTabIndex, selRoutingID, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [routingServices, setRoutingServices] = useState([]);

  useEffect(() => {
    const fetchRoutingService = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/routing/routingsystemservices/${selRoutingID}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setRoutingServices(dataArray);
      } catch (err) {}
    };
    if (selRoutingID) {
      fetchRoutingService();
    }
  }, [sendRequest, selRoutingID]);

  const stRoutingServices = new ArrayStore({
    key: 'serviceid',
    data: routingServices,
  });

  const dcRoutingServices = new DataSource({
    store: stRoutingServices,
  });

  return (
    <ResponsiveBox>
      <Col />
      <Row />
      <Item>
        <Location screen='md lg sm xs' row={0} col={0} />
        <div>
          <p>Services on Network</p>
          <DataGrid
            id='rouing-services-tab-grid'
            // ref={userDataGrid}
            dataSource={dcRoutingServices}
            showColumnHeaders={true}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            // columnAutoWidth={true}
            //  defaultFocusedRowIndex={0}
            focusedRowEnabled={true}
            // onFocusedRowChanged={e => {
            //   setCmpGrid(e.row.data);
            // }}
          >
            <Column dataField='serviceid' visible={false} />
            <Column dataField='categoryid' visible={false} />
            <Column dataField='providerid' visible={false} />
            <Column dataField='category_name' caption='Category' width={150} />
            <Column dataField='servicename' caption='Service' width={200} />
            <Column dataField='providername' caption='provider' width={200} />
            <Column dataField='currency' caption='Currency' width={70} />
            <Column dataField='funds' caption='Current Funds' dataType='number' width={100} />
            <Column dataField='funds_idle' caption='Funds Ideal' dataType='number' width={100} />
            <Column dataField='imbalance' caption='Imbalance' dataType='number' width={100} />
          </DataGrid>
        </div>
      </Item>
    </ResponsiveBox>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default RoutingServices;
