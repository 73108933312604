import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { useHttpClient } from '../../hooks/http-hook';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import DataGrid, { Column } from 'devextreme-react/data-grid';

import ArrayStore from 'devextreme/data/array_store';

const FxAccountPop = (props) => {
  const { showPopup, hidePopup, ForexAccountRowData, addAccount, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [featureChanged, setFeatureChanged] = useState(false);
  const srvDataFormRef = useRef(null);
  const [lpPortalTypes, setLpPortalTypes] = useState([]);
  const [partnerDatalist, setPartnerDatalist] = useState([]);
  const [providerDatalist, setProviderDatalist] = useState([]);
  const [specCategoryDatalist, setSpecCategoryDatalist] = useState([]);
  const [forexBaseAccountsDatalist, setForexBaseAccountsDatalist] = useState([])

  const fetchAuthorizedPartner = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/extensions/extensionpartner', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setPartnerDatalist(dataArray);
    } catch (err) {}
  };

  const fetchAuthorizedProvider = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/extensions/extensionprovider', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setProviderDatalist(dataArray);
    } catch (err) {}
  };

  const fetchSpecCategories = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/extensions/extensioncat', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setSpecCategoryDatalist(dataArray);
    } catch (err) {}
  };

  const fetchForexBaseAccounts = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/forex/forexbaseaccounts', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      console.log(8, dataArray)
      setForexBaseAccountsDatalist(dataArray);
    } catch (err) {}
  };

  useEffect(() => {
    if (showPopup) {
      fetchAuthorizedPartner();
      fetchAuthorizedProvider();
      fetchSpecCategories();
      fetchForexBaseAccounts();
    }
  }, [sendRequest, showPopup]);


  const stPartnerDatalist = new ArrayStore({
    key: 'partnerid',
    data: partnerDatalist,
  });

  const stProviderDatalist = new ArrayStore({
    key: 'providerid',
    data: providerDatalist,
  });

  const stSpecCategoryDatalist = new ArrayStore({
    key: 'category_id',
    data: specCategoryDatalist,
  });

  const stForexBaseAccountsDatalist = new ArrayStore({
    key: 'baseaccountid',
    data: forexBaseAccountsDatalist,
  });

  const fxAccountData = {
    extaccountid: addAccount ? -1 : ForexAccountRowData && ForexAccountRowData.extaccountid,
    extaccount_name: addAccount ? '' : ForexAccountRowData && ForexAccountRowData.extaccount_name,
    partnerid: addAccount ? -1 : ForexAccountRowData && ForexAccountRowData.partnerid,
    providerid: addAccount ? -1 : ForexAccountRowData && ForexAccountRowData.providerid,
    categoryid: addAccount ? 11 : ForexAccountRowData && ForexAccountRowData.categoryid,
    baseaccountid: addAccount ? 11 : ForexAccountRowData && ForexAccountRowData.baseaccountid
  };

  const modifyForexAccount = async (modify) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/extensions/modextensionaccount',
        'POST',
        JSON.stringify({
          operation: modify,
          extaccountid: fxAccountData.extaccountid,
          partnerid: fxAccountData.partnerid,
          providerid: fxAccountData.providerid,
          categoryid: fxAccountData.categoryid,
          extaccount_name: fxAccountData.extaccount_name,
          baseaccountid: fxAccountData.baseaccountid
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const savePopup = async () => {
    if (addAccount) {
      await modifyForexAccount(1);
    } else {
      await modifyForexAccount(2);
    }
    await hidePopup(true);
  };

  return (
    <Popup
      title={'Account'}
      width={800}
      height={380}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      onShowing={() => {
        setFeatureChanged(false);
      }}
    >
      <ResponsiveBox>
        <Row ratio={5} />
        <Row ratio={1} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form id={'fxaccount-pop'} ref={srvDataFormRef} formData={fxAccountData} labelLocation={'left'}>
            <Item itemType='group' colCount={6}>
            <SimpleItem
                dataField='categoryid'
                editorType='dxSelectBox'
                name='categoryid_listbox'
                colSpan={3}
                editorOptions={{
                  dataSource: stSpecCategoryDatalist,
                  valueExpr: 'category_id',
                  displayExpr: 'category_name',
                  searchEnabled: false,
                }}
              >
                <Label text='Category' />
              </SimpleItem>
              <SimpleItem
                dataField='baseaccountid'
                editorType='dxSelectBox'
                name='baseaccountid_listbox'
                colSpan={3}
                editorOptions={{
                  dataSource: stForexBaseAccountsDatalist,
                  valueExpr: 'baseaccountid',
                  displayExpr: 'baseaccountname',
                  searchEnabled: false,
                }}
              >
                <Label text='Base Account' />
              </SimpleItem>
              </Item>
            <EmptyItem />
            <Item itemType='group' colCount={6}>
              <SimpleItem
                dataField='partnerid'
                editorType='dxSelectBox'
                name='partnerid_listbox'
                colSpan={3}
                editorOptions={{
                  dataSource: stPartnerDatalist,
                  valueExpr: 'partnerid',
                  displayExpr: 'portalname',
                  searchEnabled: false,
                }}
              >
                <Label text='Merchant' />
              </SimpleItem>
              <SimpleItem
                dataField='providerid'
                editorType='dxSelectBox'
                name='providerid_listbox'
                colSpan={3}
                editorOptions={{
                  dataSource: stProviderDatalist,
                  valueExpr: 'providerid',
                  displayExpr: 'portalname',
                  searchEnabled: false,
                }}
              >
                <Label text='Provider' />
              </SimpleItem>
            </Item>
          </Form>

         </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'closebutton-2'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton-right'
                width={120}
                height={50}
                text='Save'
                type='default'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={savePopup}
              />
              <Button width={120} height={50} text='Close' type='normal' stylingMode='contained' onClick={hidePopup} />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default FxAccountPop;
