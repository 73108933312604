import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useHttpClient } from "../../hooks/http-hook";
import Button from "devextreme-react/button";

import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";

import DataGrid, { Scrolling, Column, FilterRow } from "devextreme-react/data-grid";

import Form, { SimpleItem, GroupItem, Label, ButtonItem } from "devextreme-react/form";

import CaseEditPop from "./CaseEditPop";

import { useClientData } from '../../hooks/cientdata-hook';

const getScreenGridHeight = () => {
  const anValue = window.innerHeight - 670;
  return anValue;
};

const CasesOneTab = (props) => {
  const {
    user,
    selPartnerPortal,
    passToParent,
    selnewskey,
    dataStCaseType,
    dataStCaseStates,
    dataStDevPrios,
    getReload,
    reloadGrid,
    dataStCaseSituation,
  } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { callClientData, clientUsesForex } = useClientData();
  
  const [casesDataList, setCasesDatalist] = useState([]);
  const [casesGridData, setCasesGridData] = useState([]);
  const [selEntryid, setSelEntryid] = useState(0);
  const [showCasePopup, setShowCasePopup] = useState(false);
  const [openedType, setOpenedType] = useState(1);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [clientSearchText, setClientSearchText] = useState("");

  const casesOneGrid = useRef(null);
  const [compDay, setCompDay] = useState(new Date().toISOString().split("T")[0]);
  const [selClientid, setSelClientid] = useState();

  useEffect(() => {
    selnewskey && setSelEntryid(selnewskey);
  }, [selnewskey]);

  useEffect(() => {
    if (showCasePopup === false && clientSearchText.length === 0) {
      const interval = setInterval(async () => {
        try {
          const dataArray = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + `/cases/all/${selPartnerPortal}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + user.token,
            }
          );
          await setCasesDatalist(dataArray);
          await selClientid(dataArray[0].clientid);
        } catch (err) {}
      }, 15000);
      return () => clearInterval(interval, clientSearchText);
    }
  }, [selPartnerPortal, showCasePopup, clientSearchText]);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/cases/all/${selPartnerPortal}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        await setCasesDatalist(dataArray);
        // console.log(dataArray[0].clientid);
        setSelClientid(dataArray[0].clientid);
      } catch (err) {}
    };
    if (selPartnerPortal) {
      fetchCases();
    }
  }, [sendRequest, reloadGrid, selPartnerPortal]);

  const fetchSearchCase = async (field1, field2, field3) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/cases/casesearch",
        "POST",
        JSON.stringify({
          field1: "%" + field1 + "%",
          field2: "%" + field2 + "%",
          field3: "%" + field3 + "%",
          portalid: user.portalid,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      ).then((data) => {
        // console.log(data)
        setCasesDatalist(data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const navigateToCasePage = () => {
    setOpenedType(2);
    setShowCasePopup(true);
  };

  const navigateToAddNewCasePage = () => {
    setOpenedType(1);
    setShowCasePopup(true);
  };

  const cleanfield = (anfield) => {
    let resultfield = anfield.replace("+", "");
    resultfield = resultfield.replace("-", "");
    resultfield = resultfield.replace("(", "");
    resultfield = resultfield.replace(")", "");
    resultfield = resultfield.replace(";", "");
    resultfield = resultfield.replace(",", "");
    return resultfield;
  };

  const doSearch = (anSearchText) => {
    let field1 = anSearchText.split(" ")[0] ? anSearchText.split(" ")[0] : "";
    let field2 = anSearchText.split(" ")[1] ? anSearchText.split(" ")[1] : "";
    let field3 = anSearchText.split(" ")[2] ? anSearchText.split(" ")[2] : "";

    field1 = cleanfield(field1);
    field2 = cleanfield(field2);
    field3 = cleanfield(field3);

    fetchSearchCase(field1, field2, field3);
  };

  const renderGridCell = (cellData) => {
    const gDay = cellData.value.toISOString().split("T")[0];
    // console.log(111, compDay, gDay);
    if (compDay === gDay) {
      return <div style={{ color: "blue" }}>{cellData.text}</div>;
    } else {
      return <div style={{ color: "default" }}>{cellData.text}</div>;
    }
  };

  const hideCasePopup = useCallback((doContactReload) => {
    setShowCasePopup(false);
    console.log(doContactReload);
    if (doContactReload) {
      setReloadContacts(!reloadContacts);
      getReload(true);
    }
  });

  const handleSubmit = (e) => {
    const searchtext = e.target.elements.searchtext.value;
    // console.log(1234, searchtext);
    setClientSearchText(searchtext);
    doSearch(searchtext);
    e.preventDefault();
  };

  const clientOpenClick = async() => {

    const clientData = await callClientData(user, selClientid); 
    const clientUsesFx = await clientUsesForex(user, selClientid);
 
     user.client = {
       selClientid: selClientid,
       selClientData: clientData,
       selClientUsesForex: clientUsesFx,
     };
     
     history.push({
       pathname: "/clientdata",
     });
 
   };

  return (
    <React.Fragment>
      <CaseEditPop
        showPopup={showCasePopup}
        hidePopup={hideCasePopup}
        user={user}
        entryid={selEntryid}
        openedToEdit={openedType}
        dstCaseType={dataStCaseType}
        dstCaseStates={dataStCaseStates}
        dstStDevPrios={dataStDevPrios}
        dstStCaseSituation={dataStCaseSituation}
      />
      <ResponsiveBox>
        <Col />
        <Row ratio={0.2} />
        <Row ratio={3} />


        <Item>
          <Location screen="md lg sm" row={0} col={0} />

          <ResponsiveBox>
            <Row ratio={1} />
            <Col ratio={2} />
            <Col ratio={2} />
            <Item>
              <Location screen="md lg sm xs" row={0} col={0} />
              <form
                action="search"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div id='ops-client-search-edit' >
                <Form  labelLocation="left">
                  <GroupItem cssClass="first-group" colCount={12}>
                    <SimpleItem dataField="searchtext" colSpan={8}>
                      <Label text="find" />
                    </SimpleItem>
                  <ButtonItem 
                    colSpan={3}
                    buttonOptions={{
                      text: "Open",
                      width: 120,
                      height: 36,
                      type: "default",
                      stylingMode: "outlined",
                      onClick: clientOpenClick,
                    }}
                    />
                  </GroupItem>
                </Form>
                </div>
              </form>
            </Item>
          </ResponsiveBox>
        </Item>

        <Item>
          <Location screen="md lg sm xs" row={1} col={0} colspan={2}/>
          <div>
            <DataGrid
              id="casesGrid"
              height={getScreenGridHeight}
              ref={casesOneGrid}
              dataSource={casesDataList}
              keyExpr="entryid"
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              focusedRowKey={selEntryid.toString()}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  if (e.row.key !== selEntryid) {
                    setSelEntryid(e.row.key);
                    setCasesGridData(e.row.data);
                    setSelClientid(e.row.data.clientid);
                    passToParent(e.row.key, e.row.data.subject, e.row.data.case_content, e.row.data.clientid);
                  }
                }
              }}
              onRowDblClick={() => {
                navigateToCasePage();
              }}
            >
              <FilterRow visible={false} />
              <Column dataField="entryid" visible={false} />
              <Column dataField="portaluserid" visible={false} />
              <Column dataField="portalid" visible={false} />
              <Column
                dataField="lastnote_datetime"
                caption="Last Note"
                dataType="datetime"
                width={130}
                cellRender={renderGridCell}
              ></Column>
              <Column dataField="clientname" caption="Client" width={200}></Column>
              <Column dataField="subject" caption="Subject"></Column>
              <Column dataField="casetype" width={200} caption="Section" visible={false}></Column>
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default CasesOneTab;
