import React, { useState, useEffect, useCallback } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import "./costrateservices.css";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import {
  Form,
  SimpleItem,
  EmptyItem,
  RequiredRule,
  ButtonItem,
} from "devextreme-react/form";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

import { Tabs, MultiView, SelectBox } from "devextreme-react";
import { useAuth } from "../../contexts/auth";
import { Label } from "devextreme-react/data-grid";

import CostRatesManageTab from "./CostRatesManageTab";
import ServiceFees from "./ServiceFees";

const CostRatesBasePage = () => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [costEntryid, setCostEntryid] = useState(-1);
  const [costServiceid, setCostServiceid] = useState(-1);

  const getCostEntryid = useCallback((fCostentryid, fServiceid) => {
    setCostEntryid(fCostentryid);
    setCostServiceid(fServiceid);
  });

  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Cases</h2> */}
      <div className={"content-block"}>
        <div className={"dx-card wide-card"}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={2} />
            <Row ratio={0.7} />
            <Col />

            <Item>
              <Location screen="md lg" row={0} col={0} colspan={2} />
              <Location screen="sm" row={0} col={0} colspan={1} />
              <Location screen="xs" row={0} col={0} />
              <div className="rb-header item">
                <ResponsiveBox>
                  <Row />
                  <Col ratio={3} />
                  <Col ration={1} />
                  <Item>
                    <Location screen="md lg sm" row={0} col={0} />
                    <div className="dx-fieldset-header" id="cap_item">
                      Cost Rates Base Page
                    </div>
                  </Item>
                  <Item>
                    <Location screen="md lg sm xs" row={0} col={1} />
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen="md lg sm xs" row={1} col={0} />

              <div className="rb-content item">
                <ResponsiveBox>
                  <Row ratio={1} />
                  <Row ratio={1} />
                  <Col ratio={1} />
                  <Item>
                    <Location screen="md lg sm xs" row={0} col={0} />
                    <div>
                      <MultiView height={250} animationEnabled={false}>
                        <Item title="Compliace">
                          <CostRatesManageTab
                            passToParent={getCostEntryid}
                            user={user}
                          />
                        </Item>
                      </MultiView>
                    </div>
                  </Item>
                  <Item>
                    <Location screen="md lg sm xs" row={1} col={0} />
                    <ResponsiveBox>
                      <Col />
                      <Row />
                      <Item>
                        <Location screen="md lg sm xs" row={0} col={0} />
                        <MultiView height={530} animationEnabled={false}>
                          <Item title="Cost Entries">
                            <ServiceFees
                              selCostEntryid={costEntryid}
                              selServiceid={costServiceid}
                              user={user}
                            />
                          </Item>
                        </MultiView>
                      </Item>
                    </ResponsiveBox>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen="md lg" row={2} col={0} colspan={2} />
              <Location screen="sm" row={2} col={0} colspan={1} />
              <Location screen="xs" row={2} col={0} />
              <div className="rb-footer item" id="cap_item"></div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CostRatesBasePage;
