import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import ArrayStore from 'devextreme/data/array_store';

import { Form, SimpleItem, EmptyItem, Label, ButtonItem, ButtonOptions, GroupItem } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';

const InquiryRequest = props => {
  const { user, inquiryData, reloadInquiryData } = props;
  const [lpInquiryState, setLpInquiryState] = useState([]);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchInquiryState = async () => {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/47', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpInquiryState(dataArray);
    };

    fetchInquiryState();
  }, [sendRequest]);

  const stInquiryState = new ArrayStore({
    key: 'lp_id',
    data: lpInquiryState,
  });

  const loadedInquiryData = {
    inquiryid: inquiryData && parseInt(inquiryData.inquiryid),
    subject: inquiryData && inquiryData.subject,
    inquirytext: inquiryData && inquiryData.inquirytext,
    client_response: inquiryData && inquiryData.client_response,
    inquiry_seen: inquiryData && inquiryData.inquiry_seen,
    inquirystate: inquiryData && inquiryData.inquirystate,
  };

  const updateInquiry = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/inquiries/updateInquiry',
        'POST',
        JSON.stringify({
          subject: loadedInquiryData.subject,
          inquirytext: loadedInquiryData.inquirytext,
          selInquiryId: loadedInquiryData.inquiryid,
          inquiryState: loadedInquiryData.inquirystate,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );
      reloadInquiryData(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <div id='ing-req-title'>
        <p>
          <b>Request</b>
        </p>
      </div>
      <Form id='inq-req-form' formData={loadedInquiryData} labelLocation={'left'} colCountByScreen={colCountByScreen}>
        <Item itemType='group' colCount={1}>
          <SimpleItem dataField='subject' editorOptions={{ stylingMode: 'outlined' }}>
            <Label text='Subject' visible={true} />
          </SimpleItem>
          <SimpleItem
            dataField='inquirytext'
            editorType='dxTextArea'
            editorOptions={{
              height: 200,
              stylingMode: 'outlined',
              readOnly: false,
            }}
          >
            <Label text='Request' visible={true} />
          </SimpleItem>

          <Item itemType='group' colCount={4}>
            <SimpleItem
              dataField='inquirystate'
              editorType='dxSelectBox'
              name='inquirystate_listbox'
              editorOptions={{
                dataSource: stInquiryState,
                valueExpr: 'lp_id',
                displayExpr: 'lp_value',
                searchEnabled: true,
                stylingMode: 'outlined',
              }}
            >
              <Label text='Inquiry State' />
            </SimpleItem>
          </Item>
          <ButtonItem
            editorType='dxButton'
            horizontalAlignment='right'
            verticalAlignment='center'
            buttonOptions={{
              text: 'Save',
              width: '120px',
              height: '40px',
              onClick: () => {
                updateInquiry();
              },
            }}
          />
        </Item>
      </Form>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default InquiryRequest;
