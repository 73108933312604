import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import CaseEditPop from './CaseEditPop';

const CasesOneTab = props => {
  const {
    user,
    selCompanyID,
    passToParent,
    selnewskey,
    dataStCaseType,
    dataStCaseStates,
    dataStDevPrios,
    getReload,
    reloadGrid,
  } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [casesDataList, setCasesDatalist] = useState([]);
  const [casesGridData, setCasesGridData] = useState([]);
  const [last_Base_LfdNr, setLast_Base_LfdNr] = useState(8321);
  const [showCasePopup, setShowCasePopup] = useState(false);
  const [openedType, setOpenedType] = useState(1);
  const [reloadContacts, setReloadContacts] = useState(false);

  const casesOneGrid = useRef(null);

  useEffect(() => {
    if (selnewskey) {
      setLast_Base_LfdNr(selnewskey.Base_LfdNr);
    }
  }, [selnewskey]);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        /*
        let casefilter = '';
        if (selCompanyID == -1) {
          casefilter = user.companyfilter;
        } else {
          casefilter = '(' + selCompanyID + ')';
        }
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/cases/cmp/${casefilter}`
        );
        setCasesDatalist(dataArray);
        */
      } catch (err) {}
    };
    fetchCases();
  }, [sendRequest, selCompanyID, user.companyfilter, reloadGrid, reloadContacts]);

  const navigateToCasePage = () => {
    setOpenedType(2);
    setShowCasePopup(true);
  };

  const navigateToAddNewCasePage = () => {
    setOpenedType(1);
    setShowCasePopup(true);
  };

  const renderGridCell = cellData => {
    return <div style={{ color: 'blue' }}>{cellData.text}</div>;
  };

  const hideCasePopup = useCallback(doContactReload => {
    setShowCasePopup(false);
    console.log(doContactReload);
    if (doContactReload) {
      setReloadContacts(!reloadContacts);
      getReload(true);
    }
  });

  return (
    <React.Fragment>
      <CaseEditPop
        showPopup={showCasePopup}
        hidePopup={hideCasePopup}
        user={user}
        selLfdNr={last_Base_LfdNr}
        openedToEdit={openedType}
        dstCaseType={dataStCaseType}
        dstCaseStates={dataStCaseStates}
        dstStDevPrios={dataStDevPrios}
        usedCompanyid={selCompanyID}
      />
      <ResponsiveBox>
        <Col ratio={0.3} screen='md lg sm' />
        <Col ratio={2} />
        <Row />

        <Item>
          <Location screen='md lg sm' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <Button
              width={120}
              height={50}
              text='Add'
              type='normal'
              stylingMode='outlined'
              onClick={navigateToAddNewCasePage}
            />
          </div>
          <div id='hm-btn-section-2'>
            <Button
              width={120}
              height={50}
              text='Open'
              type='normal'
              stylingMode='outlined'
              onClick={navigateToCasePage}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div>
            <DataGrid
              id='casesGrid'
              ref={casesOneGrid}
              dataSource={casesDataList}
              keyExpr='Base_LfdNr'
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={false}
              showRowLines={false}
              autoNavigateToFocusedRow={true}
              focusedRowKey={last_Base_LfdNr}
              onFocusedRowChanged={e => {
                if (e.row) {
                  setLast_Base_LfdNr(e.row.key);
                  setCasesGridData(e.row.data);
                  passToParent(e.row.key, e.row.data.Base_Gesprgegen, e.row.data.BaseContact, e.row.data.BaseAnruferid);
                }
              }}
              onRowDblClick={() => {
                navigateToCasePage();
              }}
            >
              <FilterRow visible={true} />
              <Column dataField='LfdNr' visible={false}></Column>
              <Column dataField='Base_LfdNr' visible={false}></Column>
              <Column dataField='user' visible={false}></Column>
              <Column dataField='CaseState' visible={false}></Column>
              <Column dataField='Gespraechsinhalt' visible={false}></Column>
              <Column dataField='Case_Number' visible={false}></Column>
              <Column dataField='PrioName' visible={false}></Column>
              <Column
                dataField='datenote'
                caption='Last Note'
                dataType='datetime'
                width={130}
                cellRender={renderGridCell}
              ></Column>
              <Column dataField='CaseType' width={120} caption='Section'></Column>
              <Column dataField='BaseContact' caption='Contact' width={150}></Column>
              <Column dataField='Base_Gesprgegen' caption='Subject'></Column>
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default CasesOneTab;
