import React, { useRef, useState, useEffect } from 'react';
import './ServiceProviderData.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Form, SimpleItem, Label, ButtonItem } from 'devextreme-react/form';

const ServiceProviderRegions = (props) => {
  const { user, selProviderId } = props;
  const [regionsDatalist, setRegionsDatalist] = useState([]);
  const [regionGridData, setRegionGridData] = useState({});
  const [departmentDataMod, setDeparmentDataMod] = useState(false);
  const [regionCountriesDatalist, setRegionCountriesDatalist] = useState([]);
  const [countryGridData, setCountryGridData] = useState({});
  const [lookupCountriesDatalist, setLookupCountriesDatalist] = useState([]);
  const [regionCountryDataMod, setRegionCountryDataMod] = useState(false);
  const [lpRisklevels, setLpRisklevels] = useState([]);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const regionData = {
    regionname: regionGridData && regionGridData.regionname,
    regionid: regionGridData && regionGridData.regionid,
    risklevel: regionGridData && regionGridData.risklevel,
  };

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/provider/getproviderregions/${selProviderId}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setRegionsDatalist(dataArray);
      } catch (err) {}
    };

    fetchRegions();
  }, [sendRequest, departmentDataMod, selProviderId]);

  const stRegionsData = new ArrayStore({
    key: 'regionid',
    data: regionsDatalist,
  });

  useEffect(() => {
    const fetchRisklevels = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/33',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpRisklevels(dataArray);
      } catch (err) {}
    };
    fetchRisklevels();
  }, [sendRequest]);

  useEffect(() => {
    const fetchLookupCountries = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/countries', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setLookupCountriesDatalist(dataArray);
      } catch (err) {}
    };
    fetchLookupCountries();
  }, [sendRequest, departmentDataMod]);

  const stRisklevels = new ArrayStore({
    key: 'lp_id',
    data: lpRisklevels,
  });

  const stLookupCountriesData = new ArrayStore({
    key: 'countryid',
    data: lookupCountriesDatalist,
  });

  const modPartnerRegions = async (modify) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/partner/modregion',
        'POST',
        JSON.stringify({
          regionid: regionData.regionid,
          partnerid: -1,
          providerid: selProviderId,
          risklevel: regionData.risklevel,
          regionname: regionData.regionname,
          operation: modify,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );
      setDeparmentDataMod(!departmentDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchRegionCountries = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/regioncountries/${regionData.regionid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setRegionCountriesDatalist(dataArray);
      } catch (err) {}
    };
    if (regionData.regionid) {
      fetchRegionCountries();
    } else setRegionCountriesDatalist([]);
  }, [sendRequest, regionData.regionid, regionCountryDataMod]);

  const stRegionCountriesData = new ArrayStore({
    key: 'countryid',
    data: regionCountriesDatalist,
  });

  const regionCountryData = {
    regionid: regionGridData && regionGridData.regionid,
    countryid: countryGridData && countryGridData.countryid,
    countryname: countryGridData && countryGridData.countryname,
  };

  const modRegionCountries = async (modify) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/service/regioncountries',
        'POST',
        JSON.stringify({
          regionid: regionCountryData.regionid,
          countryid: regionCountryData.countryid,
          operation: modify,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );
      setRegionCountryDataMod(!regionCountryDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='sd-header item'>
      <div className='dx-fieldset-header'>Available in Regions</div>

      <ResponsiveBox>
        <Row />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <ResponsiveBox>
            <Row />
            <Col ratio={1} />
            <Col ratio={1} />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <DataGrid
                height={430}
                dataSource={stRegionsData}
                showRowLines={true}
                showBorders={true}
                showColumnLines={true}
                columnAutoWidth={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                showColumnHeaders={true}
                onFocusedRowChanged={(e) => {
                  setRegionGridData(e.row.data);
                }}
              >
                <Column dataField='regionid' visible={false}></Column>
                <Column dataField='risklevel' visible={false}></Column>
                <Column dataField='regionname' caption='Region'></Column>
                <Column dataField='riskleveltxt' caption='Risk Level'></Column>
              </DataGrid>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={1} />

              <DataGrid
                id='service-prov-data-countries-grid'
                height={370}
                dataSource={stRegionCountriesData}
                showRowLines={true}
                showBorders={true}
                showColumnLines={true}
                columnAutoWidth={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                showColumnHeaders={true}
                onFocusedRowChanged={(e) => {
                  setCountryGridData(e.row.data);
                }}
              >
                <Column dataField='regionid' visible={false} />
                <Column dataField='countryid' visible={false} />
                <Column dataField='countryname' visible={true} caption='Country'/>
              </DataGrid>
            </Item>
          </ResponsiveBox>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

export default ServiceProviderRegions;
