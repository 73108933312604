import React, { useEffect, useState } from "react";
import Tabs, { Item } from "devextreme-react/tabs";
import { Button, DataGrid } from "devextreme-react";
import { Column, Pager } from "devextreme-react/data-grid";
import paginator from "../../../modules/paginator/Paginator";
import CalendarHelper from "../../../modules/calendar/CalendarHelper";

import { useCalendar } from "../../../contexts/calendar";

function ListView(props) {
  const { setAppointmentData, toggleShowEventPopup } = useCalendar();

  const eventListQueryStringConfig = {
    upcoming: {
      startDate: new Date(Date.now()).toISOString()
    },
    previous: {
      endDate: new Date(Date.now()).toISOString()
    }
  };

  const [tabIndex, setTabIndex] = useState(0);
  const [tabValue, setTabValue] = useState("upcoming");
  const [eventList, setEventList] = useState();

  useEffect(() => {
    setEventList(initGrid());
  }, [tabValue]);

  const initGrid = () => {
    return paginator(
      CalendarHelper.getEvents,
      [],
      "id",
      20,
      eventListQueryStringConfig[tabValue]
    );
  };

  const renderTimeCell = dateCell => {
    const startTime = new Date(dateCell.data.startTime);
    const endTime = new Date(dateCell.data.endTime);
    return `${startTime.getHours()}:${String(startTime.getMinutes()).padStart(
      2,
      "0"
    )} - ${endTime.getHours()}:${String(endTime.getMinutes()).padStart(
      2,
      "0"
    )}`;
  };

  const renderAttendeesCell = ({ value }) => {
    let result = "Names are missing";
    if (value && Array.isArray(value)) {
      result = value
        .map(attendee => {
          return `${attendee?.lastName} ${attendee?.firstName}`;
        })
        .join(", ");
    }
    return result;
  };

  const renderActionCell = cellData => {
    return (
      <React.Fragment>
        <Button
          icon="edit"
          height={35}
          onClick={() => {
            setAppointmentData(cellData.data);
            toggleShowEventPopup();
          }}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Tabs
        dataSource={[
          {
            text: "Upcoming",
            value: "upcoming"
          },
          {
            text: "Previous",
            value: "previous"
          }
        ]}
        visible={true}
        selectedIndex={tabIndex}
        onItemClick={e => {
          setTabIndex(e.itemIndex);
          setTabValue(e.itemData.value);
        }}
      >
        <Item text="Upcoming" />
        <Item text="Previous" />
      </Tabs>
      <DataGrid
        hoverStateEnabled={true}
        ref={props.forwardEventListRef}
        onRowDblClick={e => {
          setAppointmentData(e.data);
          toggleShowEventPopup();
        }}
        dataSource={eventList}
        columnAutoWidth={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        showBorders={true}
        wordWrapEnabled={true}
        showRowLines={false}
        showColumnHeaders={true}
        remoteOperations={true}
      >
        <Pager showPageSizeSelector={true} showInfo={true} />
        <Column dataField="id" visible={false} />
        <Column
          dataField="startTime"
          format={"dd.MM.yyyy"}
          caption="Date"
          dataType="datetime"
          width={130}
        />
        <Column caption="Time" width={100} cellRender={renderTimeCell} />
        <Column dataField="title" caption="Subject" dataType="string" />
        <Column
          dataField="attendees"
          alignment="left"
          cssClass="column-nowrap"
          caption="Attendees"
          width={300}
          cellRender={renderAttendeesCell}
        />
        <Column width={70} caption="Actions" cellRender={renderActionCell} />
      </DataGrid>
    </React.Fragment>
  );
}

export default ListView;