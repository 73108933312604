import {
    Form,
    SimpleItem,
    GroupItem,
    ButtonItem,
    ButtonOptions
} from "devextreme-react/form";
import {useEffect, useRef, useState} from "react";
import notify from "devextreme/ui/notify";
import DataSource from "devextreme/data/data_source";
import ClientHelper from "../../../services/ClientHelper";
import Autocomplete from "devextreme-react/autocomplete";


function ThreadsFilter({clientId, handleSubmitAction}) {
    const [formData, setFormData] = useState();
    const [foundClientsList, setFoundClientsList] = useState();
    const searchName = useRef("");

    useEffect(() => {
        setDefaultFormData();
    }, []);

    useEffect(() => {
        setFoundClientsList(initClientsList());
    }, [searchName.current]);

    const initClientsList = () => {
        return new DataSource({
            key: "clientid",
            load: () => {
                return ClientHelper.findClient(searchName.current).then(
                    (data) => {
                        data.forEach(item => {
                            item.fullName = `${item.clFirstname} ${item.clLastname}`.trim();
                        });
                        return {
                            data: data,
                            totalCount: data.length
                        };
                    }
                );
            }
        });
    };

    const setDefaultFormData = () => {
        const nowStart = new Date();
        const nowEnd = new Date();
        nowStart.setHours(0, 0, 0);
        nowEnd.setHours(23, 59, 59);
        setFormData({
            clientId: "",
            startDate: nowStart,
            endDate: nowEnd
        });

        handleSubmitAction();
    };

    const validateForm = (filterForm) => {
        const result = filterForm.validationGroup.validate();
        if (result.isValid) {
            handleSubmitAction(formData);
        } else {
            notify("Please complete all required fields", "error", 2000);
        }
    };

    const handleStartDateChange = (e) => {
        setFormData(prevState => {
            const newDate = new Date(e.value);

            if (prevState && prevState.endDate < newDate) {
                newDate.setHours(23, 59, 59);
                return {...prevState, startDate: e.value, endDate: newDate};
            }

            return {...prevState, startDate: e.value};
        });
    };

    return (
        <Form
            id="form"
            formData={formData}
            className={"mb-1"}
            labelLocation="left"
            colCount={2}
            width={"50vw"}
        >
            <GroupItem caption="Filter options"/>
            <GroupItem>
                <GroupItem>
                    <Autocomplete
                        width={"22vw"}
                        visible={!clientId}
                        showClearButton={true}
                        dataSource={foundClientsList}
                        placeholder="Search account"
                        minSearchLength={1}
                        mode={"search"}
                        itemTemplate={data => {
                            return `${data.clFirstname} ${data.clLastname}`;
                        }}
                        onItemClick={(e) => {
                            setFormData(prevState => ({...prevState, clientId: e.itemData.clientid}));
                        }}
                        valueExpr={"fullName"}
                        onValueChanged={(e) => {
                            if (e?.event?.type === "input") {
                                searchName.current = e.value;
                            }
                            if (e?.event?.type === "dxclick") {
                                setFormData(prevState => ({...prevState, clientId: ''}));
                            }
                        }}
                    />
                </GroupItem>
                <GroupItem cssClass={"mb-4"} colCount={2}>
                    <SimpleItem
                        dataField="startDate"
                        editorType="dxDateBox"
                        editorOptions={{
                            showSpinButtons: true,
                            width: "150",
                            type: "date",
                            onValueChanged: handleStartDateChange,
                            pickerType: "calendar",
                            displayFormat: "dd.MM.yyyy",
                            value: formData?.startDate
                        }}
                        label={{
                            text: "Start date:"
                        }}
                    />
                    <SimpleItem
                        dataField="endDate"
                        editorType="dxDateBox"
                        editorOptions={{
                            showSpinButtons: true,
                            width: "150",
                            type: "date",
                            pickerType: "calendar",
                            displayFormat: "dd.MM.yyyy",
                            min: formData?.startDate,
                            value: formData?.endDate
                        }}
                        label={{
                            text: "To:"
                        }}
                    />
                </GroupItem>

                <GroupItem colCount={8}>
                    <ButtonItem verticalAlignment={"bottom"} colSpan={5}>
                        <ButtonOptions
                            onClick={validateForm}
                            text={"Search"}
                            width={"100"}
                            height={35}
                        />
                    </ButtonItem>
                    <ButtonItem
                        verticalAlignment={"bottom"}
                        colSpan={3}
                        horizontalAlignment={"left"}
                    >
                        <ButtonOptions
                            onClick={setDefaultFormData}
                            text={"Reset"}
                            width={"100"}
                            height={35}
                        />
                    </ButtonItem>
                </GroupItem>
            </GroupItem>
        </Form>
    );
};

export default ThreadsFilter;
