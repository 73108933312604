import Scheduler, { AppointmentDragging } from "devextreme-react/scheduler";
import DataSource from "devextreme/data/data_source";
import React, { useEffect, useState } from "react";

import { useCalendar } from "../../../contexts/calendar";
import CalendarHelper from "../../../modules/calendar/CalendarHelper";

function CalendarView(props) {
  const { setAppointmentData, toggleShowEventPopup } = useCalendar();
  const initEventList = () => {
    return new DataSource({
      key: "id",
      paginate: false,
      load: () => {
        return CalendarHelper.getEvents({}).then(data => {
          return {
            data: data.items
          };
        });
      }
    });
  };

  const [eventDataList, setEventDataList] = useState();

  useEffect(() => {
    setEventDataList(initEventList());
  }, []);

  const handleAppointmentFormOpening = data => {
    data.cancel = true;
    setAppointmentData(data.appointmentData);
    toggleShowEventPopup();
  };

  return (
    <React.Fragment>
      <Scheduler
        ref={props.forwardEventCalendarRef}
        timeZone="Europe/Berlin"
        dataSource={eventDataList}
        views={["day", "week"]}
        firstDayOfWeek={1}
        cellDuration={30}
        editing={{
          allowDeleting: false,
          allowResizing: false,
          allowDragging: false,
          allowUpdating: false
        }}
        hoverStateEnabled={true}
        showAllDayPanel={false}
        height={600}
        startDayHour={6}
        endDayHour={22}
        dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ"
        textExpr="title"
        startDateExpr="startTime"
        endDateExpr="endTime"
        allDayExpr="allDay"
        useDropDownViewSwitcher={false}
        remoteFiltering={true}
        onAppointmentFormOpening={handleAppointmentFormOpening}
      >
        <AppointmentDragging />
      </Scheduler>
    </React.Fragment>
  );
}

export default CalendarView;