import React, { useEffect, useState, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';

import './PartnerData.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';
import PartnerDataName from './PartnerDataName';
import PartnerDataServices from './PartnerDataServices';
import PartnerDataProducts from './PartnerDataProducts';
import PartnerDataRegions from './PartnerDataRegions';
import PartnerDataClients from './PartnerDataClients';
import PartnerDataOnboarding from './PartnerDataOnboarding';
import PartnerEditReqDoc from './PartnerDataReqDoc';
import PartnerDataTOU from './PartnerDataTOU';
import PartnerDataTransactions from './PartnerDataTransactions';

const PartnerDataPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();

  const partnerBaseData = {
    partnerid: user.partner && user.partner.partnerid,
    routingid: user.partner && user.partner.routingid,
    partnername: user.partner && user.partner.partnername,
    personid: user.partner && user.partner.personid,
    routingname: user.partner && user.partner.routingname,
  };

  useEffect(() => {
    console.log(5678, user.partner);
    if (!user.partner) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='partner-data-box'>
                <PartnerDataName
                  selPartnerid={partnerBaseData.partnerid}
                  selPersonid={partnerBaseData.personid}
                  selRoutingid={partnerBaseData.routingid}
                  user={user}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='partner-data-box'>
                <PartnerDataProducts
                  user={user}
                  partnerid={partnerBaseData.partnerid}
                  selRoutingId={partnerBaseData.routingid}
                  partnername={partnerBaseData.partnername}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={1} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='partner-data-box'>
                <PartnerDataServices
                  selPartnerId={partnerBaseData.partnerid}
                  selRoutingId={partnerBaseData.routingid}
                  partnername={partnerBaseData.partnername}
                  user={user}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={1} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='partner-data-box'>
                <PartnerDataRegions user={user} selPartnerId={partnerBaseData.partnerid} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='partner-data-box'>
                <PartnerDataOnboarding user={user} selPartnerId={partnerBaseData.partnerid} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={3} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='partner-data-box'>
                <PartnerEditReqDoc user={user} selPartnerId={partnerBaseData.partnerid} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={3} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='partner-data-box'>
                <PartnerDataTOU user={user} selPartnerId={partnerBaseData.partnerid} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={4} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='partner-data-line'>
                <PartnerDataClients user={user} selPartnerId={partnerBaseData.partnerid} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={5} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='partner-data-line'>
                <PartnerDataTransactions user={user} selPartnerId={partnerBaseData.partnerid} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={6} col={1} />
          <div id={'client-close-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default PartnerDataPage;
