import React, { useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import ArrayStore from 'devextreme/data/array_store';
import { SelectBox } from 'devextreme-react/select-box';

import './PartnerData.css';

const ProductsEditPop = props => {
  const { showPopup, hidePopup, selPartnerId, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const dataFormRef = useRef(null);
  const [productData, setProductData] = useState({});
  const [lpProductType, setLpProductType] = useState([]);
  const [lpCurrencies, setLpCurrencies] = useState([]);

  useEffect(() => {
    const fetchProductType = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/30',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpProductType(dataArray);
      } catch (err) {}
    };

    const fetchCurrencies = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/21',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpCurrencies(dataArray);
        //  console.log(dataArray);
      } catch (err) {}
    };

    fetchProductType();
    fetchCurrencies();
  }, [sendRequest]);

  const stProductType = new ArrayStore({
    key: 'lp_id',
    data: lpProductType,
  });

  const stlpCurrencies = new ArrayStore({
    key: 'short_id',
    data: lpCurrencies,
  });

  const product_FormData = {
    productid: -1,
    partnerid: selPartnerId,
    productname: '',
    startdate: '',
    autoorder: false,
    userid: user.userId,
    currency: '',
    productactive: false,
    producttypeid: 1,
  };

  const ModifyProduct = async () => {
    const OpType = 1;
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/product/modproduct',
        'POST',
        JSON.stringify({
          operation: OpType,
          productid: -1,
          partnerid: selPartnerId,
          productname: product_FormData.productname,
          startdate: product_FormData.startdate,
          autoorder: product_FormData.autoorder,
          userid: product_FormData.userid,
          currency: product_FormData.currency,
          productactive: product_FormData.productactive,
          producttypeid: product_FormData.producttypeid,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    hidePopup(true);
  }

  const validateForm = async dataFormRef => {
    const result = dataFormRef.validationGroup.validate();
    if (result.isValid) {
      await ModifyProduct();
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  return (
    <Popup
      title={'Product'}
      ref={popupRef}
      width='600'
      height='350'
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      //    onShowing={() => {
      //      setContactsChanged(false);
      //    }}
    >
      <div id='productedit-pop-form-area'>
        <ResponsiveBox>
          <Row ratio={2} />
          <Row ratio={10} />
          <Row ratio={0.2} />
          <Col />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <Form
              id={'form'}
              ref={dataFormRef}
              formData={product_FormData}
              labelLocation={'top'}
              colCountByScreen={colCountByScreen}
              validationGroup='product_create_popup_data'
            >
              <Item itemType='group' colCount={5}>
                <SimpleItem dataField='productname' isRequired={true} colSpan={2} editorOptions={baseEditorOptions}>
                  <RequiredRule message='Please enter a Product Name' />
                  <Label text='Name' />
                </SimpleItem>
                <SimpleItem
                  dataField='producttypeid'
                  editorType='dxSelectBox'
                  colSpan={2}
                  name='prodtyp_listbox'
                  editorOptions={{
                    dataSource: stProductType,
                    valueExpr: 'lp_id',
                    displayExpr: 'lp_value',
                    searchEnabled: true,
                    
                  }}
                >
                  <Label text='Product Type' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={5}>
                <SimpleItem
                  dataField='currency'
                  editorType='dxSelectBox'
                  colSpan={2}
                  name='currency_listbox'
                  isRequired={true}
                  editorOptions={{
                    dataSource: stlpCurrencies,
                    valueExpr: 'short_id',
                    displayExpr: 'lp_value',
                    searchEnabled: true,
                    
                  }}
                >
                  <RequiredRule message='Please select the Product Currency' />
                  <Label text='Product Currency' />
                </SimpleItem>

                <SimpleItem
                  dataField='startdate'
                  editorType='dxDateBox'
                  colSpan={2}
                  isRequired={true}
                  editorOptions={{ type: 'date' }}
                >
                  <RequiredRule message='Please select a Start Date' />
                  <Label text='Start Date' />
                </SimpleItem>
                <SimpleItem dataField='productactive' editorType='dxCheckBox'>
                  <Label text='Active' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <EmptyItem />
            </Form>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={2} col={0} />
            <div id={'contactpopbutton'}>
              <div style={{ flexDirection: 'row' }}>
                <Button
                  id='savebutton'
                  width={120}
                  height={50}
                  text='Save'
                  type='default'
                  stylingMode='contained'
                  validationGroup='product_create_popup_data'
                  useSubmitBehavior={true}
                  onClick={validateForm}
                />
                <Button
                  width={120}
                  height={50}
                  text='Close'
                  type='normal'
                  stylingMode='contained'
                  onClick={navigateToHome}
                />
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ProductsEditPop;
