import { useEffect, useState } from "react";
import Form, {
  Item,
  GroupItem,
  ButtonItem,
  ButtonOptions,
  Label,
  SimpleItem
} from "devextreme-react/form";
import { Event } from "../../models/calendar/Event";

import CalendarHelper from "../../modules/calendar/CalendarHelper";
import notify from "devextreme/ui/notify";
import DataSource from "devextreme/data/data_source";
import { RangeRule, RequiredRule } from "devextreme-react/validator";
import MessagePop from "../../components/uiElements/MessagePop";
import { LoadPanel } from "devextreme-react/load-panel";
import { getShiftDateTime } from "../../utils/getShiftDateTime";
import { useAuth } from "../../contexts/auth";
import { useCalendar } from "../../contexts/calendar";

function AppointmentForm(props) {
  const now = new Date();

  const { eventData } = useCalendar();
  const { user: currentUser } = useAuth();

  const [formData, setFormData] = useState(new Event());
  const [enableNotification, setEnableNotification] = useState(false);
  const [hideButtonDelete, setHideButtonDelete] = useState(true);
  const [loadingStatuses, setLoadingStatuses] = useState({
    form: false,
    eventType: true,
    reminderType: true
  });

  const [usersAndClientsList, setUsersAndClientsList] = useState();
  const [eventTypesData, setEventTypesData] = useState([]);
  const [eventTypesNotificationData, setEventTypesNotificationData] = useState(
    []
  );
  const [showMessagePop, setShowMessagePop] = useState(false);

  useEffect(() => {
    initFormData();
    setStatusButtonDelete();
    getEventTypesData();
    getEventTypesNotificationData();
    setUsersAndClientsList(initUsersAndClientsList());
  }, []);

  useEffect(() => {
    usersAndClientsList?.load();
  }, [usersAndClientsList]);

  const hideMessagePop = isChangeStatus => {
    if (isChangeStatus) {
      onDelete();
    }
    setShowMessagePop(false);
  };

  const onDeleteRequest = () => {
    setShowMessagePop(true);
  };

  const getEventTypesData = () => {
    CalendarHelper.getEventTypes()
      .then(data => {
        setEventTypesData(data);
      })
      .catch(e => {
        notify("Error when trying to get event types", "error", 2000);
      });
  };

  const getEventTypesNotificationData = () => {
    CalendarHelper.getEventTypeReminders()
      .then(data => {
        setEventTypesNotificationData(data);
      })
      .catch(e => {
        notify("Error when trying to get reminders types", "error", 2000);
      })
      .finally(() => {
        setLoadingStatuses({ ...loadingStatuses, reminderType: false });
      });
  };

  const initFormData = () => {
    if (eventData.id && eventData.id > 0) {
      CalendarHelper.getEventDetails(eventData.id).then(event => {
        if (event?.attendees?.length && event?.attendees?.length > 0) {
          for (let i = 0; i < event.attendees.length; i++) {
            event.attendees[i] = event.attendees[i].id;
          }
        }

        event.enableNotification =
          event.reminderBeforeMinutes && event.reminderBeforeMinutes > 0;

        if (!event.enableNotification) {
          event.reminderBeforeMinutes = 3;
        }

        setFormData(event);
      });
    }
  };

  const initUsersAndClientsList = () => {
    return new DataSource({
      key: "id",
      paginate: false,
      group: "type",
      loadMode: "raw",
      load: async loadOptions => {
        let result = [];
        if (currentUser.providerId) {
          const searchValue = loadOptions.searchValue;
          result = await CalendarHelper.getAttendees({
            fullName: searchValue
          });
        }
        return result;
      }
    });
  };

  const renderAttendeesGroup = groupData => {
    return groupData.key + "S";
  };

  const setStatusButtonDelete = () => {
    if (eventData.id) {
      setHideButtonDelete(false);
    }
  };

  const onSubmit = data => {
    const validate = data.validationGroup.validate();
    if (validate.isValid) {
      handleFormSubmit();
    } else {
      notify("Please complete all required fields", "error", 2000);
    }
  };

  const handleFormSubmit = async () => {
    const newFormData = {
      ...formData,
      reminderBeforeMinutes: formData.enableNotification
        ? formData.reminderBeforeMinutes
        : 0,
      attendees: getGeneratedAttendees(formData.attendees || [])
    };

    setLoadingStatuses({ ...loadingStatuses, form: true });

    try {
      await CalendarHelper.saveEvent(newFormData);
      notify("The event was saved successfully", "success", 2000);

      props.hideEventPopup(true);
    } catch (e) {
      notify("Can't save event", "error", 2000);
    } finally {
      setLoadingStatuses({ ...loadingStatuses, form: false });
    }
  };

  const getGeneratedAttendees = attendeeIds => {
    const loadOptions = usersAndClientsList.loadOptions();
    let items = [];

    if (loadOptions.group) {
      usersAndClientsList.items().forEach(item => {
        items = items.concat(item.items);
      });
    } else {
      items = usersAndClientsList.items();
    }

    return attendeeIds.map(attendeeId => {
      return items.find(user => {
        return user.id === attendeeId;
      });
    });
  };

  const renderConferenceInfo = e => {
    if (isEventType("OTHER")) {
      return;
    }

    if (
      isEventType("CONFERENCE_CALL") &&
      formData.conference !== undefined &&
      formData.conference.url !== undefined
    ) {
      return (
        <p>
          <b>Conference: </b>
          <a
            style={{ fontSize: 14, display: "block", marginTop: 6 }}
            target={"_blank"}
            href={formData.conference.url}
            rel="noreferrer"
          >
            {formData.conference.url}
          </a>
          <br />
          <b>PIN: </b> {formData.conference.pin}
        </p>
      );
    }
  };

  const changeEventType = e => {
    if (isEventType("OTHER")) {
      setFormData({ ...formData, data: undefined });
    }
  };

  const isEventType = eventTypeName => {
    const foundEventType = eventTypesData?.find(eventType => {
      return eventType.id === formData.eventTypeId;
    });
    return foundEventType?.name === eventTypeName;
  };

  const isEnableNotification = () => {
    if (formData.enableNotification !== undefined) {
      return formData.enableNotification;
    }

    return !!(
      formData.reminderBeforeMinutes && formData.reminderBeforeMinutes > 0
    );
  };

  const onDelete = async () => {
    if (formData.id) {
      setLoadingStatuses({ ...loadingStatuses, form: true });

      try {
        await CalendarHelper.deleteEvent(formData.id);
        notify("Event deleted successfully", "success", 2000);
        props.hideEventPopup(true);
      } catch (e) {
        notify("Can't delete event", "error", 2000);
      } finally {
        setLoadingStatuses({ ...loadingStatuses, form: false });
      }
    }
  };

  const handleStartTimeChange = () => {
    setFormData({
      ...formData,
      endTime: getShiftDateTime(30, new Date(formData.startTime))
    });
  };

  return (
    <>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle="Confirmation"
        popMessage="Are you sure you want to delete event?"
      />

      <LoadPanel
        position={{ of: "#event-form" }}
        visible={loadingStatuses.form}
        showIndicator={true}
        shading={true}
        showPane={true}
      />

      <Form
        className={"mt-4"}
        id={"event-form"}
        formData={formData}
        labelLocation={"top"}
        validationGroup={"formValidationGroup"}
      >
        <GroupItem cssClass={"mt-3"} colCount={3}>
          <Item
            colSpan={1}
            dataField="eventTypeId"
            editorType="dxSelectBox"
            editorOptions={{
              placeholder: "Event type",
              showSpinButtons: true,
              width: 120,
              dataSource: eventTypesData,
              onValueChanged: changeEventType,
              displayExpr: "description",
              valueExpr: "id",
              noDataText: loadingStatuses.eventType
                ? "Loading..."
                : "No data to display"
            }}
            label={{
              text: "Type"
            }}
          >
            <RequiredRule message="Required field" />
          </Item>
          <SimpleItem colSpan={2} render={renderConferenceInfo} />
        </GroupItem>
        <Item
          dataField="title"
          editorType="dxTextBox"
          label={{
            text: "Subject"
          }}
        >
          <RequiredRule message="Required field" />
        </Item>

        <GroupItem cssClass={"mt-3"} colCount={3}>
          <Item
            dataField="startTime"
            editorType="dxDateBox"
            editorOptions={{
              showSpinButtons: true,
              width: "150",
              type: "datetime",
              onValueChanged: handleStartTimeChange,
              pickerType: "calendar",
              displayFormat: "dd.MM.yyyy HH:mm",
              min: now
            }}
            label={{
              text: "Start Time"
            }}
          >
            <RequiredRule message="Required field" />
          </Item>
          <Item
            dataField="endTime"
            editorType="dxDateBox"
            editorOptions={{
              showSpinButtons: true,
              width: "150",
              type: "time",
              pickerType: "rollers",
              displayFormat: "dd.MM.yyyy HH:mm",
              min: formData.startTime
            }}
            label={{
              text: "End Time"
            }}
          >
            <RequiredRule message="Required field" />
          </Item>
        </GroupItem>
        <Item
          cssClass={"mt-3"}
          dataField="attendees"
          editorType="dxTagBox"
          editorOptions={{
            searchEnabled: true,
            searchExpr: "fullName",
            dataSource: usersAndClientsList,
            showSelectionControls: true,
            displayExpr: "fullName",
            valueExpr: "id",
            grouped: true,
            groupRender: renderAttendeesGroup,
            groupTemplate: renderAttendeesGroup,
            showClearButton: true
          }}
          label={{
            text: "Attendees"
          }}
        >
          <RequiredRule message="Required field" />
        </Item>
        <GroupItem cssClass={"mt-3"} colCount={5}>
          <Item
            editorType="dxCheckBox"
            dataField={"enableNotification"}
            editorOptions={{
              value: isEnableNotification(),
              onValueChanged: e => {
                setFormData({
                  ...formData,
                  enableNotification: e.value
                });
              },
              width: 40
            }}
            label={{
              text: "Notification",
              alignment: "left",
              location: "left"
            }}
          />
          <Item
            editorType="dxSelectBox"
            dataField="reminderTypeId"
            visible={isEnableNotification()}
            colSpan={2}
            editorOptions={{
              showSpinButtons: true,
              width: 120,
              dataSource: eventTypesNotificationData,
              displayExpr: "name",
              valueExpr: "id",
              noDataText: loadingStatuses.reminderType
                ? "Loading..."
                : "No data to display"
            }}
            label={{
              alignment: "left",
              location: "left",
              text: "Type notification"
            }}
          >
            <RequiredRule message="Required field" />
          </Item>
          <Item
            editorType="dxNumberBox"
            dataField="reminderBeforeMinutes"
            visible={isEnableNotification()}
            editorOptions={{
              showSpinButtons: true,
              width: 80,
              min: 3
            }}
            label={{
              text: "Send before",
              alignment: "left",
              location: "left"
            }}
          >
            <RangeRule message="The minimum value is 3" min={3} />
          </Item>
          <GroupItem cssClass={"mt-auto"} colSpan={1}>
            <Label visible={enableNotification} text={"min"} />
          </GroupItem>
        </GroupItem>

        <GroupItem colCount={2} cssClass={"mt-4"}>
          <ButtonItem horizontalAlignment={"left"}>
            <ButtonOptions
              type={"danger"}
              width={150}
              height={35}
              text={"Delete event"}
              visible={!hideButtonDelete}
              onClick={onDeleteRequest}
            />
          </ButtonItem>
          <GroupItem colCount={10}>
            <ButtonItem colSpan={6}>
              <ButtonOptions text="Save" width={100} height={35} onClick={onSubmit} />
            </ButtonItem>
            <ButtonItem colSpan={4} horizontalAlignment={"left"}>
              <ButtonOptions
                text="Close"
                width={100}
                height={35}
                onClick={() => props.hideEventPopup()}
              />
            </ButtonItem>
          </GroupItem>
        </GroupItem>
      </Form>
    </>
  );
}

export default AppointmentForm;
