import React, { useEffect, useState } from 'react';
import DataGrid, {
  Scrolling,
  Column,
  FilterRow,
} from 'devextreme-react/data-grid';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

const CasesNews = props => {
  const { user, selCompanyID, retrieveNewsKey, reloadGrid } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [casesNewsData, setCasesNewsData] = useState([]);
  const [calenderHomeData, setCalendarHomeData] = useState([]);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        /*   let casefilter = '';
        if (selCompanyID == -1) {
          casefilter = user.companyfilter;
        } else {
          casefilter = '(' + selCompanyID + ')';
        }
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/cases/news/${casefilter}`
        );
        setCasesNewsData(dataArray);
        */
      } catch (err) {}
    };
    fetchCases();
  }, [sendRequest, selCompanyID, user.companyfilter, reloadGrid]);

  const stCasesNews = new ArrayStore({
    key: ['LfdNr', 'Base_LfdNr'],
    data: casesNewsData,
  });

  const dcCasesNews = new DataSource({
    store: stCasesNews,
  });

  return (
    <React.Fragment>
      <div>
        <DataGrid
          id='cases-news-grid'
          dataSource={dcCasesNews}
          defaultFocusedRowIndex={0}
          showBorders={false}
          focusedRowEnabled={true}
          showRowLines={true}
          onFocusedRowChanged={e => {
            retrieveNewsKey(e.row.key, e.row.data.BaseAnruferid);
          }}
        >
          <Column dataField='LfdNr' visible={false}></Column>
          <Column dataField='Base_LfdNr' visible={false}></Column>
          <Column
            dataField='newsdata'
            caption='Notes'
            encodeHtml={false}
            dataType='string'
          ></Column>
        </DataGrid>
      </div>
    </React.Fragment>
  );
};

export default CasesNews;
