import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import { useHistory } from 'react-router-dom';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import './home.scss';
import '../../global.scss';

import { Form, SimpleItem, EmptyItem, RequiredRule, ButtonItem } from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import TabPanel from 'devextreme-react/tab-panel';
import { MultiView, SelectBox } from 'devextreme-react';
import Tabs from 'devextreme-react/tabs';

import DashOneTab from './DashOneTab';
import { useAuth } from '../../contexts/auth';
import { Label } from 'devextreme-react/data-grid';

import DashClientTab from './DashClientTab';
import DashProducts from './DashProducts';
import DashServices from './DashServices';
import DashPartner from './DashPartner';
import DashProvider from './DashProvider';
import DashClientinSignupTab from './DashClientinSignupTab';

const getScreenGridHeight = () => {
  const anValue = window.innerHeight - 220;
  return anValue;
};

const HomePage = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [tabIndex, setTabIndex] = useState(0);

  const get_Date = (an_Date) => {
    const ansiDate = new Date(an_Date.getTime() - an_Date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
    return ansiDate;
  };

  const past_Date = (anDate, anDays) => {
    let dt = new Date(anDate);
    dt.setDate(dt.getDate() + anDays);
    return dt;
  };

  const anPastDate = past_Date(new Date(), -30);

  const [selStartDate, setSelStartDate] = useState(get_Date(new Date(anPastDate)));
  const [selEndDate, setSelEndDate] = useState(get_Date(new Date()));

  const openAdminDash = () => {
    history.push({
      pathname: '/admindash',
    });
  };

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={3.2} />
            <Col ratio={1} />

            <Item>
              <Location screen='md lg sm xs' row={0} col={0} colspan={2} />
              <div className='rb-header item'>
                <ResponsiveBox>
                  <Row />
                  <Col ratio={3} />
                  <Col ration={1} />
                  <Item>
                    <Location screen='md lg sm' row={0} col={0} />
                    <div className='dx-fieldset-header' id='cap_item'>
                      Dashboard - Period: {selStartDate} until {selEndDate}
                    </div>
                    <div id='admin-dash-open-btn'>
                      <Button
                        width={200}
                        height={36}
                        text='Admin Dashbort'
                        type='default'
                        stylingMode='outlined'
                        onClick={openAdminDash}
                      />
                    </div>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg sm, xs' row={1} col={0} />
              <div className='rb-content item'>
                <ResponsiveBox>
                  <Row ratio={1} />
                  <Col ratio={1} />
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={0} />
                    <Tabs
                      id='homeNotesTab'
                      selectedIndex={tabIndex}
                      width='98%'
                      // height='20px'
                      visible={true}
                      onItemClick={(e) => {
                        setTabIndex(e.itemIndex);
                      }}
                    >
                      <Item text='Transactions' />
                      <Item text='Merchants' />
                      <Item text='Products' />
                      <Item text='Services' />
                      <Item text='Service Provider' />
                      <Item text='Clients' />
                      <Item text='Clients in Signup' />
                    </Tabs>
                    <MultiView height={getScreenGridHeight} animationEnabled={false} selectedIndex={tabIndex}>
                      <Item title='Cases One'>
                        <DashOneTab selStartDate={selStartDate} selEndDate={selEndDate} user={user} />
                      </Item>
                      <Item title='Merchants'>
                        <DashPartner selStartDate={selStartDate} selEndDate={selEndDate} user={user} />
                      </Item>
                      <Item title='Products'>
                        <DashProducts selStartDate={selStartDate} selEndDate={selEndDate} user={user} />
                      </Item>
                      <Item title='Services'>
                        <DashServices selStartDate={selStartDate} selEndDate={selEndDate} user={user} />
                      </Item>
                      <Item title='Service Provider'>
                        <DashProvider selStartDate={selStartDate} selEndDate={selEndDate} user={user} />
                      </Item>
                      <Item title='Clients'>
                        <DashClientTab selStartDate={selStartDate} selEndDate={selEndDate} user={user} />
                      </Item>
                      <Item title='Clients in Signup'>
                        <DashClientinSignupTab selStartDate={selStartDate} selEndDate={selEndDate} user={user} />
                      </Item>
                    </MultiView>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomePage;
