import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useHttpClient } from "../../hooks/http-hook";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import Button from "devextreme-react/button";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

import DataGrid, {
  Scrolling,
  Column,
  FilterRow,
} from "devextreme-react/data-grid";

const ServiceProvTransactions = (props) => {
  const { user, selProviderId } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [provTransactionsData, setProvTransactionsData] = useState([]);
  const [selectedTransactionid, setSelectedTransactionid] = useState(-1);
  const [doReload, setDoReload] = useState(false);

  useEffect(() => {

    const fetchProvTransactions = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/rep/provtransactions/${selProviderId}`,
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setProvTransactionsData(dataArray);
      } catch (err) {}
    };

    if (selProviderId || doReload) {
      fetchProvTransactions();
    }
  }, [sendRequest, selProviderId, doReload]);

  const stprovTransactionsData = new ArrayStore({
    key: "transactionid",
    data: provTransactionsData,
  });

  const dcProvTransactionsData = new DataSource({
    store: stprovTransactionsData,
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />

        <Item>
          <Location screen="md lg sm xs" row={0} col={0} />
          <div>
            <DataGrid
              id="service-prov-clients-signup-tab-grid"
              dataSource={dcProvTransactionsData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              autoNavigateToFocusedRow={true}
              focusedRowKey={selectedTransactionid}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  setSelectedTransactionid(e.row.key);
                }
              }}
            >
              <Column dataField="transactionid" visible={false}></Column>
              <Column dataField="providerid" visible={false}></Column>
              <Column dataField="clientid" visible={false}></Column>
              <Column
                dataField="transferdate"
                caption="Transfer Date"
                dataType="datetime"
                width={120}
              ></Column>
              <Column
                dataField="servicename"
                caption="Service"
                visible={true}
                width={250}
              />
              <Column
                dataField="operation"
                caption="Operation"
                visible={true}
                width={80}
              />{" "}
              <Column
                dataField="assetname"
                caption="Asset"
                visible={true}
                width={100}
              />
              <Column
                dataField="assetamount"
                caption="Amount"
                visible={true}
                width={100}
              />
              <Column
                dataField="assetcalcunit"
                caption="Unit"
                visible={true}
                width={70}
              />
              <Column
                dataField="currency"
                caption="Currency"
                visible={true}
                width={100}
              />
              <Column
                dataField="currencyamount"
                caption="Amount"
                visible={true}
                width={100}
              />
              <Column
                dataField="provider_fee"
                caption="Fee"
                visible={true}
                width={100}
              />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ServiceProvTransactions;
