import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';

import './Networks.css';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import RoutingEditPop from './NetworksEditPop';

const getGridHeight = () => {
  const anValue = window.innerHeight - 700;
  // console.log(5678, anValue);
  return anValue;
};

const RoutingTab = (props) => {
  const { passToParent, user } = props;

  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [routingsList, setRoutingsList] = useState([]);
  const [selectedRoutingid, setSelectedRoutingid] = useState(false);
  const [showRoutingAddPopup, setShowRoutingAddPopup] = useState(false);
  const [reloadRoutings, setReloadRoutings] = useState(false);

  useEffect(() => {
    const fetchRoutings = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/routing/financialroutings',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setRoutingsList(dataArray);
      } catch (err) {}
    };
    fetchRoutings();
  }, [sendRequest, reloadRoutings]);

  const stRoutingData = new ArrayStore({
    key: 'routingid',
    data: routingsList,
  });

  const dcRoutingData = new DataSource({
    store: stRoutingData,
  });

  const openNetworkData = () => {
    user.routing = {
      selRoutingid: selectedRoutingid,
      user: user,
      modeInsert: false,
    };
    history.push({
      pathname: '/networkdata',
    });
  };

  const openRoutingInsert = () => {
    user.routing = {
      selRoutingid: -1,
      user: user,
      modeInsert: true,
    };
    history.push({
      pathname: '/routingedit',
    });
  };

  const openRoutingAdd = () => {
    setSelectedRoutingid(-1);
    setShowRoutingAddPopup(true);
  };

  const hideRoutingPopup = useCallback((doRoutingReload) => {
    setShowRoutingAddPopup(false);
    if (doRoutingReload) {
      setReloadRoutings(!reloadRoutings);
    }
  });

  return (
    <React.Fragment>
      <RoutingEditPop
        showPopup={showRoutingAddPopup}
        hidePopup={hideRoutingPopup}
        selRoutingid={selectedRoutingid}
        user={user}
        modeInsert={true}
      />
      <ResponsiveBox>
        <Col ratio={1} screen='md lg sm' />
        <Col ratio={10} />
        <Row />

        <Item>
          <Location screen='md lg sm' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <Button width={120} height={36} text='Add' type='default' stylingMode='outlined' onClick={openRoutingAdd} />
          </div>
          <div id='hm-btn-section-11'>
            <Button
              width={120}
              height={36}
              text='Open'
              type='success'
              stylingMode='outlined'
              onClick={openNetworkData}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div>
            <DataGrid
              id='routings-grid'
              height={getGridHeight}
              dataSource={dcRoutingData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  passToParent(e.row.key);
                  setSelectedRoutingid(e.row.key);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='routingid' visible={false}></Column>
              <Column dataField='routingname' caption='Network' width={200}></Column>
              <Column dataField='opsaccount' caption='Operational Account' width={200} />
              <Column dataField='quaaccount' caption='Quarantine Account' width={200} />
              <Column dataField='mrgaccount' caption='Margin Account' width={200} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default RoutingTab;
