import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import './PartnerPage.css';

import {
  Form,
  SimpleItem,
  EmptyItem,
  RequiredRule,
  ButtonItem,
} from 'devextreme-react/form';

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from 'devextreme-react/responsive-box';

import { Tabs, MultiView, SelectBox } from 'devextreme-react';
import { useAuth } from '../../contexts/auth';
import { Label } from 'devextreme-react/data-grid';

import PartnerTab from './PartnerTab';
import PartnerNotes from './PartnerNotes';
import PartnerServices from './PartnerServices';
import PartnerClientsSignup from './PartnerClientsSignup';
import PartnerProducts from './PartnerProducts';
import PartnerClientsApproved from './PartnerClientsApproved';
import PartnerTransactions from './PartnerTransactions';

const PartnerPage = () => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [contactPersonid, setContactPersonid] = useState();
  const [selectedPartnerid, setSelectedPartnerid] = useState();
  const [financialRoutingid, setFinancialRoutingid] = useState();
  const [tabIndex, setTabIndex] = useState(0);

  const getsavedselcompany = () => {
    const result = -1;
    let loaded = JSON.parse(localStorage.getItem('storedCompany'));
    if (loaded && loaded !== null) {
      return loaded.cid;
    } else {
      return result;
    }
  };

  const [selectedCompany, setSelectedCompany] = useState(getsavedselcompany);

  const baseformData = {
    selCompanyid: selectedCompany,
    selLfdNr: -1,
    selBase_LfdNr: -1,
  };

  const getPersonid = useCallback((fPartnerid, fPersonid, fRoutingid) => {
    setSelectedPartnerid(fPartnerid);
    setContactPersonid(fPersonid);
    setFinancialRoutingid(fRoutingid);
  });

  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Cases</h2> */}
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={2} />
            <Col />

            <Item>
              <Location screen='md lg' row={0} col={0} colspan={2} />
              <Location screen='sm' row={0} col={0} colspan={1} />
              <Location screen='xs' row={0} col={0} />
              <div className='rb-header item'>
                <ResponsiveBox>
                  <Row />
                  <Col ratio={3} />
                  <Col ration={1} />
                  <Item>
                    <Location screen='md lg sm' row={0} col={0} />
                    <div className='dx-fieldset-header' id='cap_item'>
                      Merchants / Partner
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={1} />
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg sm xs' row={1} col={0} />
              <div className='rb-content item'>
                <ResponsiveBox>
                  <Row />
                  <Col />
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={0} />
                    <PartnerTab
                            passToParent={getPersonid}
                            user={user}
                            selCompanyID={selectedCompany}
                          />
                      <div>
                      <ResponsiveBox>
                      <Col ratio={0.33} screen='md lg sm' />
                      <Col ratio={3} />
                      <Row />
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={0} />
                      </Item>
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={1} />
                        <Tabs
                          selectedIndex={tabIndex}
                          width='99%'
                          // height='20px'
                          visible={true}
                          onItemClick={e => {
                            setTabIndex(e.itemIndex);
                          }}
                        >
                          <Item text='Services' />
                          <Item text='Products' />
                          <Item text='Clients in Signup' />
                          <Item text='Active Clients' />
                          <Item text='Transactions' />
                        </Tabs>
                        <MultiView
                          height={490}
                          animationEnabled={false}
                          selectedIndex={tabIndex}
                        >
                          <Item title='Partner Services'>
                            <PartnerServices
                              selPartnerId={selectedPartnerid}
                              user={user}
                            />
                          </Item>
                          <Item title='Partner Products'>
                            <PartnerProducts
                              selPartnerId={selectedPartnerid}
                              user={user}
                            />
                          </Item>
                          <Item title='Partner Clients in Signup'>
                            <PartnerClientsSignup
                              selPartnerId={selectedPartnerid}
                              user={user}
                            />
                          </Item>

                          <Item title='Partner Clients Approved'>
                            <PartnerClientsApproved
                              selPartnerId={selectedPartnerid}
                              user={user}
                            />
                          </Item>
                          <Item title='Partner Clients Approved'>
                            <PartnerTransactions
                              selPartnerId={selectedPartnerid}
                              user={user}
                            />
                          </Item>
                        </MultiView>
                      </Item>
                    </ResponsiveBox>

                      </div>
                  </Item>
                 </ResponsiveBox>
              </div>
            </Item>

          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PartnerPage;
