import React, { createRef, useEffect, useState } from "react";
import { Button, DataGrid } from "devextreme-react";
import { Column, Pager, SearchPanel } from "devextreme-react/data-grid";
import paginator from "../../../modules/paginator/Paginator";
import { withRouter } from "react-router-dom";
import { MailingGetEmails } from "./MailingHelper";

function MailingsList(props) {
  let dataGridRef = createRef();

  const [emailsDataList, setEmailsDataList] = useState();

  useEffect(() => {
    setEmailsDataList(initGrid());
  }, []);

  const initGrid = () => {
    return paginator(MailingGetEmails, [], "id", 10);
  };

  const addNewEmail = () => {
    props.history.push("/mailing/email");
  };

  const renderAttachmentHeader = () => {
    return <i className="dx-icon dx-icon-file"/>;
  };

  const renderAttachmentCell = cellData => {
    return (
      cellData.data?.attaches?.length > 0 && (
        <i className="dx-icon dx-icon-file"/>
      )
    );
  };

  const renderRecipientCell = cellData => {
    let result = "";
    if (cellData.data.recipients) {
      result = cellData.data.recipients
        .map(recipient => {
          return recipient.address;
        })
        .join(", ");
    }
    return result;
  };

  const renderInfoCell = cellData => {
    return cellData.data.status === "SENT" ? (
      <Button
        icon="info"
        height={35}
        onClick={() => {
          props.history.push(`/mailing/review-email/${cellData.data.id}`);
        }}
      />
    ) : (
      <Button
        icon="edit"
        height={35}
        onClick={() => {
          props.history.push(`/mailing/email/${cellData.data.id}`);
        }}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="content">
        <div className={"row row-flex-space-between mt-4"}>
          <Button
            text="New email"
            type="normal"
            stylingMode="outlined"
            onClick={addNewEmail}
            height={35}
          />
        </div>
        <div className={"mt-4"}>
          <DataGrid
            ref={dataGridRef}
            id="rep-transactions-notes-grid"
            dataSource={emailsDataList}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            focusedRowEnabled={false}
            hoverStateEnabled={true}
            showBorders={true}
            wordWrapEnabled={true}
            showRowLines={true}
            showColumnHeaders={true}
            remoteOperations={true}
            height={"100%"}
          >
            <SearchPanel visible={false} placeholder="Search..." />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <Column dataField="id" visible={false} allowSearch={false} />

            <Column
              headerCellRender={renderAttachmentHeader}
              width={70}
              cellRender={renderAttachmentCell}
            />

            <Column
              dataField="subject"
              encodeHtml={false}
              caption="Subject"
              dataType="string"
            />
            <Column
              dataField="mailingAccount.fromEmail"
              encodeHtml={false}
              caption="From"
              dataType="string"
              allowSearch={false}
            />
            <Column
              dataField="recipient"
              allowSearch={false}
              alignment="left"
              cssClass="column-nowrap"
              caption="To"
              cellRender={renderRecipientCell}
              width={200}
            />
            <Column
              dataField="createdOn"
              caption="Date"
              dataType="datetime"
              width={150}
              visible={true}
            />
            <Column width={70} cellRender={renderInfoCell} />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(MailingsList);
