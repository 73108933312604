import React, { useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem, ButtonOptions } from 'devextreme-react/form';

import DataGrid, { Column } from 'devextreme-react/data-grid';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

const ComplianceRiskGroupPop = props => {
  const { showPopup, hidePopup, riskGroupid, modelid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [riskMatrixGroup, setRiskMatrixGroup] = useState([]);
  const [riskMartixDataGrid, setRiskMartixDataGrid] = useState();

  const [riskDataModified, setRiskDataModified] = useState(false);

  useEffect(() => {
    const fetchRiskGroup = async () => {
      const response = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/ai/feature-matrix/feature-group-values/${modelid}/${riskGroupid}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + user.token }
      );
      // console.log(response);
      setRiskMatrixGroup(response);
      setRiskDataModified(false);
    };

    if ((showPopup && riskGroupid) || (showPopup && riskDataModified)) {
      fetchRiskGroup();
    }
  }, [modelid, riskGroupid, showPopup, riskDataModified]);

  const striskMatrixGroup = new ArrayStore({
    key: 'group_entryid',
    data: riskMatrixGroup,
  });

  const dcRiskMatrixGroup = new DataSource({
    store: striskMatrixGroup,
  });

  const riskGroupData = {
    risk_groupid: riskGroupid,
    group_entryid:
      riskMartixDataGrid && riskMartixDataGrid.group_entryid && riskMatrixGroup.length > 0
        ? riskMartixDataGrid.group_entryid
        : 1,
    group_entry:
      riskMartixDataGrid && riskMartixDataGrid.group_entry && riskMatrixGroup.length > 0
        ? riskMartixDataGrid.group_entry
        : '',
    group_entry_score:
      riskMartixDataGrid && riskMartixDataGrid.group_entry_score && riskMatrixGroup.length > 0
        ? riskMartixDataGrid.group_entry_score
        : 1,
  };

  const modMatrixRiskGroupEntry = async modify => {
    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + '/ai/feature-matrix/mod_feature_values',
      'POST',
      JSON.stringify({
        operation: modify,
        modelid: modelid,
        risk_groupid: riskGroupid,
        group_entryid: riskGroupData.group_entryid,
        group_entry: riskGroupData.group_entry,
        group_entry_score: riskGroupData.group_entry_score,
      }),
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    );
    setRiskDataModified(response);
  };

  return (
    <Popup
      title={'Risk Groups'}
      width={800}
      height={560}
      visible={showPopup}
      resizeEnabled={false}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={10} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} colspan={3} />
          <Form formData={riskGroupData} labelLocation={'left'}>
            <Item itemType='group' colCount={14}>
              <SimpleItem
                dataField='group_entry'
                colSpan={4}
                editorOptions={{
                  
                }}
              >
                <Label location='left' text='Entry' />
              </SimpleItem>
              <SimpleItem
                dataField='group_entry_score'
                colSpan={2}
                editorOptions={{
                  
                }}
              >
                <Label location='left' text='Score' />
              </SimpleItem>
              <ButtonItem colSpan={2}>
                <ButtonOptions
                  width='120px'
                  height='100%'
                  type='default'
                  text='Add'
                  onClick={() => {
                    modMatrixRiskGroupEntry(1, riskMartixDataGrid);
                  }}
                ></ButtonOptions>
              </ButtonItem>
              <ButtonItem colSpan={2}>
                <ButtonOptions
                  width='120px'
                  height='100%'
                  type='success'
                  text='Mod'
                  onClick={() => {
                    modMatrixRiskGroupEntry(2, riskMartixDataGrid);
                  }}
                ></ButtonOptions>
              </ButtonItem>
              <ButtonItem colSpan={2}>
                <ButtonOptions
                  width='120px'
                  height='100%'
                  type='danger'
                  text='REM'
                  onClick={() => {
                    modMatrixRiskGroupEntry(3, riskMartixDataGrid);
                  }}
                ></ButtonOptions>
              </ButtonItem>
            </Item>
          </Form>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <DataGrid
            id='Matrix-Feature-Value-Grid'
            dataSource={dcRiskMatrixGroup}
            showRowLines={true}
            showBorders={true}
            showColumnLines={true}
            columnAutoWidth={true}
            defaultFocusedRowIndex={0}
            focusedRowEnabled={true}
            showColumnHeaders={true}
            onFocusedRowChanged={e => {
              setRiskMartixDataGrid(e.row.data);
            }}
          >
            <Column dataField='risk_groupid' visible={false} />
            <Column dataField='group_entryid' caption='Entryid' width='70' visible={false} />
            <Column dataField='group_entry' caption='Entry' />
            <Column dataField='group_entry_score' caption='Score' width='70' />
          </DataGrid>
          <div id={'closebutton-2'}>
            <div style={{ flexDirection: 'row' }}>
              <Button width={120} height={50} text='Close' type='normal' stylingMode='contained' onClick={hidePopup} />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default ComplianceRiskGroupPop;
