import React, { useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule } from 'devextreme-react/form';

const ServiceEditTOUPop = props => {
  const { showPopup, hidePopup, selServiceId, selTermsofUseId, popInsert, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const noteFormRef = useRef(null);
  const [touData, setTouData] = useState({});
  const [opId, setOpId] = useState(1);

  useEffect(() => {
    const fetchTouData = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/servicetou/${selServiceId}/${selTermsofUseId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setTouData(dataArray[0]);
      } catch (err) {}
    };
    if (selTermsofUseId && showPopup && popInsert === 2) {
      fetchTouData();
    } else if (selTermsofUseId && showPopup && popInsert === 1) {
      setTouData({});
    }
  }, [sendRequest, selServiceId, selTermsofUseId, showPopup]);

  const touRecord = {
    serviceid: selServiceId,
    servicetouid: touData.servicetouid || -1,
    doc_title: touData.doc_title || '',
    doc_usage_advice: touData.doc_usage_advice || '',
    doc_content: touData.doc_content || '',
    validon: touData.validon || new Date(),
    validuntil: touData.validuntil || '',
  };

  const saveTouDoc = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/service/servicedoctou',
        'POST',
        JSON.stringify({
          operation: popInsert,
          serviceid: selServiceId,
          servicetouid: touRecord.servicetouid,
          doc_title: touRecord.doc_title,
          doc_usage_advice: touRecord.doc_usage_advice,
          doc_content: touRecord.doc_content,
          validon: touRecord.validon,
          validuntil: touRecord.validuntil,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async noteFormRef => {
    const result = noteFormRef.validationGroup.validate();
    if (result.isValid) {
      saveTouDoc();
      hidePopup(true);
    } else {
      alert('Please complete all required fields');
    }
  };

  return (
    <Popup
      title='Terms of Use'
      // width={800}
      // height={640}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.1} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <Form
              id={'form'}
              ref={noteFormRef}
              formData={touRecord}
              labelLocation={'left'}
              colCountByScreen={colCountByScreen}
              validationGroup='Service_TOU_Doc'
            >
              <Item itemType='group' colCount={4}>
                <SimpleItem dataField='doc_title' isRequired={true} colSpan={2}>
                  <Label text='Document Title' />
                </SimpleItem>
                <SimpleItem dataField='validon' editorType='dxDateBox' editorOptions={{ type: 'datetime' }}>
                  <Label text='Valid on' />
                </SimpleItem>
                <SimpleItem dataField='validuntil' editorType='dxDateBox' editorOptions={{ type: 'datetime' }}>
                  <Label text='valid until' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={1}>
                <SimpleItem
                  dataField='doc_content'
                  editorType='dxTextArea'
                  isRequired={true}
                  editorOptions={{ height: 450 }}
                >
                  <Label text='Content' />
                </SimpleItem>
              </Item>
            </Form>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'closebutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                // visible={openedToEdit === 1}
                visible={true}
                width={120}
                height={50}
                text='Save'
                type='normal'
                stylingMode='contained'
                validationGroup='Service_TOU_Doc'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
              <Button
                width={120}
                height={50}
                text='close'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  hidePopup();
                }}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServiceEditTOUPop;
