import React, { useRef, useState, useEffect } from 'react';
import './GlobalDefinitions.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Form, SimpleItem, Label, EmptyItem } from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import Tabs from 'devextreme-react/tabs';
import { MultiView } from 'devextreme-react';
import HTTP from '../../api/HTTP';

const OpTemplatesTab = (props) => {
  const { user } = props;
  const selPortalid = 1;

  const [reloadData, setReloadData] = useState(false);
  const [opsTemplatesData, setOpsTemplatesData] = useState({});
  const [caseSituationsData, setCaseSituationsData] = useState([]);
  const [selSituation, setSelSituation] = useState(-1);
  const [sendtoReceiverData, setSendtoReceiverData] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [mailTemplatesData, setMailTemplatesData] = useState([]);
  const [cRMActivitiesLpData, setCRMActivitiesLpData] = useState([]);
  const [logSectionsData, setLogSectionsData] = useState([]);

  const [refreshData, setRefreshData] = useState(false);

  const oData = {
    case_situation: opsTemplatesData && opsTemplatesData.case_situation,
    portalid: opsTemplatesData && opsTemplatesData.portalid,
    sys_subject: opsTemplatesData && opsTemplatesData.sys_subject,
    sys_content: opsTemplatesData && opsTemplatesData.sys_content,
    sys_to: opsTemplatesData && opsTemplatesData.sys_to,
    adm_subject: opsTemplatesData && opsTemplatesData.adm_subject,
    adm_content: opsTemplatesData && opsTemplatesData.adm_content,
    adm_to: opsTemplatesData && opsTemplatesData.adm_to,
    prov_subject: opsTemplatesData && opsTemplatesData.prov_subject,
    prov_content: opsTemplatesData && opsTemplatesData.prov_content,
    prov_to: opsTemplatesData && opsTemplatesData.prov_to,
    adm_mail_templateid: opsTemplatesData && opsTemplatesData.adm_mail_templateid,
    adm_send_mail: opsTemplatesData && opsTemplatesData.adm_send_mail,
    adm_sms_templateid: opsTemplatesData && opsTemplatesData.adm_sms_templateid,
    adm_send_sms: opsTemplatesData && opsTemplatesData.adm_send_sms,
    adm_crm_activityid: opsTemplatesData && opsTemplatesData.adm_crm_activityid,
    adm_log: opsTemplatesData && opsTemplatesData.adm_log,
    adm_log_sectionid: opsTemplatesData && opsTemplatesData.adm_log_sectionid,
    adm_close_crm_activityid: opsTemplatesData && opsTemplatesData.adm_close_crm_activityid,
    adm_origin_crm_activityid: opsTemplatesData && opsTemplatesData.adm_origin_crm_activityid,
    adm_next_templateid: opsTemplatesData && opsTemplatesData.adm_next_templateid,
  };

  useEffect(() => {
    const fetchOpsTemplates = async () => {
      try {
        const dataArray = await HTTP.get(`/cases/opstemplate/${selSituation}`);

        setOpsTemplatesData(dataArray.data[0]);
      } catch (err) {}
    };

    if (selSituation || reloadData) {
      fetchOpsTemplates();
    }
  }, [sendRequest, selSituation, reloadData, refreshData]);

  useEffect(() => {
    const fetchCaseSituations = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/41');
        setCaseSituationsData(dataArray.data);
      } catch (err) {}
    };

    const fetchSendtoReceiver = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/42');
        setSendtoReceiverData(dataArray.data);
      } catch (err) {}
    };

    const fetchCRMActivitiesLp = async () => {
      try {
        const dataArray = await HTTP.get('/base/crm-activities-lp');
        setCRMActivitiesLpData(dataArray.data);
      } catch (err) {}
    };

    const fetchLogSections = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/57');
        setLogSectionsData(dataArray.data);
      } catch (err) {}
    };

    fetchCaseSituations();
    fetchSendtoReceiver();
    fetchCRMActivitiesLp();
    fetchLogSections();
  }, [refreshData]);

  useEffect(() => {
    const fetchMailTemplates = async () => {
      try {
        const dataArray = await HTTP.get(`/email/mailtemplates/${selPortalid}`);
        setMailTemplatesData(dataArray.data);
      } catch (err) {}
    };
    if (selPortalid) {
      fetchMailTemplates();
    }
  }, [selPortalid, reloadData, refreshData]);

  const stMailTemplatesData = new ArrayStore({
    key: 'template_id',
    data: mailTemplatesData,
  });

  const stCaseSituationData = new ArrayStore({
    key: 'lp_id',
    data: caseSituationsData,
  });

  const stsendtoReceiverData = new ArrayStore({
    key: 'lp_id',
    data: sendtoReceiverData,
  });

  const stCRMActivitiesLpData = new ArrayStore({
    key: 'activityid',
    data: cRMActivitiesLpData,
  });

  const stLogSectionsData = new ArrayStore({
    key: 'lp_id',
    data: logSectionsData,
  });

  const modifyOpsTemplate = async (modify) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/cases/modopstemplate',
        'POST',
        JSON.stringify({
          operation: modify,
          case_situation: selSituation,
          portalid: 1,
          sys_subject: oData.sys_subject,
          sys_content: oData.sys_content,
          sys_to: oData.sys_to,
          adm_subject: oData.adm_subject,
          adm_content: oData.adm_content,
          adm_to: oData.adm_to,
          prov_subject: oData.prov_subject,
          prov_content: oData.prov_content,
          prov_to: oData.prov_to,
          adm_mail_templateid: oData.adm_mail_templateid,
          adm_send_mail: oData.adm_send_mail,
          adm_sms_templateid: oData.adm_sms_templateid,
          adm_send_sms: oData.adm_send_sms,
          adm_crm_activityid: oData.adm_crm_activityid,
          adm_log: oData.adm_log,
          adm_log_sectionid: oData.adm_log_sectionid,
          adm_close_crm_activityid: oData.adm_close_crm_activityid,
          adm_origin_crm_activityid: oData.adm_origin_crm_activityid,
          adm_next_templateid: oData.adm_next_templateid,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setReloadData(!reloadData);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Operation Templates</div>

        <div id='op-template-form'>
          <ResponsiveBox>
            <Row ratio={0} />
            <Col ratio={5} />
            <Col ratio={12} />

            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <div>
                <DataGrid
                  id='ops-template-grid'
                  dataSource={stCaseSituationData}
                  showRowLines={true}
                  showBorders={true}
                  showColumnLines={true}
                  columnAutoWidth={true}
                  defaultFocusedRowIndex={0}
                  focusedRowEnabled={true}
                  showColumnHeaders={true}
                  onFocusedRowChanged={(e) => {
                    e.row && setSelSituation(e.row.key);
                  }}
                >
                  <Column dataField='lp_id' visible={true} caption='ID' width='50' />
                  <Column dataField='lp_value' caption='Situation' width='250'></Column>
                </DataGrid>
              </div>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={1} />
              <ResponsiveBox>
                <Row ratio={9} />
                <Row ratio={1} />
                <Col />
                <Item>
                  <Location screen='md lg sm xs' row={0} col={0} />
                  <div>
                    <Form
                      id={'mail-template-form'}
                      formData={oData}
                      labelLocation={'left'}
                      colCountByScreen={colCountByScreen}
                    >
                      <Item itemType='group' colCount={10}>
                        <SimpleItem dataField='adm_subject' colSpan={6}>
                          <Label text='Subject' />
                        </SimpleItem>
                        <SimpleItem
                          dataField='adm_to'
                          editorType='dxSelectBox'
                          colSpan={4}
                          name='sys_to_listbox'
                          editorOptions={{
                            dataSource: stsendtoReceiverData,
                            valueExpr: 'lp_id',
                            displayExpr: 'lp_value',
                            searchEnabled: true,
                          }}
                        >
                          <Label text='Send to' />
                        </SimpleItem>
                      </Item>
                      <Item itemType='group' colCount={1}>
                        <SimpleItem dataField='adm_content' editorType='dxTextArea' editorOptions={{ height: 150 }}>
                          <Label text='Message' />
                        </SimpleItem>
                      </Item>
                      <EmptyItem />
                      <Item itemType='group' colCount={10}>
                        <SimpleItem dataField='adm_send_mail' colSpan={2} editorType='dxCheckBox'>
                          <Label text='Send Mail' />
                        </SimpleItem>
                        <SimpleItem
                          dataField='adm_mail_templateid'
                          editorType='dxSelectBox'
                          colSpan={4}
                          name='adm_mail_listbox'
                          editorOptions={{
                            dataSource: stMailTemplatesData,
                            valueExpr: 'template_id',
                            displayExpr: 'template_name',
                            searchEnabled: true,
                          }}
                        >
                          <Label text='Mail Template' />
                        </SimpleItem>
                      </Item>
                      <Item itemType='group' colCount={10}>
                        <SimpleItem dataField='adm_send_sms' colSpan={2} editorType='dxCheckBox'>
                          <Label text='Send SMS' />
                        </SimpleItem>
                        <SimpleItem
                          dataField='adm_sms_templateid'
                          editorType='dxSelectBox'
                          colSpan={4}
                          name='adm_sms_listbox'
                          editorOptions={{
                            // dataSource: stMailTemplatesData,
                            valueExpr: 'template_id',
                            displayExpr: 'template_name',
                            searchEnabled: true,
                          }}
                        >
                          <Label text='SMS Template' />
                        </SimpleItem>
                      </Item>
                      <EmptyItem />
                      <Item itemType='group' colCount={10} caption='CRM Activity - Target'>
                        <SimpleItem
                          dataField='adm_crm_activityid'
                          editorType='dxSelectBox'
                          colSpan={5}
                          name='crm_activities_listbox'
                          editorOptions={{
                            dataSource: stCRMActivitiesLpData,
                            valueExpr: 'activityid',
                            displayExpr: 'activityname',
                            searchEnabled: true,
                          }}
                        >
                          <Label text='Set Activity' />
                        </SimpleItem>
                        <SimpleItem
                          dataField='adm_next_templateid'
                          editorType='dxSelectBox'
                          colSpan={5}
                          name='crm_activities_listbox'
                          editorOptions={{
                            dataSource: stCaseSituationData,
                            valueExpr: 'lp_id',
                            displayExpr: 'lp_value',
                            searchEnabled: true,
                          }}
                        >
                          <Label text='Next Auto Template' />
                        </SimpleItem>
                      </Item>
                      <Item itemType='group' colCount={10} caption='CRM Activity - Origin'>
                        <SimpleItem
                          dataField='adm_close_crm_activityid'
                          editorType='dxSelectBox'
                          colSpan={5}
                          name='close_crm_activities_listbox'
                          editorOptions={{
                            dataSource: stCRMActivitiesLpData,
                            valueExpr: 'activityid',
                            displayExpr: 'activityname',
                            searchEnabled: true,
                          }}
                        >
                          <Label text='Close Activity' />
                        </SimpleItem>
                        <SimpleItem
                          dataField='adm_origin_crm_activityid'
                          editorType='dxSelectBox'
                          colSpan={5}
                          name='origin_crm_activities_listbox'
                          editorOptions={{
                            dataSource: stCRMActivitiesLpData,
                            valueExpr: 'activityid',
                            displayExpr: 'activityname',
                            searchEnabled: true,
                          }}
                        >
                          <Label text='Set Activity' />
                        </SimpleItem>
                      </Item>
                      <EmptyItem />
                      <Item itemType='group' colCount={10}>
                        <SimpleItem dataField='adm_log' colSpan={2} editorType='dxCheckBox'>
                          <Label text='Log' />
                        </SimpleItem>
                        <SimpleItem
                          dataField='adm_log_sectionid'
                          editorType='dxSelectBox'
                          colSpan={4}
                          name='adm_log_sectionid_listbox'
                          editorOptions={{
                            dataSource: stLogSectionsData,
                            valueExpr: 'lp_id',
                            displayExpr: 'lp_value',
                            searchEnabled: true,
                          }}
                        >
                          <Label text='to Section' />
                        </SimpleItem>
                      </Item>
                    </Form>
                  </div>
                </Item>
                <Item>
                  <Location screen='md lg sm xs' row={1} col={0} />
                  <div id={'closebutton-2'}>
                    <div style={{ flexDirection: 'row' }}>
                      <Button
                        id='templatebutton-right'
                        width={120}
                        height={36}
                        text='Refresh'
                        icon='refresh'
                        type='normal'
                        stylingMode='outlined'
                        // useSubmitBehavior={true}
                        onClick={() => {
                          setRefreshData(!refreshData);
                        }}
                      />{' '}
                      <Button
                        id='templatebutton-right'
                        width={120}
                        height={36}
                        text='Save'
                        type='success'
                        stylingMode='outlined'
                        // useSubmitBehavior={true}
                        onClick={() => {
                          modifyOpsTemplate(2);
                        }}
                      />{' '}
                      <Button
                        width={120}
                        height={36}
                        text='Cancel'
                        type='normal'
                        stylingMode='outlined'
                        onClick={() => {
                          setReloadData(!reloadData);
                        }}
                      />
                    </div>
                  </div>
                </Item>
              </ResponsiveBox>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default OpTemplatesTab;
