import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { ArgumentAxis, Chart, Series, ValueAxis } from 'devextreme-react/chart';

const DashOneTab = props => {
  const { selStartDate, selEndDate, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [dashRevData, setDashRevData] = useState([]);

  useEffect(() => {
    const fetchDashRev = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/rep/dashrevenue/${selStartDate}/${selEndDate}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setDashRevData(dataArray);
      } catch (err) {}
    };
    fetchDashRev();
  }, [sendRequest]);

  const stDashRevData = new ArrayStore({
    key: 'day',
    data: dashRevData,
  });

  const dcDashRevData = new DataSource({
    store: stDashRevData,
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col ratio={0.1} screen='md lg sm  xs' />
        <Col ratio={2} />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div id='dash-area-01'>
            <Chart id='dash-rev-chart' dataSource={dcDashRevData}>
              <Series valueField='revenue' argumentField='day' name='Transactions' type='bar' color='#ffaa66' />
              <ArgumentAxis argumentType='datetime' title='Days' />
              <ValueAxis title='EUR' />
            </Chart>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default DashOneTab;
