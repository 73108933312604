import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import './costrateservices.css';

import Tabs from 'devextreme-react/tabs';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow, Lookup, MasterDetail } from 'devextreme-react/data-grid';

import ServiceFeeGridDetails from './ServiceFeeGridDetails';

const ServiceFees = props => {
  const { selCostEntryid, user, selServiceid } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [costRateHeaderList, setCostRateHeaderList] = useState({});
  const [selRiskid, setSelRiskid] = useState(1);
  const [costRiskEntriesList, setCostRiskEntriesList] = useState([]);
  const [riskGridData, setRiskGridData] = useState({});
  const [selWorkId, setSelWorkId] = useState(-1);

  useEffect(() => {
    const fetchCostRatesHeader = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/singlecostrateheader/${selCostEntryid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCostRateHeaderList(dataArray[0]);
      } catch (err) {}
    };
    if (selCostEntryid && selCostEntryid > -1) {
      fetchCostRatesHeader();
    }
  }, [sendRequest, selCostEntryid]);

  useEffect(() => {
    const fetchCostRiskEntries = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/viewcostriskentries/${selCostEntryid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCostRiskEntriesList(dataArray);
      } catch (err) {}
    };
    if (selCostEntryid && selCostEntryid > -1) {
      fetchCostRiskEntries();
    }
  }, [sendRequest, selCostEntryid]);

  const stCostRiskEntriesData = new ArrayStore({
    key: ['riskid'],
    data: costRiskEntriesList,
  });

  const dcCostRiskEntriesData = new DataSource({
    store: stCostRiskEntriesData,
  });

  const selRiskGridData = {
    serviceid: selServiceid,
    costentryid: selCostEntryid,
    riskid: riskGridData.riskid,
    not_applicable: riskGridData.not_applicable,
    rates_set: riskGridData.rates_set,
    setupfee: riskGridData.setupfee,
    recurringfee: riskGridData.recurringfee,
    recurringperiodid: riskGridData.recurringperiodid,
    regional_limitation: riskGridData.regional_limitation,
    regional_limittypeid: riskGridData.regional_limittypeid,
  };

  const openServiceFeeEdit = async () => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/service/costrateworkcopy/${selServiceid}/${selCostEntryid}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + user.token }
      );
      history.push({
        pathname: '/servicefees_edit',
        costrates: {
          selServiceid: selServiceid,
          selCostEntryid: selCostEntryid,
          user: user,
          workId: dataArray[0].work_copy_costrates,
          originid: 1,
        },
      });
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col ratio={0.3} screen='md lg sm xs' />
        <Col ratio={3} />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <Button
              width={120}
              height={50}
              text='Edit'
              type='normal'
              stylingMode='outlined'
              onClick={openServiceFeeEdit}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div>
            <Form
              id='service-cost-entries-form-edit-base'
              // ref={dataFormRef}
              formData={selRiskGridData}
              labelLocation={'left'}
              colCountByScreen={colCountByScreen}
              scrollingEnabled={true}
            >
              <Item>
                <DataGrid
                  // id={'service-cost-rates-entries-base-grid'}
                  dataSource={dcCostRiskEntriesData}
                  defaultFocusedRowIndex={0}
                  columnAutoWidth={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  focusedRowEnabled={true}
                  showBorders={true}
                  showRowLines={true}
                  showColumnLines={true}
                  autoNavigateToFocusedRow={true}
                  onFocusedRowChanged={e => {
                    setRiskGridData(e.row.data);
                    setSelRiskid(e.row.data.riskid);
                  }}
                >
                  <Column dataField='serviceid' visible={false} />
                  <Column dataField='costentryid' visible={false} />
                  <Column dataField='riskid' visible={false} />
                  <Column dataField='risklevel' caption='Risk Level' width='150px' allowEditing={false} />
                  <Column dataField='rates_set' caption='Rates Set' width='90px' visible={false} />
                  <Column dataField='not_applicable' caption='Not Applicable' width='120px' />
                  <Column dataField='setupfee' caption='Setup Fee' dataType='number' width='100' />
                  <Column dataField='recurringfee' caption='Service Fee' dataType='number' width='100' />
                  <Column dataField='recperiod' caption='Recurring' width='100'></Column>
                  <MasterDetail enabled={true} component={ServiceFeeGridDetails} />
                </DataGrid>
              </Item>
            </Form>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServiceFees;
