import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import MultiView from 'devextreme-react/multi-view';

import DataGrid, { Column } from 'devextreme-react/data-grid';
import './ProductEditPage.css';

import { SelectBox } from 'devextreme-react/select-box';
import Box from 'devextreme-react/box';
import Tabs from 'devextreme-react/tabs';
import ArrayStore from 'devextreme/data/array_store';
import { Switch } from 'devextreme-react/switch';

const ProductSetup = props => {
  const { selPartnerId, selProductId, selRoutingId, user, modeInsert } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const dataFormRef = useRef(null);
  const [lpRecPeriod, setLpRecPeriod] = useState([]);
  const [lpIdentityType, setLpIdentityType] = useState([]);
  const [lpRiskLevel, setLpRiskLevel] = useState([]);
  const [productData, setProductData] = useState({});
  const [productServicesData, setProductServicesData] = useState([]);
  const [routingServicesData, setRoutingServicesData] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [productServiceDataMod, setProductServiceDataMod] = useState();
  const [lpProductType, setLpProductType] = useState([]);
  const [RoutingServicesDataLp, setRoutingServicesDataLp] = useState([]);

  useEffect(() => {
    const fetchRecPeriod = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/2',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpRecPeriod(dataArray);
      } catch (err) {}
    };

    const fetchIdentityType = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/3',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpIdentityType(dataArray);
      } catch (err) {}
    };

    const fetchRiskLevel = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/4',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpRiskLevel(dataArray);
      } catch (err) {}
    };

    const fetchProductType = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/30',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpProductType(dataArray);
      } catch (err) {}
    };

    const fetchRoutingServicesLp = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/routing/routingsystemserviceslp/${selRoutingId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );

        setRoutingServicesDataLp(dataArray);
      } catch (err) {}
    };

    fetchRecPeriod();
    fetchIdentityType();
    fetchRiskLevel();
    fetchProductType();
    fetchRoutingServicesLp();
  }, [sendRequest]);

  useEffect(() => {
    const fetchSingleProduct = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/product/singleproduct/${selProductId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProductData(dataArray[0]);
      } catch (err) {}
    };
    if (selProductId) {
      fetchSingleProduct();
    }
  }, [sendRequest, selProductId]);

  useEffect(() => {
    const fetchProductServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/product/systemservices/${selProductId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProductServicesData(dataArray);
      } catch (err) {}
    };
    if (selProductId) {
      fetchProductServices();
    }
  }, [sendRequest, selProductId, productServiceDataMod]);

  useEffect(() => {
    const fetchRoutingServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/routing/routingsystemservices/${selRoutingId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setRoutingServicesData(dataArray);
      } catch (err) {}
    };

    if (selRoutingId) {
      fetchRoutingServices();
    }
  }, [sendRequest, selRoutingId]);

  const stProductServicesData = new ArrayStore({
    key: 'serviceid',
    data: productServicesData,
  });

  const stRoutingServicesData = new ArrayStore({
    key: 'serviceid',
    data: routingServicesData,
  });

  const stRoutingServicesDataLp = new ArrayStore({
    key: 'serviceid',
    data: RoutingServicesDataLp,
  });

  const stlpIdentityType = new ArrayStore({
    key: 'lp_id',
    data: lpIdentityType,
  });

  const stRiskLevel = new ArrayStore({
    key: 'lp_id',
    data: lpRiskLevel,
  });

  const stProductType = new ArrayStore({
    key: 'lp_id',
    data: lpProductType,
  });

  const product_FormData = {
    productid: (productData && productData.productid) || -1,
    partnerid: (productData && productData.partnerid) || user.managedpartner,
    productname: (productData && productData.productname) || '',
    startdate: productData && productData.startdate,
    enddate: productData && productData.enddate,
    setupfee: productData && productData.setupfee,
    recurring_fee: productData && productData.recurring_fee,
    recurring_period: (productData && productData.recurring_period) || -1,
    blacklist_id: (productData && productData.blacklist_id) || -1,
    whitelist_id: (productData && productData.whitelist_id) || -1,
    identitytype: (productData && productData.identitytype) || -1,
    risklevel: (productData && productData.risklevel) || -1,
    autoorder: (productData && productData.autoorder) || false,
    userid: (productData && productData.userid) || user.userId,
    currency: (productData && productData.currency) || '',
    productactive: (productData && productData.productactive) || false,
    producttypeid: (productData && productData.producttypeid) || 1,
  };

  const service_FormData = {
    serviceid: (selectedService && selectedService.serviceid) || -1,
    main_bank_account: (selectedService && selectedService.main_bank_account) || false,
  };

  const ModifyProduct = async () => {
    let OpType = 1;
    if (modeInsert) {
      OpType = 1;
    } else {
      OpType = 2;
    }
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/product/modproduct',
        'POST',
        JSON.stringify({
          operation: OpType,
          productid: product_FormData.productid,
          partnerid: product_FormData.partnerid,
          productname: product_FormData.productname,
          startdate: product_FormData.startdate,
          enddate: product_FormData.enddate,
          setupfee: product_FormData.setupfee,
          recurring_fee: product_FormData.recurring_fee,
          recurring_period: product_FormData.recurring_period,
          blacklist_id: product_FormData.blacklist_id,
          whitelist_id: product_FormData.whitelist_id,
          identitytype: product_FormData.identitytype,
          risklevel: product_FormData.risklevel,
          autoorder: product_FormData.autoorder,
          userid: product_FormData.userid,
          currency: product_FormData.currency,
          productactive: product_FormData.productactive,
          producttypeid: product_FormData.producttypeid,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    history.goBack();
  }

  const validateForm = async dataFormRef => {
    const result = dataFormRef.validationGroup.validate();
    if (result.isValid) {
      await ModifyProduct();
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  const modProductService = async modify => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/product/modproductservices',
        'POST',
        JSON.stringify({
          productid: product_FormData.productid,
          system_service_id: service_FormData.serviceid,
          main_bank_account: service_FormData.main_bank_account,
          operation: modify,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setProductServiceDataMod(!productServiceDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id='partner-customer-product-edit'>
      <ResponsiveBox>
        <Row ratio={2} />
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form
            id={'form'}
            ref={dataFormRef}
            formData={product_FormData}
            labelLocation={'top'}
            colCountByScreen={colCountByScreen}
            validationGroup='product_create_data'
          >
            <Item itemType='group' colCount={4}>
              <SimpleItem dataField='productname' isRequired={true} editorOptions={baseEditorOptions}>
                <RequiredRule message='Please enter a Product Name' />
                <Label text='Name' />
              </SimpleItem>
              <SimpleItem
                dataField='producttypeid'
                editorType='dxSelectBox'
                colSpan={1}
                name='service_listbox'
                editorOptions={{
                  dataSource: stProductType,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                  
                }}
              >
                <Label text='Product Type' />
              </SimpleItem>
              <SimpleItem dataField='currency' editorOptions={baseEditorOptions}>
                <Label text='Currency' />
              </SimpleItem>
            </Item>

            <Item itemType='group' colCount={4}>
              <SimpleItem dataField='productactive' editorType='dxCheckBox'>
                <Label text='Active' />
              </SimpleItem>
              <SimpleItem dataField='startdate' editorType='dxDateBox' editorOptions={{ type: 'date' }}>
                <Label text='Start Date' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <ResponsiveBox>
            <Row ratio={1} />
            <Row ratio={4} />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} colspan={3} />
              <div id='partner-customer-services-edit-box'>
                <Box direction='row' height={60} width='82%'>
                  <Item ratio={4}>
                    <Form id={'form'} formData={service_FormData} labelLocation={'left'}>
                      <Item itemType='group' colCount={4}>
                        <SimpleItem
                          dataField='serviceid'
                          editorType='dxSelectBox'
                          colSpan={2}
                          name='service_listbox'
                          editorOptions={{
                            dataSource: stRoutingServicesDataLp,
                            valueExpr: 'serviceid',
                            displayExpr: 'servicename',
                            searchEnabled: true,
                            
                          }}
                        >
                          <Label location='left' text='Service' />
                        </SimpleItem>
                        <SimpleItem dataField='main_bank_account' editorType='dxCheckBox'>
                          <Label text='Main Bank Account' />
                        </SimpleItem>
                      </Item>
                    </Form>
                  </Item>
                  <Item ratio={1}>
                    <Button
                      id='companytab-button'
                      width={130}
                      height={'36px'}
                      text='Add'
                      type='default'
                      onClick={() => {
                        modProductService(1);
                      }}
                    />
                  </Item>
                  <Item ratio={1}>
                    <Button
                      id='companytab-button'
                      width={130}
                      height={'36px'}
                      text='Change'
                      type='success'
                      onClick={() => {
                        modProductService(2);
                      }}
                    />
                  </Item>
                  <Item ratio={1}>
                    <Button
                      id='companytab-button'
                      width={130}
                      height={'36px'}
                      text='Remove'
                      type='danger'
                      onClick={() => {
                        modProductService(3);
                      }}
                    />
                  </Item>
                </Box>
              </div>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={1} col={0} />
              <DataGrid
                id='partner-product-services-edit-grid'
                // ref={departmentDataGrid}
                dataSource={stProductServicesData}
                showRowLines={true}
                showBorders={true}
                showColumnLines={true}
                columnAutoWidth={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                showColumnHeaders={true}
                onFocusedRowChanged={e => {
                  setSelectedService(e.row.data);
                }}
              >
                <Column dataField='serviceid' visible={false} />
                <Column dataField='servicename' caption='Contained Services' />
                <Column dataField='category_name' caption='Category' />
                <Column dataField='main_bank_account' dataType='boolean' caption='Main Bank Account' width='140' />
              </DataGrid>
              <div>
                <p>Attention a Standard Product requires at least one (Main) Bank Account!</p>
              </div>
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={2} col={0} />
          <div id={'contactpopbutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={50}
                text='Save'
                type='default'
                stylingMode='contained'
                validationGroup='product_create_data'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ProductSetup;
