import React, { useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import DocExchangeHelper from '../docexchange/DocExchangeHelper';
import notify from 'devextreme/ui/notify';
import FileUploader from 'devextreme-react/file-uploader';
import { Validator } from 'devextreme-react';
import { Form, Item as FormItem, Label, RequiredRule, SimpleItem } from 'devextreme-react/form';
import ResponsiveBox, { Col, Item, Location, Row } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';

const AddCustomFile = props => {
  const { showAddCustomFilePopup, hideAddCustomFilePopup, selInquiryId, selPartnerId } = props;
  const docNoteFormRef = useRef(null);
  const uploadFileRef = useRef(null);

  const [docDetails, setDocDetails] = useState({});

  const docTemplateData = {
    doc_title: docDetails?.doc_title || '',
    docComment: docDetails?.comment || '',
    file: null,
  };

  const saveRequiredDoc = async () => {
    try {
      const docData = new FormData();

      if (docTemplateData.docComment) {
        docData.append('comment', docTemplateData.docComment);
      }

      if (docTemplateData.doc_title) {
        docData.append('usage_advice', docTemplateData.doc_title);
      }

      if (docTemplateData.file) {
        docData.append('threadId', selInquiryId);
        docData.append('selPartnerId', selPartnerId);
        docData.append('file', docTemplateData.file);
      }

      await DocExchangeHelper.uploadCustomDocumentThread(docData);

      notify('Document was successfully added', 'success', 2000);
    } catch (err) {
      notify('Error sending data', 'error', 2000);
    }
  };

  const resetValues = () => {
    docTemplateData.doc_title = '';
    docTemplateData.docComment = '';
    docTemplateData.file = null;
    uploadFileRef.current.instance.reset();
  };

  const validateForm = async docNoteFormRef => {
    // TODO: Hier gibts noch ein Valid Fehler
    await saveRequiredDoc();

    resetValues();
    hideAddCustomFilePopup(true);

    // const result = docNoteFormRef.validationGroup.validate();

    // if (result.isValid) {
    //     await saveRequiredDoc();
    //     hideAddCustomFilePopup(true);
    //     let fileUploaderInstance = document.getElementById("serviceCustomerFileUploader")
    // } else {
    //     notify("Please complete all required fields", "error", 2000);
    // }
  };

  const renderFileUploader = () => {
    return (
      <div className='fileuploader-container'>
        <FileUploader
          id={'requiredDocFileUploader'}
          selectButtonText='Select a file'
          uploadMode='instantly'
          uploadFile={file => {
            docTemplateData.file = file;
          }}
          ref={uploadFileRef}
        >
          {/*<Validator validationGroup="RequiredDocData">*/}
          {/*    <RequiredRule message="Select a file"/>*/}
          {/*</Validator>*/}
        </FileUploader>
      </div>
    );
  };

  return (
    <Popup
      title='Required Document'
      visible={showAddCustomFilePopup}
      height={'auto'}
      width={'480px'}
      closeOnOutsideClick={false}
      showCloseButton={true}
      resizeEnabled={true}
      onHiding={() => {
        hideAddCustomFilePopup();
      }}
    >
      <ResponsiveBox>
        <Row ratio={7} />
        <Row ratio={2} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form
            id={'requiredDoc'}
            formData={docTemplateData}
            ref={docNoteFormRef}
            labelLocation={'top'}
            validationGroup={'RequiredDocData'}
          >
            <SimpleItem dataField='doc_title' isRequired={false} colSpan={2}>
              <Label text='Document Title' />
            </SimpleItem>
            <FormItem itemType='group' colCount={1}>
              <SimpleItem
                dataField='docComment'
                editorType='dxTextArea'
                isRequired={false}
                editorOptions={{ height: 100 }}
              >
                <Label text='Description' />
              </SimpleItem>
            </FormItem>
            <FormItem itemType='group' colCount={1}>
              <SimpleItem dataField='file' render={renderFileUploader}>
                <Label text='Upload document' />
              </SimpleItem>
            </FormItem>
            <FormItem itemType='group' colCount={1}>
              <Button
                className='btn-popup'
                width={120}
                height={40}
                text='Close'
                type='normal'
                onClick={() => {
                  resetValues();
                  hideAddCustomFilePopup(true);
                }}
              />
              <Button
                className='btn-popup'
                width={120}
                height={40}
                text='Save'
                type='default'
                onClick={validateForm}
                useSubmitBehavior={false}
              />
            </FormItem>
          </Form>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default AddCustomFile;
