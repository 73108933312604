import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';

const ClientsApprovedRiskMatrix = props => {
  const { tabVisibleIndex, selSignupClientid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showPopup, setShowPopup] = useState(false);
  const [clientRiskMatrixData, setClientRiskMatrixData] = useState([]);
  const [clientRiskMatrixResult, setClientRiskMatrixResult] = useState({});

  useEffect(() => {
    const fetchClientRiskMatrix = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/clientriskmatrix/${selSignupClientid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setClientRiskMatrixData(dataArray);
      } catch (err) {}
    };
    const fetchClientRiskMatrixResult = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/clientriskmatrixresult/${selSignupClientid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setClientRiskMatrixResult(dataArray[0]);
      } catch (err) {}
    };

    if (selSignupClientid) {
      fetchClientRiskMatrix();
      fetchClientRiskMatrixResult();
    }
  }, [sendRequest, selSignupClientid]);

  function createMatrix() {
    return clientRiskMatrixData.map((item, i) => (
      <div className='note-details-personally-block' key={i}>
        <div className='note-details-personally-block-infoText'>{item.risk_name}</div>
        <div className='note-details-personally-block-outputText'>{item.group_entry}</div>
      </div>
    ));
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div className='dx-fieldset'>
            <div className='dx-fieldset-header'>Client Risk Matrix</div>
            {createMatrix()}
            <div className='dx-fieldset-header'>
              AI - based Result - {clientRiskMatrixResult && clientRiskMatrixResult.matrixresult}
              {' - '}
              {clientRiskMatrixResult && clientRiskMatrixResult.risklevel}
              {' - '}
              {clientRiskMatrixResult && clientRiskMatrixResult.lp_value}
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientsApprovedRiskMatrix;
