import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

import './ServiceProviderData.css';

import { Button } from 'devextreme-react/button';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import ServiceProviderName from './ServiceProviderName';
import ServiceProviderServices from './ServiceProviderServices';
import ServiceRegionsTab from './ServiceProviderRegions';
import ServiceProviderTransactions from './ServiceProviderTransactions';
import ServiceProviderDocs from './ServiceProviderDocs';
import ServiceProviderTOU from './ServiceProviderTOU';

const ServiceProviderDataPage = () => {
  const { user } = useAuth();
  const history = useHistory();

  const svProviderId = user.serviceProvider && user.serviceProvider.svProviderId;
  const svPersonId = user.serviceProvider && user.serviceProvider.svPersonId;
  const modeInsert = user.serviceProvider && user.serviceProvider.modeInsert;

  useEffect(() => {
    if (!user.serviceProvider) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='serviceprovider-data-box'>
                <ServiceProviderName
                  svProviderId={svProviderId}
                  svPersonId={svPersonId}
                  modeInsert={modeInsert}
                  user={user}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='serviceprovider-data-box'>
                <ServiceProviderServices selProviderId={svProviderId} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={1} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='serviceprovider-data-box'>
                <ServiceRegionsTab selProviderId={svProviderId} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={1} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='serviceprovider-data-box'>
                <ServiceProviderDocs selProviderId={svProviderId} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='serviceprovider-data-box'>
                <ServiceProviderTOU selProviderId={svProviderId} user={user} />
              </div>
            </div>
          </div>
        </Item>

        <Item>
          <Location screen='md lg xs sm' row={3} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='serviceprovider-data-line'>
                <ServiceProviderTransactions selProviderId={svProviderId} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={4} col={1} />
          <div id={'datapage-close-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ServiceProviderDataPage;
