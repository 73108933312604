import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { useAuth } from '../contexts/auth';
import './detailgrids.css';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

const ServiceFeeGridDetails = props => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const provservice = props.data.data;
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    const fetchSystemService = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/systemservicefeatures/${provservice.serviceid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setServiceData(dataArray);
      } catch (err) {}
    };
    if (provservice.serviceid) {
      fetchSystemService();
    }
  }, [sendRequest, provservice.serviceid]);

  const stServiceData = new ArrayStore({
    key: ['serviceid', 'featureid'],
    data: serviceData,
  });

  const dcServiceData = new DataSource({
    store: stServiceData,
  });

  return (
    <div>
      <DataGrid
        id='provservice-features-detailgrid'
        dataSource={dcServiceData}
        // defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        focusedRowEnabled={true}
        showBorders={true}
        showRowLines={true}
        showColumnLines={true}
        autoNavigateToFocusedRow={true}
      >
        <FilterRow visible={false} />
        <Column dataField='serviceid' visible={false}></Column>
        <Column dataField='featureid' visible={false}></Column>
        <Column dataField='feature' caption='Transfer from' width='300px'></Column>
        <Column dataField='usefeature' caption='Transfer to' width='300px'></Column>
        <Column dataField='list' caption='Date' width='80'></Column>
        <Column dataField='send' caption='Amount' width='120'></Column>
        <Column dataField='receive' dataType='boolean' caption='Cleared' width='70'></Column>
        <Column dataField='receive' dataType='boolean' caption='Effective' width='70'></Column>
      </DataGrid>
    </div>
  );
};
export default ServiceFeeGridDetails;
