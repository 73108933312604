import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './NetworkData.css';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';

import DataGrid, { Column, Editing } from 'devextreme-react/data-grid';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

const NetworkDataPartner = (props) => {
  const { selRoutingid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const [routingPartnerData, setRoutingPartnerData] = useState([]);
  const [routingProducts, setRoutingProducts] = useState([]);

  useEffect(() => {
    const fetchRoutingPartner = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/routing/partneronrouting/${selRoutingid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setRoutingPartnerData(dataArray);
      } catch (err) {}
    };

    const fetchRoutingProducts = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/routing/partnerproductsrouting/${selRoutingid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setRoutingProducts(dataArray);
      } catch (err) {}
    };

    if (selRoutingid) {
      fetchRoutingPartner();
      fetchRoutingProducts();
    }
  }, [sendRequest, selRoutingid]);

  const stRoutingPartnerData = new ArrayStore({
    key: ['partnerid'],
    data: routingPartnerData,
  });

  const dcRoutingPartnerData = new DataSource({
    store: stRoutingPartnerData,
  });

  const stRoutingProducts = new ArrayStore({
    key: ['productid'],
    data: routingProducts,
  });

  const dcRoutingProducts = new DataSource({
    store: stRoutingProducts,
  });

  function navigateToHome() {
    history.goBack();
  }

  return (
    <div className='sd-header item'>
      <div className='dx-fieldset-header'>Partner on Network</div>
      <ResponsiveBox>
        <Row />

        <Col />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <Form
              // ref={dataFormRef}
              // formData={costRateHeader}
              labelLocation={'left'}
              colCountByScreen={colCountByScreen}
              scrollingEnabled={true}
            >
              <Item>
                <DataGrid
                  dataSource={dcRoutingPartnerData}
                  showBorders={true}
                  showRowLines={true}
                  showColumnLines={true}
                  columnAutoWidth={true}
                  focusedRowEnabled={true}
                >
                  <Column dataField='partnerid' visible={false}></Column>
                  <Column dataField='routingid' visible={false}></Column>
                  <Column dataField='partnername' caption='Merchant' width='250'></Column>
                  <Column dataField='activedate' dataType='date' caption='Assigned' width='150'></Column>
                </DataGrid>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={5} caption='Products on Network'>
                <DataGrid
                  height={290}
                  dataSource={stRoutingProducts}
                  showColumnHeaders={true}
                  showBorders={true}
                  showRowLines={true}
                  showColumnLines={true}
                  focusedRowEnabled={true}
                >
                  <Column dataField='productid' visible={false} />
                  <Column dataField='routingid' visible={false} />
                  <Column dataField='partnername' caption='Merchant/Partner' width={150} />
                  <Column dataField='productname' caption='Product' width={200} />
                  <Column dataField='clients' caption='Clients' width={100} />
                  <Column dataField='revperday' caption='Rev p. Day' width={100} />
                </DataGrid>
              </Item>
            </Form>
          </div>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default NetworkDataPartner;
