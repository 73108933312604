import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { Button } from 'devextreme-react/button';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';

const ClientsApprovedKYC = (props) => {
  const { tabVisibleIndex, selSignupClientid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [signupClientDatalist, setSignupClientDatalist] = useState([]);
  const [screeningResults, setScreeningResults] = useState([]);
  const [screeningHits, setScreeningHits] = useState([]);
  const [screeningHitsMedia, setScreeningHitsMedia] = useState([]);

  const [isPopupVisible, setPopupVisibility] = useState(false);

  const togglePopup = () => {
    setPopupVisibility(!isPopupVisible);
  };

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/client/clientkycdata/${selSignupClientid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        console.log(dataArray);
        setSignupClientDatalist(dataArray);
      } catch (err) {}
    };

    const fetchScreeningResultsData = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/client/screeningresults/${selSignupClientid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        console.log(dataArray);
        setScreeningResults(dataArray);
      } catch (err) {}
    };

    const fetchScreeningHitsData = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/client/screeninghits/${selSignupClientid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        console.log(dataArray);
        setScreeningHits(dataArray);
      } catch (err) {}
    };

    const fetchScreeningHitsMedia = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/client/screeninghitsmedia/${selSignupClientid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        console.log(dataArray);
        setScreeningHitsMedia(dataArray);
      } catch (err) {}
    };

    if (selSignupClientid) {
      // fetchSignupClientData();
      fetchSignupClientData();
      fetchScreeningResultsData();
      fetchScreeningHitsData();
      fetchScreeningHitsMedia();
    }
  }, [sendRequest, selSignupClientid]);

  const downloadReport = async () => {
    try {
      await fetch(
        process.env.REACT_APP_BACKEND_URL +
          `/client/clientkycreport/${selSignupClientid}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        }
      )
        .then((response) => response.blob())
        .then(function (b) {
          // console.log(b)
          const fileReader = new FileReader();
          fileReader.readAsDataURL(b);
          fileReader.onloadend = async () => {
            if (fileReader.result) {
              const link = document.createElement('a');
              link.href = fileReader.result.toString();
              link.setAttribute('download', 'report.pdf');
              document.body.appendChild(link);
              link.click();
            }
          };
        });
    } catch (e) {
      console.log(e);
    }
  };

  const openReport = async () => {
    try {
      await fetch(
        process.env.REACT_APP_BACKEND_URL +
          `/client/clientkycreport/${selSignupClientid}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        }
      )
        .then((response) => response.blob())
        .then(function (b) {
          // console.log(b)
          const fileReader = new FileReader();
          fileReader.readAsDataURL(b);
          fileReader.onloadend = async () => {
            if (fileReader.result) {
              const link = document.createElement('a');
              link.href = fileReader.result.toString();
              link.setAttribute('target', '_blank');
              document.body.appendChild(link);
              link.click();
            }
          };
        });
    } catch (e) {
      console.log(e);
    }
  };

  function getResult(signupClientDatalist) {
    if (typeof signupClientDatalist.reviewResult !== 'undefined') {
      return signupClientDatalist.reviewResult.reviewAnswer;
    } else {
      return 'Not available';
    }
  }

  const renderContent = (screeningHits, screeningHitsMedia) => {
    return (
      <>
        <ScrollView height="100%" width="100%">
          <div className="dx-fieldset">
            {screeningHits && (
              <>
                <b>Hits</b>
                {screeningHits.map((hits, index) => (
                  <>
                    <div
                      className="note-details-personally-block"
                      key={String(index + 21)}
                    >
                      <div className="note-details-personally-block-infoText">
                        ({index + 1}) <br />
                        Entity
                      </div>
                      <div className="note-details-personally-block-outputText">
                        <br />
                        {hits.entity_type}
                      </div>
                    </div>
                    <div
                      className="note-details-personally-block"
                      key={String(index + 22)}
                    >
                      <div className="note-details-personally-block-infoText">
                        Score
                      </div>
                      <div className="note-details-personally-block-outputText">
                        <b>{hits.score}</b>
                      </div>
                    </div>
                    <div
                      className="note-details-personally-block"
                      key={String(index + 23)}
                    >
                      <div className="note-details-personally-block-infoText">
                        Types
                      </div>
                      <div className="note-details-personally-block-outputText">
                        {hits.types
                          .replace('{', '')
                          .replace('}', '')
                          .replace(/"/g, '')}
                      </div>
                    </div>
                    <div
                      className="note-details-personally-block"
                      key={String(index + 24)}
                    >
                      <div className="note-details-personally-block-infoText">
                        Media
                      </div>
                      <div className="note-details-personally-block-outputText">
                        {getScreeningHitsMedia(
                          hits.entity_type,
                          screeningHitsMedia
                        )}
                      </div>
                    </div>
                    <br />
                  </>
                ))}
              </>
            )}
          </div>
        </ScrollView>
      </>
    );
  };

  function getScreeningHitsMedia(hitsEntityType, screeningHitsMedia) {
    let mediaList = '';
    for (let i = 0; i < screeningHitsMedia.length; i++) {
      if (hitsEntityType === screeningHitsMedia[i].entity_type) {
        // console.log(screeningHitsMedia[i].media_title);
        mediaList +=
          '(' +
          (i + 1) +
          ' - ' +
          screeningHitsMedia[i].media_date.split('T')[0] +
          ') ' +
          '<a href="' +
          screeningHitsMedia[i].media_url +
          '" target="_blank">' +
          screeningHitsMedia[i].media_title +
          '</a><br />';
      }
    }

    // console.log(mediaList);
    return <div dangerouslySetInnerHTML={{ __html: mediaList }}></div>;
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />
        <Row />
        <Item>
          <Location screen="md lg sm xs" row={0} col={0} />
          <div className="dx-fieldset">
            <div className="dx-fieldset-header">SumSub Applicant Status</div>
            {signupClientDatalist && (
              <>
                <div className="note-details-personally-block" key="1">
                  <div className="note-details-personally-block-infoText">
                    Creation Date: {signupClientDatalist.createDate}
                    <br />
                    Status: {signupClientDatalist.reviewStatus}
                    <br />
                    Result: <b>{getResult(signupClientDatalist)}</b>
                  </div>
                  <div className="note-details-personally-block-outputText">
                    <Button
                      className="mb-1"
                      width={220}
                      height={32}
                      text="Open Report"
                      type="normal"
                      stylingMode="contained"
                      onClick={() => {
                        openReport();
                      }}
                    />
                    <br />
                    <Button
                      className="mb-1"
                      width={220}
                      height={32}
                      text="Download Report"
                      type="normal"
                      stylingMode="contained"
                      onClick={() => {
                        downloadReport();
                      }}
                    />
                    <br />
                    <Button
                      width={220}
                      height={32}
                      text="-> SumSub"
                      type="normal"
                      stylingMode="contained"
                      onClick={() => {
                        window.open(
                          'https://cockpit.sumsub.com/checkus/#/applicant/' +
                            signupClientDatalist.applicantId +
                            '/basicInfo?clientId=finbuilder',
                          '_blank'
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Item>
        <Item>
          <Location screen="md lg sm xs" row={1} col={0} />
          <div className="dx-fieldset">
            {screeningResults && screeningHits && screeningHitsMedia && (
              <>
                <div className="dx-fieldset-header">
                  Comply Advantage - Risk Level:{' '}
                  <b>{screeningResults.risk_level}</b>
                </div>
                <div className="note-details-personally-block" key="3">
                  <div className="note-details-personally-block-infoText">
                    <b>Hits</b>
                    <br />
                    {screeningHits.map((hits, index) => (
                      <>
                        ({index + 1}) Entity: <b>{hits.entity_type}</b> - Score:{' '}
                        <b>{hits.score}</b>
                        <br />
                      </>
                    ))}
                  </div>
                  <div className="note-details-personally-block-outputText">
                    <Button
                      className="mb-1"
                      width={220}
                      height={32}
                      text="Show Hits Details"
                      type="normal"
                      stylingMode="contained"
                      onClick={togglePopup}
                    />
                    <br />
                    <Button
                      width={220}
                      height={32}
                      text="-> Comply Advantage"
                      type="normal"
                      stylingMode="contained"
                      onClick={() => {
                        window.open(
                          'https://app.complyadvantage.com/#/case-management'
                        );
                      }}
                    />
                    <Popup
                      showTitle={true}
                      title="Comply Advantage Summary"
                      visible={isPopupVisible}
                      closeOnOutsideClick={true}
                      onHiding={togglePopup}
                      position="center"
                      showCloseButton={true}
                      contentRender={() =>
                        renderContent(screeningHits, screeningHitsMedia)
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientsApprovedKYC;
