import React, { useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule } from 'devextreme-react/form';

const CasesNotesPop = props => {
  const { showPopup, hidePopup, user, selLfdNr, openedToEdit, caseNumber, caseContact, caseSubject } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const noteFormRef = useRef(null);
  const [caseData, setCaseData] = useState({});

  useEffect(() => {
    const fetchCases = async () => {
      try {
        /*
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/cases/${selLfdNr}`
        );
        setCaseData(dataArray[0]);
      */
      } catch (err) {}
    };
    if (selLfdNr && showPopup && openedToEdit === 2) {
      fetchCases();
    } else if (selLfdNr && showPopup && openedToEdit === 1) {
      setCaseData({});
    }
  }, [sendRequest, selLfdNr, showPopup, openedToEdit]);

  const caseNote = {
    LfdNr: caseData.LfdNr || -1,
    Base_LfdNr: caseData.Base_LfdNr || caseNumber,
    userid: caseData.Mitarbeiterid || user.userId,
    ucompanyid: caseData.ucompanyid || -1,
    notedate: caseData.created || new Date(),
    casetext: (caseData.BaseContact || caseContact) + ' --- ' + (caseData.Base_Gesprgegen || caseSubject),
    notesubject: caseData.Gesprgegen || '',
    notetext: caseData.Gespraechsinhalt || '',
    noteuser: caseData.user || user.lastname,
  };

  const saveNote = async () => {
    try {
      /*
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/cases/note',
        'POST',
        JSON.stringify({
          base_lfdnr: caseNote.Base_LfdNr,
          subject: caseNote.notesubject,
          content: caseNote.notetext,
          userId: user.userId,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token  }
      );
      */
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async noteFormRef => {
    const result = noteFormRef.validationGroup.validate();
    if (result.isValid) {
      saveNote();
      hidePopup(true);
    } else {
      alert('Please complete all required fields');
    }
  };

  return (
    <Popup
      title={caseNote.casetext}
      width={900}
      height={760}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(openedToEdit === 1);
      }}
    >
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.1} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <Form
              id={'form'}
              ref={noteFormRef}
              formData={caseNote}
              labelLocation={'left'}
              colCountByScreen={colCountByScreen}
              validationGroup='NoteData'
            >
              <Item itemType='group' colCount={4}>
                <SimpleItem dataField='notedate' editorType='dxDateBox' editorOptions={{ type: 'datetime' }}>
                  <Label text='Created' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={1}>
                <SimpleItem dataField='notesubject' isRequired={true}>
                  <Label text='Subject' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={1}>
                <SimpleItem
                  dataField='notetext'
                  editorType='dxTextArea'
                  isRequired={true}
                  editorOptions={{ height: 350 }}
                >
                  <Label text='Content' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={4}>
                <SimpleItem dataField='noteuser' editorOptions={{ readOnly: true }}>
                  <Label text='by' />
                </SimpleItem>
              </Item>
            </Form>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'closebutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                visible={openedToEdit === 1}
                disabled={true}
                width={120}
                height={40}
                text='Save'
                type='normal'
                stylingMode='contained'
                validationGroup='NoteData'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
              <Button
                width={120}
                height={40}
                text='close'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  hidePopup();
                }}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default CasesNotesPop;
