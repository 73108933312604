import React, { useEffect, useState, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';

import './Client.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';

import ClientBase from './ClientBase';
import ClientComms from './communication/ClientComms';
import ClientProduct from './ClientProduct';
import ClientAccess from './ClientAccess';
import ClientUsers from './ClientUsers';
import ClientRiskMatrix from './ClientRiskMatrix';
import ClientKYC from './ClientKYC';
import ClientsTransactions from './ClientTransactions';
import PortalComms from './comprovider/PortalComms';
import ForexTrades from './ForexTrades';

const ClientPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const clientBaseData = {
    selClientId: user.client ? user.client.selClientid : 0,
    clientData: user.client ? user.client.selClientData : {},
    usesForex: user.client ? user.client.selClientUsesForex : false,
  };

  useEffect(() => {
    if (!user.client) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} colspan={2} />
          <ResponsiveBox>
            <Row ratio={1} />
            <Row ratio={1} />
            <Col ratio={1} />
            <Col ratio={1} />
            <Item>
              <Location screen='md lg xs sm' row={0} col={0} />
              <div className={'content-block'}>
                <div className={'dx-card'}>
                  <div id='client-base-box'>
                    <ClientBase user={user} selSignupClientid={clientBaseData.selClientId} />
                  </div>
                </div>
              </div>
            </Item>
            <Item>
              <Location screen='md lg xs sm' row={1} col={0} />
              <div className={'content-block'}>
                <div className={'dx-card'}>
                  <div id='client-base-box'>
                    <ClientProduct user={user} selSignupClientid={clientBaseData.selClientId} />
                  </div>
                </div>
              </div>
            </Item>
            <Item>
              <Location screen='md lg xs sm' row={0} col={1} rowspan={2} />
              <div className={'content-block'}>
                <div className={'dx-card'}>
                  <div id='client-base-box-right'>
                    <ClientComms
                      user={user}
                      selClientId={clientBaseData.selClientId}
                      clientChatid={clientBaseData.clientData.chatid}
                    />
                  </div>
                </div>
              </div>
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='client-base-box-right'>
                <ClientAccess user={user} selSignupClientid={clientBaseData.selClientId} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='client-base-box-right'>
                <ClientUsers user={user} selSignupClientid={clientBaseData.selClientId} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={3} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='client-details-box-left'>
                <ClientRiskMatrix user={user} selSignupClientid={clientBaseData.selClientId} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={3} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='client-details-box-left'>
                <ClientKYC user={user} selSignupClientid={clientBaseData.selClientId} />
              </div>
            </div>
          </div>
        </Item>
        {clientBaseData && clientBaseData.usesForex && (
          <Item>
            <Location screen='md lg xs sm' row={4} col={0} colspan={2} />
            <div className={'content-block'}>
              <div className={'dx-card'}>
                <div id='client-ForexTrades-box'>
                  <ForexTrades user={user} selClientid={clientBaseData.selClientId} />
                </div>
              </div>
            </div>
          </Item>
        )}

        <Item>
          <Location screen='md lg xs sm' row={5} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='client-Transaction-box'>
                <ClientsTransactions
                  selectedKey={clientBaseData.selClientId}
                  user={user}
                  selClientid={clientBaseData.selClientId}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={6} col={1} />
          <div id={'client-close-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientPage;
