import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import Button from "devextreme-react/button";
import Form, {
  ButtonItem,
  ButtonOptions,
  EmailRule,
  GroupItem,
  NumericRule,
  RequiredRule,
  SimpleItem
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import {MailingGetAccount, MailingSaveAccount} from "./components/MailingHelper";


function AccountFormPage(props) {
  const [account, setAccount] = useState({
    id: null,
    imapLogin: "",
    imapPort: null,
    imapPassword: "",
    incomingFolders: "",
    smtpLogin: "",
    smtpPort: null,
    smtpPassword: "",
    isActive:false,
    fromName: "",
    fromEmail: "",
    isDefault: "",
    host: "",
  });
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.match.params.accountId !== undefined) {
      MailingGetAccount(props.match.params.accountId).then(
          (data) => {
            setAccount(data);
          }
      );
    }
  }, []);

  const handleBackButton = () => {
    props.history.push({ pathname: "/mailing", state: "2" });
  };

  const handleSubmit = (data) => {
    const validate = data.validationGroup.validate();

    if (validate.isValid) {
      setLoading(true);
      MailingSaveAccount(account)
        .then((data) => {
          setAccount(data);
          notify("Mailing Account saved successfully.", "success", 2000);
        })
        .catch(err => {
          notify("An error occurred while sending the data.", "error", 2000);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notify("Please complete all required fields", "error", 2000);
    }
  };


    return (
      <React.Fragment>
        <h2 className={"content-block"}>Mailing Account</h2>
        <div className={"content-block"}>
          <div className="dx-card wide-card p-4">
            <Button
              width={220}
              height={35}
              icon={"back"}
              type={"default"}
              className={"dx-shape-standard mb-4"}
              text={"Back to Accounts"}
              stylingMode="outlined"
              onClick={handleBackButton}
            />

            <Form
              className={"mt-4"}
              formData={account}
              labelLocation={"left"}
            >
              <GroupItem cssClass={"mb-4"} colSpan={2}>
              </GroupItem>
              <GroupItem colCount={2}>
                <GroupItem caption="SMTP Settings">
                  <SimpleItem dataField="host">
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem dataField="fromName">
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem dataField="fromEmail">
                    <RequiredRule />
                    <EmailRule />
                  </SimpleItem>
                  <SimpleItem dataField="smtpLogin">
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem dataField="smtpPort" editorType={"dxNumberBox"}>
                    <RequiredRule />
                    <NumericRule message="This field should contain a number" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="smtpPassword"
                    editorOptions={{ mode: "password" }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem dataField="isActive" />
                </GroupItem>
                {/* <GroupItem caption="IMAP Settings">
                  <SimpleItem dataField="imapLogin">
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem dataField="imapPort" editorType={"dxNumberBox"}>
                    <NumericRule message="This field should contain a number" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="imapPassword"
                    editorOptions={{ mode: "password" }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem dataField="incomingFolders">
                    <RequiredRule />
                  </SimpleItem>
                </GroupItem> */}
              </GroupItem>
              <ButtonItem cssClass={"mt-4"}>
                <ButtonOptions
                  disabled={loading}
                  onClick={handleSubmit}
                  type={"success"}
                  width={180}
                  height={35}
                  stylingMode="contained"
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"20px"}
                        height={"20px"}
                        visible={true}
                      />
                    ) : (
                      "Save account"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );

}

export default withRouter(AccountFormPage);
