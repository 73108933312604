import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './servicesedit.css';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import { useHttpClient } from '../../hooks/http-hook';

import { SelectBox } from 'devextreme-react/select-box';
import ArrayStore from 'devextreme/data/array_store';

const ServiceProviderEditName = props => {
  const { selProviderId, user } = props;

  const history = useHistory();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const dataFormRef = useRef(null);
  const [providerData, setProviderData] = useState({});
  const [companyContact, setCompanyContact] = useState({});
  const [lpCategories, setLpCategories] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const fetchServiceProvider = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/provider/serviceprov/${selProviderId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProviderData(dataArray[0]);
        let anpersonid = -1;
        if (dataArray[0].personid) {
          anpersonid = dataArray[0].personid;
        }
        const ContactdataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/contacts/company/${dataArray[0].personid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCompanyContact(ContactdataArray[0]);
      } catch (err) {}
    };

    if (selProviderId) {
      fetchServiceProvider();
    }
  }, [sendRequest, selProviderId]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/service/servicecat', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setLpCategories(dataArray);
      } catch (err) {}
    };

    fetchCategories();
  }, [sendRequest]);

  const stLpCategoriesData = new ArrayStore({
    key: 'category_id',
    data: lpCategories,
  });

  const providerFormData = {
    providerid: (providerData && providerData.providerid) || -1,
    personid: (providerData && providerData.personid) || -1,
    categoryid: (providerData && providerData.categoryid) || -1,
    providername: (providerData && providerData.providername) || '',

    contactid: companyContact.contactid,
    lastname: companyContact.lastname,
    street: companyContact.street,
    city_code: companyContact.city_code,
    city: companyContact.city,
    phone_one: companyContact.phone_one,
    email: companyContact.email,
    homepage: companyContact.homepage,
    addressfield: companyContact.addressfield,
    nationality: companyContact.nationality,
    res_cntry: companyContact.res_cntry,
    contact_cat: companyContact.contact_cat,
  };

  function navigateToHome() {
    history.push({
      pathname: '/partner',
    });
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form
            id='serviceprovider-edit-form-edit'
            ref={dataFormRef}
            formData={providerFormData}
            labelLocation={'top'}
            colCountByScreen={colCountByScreen}
            validationGroup='ServProvData'
            readOnly={true}
          >
            <Item itemType='group' colCount={3}>
              <SimpleItem dataField='providername' isRequired={true} editorOptions={baseEditorOptions}>
                <RequiredRule message='Please enter a Service Provider Name' />
                <Label text='Name' />
              </SimpleItem>
              <SimpleItem
                dataField='categoryid'
                editorType='dxSelectBox'
                colSpan={1}
                name='category_listbox'
                editorOptions={{
                  dataSource: stLpCategoriesData,
                  valueExpr: 'category_id',
                  displayExpr: 'category_name',
                  searchEnabled: true,
                  
                }}
              >
                <Label text='Category' />
              </SimpleItem>
            </Item>
            <EmptyItem />

            <Item itemType='group' colCount={3} caption='Address'>
              <SimpleItem dataField='addressfield' editorType='dxTextArea' editorOptions={{ height: 120 }}>
                <Label text='Address' visible={false} />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={3}>
              <SimpleItem dataField='email' editorOptions={baseEditorOptions}>
                <Label text='E-Mail' />
              </SimpleItem>
              <SimpleItem dataField='phone_one' editorOptions={baseEditorOptions}>
                <Label text='Phone' />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={3}>
              <SimpleItem dataField='homepage' editorOptions={baseEditorOptions}>
                <Label text='Home Page' />
              </SimpleItem>
            </Item>
          </Form>
          <div id={'serviceprovider-edit-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='serviceprovider-edit-close-button'
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ServiceProviderEditName;
