import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import './ComplianceMatrix.css';
import 'devextreme/dist/css/dx.light.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';
import DataGrid, { Column, Lookup, Editing } from 'devextreme-react/data-grid';

import { useAuth } from '../../contexts/auth';

import AiModelPop from './matrix-dialogs/AiModelPop';
import MatrixModel from './nmatrix/MatrixModel';
import NeuronalNet from './nmatrix/NeuronalNet';
import MatrixData from './nmatrix/MatrixData';

const ComplianceMatrixPage = () => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [modelid, setModelid] = useState(1);

  const [aiModelFocusedDataRow, setAiModelFocusedDataRow] = useState({});
  const [aiModelsData, setAiModelsData] = useState([]);
  const [showAiModelPopup, setShowAiModelPopup] = useState(false);
  const [reloadAiModels, setReloadAiModels] = useState(false);
  const [addAiModel, setAddAiModel] = useState(false);

  useEffect(() => {
    const fetchAiModels = async () => {
      const response = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/ai/models', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setAiModelsData(response);
    };
    if (user) {
      fetchAiModels();
    }
  }, [reloadAiModels, user]);

  const martrixData = {
    modelid: 1,
    modelName: '',
  };

  const addModel = () => {
    setAddAiModel(true);
    setShowAiModelPopup(true);
  };

  const modModel = () => {
    setAddAiModel(false);
    setShowAiModelPopup(true);
  };

  const remModel = () => {};

  const hideAiModelPopup = () => {
    setShowAiModelPopup(false);
    setReloadAiModels(true);
  };

  return (
    <React.Fragment>
      <AiModelPop
        showPopup={showAiModelPopup}
        hidePopup={hideAiModelPopup}
        modelid={modelid}
        modeldatarow={aiModelFocusedDataRow}
        addAiModel={addAiModel}
        user={user}
      />

      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <div id='ai-form'>
            <ResponsiveBox>
              <Row ratio={1} />
              <Row ratio={1} />
              <Row ratio={1} />
              <Row ratio={1} />
              <Col />

              <Item>
                <Location screen='md lg xs sm' row={0} col={0} />
                <h4 className='card-heading'>AI-Models</h4>

                <ResponsiveBox>
                  <Row ratio={1} />
                  <Col ratio={2} />
                  <Col ratio={16} />
                  <Item>
                    <Location screen='md lg xs sm' row={0} col={0} />
                    <div id='matrix-btn-1'>
                      <Button width={120} height={36} text='Add' type='default' stylingMode='outlined' onClick={addModel} />
                    </div>
                    <div id='matrix-btn-2'>
                      <Button width={120} height={36} text='Open' type='success' stylingMode='outlined' onClick={modModel} />
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg xs sm' row={0} col={1} />
                    <DataGrid
                      id='Model-Grid'
                      dataSource={aiModelsData}
                      showBorders={true}
                      keyExpr={'modelid'}
                      showRowLines={true}
                      showColumnLines={true}
                      columnAutoWidth={true}
                      focusStateEnabled={true}
                      defaultFocusedRowIndex={0}
                      focusedRowEnabled={true}
                      onFocusedRowChanged={e => {
                        if (e) {
                          setAiModelFocusedDataRow(e.row.data);
                          setModelid(e.row.key);
                        }
                      }}
                    >
                      <Column dataField='modelid' visible={false} />
                      <Column dataField='modelname' visible={true} caption='Model' width='260px' />
                      <Column dataField='assigned_nnw' visible={true} caption='Assigned Network' width='150px' />
                      <Column dataField='nmw_trained' visible={true} caption='Trained' width='150px' />
                      <Column dataField='nmw_active' visible={true} caption='Active' width='150px' />
                      <Column dataField='modelident' visible={true} caption='Indentifier' width='80px' />
                    </DataGrid>
                  </Item>
                </ResponsiveBox>
              </Item>
              <Item>
                <Location screen='md lg xs sm' row={1} col={0} />
                <MatrixModel modelid={modelid} user={user} />
              </Item>
              <Item>
                <Location screen='md lg xs sm' row={2} col={0} />
                <MatrixData modelid={modelid} user={user} />
              </Item>
              <Item>
                <Location screen='md lg xs sm' row={3} col={0} />
                <NeuronalNet modelid={modelid} user={user} />
              </Item>
            </ResponsiveBox>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ComplianceMatrixPage;
