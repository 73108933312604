/**
 *
 * @param shiftTime number
 * @param dt Date
 * @returns {Date}
 */
export const getShiftDateTime = (shiftTime, dt) => {
  if (!dt) { dt = new Date() }
  let shiftTimeMs = shiftTime * 60000;
  return new Date(dt.getTime() + shiftTimeMs);
};
