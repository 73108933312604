import React, { useState, useEffect } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

const ServiceProvider = props => {
  const { selServiceId, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [companyContact, setCompanyContact] = useState({});

  useEffect(() => {
    const fetchCompanyContact = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/provider/providercontact/${selServiceId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCompanyContact(dataArray[0]);
      } catch (err) {}
    };
    if (selServiceId) {
      fetchCompanyContact();
    }
  }, [sendRequest, selServiceId]);

  return (

    <div className="note-details-container-small">
    <div className="note-details-wrapper">
      <div className="note-details-personally">
        <div className="note-details-personally-none">
          <div className="note-details-personally-block">
            <div className="note-details-personally-block-infoText">
              {"Address: "}
            </div>
            <div className="note-details-personally-block-outputText">
            {companyContact.addressfield}
            </div>
          </div>

          <div className="note-details-personally-block">
            <div className="note-details-personally-block-infoText">
              {"E-Mail: "}
            </div>
            <div className="note-details-personally-block-outputText">
            {companyContact.email}
            </div>
          </div>

          <div className="note-details-personally-block">
            <div className="note-details-personally-block-infoText">
              {"Phone: "}
            </div>
            <div className="note-details-personally-block-outputText">
            {companyContact.phone_one}
            </div>
          </div>

          <div className="note-details-personally-block">
            <div className="note-details-personally-block-infoText">
              {"Hompage: "}
            </div>
            <div className="note-details-personally-block-outputText">
            {companyContact.homepage}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServiceProvider;
