import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { ArgumentAxis, Chart, Series, ValueAxis } from 'devextreme-react/chart';

const DashClientTab = props => {
  const { user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [dashClientsData, setDashClientsData] = useState([]);

  useEffect(() => {
    const fetchDashClients = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/rep/dashclients', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setDashClientsData(dataArray);
      } catch (err) {}
    };
    fetchDashClients();
  }, [sendRequest]);

  const stDashClientsData = new ArrayStore({
    key: 'partner',
    data: dashClientsData,
  });

  const dcClientsData = new DataSource({
    store: stDashClientsData,
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />

        <Item></Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='dash-area-02'>
            <Chart id='dash-client-chart' dataSource={dcClientsData} rotated={true}>
              <Series valueField='clients' argumentField='partner' name='Clients' type='bar' color='#389696' />
              <ArgumentAxis title='Merchants' />
              <ValueAxis title='Clients' />
            </Chart>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default DashClientTab;
