import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './servicesedit.css';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';

const ServicesEditTermsofUse = props => {
  const history = useHistory();
  const { systemServiceId, user } = props;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [serviceData, setServiceData] = useState({});

  useEffect(() => {
    const fetchSystemService = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/systemservice/${systemServiceId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setServiceData(dataArray[0]);
      } catch (err) {}
    };

    if (systemServiceId) {
      fetchSystemService();
    }
  }, [sendRequest, systemServiceId]);

  function navigateToHome() {
    history.push({
      pathname: '/partner',
    });
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='form-edit'>
            <DataGrid
              id='service-edit-featureGrid'
              // dataSource={serviceFeatureDataList}
              keyExpr={'servicefeature_id'}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              columnAutoWidth={true}
              defaultFocusedRowIndex={0}
              focusedRowEnabled={true}
              //  onFocusedRowChanged={e => {
              //    setFocusedDataRow(e.row.data);
              //  }}
            >
              <Column dataField='servicefeature_id' visible={false}></Column>
              <Column dataField='category_id' visible={false}></Column>
              <Column dataField='buysellasset' visible={false}></Column>
              <Column dataField='sendreceiveasset' visible={false}></Column>
              <Column dataField='feature' caption='Terms of Use Document' width='200px'></Column>
              <Column dataField='validfrom' caption='Valid' width='100px' />
              <Column dataField='validuntil' caption='Until' width='100px' />
              <Column dataField='buysellasset_txt' caption='Content'></Column>

              <Column dataField='receive' caption='Required' dataType='boolean' width='100px'></Column>
            </DataGrid>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} colspan={3} />
          <div id={'services-edit-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='services-edit_close-button'
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ServicesEditTermsofUse;
