import React, { useEffect, useState, useCallback } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import '../Client.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import AddInquiry from '../../../modules/inquiries/AddInquiry';

const ClientDocuments = (props) => {
  const { user, selClientid } = props;
  const { sendRequest } = useHttpClient();
  const history = useHistory();

  return (
      <ResponsiveBox>
        <Col ratio={0.6} screen='md lg sm xs' />
        <Col ratio={2} />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <p>Document</p>
          </div>
          <div id='hm-btn-section-1'>
            <Button
              width={120}
              height={36}
              text='Open'
              type='success'
              stylingMode='outlined'
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={1} />
          <div>
            <DataGrid
              id='client-comms-documents-grid'
              height={700}
              // dataSource={dcInquiriesDataList}
              defaultFocusedRowIndex={0}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              wordWrapEnabled={true}
              showRowLines={true}
              showColumnLines={true}
              showColumnHeaders={true}
              onFocusedRowChanged={(e) => {
                console.log(e);
                // setSelRowData(e.row.data);
              //   setSelInquiryId(e.row.key);
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='documentid' visible={false} caption='ID' />
              <Column dataField='document' alignment='left' caption='Document' visible={true} width={250} />
              <Column dataField='documentdate' caption='Date' dataType='datetime' visible={true} width={130} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
  );
};

export default ClientDocuments;
