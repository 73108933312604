import React, { useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';

import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import Box, { Item } from 'devextreme-react/box';

import { Form, SimpleItem, Label, EmptyItem } from 'devextreme-react/form';

import ArrayStore from 'devextreme/data/array_store';

const ComplianceMatrixPop = props => {
  const { showPopup, hidePopup, rskEntryID, rskRowData, rskEntryadd, modelid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [featureChanged, setFeatureChanged] = useState(false);
  const [lpClientType, setLpClientType] = useState([]);
  const [lpRiskArea, setLpRiskArea] = useState([]);

  useEffect(() => {
    const fetchClientType = async () => {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/3', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpClientType(dataArray);
    };

    const fetchRiskArea = async () => {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/32', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpRiskArea(dataArray);
    };

    fetchClientType();
    fetchRiskArea();

    if (showPopup) {
      fetchClientType();
      fetchRiskArea();
    }
  }, [showPopup]);

  const stClientType = new ArrayStore({
    key: 'lp_id',
    data: lpClientType,
  });

  const stRiskArea = new ArrayStore({
    key: 'lp_id',
    data: lpRiskArea,
  });

  const riskMatrixEntry = {
    entryid: rskRowData && !rskEntryadd ? rskRowData.entryid : -1,
    clienttype: rskRowData && !rskEntryadd ? rskRowData.clienttype : -1,
    riskarea: rskRowData && !rskEntryadd ? rskRowData.riskarea : -1,
    risk_name: rskRowData && !rskEntryadd ? rskRowData.risk_name : '',
    risk_groupid: rskRowData && !rskEntryadd ? rskRowData.client_input : false,
    risk_question: rskRowData && !rskEntryadd ? rskRowData.risk_question : '',
    client_input: rskRowData && !rskEntryadd ? rskRowData.client_input : false,
    entryident: rskRowData && !rskEntryadd ? rskRowData.entryident : '',
  };

  const modRiskMatrixEntry = async modify => {
    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + '/ai/feature-matrix/mod_matrix_entry',
      'POST',
      JSON.stringify({
        operation: modify,
        modelid: modelid,
        entryid: riskMatrixEntry.entryid,
        clienttype: riskMatrixEntry.clienttype,
        riskarea: riskMatrixEntry.riskarea,
        risk_name: riskMatrixEntry.risk_name,
        client_input: riskMatrixEntry.risk_groupid,
        risk_question: riskMatrixEntry.risk_question,
        entryident: riskMatrixEntry.entryident,
      }),
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    );
    console.log(response);
  };

  const SaveInput = async () => {
    if (rskEntryadd) {
      await modRiskMatrixEntry(1);
    } else {
      await modRiskMatrixEntry(2);
    }
    hidePopup(true);
  };

  return (
    <Popup
      title={'Risk Matrix Entries'}
      width={700}
      height={520}
      visible={showPopup}
      resizeEnabled={false}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      onShowing={() => {
        setFeatureChanged(false);
      }}
    >
      <Box id='employeeform' direction='col' height='100%'>
        <Item ratio={4} width='100%'>
          <Form id={'form'} labelLocation={'top'} formData={riskMatrixEntry}>
            <Item itemType='group' colCount={12}>
              <SimpleItem
                dataField='clienttype'
                editorType='dxSelectBox'
                colSpan={4}
                name='clienttype_listbox'
                editorOptions={{
                  dataSource: stClientType,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                  
                }}
              >
                <Label text='Affects' />
              </SimpleItem>

              <SimpleItem
                dataField='riskarea'
                editorType='dxSelectBox'
                colSpan={6}
                name='riskarea_listbox'
                editorOptions={{
                  dataSource: stRiskArea,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                  
                }}
              >
                <Label text='Risk Area' />
              </SimpleItem>
            </Item>
            <EmptyItem />
            <Item itemType='group' colCount={12}>
              <SimpleItem
                dataField='risk_name'
                colSpan={10}
                editorOptions={{
                  
                }}
              >
                <Label text='Risk Name' />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={12}>
              <SimpleItem
                dataField='risk_question'
                colSpan={12}
                editorOptions={{
                  
                }}
              >
                <Label text='Risk Question' />
              </SimpleItem>
            </Item>
            <EmptyItem />
            <Item itemType='group' colCount={12}>
              <SimpleItem dataField='client_input' colSpan={8} editorType='dxCheckBox'>
                <Label text='Client Input - is to be answered by Client during Signup' />
              </SimpleItem>
              <SimpleItem
                dataField='entryident'
                colSpan={4}
                editorOptions={{
                  
                }}
              >
                <Label text='Entry Identifier' />
              </SimpleItem>
            </Item>
            <EmptyItem />
          </Form>
          <div id={'closebutton-2'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton-right'
                width={120}
                height={50}
                text='Save'
                type='default'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={SaveInput}
              />
              <Button width={120} height={50} text='Close' type='normal' stylingMode='contained' onClick={hidePopup} />
            </div>
          </div>
        </Item>
      </Box>
    </Popup>
  );
};

export default ComplianceMatrixPop;
