import React, { useState, createContext, useContext, useCallback, useMemo } from 'react';

import { getRoundedDateNearMinutes } from '../utils/getRoundedDateNearMinutes';
import { getShiftDateTime } from '../utils/getShiftDateTime';
import { Conference } from '../models/calendar/Conference';

const CalendarContext = createContext({});
const useCalendar = () => useContext(CalendarContext);

function CalendarProvider(props) {
  const defaultEventData = useMemo(() => {
    const startTime = getRoundedDateNearMinutes(10);
    return {
      startTime,
      endTime: getShiftDateTime(30, startTime),
      reminderBeforeMinutes: 10,
      enableNotification: false,
      conference: new Conference(),
    };
  }, []);

  const [eventData, setEventData] = useState(defaultEventData);
  const [showEventPopup, setShowEventPopup] = useState(false);

  const toggleShowEventPopup = useCallback(() => {
    setShowEventPopup(!showEventPopup);
  }, [showEventPopup]);

  const clearAppointmentData = useCallback(() => {
    setEventData(defaultEventData);
  }, []);

  const setAppointmentData = useCallback(eventData => {
    const newEventData = {
      ...eventData,
      data: eventData.data ? JSON.parse(eventData.data) : undefined,
      attendees: eventData.attendees
        ? eventData.attendees.map(attendee => {
            return attendee.id;
          })
        : undefined,
    };

    setEventData(newEventData);
  }, []);

  return (
    <CalendarContext.Provider
      value={{
        showEventPopup,
        toggleShowEventPopup,
        eventData,
        setAppointmentData,
        clearAppointmentData,
      }}
      {...props}
    />
  );
}

export { CalendarProvider, useCalendar, CalendarContext };
