import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { useHttpClient } from '../../hooks/http-hook';

import { NumberBox } from 'devextreme-react/number-box';

const ForexLeveragePop = (props) => {
  const { showPopup, hidePopup, user, extAccountId, forexLeverageValue, popActivity } = props;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [popLeverageValue, setPopLeverageValue] = useState(5);
  const [featureChanged, setFeatureChanged] = useState(false);

  const modifyBaseLeverageValue = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/forex/modforexbaseleverage',
        'POST',
        JSON.stringify({
          operation: (popActivity === 'add') ? 1 : 3,
          extaccountid: extAccountId,
          leverage: popLeverageValue,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const saveLeverage = async () => {
    await modifyBaseLeverageValue();
    await hidePopup(true);
  };

  return (
    <Popup
      title={'Levarage'}
      width={300}
      height={200}
      visible={showPopup}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      onShowing={() => {
        setPopLeverageValue(forexLeverageValue);
        setFeatureChanged(false);
      }}
    >
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <NumberBox
            id='forex-leverage-numberbox'
            value={popLeverageValue}
            showSpinButtons={true}
            onValueChanged={(e) => {
              setPopLeverageValue(e.value);
            }}
          />
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'closebutton-2-20'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton-right'
                width={100}
                height={36}
                text={popActivity}
                type='default'
                stylingMode='outlined'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={saveLeverage}
              />
              <Button width={100} height={36} text='Cancel' type='normal' stylingMode='outlined' onClick={hidePopup} />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default ForexLeveragePop;
