import React, { useState, useEffect, useCallback } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import { useHistory, useLocation } from "react-router-dom";
import "./servicesedit.css";

import Tabs from "devextreme-react/tabs";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import Button from "devextreme-react/button";

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from "devextreme-react/form";

import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";

import DataGrid, { Scrolling, Column, FilterRow, Editing, Lookup, MasterDetail } from "devextreme-react/data-grid";

import ServiceFeeGridDetails from "../../detailgrids/ServiceFeeGridDetails";

const ServicesEditCostRates = (props) => {
  const { selServiceId, user } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [costRateHeaderList, setCostRateHeaderList] = useState({});
  const [costRiskEntriesList, setCostRiskEntriesList] = useState([]);

  useEffect(() => {
    const fetchServiceCostRatesHeader = async () => {
      let anCostEntryId = -1;

      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/singleservicecostrateheader/${selServiceId}`,
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );

        setCostRateHeaderList(dataArray[0]);

        if (dataArray[0].costentryid) {
          anCostEntryId = dataArray[0].costentryid;
        }
        const costRiskDataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/viewcostriskentries/${anCostEntryId}`,
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );

        setCostRiskEntriesList(costRiskDataArray);
      } catch (err) {
        console.log(err);
      }
    };

    if (selServiceId && selServiceId > 0) {
      fetchServiceCostRatesHeader();
    }
  }, [sendRequest, selServiceId]);

  const costRateHeader = {
    serviceid: (costRateHeaderList && costRateHeaderList.serviceid) || -1,
    costentryid: (costRateHeaderList && costRateHeaderList.costentryid) || -1,
    generated: costRateHeaderList && costRateHeaderList.generated,
    valid_on: costRateHeaderList && costRateHeaderList.valid_on,
    valid_until: costRateHeaderList && costRateHeaderList.valid_until,
    currency: (costRateHeaderList && costRateHeaderList.currency) || "",
    entrynumber: (costRateHeaderList && costRateHeaderList.entrynumber) || "",
    comment: (costRateHeaderList && costRateHeaderList.comment) || "",
    userid: (costRateHeaderList && costRateHeaderList.userid) || -1,
    active: (costRateHeaderList && costRateHeaderList.active) || false,
    active: costRateHeaderList && costRateHeaderList.active_date,
  };

  const stCostRiskEntriesData = new ArrayStore({
    key: ["riskid"],
    data: costRiskEntriesList,
  });

  const dcCostRiskEntriesData = new DataSource({
    store: stCostRiskEntriesData,
  });

  function navigateToHome() {
    history.push({
      pathname: "/partner",
    });
  }

  const openServiceFeeEdit = async () => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/service/costrateworkcopy/${costRateHeader.serviceid}/${costRateHeader.costentryid}`,
        "GET",
        null,
        { Authorization: "Bearer " + user.token }
      );
      history.push({
        pathname: "/servicefees_edit",
        costrates: {
          selServiceid: costRateHeader.serviceid,
          selCostEntryid: costRateHeader.costentryid,
          user: user,
          workId: dataArray[0].work_copy_costrates,
          originid: 2,
        },
      });
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />

        <Item>
          <Location screen="md lg sm xs" row={0} col={0} />

          <ResponsiveBox>
            <Col />
            <Row />

            <Item>
              <Location screen="md lg sm xs" row={0} col={0} />
              <Form
                id="service-cost-entries-form-edit-base"
                formData={costRateHeader}
                labelLocation={"left"}
                colCountByScreen={colCountByScreen}
                scrollingEnabled={true}
              >
                <Item itemType="group" colCount={5}>
                  <SimpleItem dataField="currency">
                    <Label text="Currency" />
                  </SimpleItem>
                  <SimpleItem dataField="valid_on" dataType="date" editorType="dxDateBox">
                    <Label text="Valid on" />
                  </SimpleItem>
                  <SimpleItem dataField="valid_until" dataType="date" editorType="dxDateBox">
                    <Label text="Valid on" />
                  </SimpleItem>
                </Item>
                <Item itemType="group" colCount={5}>
                  <SimpleItem dataField="active" dataType="boolean" editorType="dxCheckBox">
                    <Label text="Active" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="active_date"
                    dataType="date"
                    editorType="dxDateBox"
                    editorOptions={{ readOnly: true }}
                  >
                    <Label text="Active on" />
                  </SimpleItem>
                </Item>

                <Item>
                  <DataGrid
                    id={"partner-service-edit-cost-rates-risk-grid"}
                    dataSource={dcCostRiskEntriesData}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    focusedRowEnabled={true}
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={true}
                    autoNavigateToFocusedRow={true}
                    // onFocusedRowChanged={e => {
                    //   setRiskGridData(e.row.data);
                    //   setSelRiskid(e.row.data.riskid);
                    // }}
                  >
                    <Column dataField="partnerid" visible={false} />
                    <Column dataField="serviceid" visible={false} />
                    <Column dataField="costentryid" visible={false} />
                    <Column dataField="riskid" visible={false} />
                    <Column dataField="risklevel" caption="Risk Level" width="150px" allowEditing={false} />
                    <Column dataField="rates_set" caption="Rates Set" width="90px" visible={false} />
                    <Column dataField="not_applicable" caption="Not Applicable" width="120px" />
                    <Column dataField="setupfee" caption="Setup Fee" dataType="number" width="100" />
                    <Column dataField="recurringfee" caption="Service Fee" dataType="number" width="100" />
                    <Column dataField="recperiod" caption="Recurring" width="100"></Column>
                    <MasterDetail enabled={true} component={ServiceFeeGridDetails} />
                  </DataGrid>
                </Item>
              </Form>
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen="md lg sm xs" row={1} col={0} />
          <div id={"services-edit-button"}>
            <div style={{ flexDirection: "row" }}>
              <Button
                id="services-edit_close-button"
                width={120}
                height={50}
                text="Close"
                type="normal"
                stylingMode="contained"
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: "unterlined" };

export default ServicesEditCostRates;
