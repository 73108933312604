import React, { useEffect, useRef, useState } from 'react';
import './ClientsApprovedPage.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { useHttpClient } from '../../hooks/http-hook';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import DataGrid, { Column } from 'devextreme-react/data-grid';
import CasesNotesToPop from '../operations/CasesNotesToPop';

const ClientApprovalPop = props => {
  const { showPopup, hidePopup, clientApprovalStateData, selSignupClientid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [contactClientid, setContactClientid] = useState([]);
  const [showCasesNotesTo, setShowCasesNotesTo] = useState(false);
  const [noteCaseSituation, setNoteCaseSituation] = useState(0);
  const [contactClient, setContactClient] = useState(false);

  const stclientApprovalStateData = new ArrayStore({
    key: 'assetaccountid',
    data: clientApprovalStateData,
  });

  const dcClientApprovalStateData = new DataSource({
    store: stclientApprovalStateData,
  });

  const openApprovalNotesToPopup = async () => {
    await setContactClientid(selSignupClientid);
    await setNoteCaseSituation(10);
    await setContactClient(false);
    await setShowCasesNotesTo(true);
  };

  const openAdditionalNotesToPopup = async () => {
    await setContactClientid(selSignupClientid);
    await setNoteCaseSituation(20);
    await setContactClient(true);
    await setShowCasesNotesTo(true);
  };

  const hideCasesNotesTo = async () => {
    setShowCasesNotesTo(false);
    hidePopup(true);
  };

  return (
    <Popup
      title={'Account Approval'}
      width={760}
      height={420}
      visible={showPopup}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <CasesNotesToPop
        showPopup={showCasesNotesTo}
        hidePopup={hideCasesNotesTo}
        user={user}
        caseSituationAction={true}
        caseSituation={noteCaseSituation}
        contactClientid={contactClientid}
        entryid={-1}
        caseEntryid={-1}
        contactPortalid={-1}
        contactClient={contactClient}
      />
      <ResponsiveBox>
        <Row ratio={6} />
        <Row ratio={0.2} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='client-approval-pop-area'>
            <div id='client-approval-pop-one'>
              <table>
                <tbody>
                  <tr>
                    <td width='100px'>Client Access:</td>
                    <td width='100px'>Active</td>
                    <td width='60px'>Since:</td>
                    <td width='100px'>01.01.2022</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <DataGrid
              id='client-approval-pop-grid'
              dataSource={dcClientApprovalStateData}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              onFocusedRowChanged={e => {
                if (e.row) {
                  //    setSelectedClientid(e.row.key.clientid);
                  //    passToParent(e.row.key.clientid);
                }
              }}
            >
              <Column dataField='assetaccountid' visible={false}></Column>
              <Column dataField='providerid' visible={false}></Column>
              <Column dataField='servicename' caption='Service' width={150} />
              <Column dataField='providername' caption='Provider' width={120} />
              <Column dataField='provider_approved' caption='Approved' width={80} />
              <Column dataField='access_state_txt' caption='Access State' width={120} />
              <Column dataField='access_state_time' caption='on' dataType='datetime' width={120} />
            </DataGrid>
            <div id='client-approval-pop-two'>
              <table>
                <tbody>
                  <tr>
                    <td width='130px'>Onboarding Fee:</td>
                    <td width='100px'>Paid</td>
                    <td width='60px'>01.01.2022</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'access-pop-closebutton-right'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='access-pop-savebutton-right'
                width={280}
                height={50}
                text='Request Provider Approval'
                type='normal'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={openApprovalNotesToPopup}
              />
              <Button
                id='access-pop-savebutton-right'
                width={350}
                height={50}
                text='Request Additional Client Documents'
                type='normal'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={openAdditionalNotesToPopup}
              />
              {/* 
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={hidePopup}
              />*/}
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default ClientApprovalPop;
