import React, { useState, useEffect } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from 'devextreme-react/responsive-box';

const ProductCompliance = props => {
  const { selContactPersonid, user, selCompanyID } = props;

  return (
    <ResponsiveBox>
      <Col />
      <Row />
      <Item>
        <Location screen='md lg sm xs' row={0} col={0} />
        <div>
          <p>Product Compliance</p>
        </div>
      </Item>
    </ResponsiveBox>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ProductCompliance;
