import React, { useEffect, useRef, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./ServiceProviderData.css";
import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";
import { Button } from "devextreme-react/button";
import { useHttpClient } from "../../hooks/http-hook";
import DataGrid, { Column } from "devextreme-react/data-grid";

import { SelectBox } from "devextreme-react/select-box";
import ArrayStore from "devextreme/data/array_store";

import ServiceEditTOUPop from "./ServiceEditTOUPop";
import MessagePop from "../../ui_elements/MessagePop";

const ServiceProviderTOU = (props) => {
  const { user } = props;
  const history = useHistory();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [serviceTouData, setServiceTouData] = useState([]);
  const [serviceTouId, setServiceTouId] = useState(-1);
  const [showPopup, setShowPopup] = useState(false);
  const [popInsert, setPopInsert] = useState(0);
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    const fetchServiceTou = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + `/service/servicedoctou`, "GET", null, {
          Authorization: "Bearer " + user.token,
        });
        setServiceTouData(dataArray);
      } catch (err) {}
    };

    fetchServiceTou();
  }, [sendRequest, reloadData]);

  const stServiceTouData = new ArrayStore({
    key: "servicetouid",
    data: serviceTouData,
  });

  const removeTouDoc = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/service/servicedoctou",
        "POST",
        JSON.stringify({
          operation: 3,
          servicetouid: serviceTouId,
        }),
        { "Content-Type": "application/json", Authorization: "Bearer " + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const addTermsofUse = () => {
    setReloadData(false);
    setPopInsert(1);
    setShowPopup(true);
  };

  const openTermsofUse = () => {
    setReloadData(false);
    setPopInsert(2);
    setShowPopup(true);
  };

  const hidePopup = () => {
    setShowPopup(false);
    setReloadData(true);
  };

  function navigateToHome() {
    history.goBack();
  }

  const hideMessagePop = useCallback((doRemove) => {
    setShowMessagePop(false);
    if (doRemove) {
      removeTouDoc();
      setReloadData(true);
    }
  });

  const removeTOU = () => {
    setReloadData(false);
    setShowMessagePop(true);
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle="Confirmation"
        popMessage="Delete the selected Terms of Use?"
      />
      <ServiceEditTOUPop
        showPopup={showPopup}
        hidePopup={hidePopup}
        selTermsofUseId={serviceTouId}
        popInsert={popInsert}
        user={user}
      />

      <div id="settings-tou-box">
        <div className="rb-header item">
          <div className="dx-fieldset-header">Terms of Use</div>
        </div>

        <ResponsiveBox>
          <Row ratio={2} />
          <Col ratio={2} />
          <Col ratio={10} />

          <Item>
            <Location screen="md lg sm xs" row={0} col={0} />
            <div id="hm-btn-section-0-1">
              <Button
                width={100}
                height={36}
                text="Add"
                type="default"
                disabled={true}
                stylingMode="outlined"
                onClick={addTermsofUse}
              />
            </div>
            <div id="hm-btn-section-0-11">
              <Button
                width={100}
                height={36}
                text="Edit"
                disabled={true}
                type="success"
                stylingMode="outlined"
                onClick={openTermsofUse}
              />
            </div>
            <div id="hm-btn-section-0-11">
              <Button
                width={100}
                height={36}
                text="Remove"
                disabled={true}
                type="danger"
                stylingMode="outlined"
                onClick={removeTOU}
              />
            </div>
          </Item>
          <Item>
            <Location screen="md lg sm xs" row={0} col={1} />
            <DataGrid
              height={400}
              dataSource={stServiceTouData}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              columnAutoWidth={true}
              defaultFocusedRowIndex={0}
              focusedRowEnabled={true}
              onFocusedRowChanged={(e) => {
                setServiceTouId(e.row.key);
              }}
            >
              <Column dataField="serviceid" visible={false}></Column>
              <Column dataField="servicetouid" visible={false}></Column>
              <Column dataField="doc_title" caption="Terms of Use" width="250px"></Column>
              <Column dataField="validon" dataType="date" width="100px"></Column>
              <Column dataField="validuntil" dataType="date" width="100px"></Column>
            </DataGrid>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: "unterlined" };

export default ServiceProviderTOU;
