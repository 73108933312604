import React, { useRef, useState, useEffect } from 'react';
import './GlobalDefinitions.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Form, SimpleItem, EmptyItem, Label } from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import MessagePop from '../../ui_elements/MessagePop';
import MailTemplatesPop from './MailTemplatePop';

const MailTemplatesTab = (props) => {
  const { user } = props;

  const templateFormRef = useRef(null);
  const [portalsDatalist, setPortalsDatalist] = useState([]);
  const [templateGridData, setTemplateGridData] = useState();
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const [selPortalid, setSelPortalid] = useState(1);
  const [mailTemplatesData, setMailTemplatesData] = useState([]);

  const [showMailTemplatePopup, setShowMailTemplatePopup] = useState(false);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const templateData = {
    portalid: templateGridData && templateGridData.portalid,
    template_id: templateGridData && templateGridData.template_id,
    template_ident: templateGridData && templateGridData.template_ident,
    template_name: templateGridData && templateGridData.template_name,
    template_subject: templateGridData && templateGridData.template_subject,
    template_content: templateGridData && templateGridData.template_content,
    template_fields: templateGridData && templateGridData.template_fields,
  };

  useEffect(() => {
    const fetchPortals = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/management/portals', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setPortalsDatalist(dataArray);
      } catch (err) {}
    };

    fetchPortals();
  }, [sendRequest]);

  useEffect(() => {
    const fetchMailTemplates = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/email/mailtemplates/${selPortalid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setMailTemplatesData(dataArray);
      } catch (err) {}
    };
    if (selPortalid) {
      fetchMailTemplates();
    }
  }, [sendRequest, selPortalid, reloadData]);

  const stPortalData = new ArrayStore({
    key: 'portalid',
    data: portalsDatalist,
  });

  const stMailTemplatesData = new ArrayStore({
    key: 'template_id',
    data: mailTemplatesData,
  });

  const removeTemplate = () => {
    modifyMailTemplate(3);
  };

  const hideMessagePop = (doRemove) => {
    setShowMessagePop(false);
    if (doRemove) {
      removeTemplate();
      setReloadData(!reloadData);
    }
  };

  const modifyMailTemplate = async (modify) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/email/modmailtemplate',
        'POST',
        JSON.stringify({
          operation: modify,
          portalid: selPortalid,
          template_id: templateData.template_id,
          template_name: templateData.template_name,
          template_ident: templateData.template_ident,
          template_subject: templateData.template_subject,
          template_content: templateData.template_content,
          template_fields: templateData.template_fields,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setReloadData(!reloadData);
    } catch (err) {
      console.log(err);
    }
  };

  const hideMailTemplatePopup = () => {
    setShowMailTemplatePopup(false);
    setReloadData(!reloadData);
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected Feature?'
      />
      <MailTemplatesPop
        showPopup={showMailTemplatePopup}
        hidePopup={hideMailTemplatePopup}
        user={user}
        stPortalData={stPortalData}
      />
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Mail Templates</div>
        <ResponsiveBox>
          <Row ratio={0.1} />
          <Row ratio={10} />
          <Row ratio={0.1} />
          <Col ratio={7} />
          <Col ratio={13} />

          <Item>
            <Location screen='md lg sm xs' row={0} col={0} colspan={2} />
            <ResponsiveBox>
              <Row />
              <Col ratio={1} />
              <Col ratio={1.3} />
              <Col ratio={4} />
              <Item>
                <Location screen='md lg sm xs' row={0} col={0} />
                <p>Mail Templates</p>
              </Item>
              <Item>
                <Location screen='md lg sm xs' row={0} col={1} />
                <SelectBox
                  width={'200px'}
                  dataSource={stPortalData}
                  valueExpr={'portalid'}
                  displayExpr={'portalname'}
                  searchEnabled={true}
                  defaultValue={selPortalid}
                  onValueChanged={(e) => {
                    e && setSelPortalid(e.value);
                  }}
                />
              </Item>
              <Item>
                <Location screen='md lg sm xs' row={0} col={2} />
                <Button
                  id='templatebutton-copy'
                  width={120}
                  height={36}
                  text='Copy'
                  type='normal'
                  stylingMode='contained'
                  onClick={() => {
                    setShowMailTemplatePopup(true);
                  }}
                />
              </Item>
            </ResponsiveBox>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={1} col={0} />
            <div id='mail-template-data-grid-box'>
              <DataGrid
                id='mail-template-grid'
                dataSource={stMailTemplatesData}
                showRowLines={true}
                showBorders={true}
                showColumnLines={true}
                columnAutoWidth={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                showColumnHeaders={true}
                onFocusedRowChanged={(e) => {
                  e.row && setTemplateGridData(e.row.data);
                }}
              >
                <Column dataField='template_id' visible={true} caption='ID' width='50' />
                <Column dataField='portalid' visible={false} />
                <Column dataField='template_name' caption='Template' width='200'></Column>
                <Column dataField='template_ident' caption='Short Ident' visible={true} width='80' />
                <Column dataField='template_subject' visible={false} />
                <Column dataField='template_content' visible={false} />
                <Column dataField='template_fields' visible={false} />
              </DataGrid>
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={1} col={1} />
            <div>
              <Form
                id='global-defs-mail-template-form'
                ref={templateFormRef}
                formData={templateData}
                labelLocation={'left'}
                colCountByScreen={colCountByScreen}
              >
                <Item itemType='group' colCount={10}>
                  <SimpleItem dataField='template_id' colSpan={2}>
                    <Label text='ID' />
                  </SimpleItem>
                  <SimpleItem dataField='template_name' colSpan={5}>
                    <Label text='Name' />
                  </SimpleItem>
                  <SimpleItem dataField='template_ident' colSpan={3}>
                    <Label text='Ident' />
                  </SimpleItem>
                </Item>
                <Item itemType='group' colCount={10}>
                  <SimpleItem dataField='template_subject' colSpan={10}>
                    <Label text='Subject' />
                  </SimpleItem>
                </Item>
                <Item itemType='group' colCount={1}>
                  <SimpleItem dataField='template_content' editorType='dxTextArea' editorOptions={{ height: 440 }}>
                    <Label text='Template' />
                  </SimpleItem>
                </Item>
                <Item itemType='group' colCount={10}>
                  <SimpleItem dataField='template_fields' colSpan={10}>
                    <Label text='Fields' />
                  </SimpleItem>
                </Item>
              </Form>
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={2} col={1} />
            <div id={'closebutton-2'}>
              <div style={{ flexDirection: 'row' }}>
                <Button
                  id='templatebutton-right'
                  width={120}
                  height={36}
                  text='Insert'
                  type='default'
                  stylingMode='outlined'
                  onClick={() => {
                    modifyMailTemplate(1);
                  }}
                />{' '}
                <Button
                  id='templatebutton-right'
                  width={120}
                  height={36}
                  text='Save'
                  type='success'
                  stylingMode='outlined'
                  onClick={() => {
                    modifyMailTemplate(2);
                  }}
                />{' '}
                <Button
                  id='templatebutton-right'
                  width={120}
                  height={36}
                  text='Remove'
                  type='danger'
                  stylingMode='outlined'
                  onClick={() => {
                    modifyMailTemplate(3);
                  }}
                />{' '}
                <Button
                  width={120}
                  height={36}
                  text='Cancel'
                  type='normal'
                  stylingMode='outlined'
                  onClick={() => {
                    setReloadData(!reloadData);
                  }}
                />
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default MailTemplatesTab;
