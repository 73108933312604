export const navigation = [
  {
    DASH: true,
    text: 'Dashboard',
    path: '/home',
    icon: 'home',
  },
  {
    OPNS: true,
    text: 'Operations',
    path: '/operations',
    icon: 'user',
  },
  {
    CLINT: true,
    text: 'Clients',
    path: '/clients',
    icon: 'group',
  },
  {
    MEPAR: true,
    text: 'Merchants / Partner',
    icon: 'globe',
    path: '/partner',
  },
  {
    FNET: true,
    text: 'Financial Networks',
    icon: 'link',
    path: '/networks',
  },
  {
    SVRCE: true,
    text: 'Services',
    icon: 'runner',
    isExpanded: false,
    items: [
      {
        SYSRV: true,
        text: 'Services',
        icon: 'spinnext',
        path: '/services',
      },
      {
        SPROV: true,
        text: 'Service Provider',
        icon: 'spinnext',
        path: '/serviceprovider',
      },
    ],
  },
  {
    CMPLC: true,
    text: 'Compliance',
    icon: 'exportpdf',
    isExpanded: false,
    items: [
      {
        RMTRX: true,
        text: 'Risk Matrix',
        icon: 'spinnext',
        path: '/compliancematrix',
      },
      {
        KYC: true,
        text: 'KYC',
        icon: 'spinnext',
        path: '/kyc',
      },
    ],
  },
  {
    RPTNG: true,
    text: 'Reporting',
    icon: 'bulletlist',
    isExpanded: false,
    items: [
      {
        RTAN: true,
        text: 'Transactions',
        icon: 'spinnext',
        path: '/transactions',
      },
      {
        ROVER: true,
        text: 'Cubes',
        icon: 'spinnext',
        path: '/reporting',
      },
    ],
  },

  {
    COMCN: true,
    text: 'Communication',
    icon: 'tel',
    isExpanded: false,
    items: [
      {
        CMSG: true,
        text: 'Client Messaging',
        icon: 'spinnext',
        path: '/messages',
      },
      {
        CMAIL: true,
        text: 'Client Mailing',
        icon: 'spinnext',
        path: '/mailing',
      },
      {
        CCALL: true,
        text: 'Client Video Call',
        icon: 'spinnext',
        path: '/calendar',
      },
    ],
  },
  {
    STTNG: true,
    text: 'Extensions',
    icon: 'chart',
    path: '/extensions',
  },
  {
    STTNG: true,
    text: 'Settings',
    path: '/globaldefinitions',
    icon: 'preferences',
  },
];
