import HTTP from "../api/HTTP";

class ServiceProviderHelper {
  getServiceProviders = () => {
    return HTTP.get(`/provider/serviceprovider`);
  };

  getServiceProvidersLP = () => {
    return HTTP.get(`/provider/serviceproviderlp`).then(
      response => response.data
    );
  };

  saveServiceProviderInfo = (info) => {
    return HTTP.post(`/provider/serviceprovider`, info);
  };

  getServiceProviderInfo = (serviceProviderId) => {
    return HTTP.get(`/provider/serviceprov/${serviceProviderId}`);
  };

  getServiceProviderServices = (providerId) => {
    return HTTP.get(`/provider/providerservices/${providerId}`);
  };

  getServiceProviderContact = (serviceId) => {
    return HTTP.get(`/provider/providercontact/${serviceId}`);
  };

  saveApprovedClient = (data) => {
    return HTTP.post(`/provider/approveclient`, data).then(
      response => response.data
    );
  };

  getSystemServiceFeatures = (serviceId) => {
    return HTTP.get(`/service/systemservicefeatures/${serviceId}`).then(
      response => response.data
    );
  };

  saveSystemServiceFeatures = (featureData) => {
    return HTTP.post(`/services/modsystemservicefeature`, featureData);
  };

  getServiceAccounts = (serviceId, queryStringData) => {
    return HTTP.get(`/service/serviceaccounts/${serviceId}`, {
      params: queryStringData
    }).then(response => response.data);
  };

  addServiceAccount = (accountData) => {
    return HTTP.post(`/services/addserviceaccount`, accountData);
  };

  getCostRiskEntries = (costEntryId) => {
    return HTTP.get(`/service/viewcostriskentries/${costEntryId}`).then(
      response => response.data
    );
  };

  getCostRateWorkCopy = (serviceId, costEntryId) => {
    return HTTP.get(
      `/services/costrateworkcopy/${serviceId}/${costEntryId}`
    ).then(response => response.data);
  };

  getServiceRequiredCustomerDoc = (serviceId) => {
    return HTTP.get(`/service/servicedocreqcl/${serviceId}`).then(
      response => response.data
    );
  };

  addServiceRequiredCustomerDoc = (docData) => {
    return HTTP.post(`/service/servicedocreq`, docData);
  };

  getServiceTermOfUseDoc = (serviceId) => {
    return HTTP.get(`/service/servicedoctou/${serviceId}`).then(
      response => response.data
    );
  };

  deleteServiceTermOfUseDoc = (docData) => {
    return HTTP.post(`/service/servicedoctou`, docData);
  };

  getServiceTermOfUse = (serviceId, touId) => {
    return HTTP.get(`/service/servicetou/${serviceId}/${touId}`).then(
      response => response.data
    );
  };

  addServiceTermOfUseDoc = (docData) => {
    return HTTP.post(`/service/servicedoctou`, docData);
  };

  getServiceReqDoc = (serviceId, reqId) => {
    return HTTP.get(`/service/servicereqDoc/${serviceId}/${reqId}`).then(
      response => response.data
    );
  };

  addServiceReqCustDoc = (docData) => {
    return HTTP.post(`/service/servicedocreq`, docData);
  };

  getSignleServiceDetails = (sid) => {
    return HTTP.get(`/service/signleservicedetails/${sid}`).then(
      response => response.data
    );
  };
}

export default new ServiceProviderHelper();
