import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';

import './communication.scss';

import { Form, SimpleItem, EmptyItem, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import TabPanel from 'devextreme-react/tab-panel';
import { MultiView, SelectBox } from 'devextreme-react';
import Tabs from 'devextreme-react/tabs';

import CasesOneTab from './CasesOneTab';
import CasesNotesTab from './CasesNotesTab';
import CasesNews from './CasesNews';
import { useAuth } from '../../contexts/auth';
import { Label } from 'devextreme-react/data-grid';

const CompliancePage = () => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [caseDetails, setCaseDetails] = useState();
  const [userCompanies, setUserCompanies] = useState([]);
  const [selCompanyVisible, setSelCompanyVisible] = useState(user.companyselect);
  const [newsKey, setNewsKey] = useState();
  const [reloadCasesGrids, setReloadCasesGrids] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const getsavedselcompany = () => {
    const result = -1;
    let loaded = JSON.parse(localStorage.getItem('storedCompany'));
    if (loaded && loaded !== null) {
      return loaded.cid;
    } else {
      return result;
    }
  };

  const [selectedCompany, setSelectedCompany] = useState(getsavedselcompany);

  const [lpCaseTypes, setLpCaseTypes] = useState([]);
  const [lpCaseStates, setLpCaseStates] = useState([]);
  const [lpDevPrios, setLpDevPrios] = useState([]);

  const baseformData = {
    selCompanyid: selectedCompany,
    selLfdNr: -1,
    selBase_LfdNr: -1,
  };

  const getKeyVal_Base_LfdNr = useCallback((value, subject, contact, contactid) => {
    setCaseDetails({
      base_lfdnr: value,
      subject: subject,
      contact: contact,
      contactid: contactid,
    });
  });

  const getNewsKey = useCallback((annewskey, ancontactid) => {
    setNewsKey(annewskey);
    setCaseDetails({
      base_lfdnr: annewskey.Base_LfdNr,
      subject: '',
      contact: '',
      contactid: ancontactid,
    });
  });

  const getReloadCondition = useCallback(doGridReload => {
    setReloadCasesGrids(doGridReload);
  });

  const stuserCompaniesData = new ArrayStore({
    key: 'companyid',
    data: userCompanies,
  });

  useEffect(() => {
    const fetchCaseTypes = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/27',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpCaseTypes(dataArray);
      } catch (err) {}
    };

    const fetchcasestates = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/29',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpCaseStates(dataArray);
      } catch (err) {}
    };

    const fetchDevPrios = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/28',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpDevPrios(dataArray);
      } catch (err) {}
    };

    fetchCaseTypes();
    fetchcasestates();
    fetchDevPrios();
  }, [sendRequest]);

  const stCaseType = new ArrayStore({
    key: 'lp_id',
    data: lpCaseTypes,
  });

  const stCaseStates = new ArrayStore({
    key: 'lp_id',
    data: lpCaseStates,
  });

  const stDevPrios = new ArrayStore({
    key: 'lp_id',
    data: lpDevPrios,
  });

  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Cases</h2> */}
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={2} />
            <Row ratio={0.7} />
            <Col ratio={3} />
            <Col ratio={1.4} screen='md lg' />

            <Item>
              <Location screen='md lg' row={0} col={0} colspan={2} />
              <Location screen='sm' row={0} col={0} colspan={1} />
              <Location screen='xs' row={0} col={0} />
              <div className='rb-header item'>
                <ResponsiveBox>
                  <Row />
                  <Col ratio={3} />
                  <Col ration={1} />
                  <Item>
                    <Location screen='md lg sm' row={0} col={0} />
                    <div className='dx-fieldset-header' id='cap_item'>
                      Communication
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={1} />
                    <div id='cmpny-select'>
                      <Form
                        id={'form'}
                        formData={baseformData}
                        labelLocation={'left'}
                        onFieldDataChanged={e => {
                          if (e.dataField) {
                            if (e.dataField === 'selCompanyid') {
                              setSelectedCompany(e.value);
                              localStorage.setItem(
                                'storedCompany',
                                JSON.stringify({
                                  cid: e.value,
                                  cname: e.component.option('text'),
                                })
                              );
                            }
                          }
                        }}
                      >
                        <SimpleItem
                          dataField='selCompanyid'
                          editorType='dxSelectBox'
                          name='selcompany_listbox'
                          visible={selCompanyVisible}
                          editorOptions={{
                            dataSource: stuserCompaniesData,
                            valueExpr: 'companyid',
                            displayExpr: 'company',
                            searchEnabled: true,
                          }}
                        >
                          <Label text='Company' />
                        </SimpleItem>
                      </Form>
                    </div>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg sm' row={1} col={0} />
              <Location screen='xs' row={1} col={0} />
              <div className='rb-content item'>
                <ResponsiveBox>
                  <Row ratio={1} />
                  <Row ratio={1} />
                  <Col ratio={1} />
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={0} />
                    <div>
                      <MultiView height={350} loop={false}>
                        <Item title='Cases One'>
                          <CasesOneTab
                            passToParent={getKeyVal_Base_LfdNr}
                            user={user}
                            selCompanyID={selectedCompany}
                            selnewskey={newsKey}
                            dataStCaseType={stCaseType}
                            dataStCaseStates={stCaseStates}
                            dataStDevPrios={stDevPrios}
                            getReload={getReloadCondition}
                            reloadGrid={reloadCasesGrids}
                          />
                        </Item>
                      </MultiView>
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={1} col={0} />
                    <Tabs
                      id='homeNotesTab'
                      selectedIndex={tabIndex}
                      width='98%'
                      // height='20px'
                      visible={true}
                      onItemClick={e => {
                        setTabIndex(e.itemIndex);
                      }}
                    >
                      <Item text='Notes' />
                      <Item text='Communication' />
                      <Item text='Contact' />
                    </Tabs>
                    <MultiView height={350} animationEnabled={false} selectedIndex={tabIndex}>
                      <Item title='Notes'>
                        <CasesNotesTab
                          caseDetails={caseDetails}
                          user={user}
                          selCompanyID={selectedCompany}
                          getReload={getReloadCondition}
                        />
                      </Item>
                      <Item title='Communication'></Item>
                      <Item title='Contact'></Item>
                    </MultiView>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg' row={1} col={1} />
              <div className='rb-right-side-bar item'>
                <CasesNews
                  user={user}
                  selCompanyID={selectedCompany}
                  retrieveNewsKey={getNewsKey}
                  reloadGrid={reloadCasesGrids}
                />
              </div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompliancePage;
