import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './ServicesData.css';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';

import DataGrid, { Column, Editing } from 'devextreme-react/data-grid';
import ServiceEditFeaturePop from './ServiceEditFeaturePop';
import MessagePop from '../../ui_elements/MessagePop';

const ServicesDataFeatures = (props) => {
  const { selServiceId, selCategoryId, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const [serviceFeaturesData, setServiceFeaturesData] = useState([]);
  const [showServiceFeaturePopup, setShowServiceFeaturePopup] = useState(false);
  const [addServiceFeature, setAddServiceFeature] = useState(false);
  const [reloadServiceFeatures, setReloadServiceFeatures] = useState(false);
  const [selFeatureId, setSelFeatureId] = useState(-1);
  const [selFeatureGroupId, setSelFeatureGroupId] = useState(-1);
  const [selFeatureGroupAssetId, setSelFeatureGroupAssetId] = useState(-1);
  const [showMessagePop, setShowMessagePop] = useState(false);

  useEffect(() => {
    const fetchServiceFeatures = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/systemservicefeatures/${selServiceId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setServiceFeaturesData(dataArray);
        setReloadServiceFeatures(false);
      } catch (err) {}
    };
    if (selServiceId || reloadServiceFeatures) {
      fetchServiceFeatures();
    }
  }, [sendRequest, selServiceId, reloadServiceFeatures]);

  const stServiceFeaturesData = new ArrayStore({
    key: ['featureid', 'group_id', 'group_assetid'],
    data: serviceFeaturesData,
  });

  const dcServiceFeaturesData = new DataSource({
    store: stServiceFeaturesData,
  });

  const delServiceFeatureGroup = async () => {
    try {
      sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/service/modsystemservicefeature',
        'POST',
        JSON.stringify({
          operation: 3,
          serviceid: selServiceId,
          featureid: selFeatureId,
          group_id: selFeatureGroupId,
          group_assetid: selFeatureGroupAssetId,
          usefeature: false,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    history.goBack();
  }

  const addNewServiceFeature = () => {
    setAddServiceFeature(true);
    setShowServiceFeaturePopup(true);
  };

  const hideServiceFeaturePopup = () => {
    setShowServiceFeaturePopup(false);
    setReloadServiceFeatures(true);
  };

  const removeServiceFeatureGroup = () => {
    setShowMessagePop(true);
  };

  const hideMessagePop = async (doRemove) => {
    setShowMessagePop(false);
    if (doRemove) {
      // console.log('run remove');
      await delServiceFeatureGroup();
      setReloadServiceFeatures(true);
    }
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected Feature Group?'
      />
      <ServiceEditFeaturePop
        showPopup={showServiceFeaturePopup}
        hidePopup={hideServiceFeaturePopup}
        serviceId={selServiceId}
        serviceCategoryId={selCategoryId}
        addServiceFeature={addServiceFeature}
        user={user}
      />
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Features</div>

        <ResponsiveBox>
          <Row ratio={1} />
          <Col ratio={0.4} screen='md lg sm xs' />
          <Col ratio={3} />

          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
              <div id='hm-btn-section-0-1'>
                <Button
                  width={120}
                  height={36}
                  text='Add'
                  type='default'
                  stylingMode='outlined'
                  onClick={addNewServiceFeature}
                />
              </div>
              <div id='hm-btn-section-0-11'>
                <Button
                  width={120}
                  height={36}
                  text='Remove'
                  type='danger'
                  stylingMode='outlined'
                  disabled={selFeatureGroupId === 1}
                  onClick={removeServiceFeatureGroup}
                />
              </div>
          </Item>

          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <div>
              <DataGrid
                height={400}
                dataSource={dcServiceFeaturesData}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                onFocusedRowChanged={(e) => {
                  if (e.row) {
                    // console.log(e.row.key);
                    setSelFeatureId(e.row.key.featureid);
                    setSelFeatureGroupId(e.row.key.group_id);
                    setSelFeatureGroupAssetId(e.row.key.group_assetid);
                  }
                }}
              >
                <Column dataField='servicefeature_id' visible={false}></Column>
                <Column dataField='group_id' visible={false}></Column>
                <Column dataField='group_assetid' visible={false}></Column>
                <Column dataField='group_position' visible={false}></Column>
                <Column dataField='category_id' visible={false}></Column>
                <Column dataField='buysellasset' visible={false}></Column>
                <Column dataField='sendreceiveasset' visible={false}></Column>
                <Column dataField='feature' caption='Feature' width='230'></Column>
                <Column dataField='groupasset' caption='Group' width='100'></Column>
                <Column dataField='buysellasset_txt' caption='Asset' width='120px' visible={true}></Column>
                <Column dataField='main_feature' dataType='boolean' caption='Service' width='70'></Column>
                <Column dataField='account_feature' dataType='boolean' caption='Account' width='70'></Column>
                <Column dataField='buy' dataType='boolean' caption='Buy' width='60' visible={true}></Column>
                <Column dataField='sell' dataType='boolean' caption='Sell' width='60' visible={true}></Column>
                <Column dataField='list' dataType='boolean' caption='List' width='60' visible={true}></Column>
                <Column dataField='send' dataType='boolean' caption='Send' width='60' visible={true}></Column>
                <Column dataField='receive' dataType='boolean' caption='Receive' width='70' visible={true}></Column>
                <Column dataField='usefeature' caption='Use' dataType='boolean' visible={false}></Column>
              </DataGrid>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default ServicesDataFeatures;
