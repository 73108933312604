import React, {useRef, useState} from "react";
import {Button, DataGrid} from "devextreme-react";
import {Column, Pager, SearchPanel} from "devextreme-react/data-grid";
import paginator from "../../../modules/paginator/Paginator";
import {withRouter} from "react-router-dom";
import {Popup} from "devextreme-react/popup";
import Box, {Item} from "devextreme-react/box";
import {MailingDeleteTemplate, MailingGetTemplates} from "./MailingHelper";

function TemplatesList(props) {
    let dataGridRef = useRef();

    const initGrid = () => {
        return paginator(MailingGetTemplates, [], "id", 10);
    };

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templatesDataList, setTemplatesDataList] = useState(initGrid());
    const [showForm, setShowForm] = useState(false);
    const [btnClicked, setBtnClicked] = useState(false);

    const addNewTemplate = () => {
        props.history.push("/mailing/template");
    };

    const renderActionCell = cellData => {
        return (
            <>
                <Button
                    height={35}
                    icon="edit"
                    onClick={() => {
                        editTemplate(cellData.data.id);
                    }}
                />
                <Button
                    height={35}
                    icon="trash"
                    onClick={() => {
                        setSelectedTemplate(cellData.data.id);
                        setShowForm(true);
                    }}
                />
            </>
        );
    };

    const editTemplate = templateId => {
        props.history.push(`/mailing/template/${templateId}`);
    };

    const deleteTemplate = templateId => {
        MailingDeleteTemplate(templateId).then(response => {
            setTemplatesDataList(initGrid());
        });
    };

    return (
        <React.Fragment>
            <Popup
                width={420}
                height={215}
                visible={showForm}
                resizeEnabled={false}
                showCloseButton={true}
                onHiding={() => {
                    if (!btnClicked) {
                        setShowForm(false);
                    }
                }}
                onShowing={() => {
                    setBtnClicked(false);
                }}
            >
                <Box id="employeeform" direction="col" height="100%">
                    <Item ratio={1}>
                        <h5 style={{textAlign: "center"}}>
                            {"Are you sure you want to delete this template?"}
                        </h5>
                    </Item>
                    <Item ratio={1}>
                        <div id={"closebutton-2"}>
                            <div className="button-wrapper">
                                <Button
                                    id="savebutton-right"
                                    width={100}
                                    text="YES"
                                    type="normal"
                                    stylingMode="contained"
                                    height={35}
                                    onClick={() => {
                                        deleteTemplate(selectedTemplate);
                                        setShowForm(false);
                                    }}
                                />
                                <Button
                                    width={100}
                                    height={35}
                                    text="NO"
                                    type="normal"
                                    stylingMode="contained"
                                    onClick={() => {
                                        setShowForm(false);
                                    }}
                                />
                            </div>
                        </div>
                    </Item>
                </Box>
            </Popup>
            <div className="content">
                <div className={"row row-flex-space-between mt-4"}>
                    <Button
                        text="New Template"
                        type="normal"
                        stylingMode="outlined"
                        height={35}
                        onClick={addNewTemplate}
                    />
                </div>
                <div className={"mt-4"}>
                    <DataGrid
                        ref={dataGridRef}
                        id="rep-transactions-notes-grid"
                        dataSource={templatesDataList}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        focusedRowEnabled={false}
                        hoverStateEnabled={true}
                        showBorders={true}
                        wordWrapEnabled={true}
                        showRowLines={true}
                        showColumnHeaders={true}
                        remoteOperations={true}
                    >
                        <SearchPanel visible={false} placeholder="Search..."/>
                        <Pager showPageSizeSelector={true} showInfo={true}/>
                        <Column dataField="id" visible={false}/>
                        <Column
                            dataField="title"
                            encodeHtml={false}
                            caption="Title"
                            dataType="string"
                            width={"100%"}
                        />
                        <Column
                            width={100}
                            caption="Action"
                            cellRender={renderActionCell}
                        />
                    </DataGrid>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(TemplatesList);