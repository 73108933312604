import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import DataSource from "devextreme/data/data_source";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import FileUploader from "devextreme-react/file-uploader";
import Validator, {EmailRule, RequiredRule} from "devextreme-react/validator";
import Form, {
    ButtonItem,
    ButtonOptions,
    GroupItem,
    Item,
    Label,
    SimpleItem
} from "devextreme-react/form";
import {htmlEditorToolbarConfig} from "../../utils/htmlEditorToolbarConfig";
import SelectBox from "devextreme-react/select-box";
import SelectTemplatePop from "../../components/uiElements/SelectTemplatePop";
import {bufferToBlob} from "../../utils/attachesHelper";
import LoadIndicator from "devextreme-react/load-indicator";
import {
    MailingGetAccounts,
    MailingGetEmail,
    MailingSaveEmail,
    MailingGetTemplates
} from "./components/MailingHelper";

function EmailFormPage(props) {
    const [formData, setFormData] = useState({
        id: null,
        mailingAccountId: null,
        recipients: [{type: "TO", address: ""}],
        subject: "",
        body: "",
        status: "",
        attaches: [],
        createdOn: "",
        updatedOn: ""
    });

    const initTemplatesList = () => {
        return new DataSource({
            key: "id",
            load: () => {
                return MailingGetTemplates({}).then(data => {
                    return {
                        data: data.items,
                        totalCount: data.items.length
                    };
                });
            }
        });
    };

    const [mailingAccountsList, setMailingAccountsList] = useState(undefined);
    const [isShowMessagePopup, setIsShowMessagePopup] = useState(false);
    const [bodyBuffer, setBodyBuffer] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [editType, setEditType] = useState("dxHtmlEditor");
    const [loading, setLoading] = useState(false);
    const [mailingTemplatesList, setMailingTemplatesList] = useState(
        initTemplatesList()
    );

    useEffect(() => {
        MailingGetAccounts({}).then(data => {
            let defaultAccountId = null;
            if (!isEmailHasId()) {
                defaultAccountId = getDefaultAccountId(data.items);
            }
            setMailingAccountsList(data.items);
            setFormData({...formData, mailingAccountId: defaultAccountId});
        });

        if (isEmailHasId()) {
            MailingGetEmail(props.match.params.emailId).then(data => {
                const dataFiles = [];
                if (data.attaches && data.attaches.length > 0) {
                    for (let i = 0; i < data.attaches.length; i++) {
                        const blob = bufferToBlob(data.attaches[i].content.data);
                        const fileName = data.attaches[i].fileName;
                        dataFiles.push(new File([blob], fileName));
                    }
                }

                setFormData({
                    ...data,
                    id: Number(props.match.params.emailId),
                    attaches: dataFiles
                });
            });
        }
    }, []);

    const isEmailHasId = () => {
        return props.match.params.emailId !== undefined;
    };

    const getDefaultAccountId = items => {
        const candidate = items.find(item => item.isDefault === true);
        if (candidate) {
            return candidate.id;
        }
        return null;
    };

    const generateRecipientActionButtonOptions = index => {
        if (index === 0) {
            return {
                stylingMode: "outlined",
                icon: "add",
                type: "normal",
                onClick: () => {
                    const newRecipients = formData.recipients.slice();
                    newRecipients.push({
                        type: "TO",
                        address: ""
                    });

                    setFormData({...formData, recipients: newRecipients});
                }
            };
        } else {
            return {
                stylingMode: "outlined",
                icon: "remove",
                type: "danger",
                onClick: () => {
                    const newRecipients = formData.recipients.slice();
                    newRecipients.splice(index, 1);

                    setFormData({...formData, recipients: newRecipients});
                }
            };
        }
    };

    const handleBackButton = () => {
        props.history.push({pathname: "/mailing", state: "0"});
    };

    const handleChangeAccount = value => {
        setFormData({...formData, mailingAccountId: value});
    };

    const handleShowPopup = value => {
        setTemplateName(value.title);
        setBodyBuffer(value.body);
        setIsShowMessagePopup(true);
    };

    const handleHidePopup = answer => {
        if (answer) {
            setFormData({...formData, body: bodyBuffer});
            setIsShowMessagePopup(false);
        } else {
            setIsShowMessagePopup(false);
        }
    };

    const handleValidateForm = (data, action) => {
        const validate = data.validationGroup.validate();
        if (validate.isValid) {
            handleSaveEmail(action);
        } else {
            notify("Please complete all required fields", "error", 2000);
        }
    };

    const getRecipientsStringByType = (recipients, type) => {
        const filteredRecipients = recipients.filter(
            recipient => recipient.type === type
        );
        const addresses = filteredRecipients.map(recipient => recipient.address);
        return addresses.join(",");
    };

    const handleSaveEmail = action => {
        setLoading(true);
        const recipientsTo = getRecipientsStringByType(formData.recipients, "TO");
        const recipientsCc = getRecipientsStringByType(formData.recipients, "CC");
        const recipientsBcc = getRecipientsStringByType(formData.recipients, "BCC");

        const _formData = new FormData();
        _formData.append("id", String(formData.id));
        _formData.append("body", formData.body);
        _formData.append("subject", formData.subject);
        _formData.append("mailingAccount", String(formData.mailingAccountId));
        _formData.append("to", recipientsTo);
        _formData.append("cc", recipientsCc);
        _formData.append("bcc", recipientsBcc);
        _formData.append("action", action);

        if (formData.attaches && formData.attaches.length > 0) {
            formData.attaches.forEach(file => {
                _formData.append("files", file, file.name);
            });
        }

        MailingSaveEmail(_formData)
            .then(res => {
                notify("Success", "success", 2000);

                if (formData.id === null && action === "DRAFT") {
                    props.history.push(`/mailing/email/${res.data.id}`);
                }

                if (action === "SEND") {
                    props.history.push(`/mailing`);
                } else {
                    setLoading(false);
                }
            })
            .catch(() => {
                notify("An error occurred while sending the email.", "error", 2000);
                setLoading(false);
            });
    };

    const renderFileUploader = () => {
        return (
            <FileUploader
                style={{
                    padding: 0,
                }}
                labelText={""}
                selectButtonText="Attach file"
                uploadMode="useForm"
                showFileList={true}
                multiple={true}
                value={formData.attaches}
                onValueChanged={e => {
                    setFormData({...formData, attaches: e.value});
                }}
            />
        );
    };

    return (
        <React.Fragment>
            <SelectTemplatePop
                showPopup={isShowMessagePopup}
                hidePopup={handleHidePopup}
                popTitle={templateName}
                popMessage={bodyBuffer}
            />
            <h2 className={"content-block"}>Email</h2>
            <div className={"content-block"}>
                <div className="dx-card wide-card p-4" id={"email-form"}>
                    <Button
                        width={180}
                        height={35}
                        icon={"back"}
                        type={"default"}
                        className={"dx-shape-standard mb-4"}
                        text={"Back to Emails"}
                        stylingMode="outlined"
                        onClick={handleBackButton}
                    />
                    <Form
                        className={"mt-4"}
                        formData={formData}
                        labelLocation={"left"}
                        validationGroup={"formValidationGroup"}
                    >
                        <GroupItem colCount={2}>
                            <GroupItem>
                                <GroupItem colCount={10}>
                                    <GroupItem colSpan={2}>
                                        <Label visible={true} text={"MAILING ACCOUNT"}/>
                                    </GroupItem>
                                    <Item colSpan={8}>
                                        <SelectBox
                                            dataSource={mailingAccountsList}
                                            displayExpr={item => {
                                                return item && `${item.fromName}<${item.fromEmail}>`;
                                            }}
                                            valueExpr={"id"}
                                            placeholder={"Select Account"}
                                            onValueChange={handleChangeAccount}
                                            value={formData.mailingAccountId}
                                        >
                                            <Validator validationGroup="formValidationGroup">
                                                <RequiredRule message="Required field"/>
                                            </Validator>
                                        </SelectBox>
                                    </Item>
                                </GroupItem>
                                <GroupItem>
                                    {formData.recipients.map((recipient, index) => (
                                        <GroupItem key={`recipient${index}`} colCount={10}>
                                            <Item
                                                colSpan={2}
                                                dataField={`recipients[${index}].type`}
                                                editorType="dxSelectBox"
                                                editorOptions={{
                                                    width: 75,
                                                    items: ["TO", "CC", "BCC"]
                                                }}
                                                label={{visible: false}}
                                            />

                                            <SimpleItem
                                                label={{visible: false}}
                                                colSpan={7}
                                                dataField={`recipients[${index}].address`}
                                            >
                                                <RequiredRule message="Required field"/>
                                                <EmailRule message="Enter a valid email address"/>
                                            </SimpleItem>
                                            <Item
                                                cssClass="recipient-item-button"
                                                itemType="button"

                                                buttonOptions={generateRecipientActionButtonOptions(
                                                    index
                                                )}
                                            />
                                        </GroupItem>
                                    ))}
                                </GroupItem>

                                <GroupItem colCount={10}>
                                    <GroupItem colSpan={2}>
                                        <Label visible={true} text={"SUBJECT"}/>
                                    </GroupItem>
                                    <SimpleItem
                                        colSpan={8}
                                        dataField={"subject"}
                                        label={{visible: false}}
                                    />
                                </GroupItem>
                            </GroupItem>

                            <GroupItem>
                                <Item>
                                    <SelectBox
                                        style={{alignSelf: "flex-end", marginLeft: "auto"}}
                                        width={300}
                                        dataSource={mailingTemplatesList}
                                        displayExpr="title"
                                        placeholder={"Select Template"}
                                        onValueChange={handleShowPopup}
                                    />
                                </Item>
                            </GroupItem>
                        </GroupItem>
                        <GroupItem>
                            <Item>
                                <Button
                                    id="paste-src-content"

                                    onClick={() => {
                                        editType == "dxHtmlEditor"
                                            ? setEditType("dxTextArea")
                                            : setEditType("dxHtmlEditor");
                                    }}
                                >
                                    {editType == "dxHtmlEditor"
                                        ? "SOURCE CODE"
                                        : "RICH TEXT FORMAT"}
                                </Button>
                            </Item>
                        </GroupItem>
                        <SimpleItem
                            cssClass={"mt-4"}
                            dataField={"body"}
                            editorType={editType}
                            editorOptions={{
                                height: 350,
                                toolbar: htmlEditorToolbarConfig
                            }}
                        >
                            <Label text="BODY" location="top"/>
                        </SimpleItem>
                        <GroupItem colCount={2}>
                            <GroupItem colSpan={1}>
                                <SimpleItem

                                    dataField="attaches"
                                    render={renderFileUploader}
                                    label={{visible: false}}
                                />
                                <ButtonItem cssClass={"align-left"}>
                                    <ButtonOptions
                                        disabled={loading}
                                        onClick={data => handleValidateForm(data, "DRAFT")}
                                        width={180}
                                        height={35}
                                        type={"default"}
                                        stylingMode="contained"
                                    >
                    <span className="dx-button-text">
                      {loading ? (
                          <LoadIndicator
                              width={"20px"}
                              height={"20px"}
                              visible={true}
                          />
                      ) : (
                          "Save"
                      )}
                    </span>
                                    </ButtonOptions>
                                </ButtonItem>
                            </GroupItem>
                            <ButtonItem cssClass={"mt-auto"}>
                                <ButtonOptions
                                    disabled={loading}
                                    onClick={data => handleValidateForm(data, "SEND")}
                                    width={180}
                                    height={35}
                                    type={"success"}
                                    stylingMode="contained"
                                >
                  <span className="dx-button-text">
                    {loading ? (
                        <LoadIndicator
                            width={"20px"}
                            height={"20px"}
                            visible={true}
                        />
                    ) : (
                        "Send"
                    )}
                  </span>
                                </ButtonOptions>
                            </ButtonItem>
                        </GroupItem>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(EmailFormPage);
