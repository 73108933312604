import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import ArrayStore from "devextreme/data/array_store";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./operations.scss";

import {
  Form,
  SimpleItem,
  EmptyItem,
  RequiredRule,
  ButtonItem,
} from "devextreme-react/form";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

import TabPanel from "devextreme-react/tab-panel";
import { Button, MultiView, SelectBox } from "devextreme-react";
import Tabs from "devextreme-react/tabs";

import CasesOneTab from "./CasesOneTab";
import CasesNotesTab from "./CasesNotesTab";
import CasesNews from "./CasesNews";
import CasesRelatedTab from "./CasesRelatedTab";
import CasesContactTab from "./CasesContactTab";
import { useAuth } from "../../contexts/auth";
import { Label } from "devextreme-react/data-grid";

import ClientsApprovedDetails from "../clientsapproved/ClientsApprovedDetails";
import ClientsApprovedRiskMatrix from "../clientsapproved/ClientsApprovedRiskMatrix";
import ClientsApprovedProduct from "../clientsapproved/ClientsApprovedProduct";
import ClientsApprovedTransactions from "../clientsapproved/ClientsApprovedTransactions";
import ClientsApprovedKYC from "../clientsapproved/ClientsApprovedKYC";
import ClientsApprovedInquiry from "../clientsapproved/ClientsApprovedInquiry";
import ClientsApprovedAccess from "../clientsapproved/ClientsApprovedAccess";
import ClientsApprovedAccountUsers from "../clientsapproved/ClientsApprovedAccountUsers";

const OperationsPage = () => {
  const { user } = useAuth();
  const selPartnerPortalRef = useRef();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [caseDetails, setCaseDetails] = useState({});
  const [newsKey, setNewsKey] = useState();
  const [reloadCasesGrids, setReloadCasesGrids] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [lpCaseTypes, setLpCaseTypes] = useState([]);
  const [lpCaseStates, setLpCaseStates] = useState([]);
  const [lpCasePrio, setLpCasePrio] = useState([]);
  const [lpCaseSituation, setLpCaseSituation] = useState([]);

  const [partnerPortalsDatalist, setPartnerPortalsDatalist] = useState([]);
  const [selPartnerPortal, setSelPartnerPortal] = useState(-1);
  const [reloadNewsGrid, setReloadNewsGrid] = useState(false);

  const baseformData = {
    // selCompanyid: selectedCompany,
    selLfdNr: -1,
    selBase_LfdNr: -1,
  };

  const getKeyValues = useCallback((value, subject, contact, contactid) => {
    setCaseDetails({
      entryid: value,
      subject: subject,
      contact: contact,
      contactid: contactid,
    });
  });

  const getNewsKey = useCallback((annewskey, ancontactid) => {
    // console.log(4564, annewskey);
    setNewsKey(annewskey);
    setCaseDetails({
      entryid: annewskey,
      subject: "",
      contact: "",
      contactid: ancontactid,
    });
  });

  const getReloadCondition = useCallback((doGridReload) => {
    setReloadCasesGrids(doGridReload);
  });

  useEffect(() => {
    const fetchCaseTypes = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/40",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpCaseTypes(dataArray);
      } catch (err) {}
    };

    const fetchCaseStates = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/39",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpCaseStates(dataArray);
      } catch (err) {}
    };

    const fetchCasePrio = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/38",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpCasePrio(dataArray);
      } catch (err) {}
    };

    const fetchCaseSituation = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/41",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpCaseSituation(dataArray);
      } catch (err) {}
    };

    fetchCaseTypes();
    fetchCaseStates();
    fetchCasePrio();
    fetchCaseSituation();
  }, [sendRequest]);

  const stCaseType = new ArrayStore({
    key: "lp_id",
    data: lpCaseTypes,
  });

  const stCaseStates = new ArrayStore({
    key: "lp_id",
    data: lpCaseStates,
  });

  const stCasePrio = new ArrayStore({
    key: "lp_id",
    data: lpCasePrio,
  });

  const stCaseSituation = new ArrayStore({
    key: "lp_id",
    data: lpCaseSituation,
  });

  useEffect(() => {
    const fetchPortals = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/management/partnerportals",
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setPartnerPortalsDatalist(dataArray);
      } catch (err) {}
    };

    fetchPortals();
  }, [sendRequest]);

  const stpartnerPortalsDatalist = new ArrayStore({
    key: "portalid",
    data: partnerPortalsDatalist,
  });

  const reloadNewsGridCondition = async (anValue) => {
    await setReloadNewsGrid(anValue);
  };

  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Cases</h2> */}
      <div className={"content-block"}>
        <div className={"dx-card wide-card"}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={2} />
            <Row ratio={0.7} />
            <Col ratio={3} />
            <Col ratio={1.4} screen="md lg" />

            <Item>
              <Location screen="md lg" row={0} col={0} colspan={2} />
              <Location screen="sm" row={0} col={0} colspan={1} />
              <Location screen="xs" row={0} col={0} />
              <div className="rb-header item">
                <ResponsiveBox>
                  <Row />
                  <Col ratio={1.8} />
                  <Col ratio={2.5} />
                  <Col ratio={10} />
                  <Item>
                    <Location screen="md lg sm" row={0} col={0} />
                    <div className="dx-fieldset-header" id="cap_item">
                      Operations of:
                    </div>
                  </Item>
                  <Item>
                    <Location screen="md lg sm" row={0} col={1} />
                    <div id="partner-portal-selectbox">
                      <SelectBox
                        width={"200px"}
                        ref={selPartnerPortalRef}
                        dataSource={stpartnerPortalsDatalist}
                        valueExpr={"portalid"}
                        displayExpr={"portalname"}
                        searchEnabled={true}
                        defaultValue={selPartnerPortal}
                        value={selPartnerPortal}
                        onValueChanged={(e) => {
                          e && setSelPartnerPortal(e.value);
                        }}
                      />
                    </div>
                  </Item>
                  <Item>
                    <Location screen="md lg sm" row={0} col={2} />
                    <div id="partner-portal-selectbox">
                      <Button
                        width={"100px"}
                        height={"36px"}
                        text="Clear"
                        onClick={(e) => {
                          setSelPartnerPortal(-1);
                        }}
                      />
                    </div>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen="md lg sm" row={1} col={0} />
              <Location screen="xs" row={1} col={0} />
              <div className="operation-page-content">
                <div className="operation-page-content-container">
                  <ResponsiveBox>
                    <Row />
                    <Col />
                    <Item>
                      <Location screen="md lg sm xs" row={0} col={0} />
                      <CasesOneTab
                        passToParent={getKeyValues}
                        user={user}
                        selPartnerPortal={selPartnerPortal}
                        selnewskey={newsKey}
                        dataStCaseType={stCaseType}
                        dataStCaseStates={stCaseStates}
                        dataStDevPrios={stCasePrio}
                        dataStCaseSituation={stCaseSituation}
                        getReload={getReloadCondition}
                        reloadGrid={reloadCasesGrids}
                      />
                      <div style={{ flexDirection: "column" }}>
                      <div id="operations-notes-tab">
                        <Tabs
                          selectedIndex={tabIndex}
                          width="100%"
                          // height='20px'
                          visible={true}
                          onItemClick={(e) => {
                            setTabIndex(e.itemIndex);
                          }}
                        >
                          <Item text="Activity" />
                          <Item text="Client" />
                          <Item text="Access" />
                          <Item text="User" />
                          <Item text="R.-Matrix" />
                          <Item text="KYC" />
                          <Item text="Product" />
                          <Item text="Transactions" />
                          <Item text="Inquiries" />
                        </Tabs>
                        <MultiView
                          height={400}
                          animationEnabled={false}
                          selectedIndex={tabIndex}
                        >
                          <Item title="Notes">
                            <CasesNotesTab
                              tabVisibleIndex={tabIndex}
                              caseDetails={caseDetails}
                              selPartnerPortal={selPartnerPortal}
                              user={user}
                              getReload={getReloadCondition}
                              reloadNewsGridCondition={reloadNewsGridCondition}
                            />
                          </Item>

                          <Item title="Client Details">
                            <ClientsApprovedDetails
                              selectedKey={caseDetails.contactid}
                              user={user}
                              selSignupClientid={caseDetails.contactid}
                              reloadNewsGridCondition={reloadNewsGridCondition}
                            />
                          </Item>

                          <Item title="Access">
                            <ClientsApprovedAccess
                              selectedKey={caseDetails.contactid}
                              user={user}
                              selSignupClientid={caseDetails.contactid}
                              reloadNewsGridCondition={reloadNewsGridCondition}
                            />
                          </Item>

                          <Item title="Users">
                            <ClientsApprovedAccountUsers
                              selectedKey={caseDetails.contactid}
                              user={user}
                              selSignupClientid={caseDetails.contactid}
                              reloadNewsGridCondition={reloadNewsGridCondition}
                            />
                          </Item>

                          <Item title="Risk Matrix">
                            <ClientsApprovedRiskMatrix
                              selectedKey={caseDetails.contactid}
                              user={user}
                              selSignupClientid={caseDetails.contactid}
                            />
                          </Item>
                          <Item title="KYC">
                            <ClientsApprovedKYC
                              selSignupClientid={caseDetails.contactid}
                              user={user}
                              selectedKey={caseDetails.contactid}
                            />
                          </Item>
                          <Item title="Product">
                            <ClientsApprovedProduct
                              user={user}
                              selSignupClientid={caseDetails.contactid}
                            />
                          </Item>

                          <Item title="Transactions">
                            <ClientsApprovedTransactions
                              selClientid={caseDetails.contactid}
                              user={user}
                              selectedKey={caseDetails.contactid}
                              reloadNewsGridCondition={reloadNewsGridCondition}
                            />
                          </Item>
                          <Item title="Inquiries">
                            <ClientsApprovedInquiry
                              selSignupClientid={caseDetails.contactid}
                              user={user}
                              selectedKey={caseDetails.contactid}
                            />
                          </Item>
                        </MultiView>
                      </div>

                      </div>
                    </Item>
                  </ResponsiveBox>
                </div>
              </div>
            </Item>

            <Item>
              <Location screen="md lg" row={1} col={1} />
              <div className="rb-right-side-bar item">
                <CasesNews
                  user={user}
                  selPartnerPortal={selPartnerPortal}
                  retrieveNewsKey={getNewsKey}
                  reloadGrid={reloadCasesGrids}
                  reloadNewsGrid={reloadNewsGrid}
                  reloadNewsGridCondition={reloadNewsGridCondition}
                />
              </div>
            </Item>

            <Item>
              <Location screen="md lg" row={2} col={0} colspan={2} />
              <Location screen="sm" row={2} col={0} colspan={1} />
              <Location screen="xs" row={2} col={0} />
              <div className="rb-footer item" id="cap_item"></div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OperationsPage;
