import { getRoundedDateNearMinutes } from "../../utils/getRoundedDateNearMinutes";
import { getShiftDateTime } from "../../utils/getShiftDateTime";
import { Conference } from "./Conference";


export class Event {
  id
  ownerId
  title
  eventTypeId
  startTime
  endTime
  reminderBeforeMinutes
  reminderTypeId
  data
  attendees
  enableNotification
  conference

  constructor() {
    this.startTime = getRoundedDateNearMinutes(10);
    this.endTime = getShiftDateTime(30, this.startTime);
    this.reminderBeforeMinutes = 10;
    this.enableNotification = false;
    this.conference = new Conference();
  }
}
