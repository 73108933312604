import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

import './bankaccountsedit.css';
import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from 'devextreme-react/responsive-box';
import MultiView from 'devextreme-react/multi-view';
import Tabs from 'devextreme-react/tabs';

import BankAccountsEditDetails from './BankAccountsEditDetails';

const BankAccountsEdit = () => {
  const { user } = useAuth();
  const history = useHistory();

  const systemServiceId = user.bankaccount.systemServiceId;
  const modeInsert = user.bankaccount.modeInsert;

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={10} />
            <Col />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <div className='rb-header item'>
                <div className='dx-fieldset-header' id='cap_item'>
                  Bank Account
                </div>
              </div>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={1} col={0} />

              <Tabs
                id='routing-edit-tab'
                selectedIndex={tabIndex}
                width='98%'
                visible={true}
                onItemClick={e => {
                  setTabIndex(e.itemIndex);
                }}
              >
                <Item text='Account Details' />
                <Item text='Status' />
                <Item text='Account Statements' />
              </Tabs>

              <MultiView
                height={750}
                animationEnabled={false}
                selectedIndex={tabIndex}
              >
                <Item title='Setup'>
                  <BankAccountsEditDetails
                    systemServiceId={systemServiceId}
                    modeInsert={modeInsert}
                  />
                </Item>
                <Item title='Account Details'></Item>
                <Item title='Status'></Item>
                <Item title='Account Statements'></Item>
              </MultiView>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default BankAccountsEdit;
