import React, { useEffect, useRef, useState } from 'react';
import './PartnerPage.css';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import MultiView from 'devextreme-react/multi-view';
import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';

import { SelectBox } from 'devextreme-react/select-box';
import Box from 'devextreme-react/box';
import Tabs from 'devextreme-react/tabs';
import ArrayStore from 'devextreme/data/array_store';
import { TabPanel } from 'devextreme-react';

const PartnerEditPop = props => {
  const { showPopup, hidePopup, svProviderId, svPersonId, user, selcontactid, selucompany, showTabs, modeInsert } =
    props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const dataFormRef = useRef(null);
  const [partnerData, setPartnerData] = useState({});
  const [companyContact, setCompanyContact] = useState({});
  const [lpRoutings, setLpRoutings] = useState([]);

  useEffect(() => {
    const fetchServiceProvider = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/partner/singlepartner/${svProviderId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setPartnerData(dataArray[0]);
      } catch (err) {}
    };

    const fetchCompanyContact = async an_contactid => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/contacts/company/${an_contactid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCompanyContact(dataArray[0]);
      } catch (err) {}
    };

    if (svProviderId && showPopup) {
      fetchServiceProvider();

      if (svPersonId) {
        fetchCompanyContact(svPersonId);
      } else {
        fetchCompanyContact(-1);
      }
    }
  }, [sendRequest, svProviderId, showPopup]);

  useEffect(() => {
    const fetchRoutings = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/routing/financialroutings',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpRoutings(dataArray);
      } catch (err) {}
    };

    if (showPopup) {
      fetchRoutings();
    }
  }, [sendRequest, showPopup]);

  const stLpRoutingData = new ArrayStore({
    key: 'routingid',
    data: lpRoutings,
  });

  const partnerFormData = {
    partnerid: (partnerData && partnerData.partnerid) || -1,
    routingid: (partnerData && partnerData.routingid) || -1,
    personid: (partnerData && partnerData.personid) || -1,
    partnername: (partnerData && partnerData.partnername) || '',
    activedate: partnerData && partnerData.activedate,

    contactid: companyContact.contactid,
    lastname: companyContact.lastname,
    street: companyContact.street,
    city_code: companyContact.city_code,
    city: companyContact.city,
    phone_one: companyContact.phone_one,
    email: companyContact.email,
    homepage: companyContact.homepage,
    addressfield: companyContact.addressfield,
    nationality: companyContact.nationality,
    res_cntry: companyContact.res_cntry,
    contact_cat: companyContact.contact_cat,
  };

  function navigateToHome() {
    hidePopup();
  }

  const validateForm = async dataFormRef => {
    const result = dataFormRef.validationGroup.validate();
    // console.log(result);
    if (result.isValid) {
      saveNameAddress(1);
      hidePopup(true);
    } else {
      alert('Please complete all required fields');
    }
  };

  const saveNameAddress = async addressOperation => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/partner/partner',
        'POST',
        JSON.stringify({
          operation: addressOperation,
          p_partnerid: partnerFormData.partnerid,
          p_routingid: partnerFormData.routingid || -1,
          p_personid: partnerFormData.personid || -1,
          p_partnername: partnerFormData.partnername || '',
          c_contactid: partnerFormData.contactid || -1,
          c_lastname: partnerFormData.partnername || '',
          c_addressfield: partnerFormData.addressfield || '',
          c_email: partnerFormData.email || '',
          c_phone_one: partnerFormData.phone_one || '',
          c_homepage: partnerFormData.homepage || '',
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      // setContactsChanged(true);
      // setEmployeesChanged(!employeesChanged);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Popup
      title={'Merchant Partner'}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      //    onShowing={() => {
      //      setContactsChanged(false);
      //    }}
    >
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='form-edit'>
            <Form
              id={'form'}
              ref={dataFormRef}
              formData={partnerFormData}
              labelLocation={'top'}
              colCountByScreen={colCountByScreen}
              validationGroup='Partner_Edit_Data'
            >
              <Item itemType='group' colCount={3}>
                <SimpleItem dataField='partnername' isRequired={true} editorOptions={baseEditorOptions}>
                  <RequiredRule message='Please enter a Merchant/Partner Name' />
                  <Label text='Name' />
                </SimpleItem>
                <SimpleItem
                  dataField='routingid'
                  editorType='dxSelectBox'
                  colSpan={1}
                  name='routing_listbox'
                  isRequired={true}
                  editorOptions={{
                    dataSource: stLpRoutingData,
                    valueExpr: 'routingid',
                    displayExpr: 'routingname',
                    searchEnabled: true,
                    
                  }}
                >
                  <RequiredRule message='Please select a Financial Network' />
                  <Label text='Routing' />
                </SimpleItem>
              </Item>
              <EmptyItem />

              <Item itemType='group' colCount={3} caption='Address'>
                <SimpleItem dataField='addressfield' editorType='dxTextArea' editorOptions={{ height: 120 }}>
                  <Label text='Address' visible={false} />
                </SimpleItem>
              </Item>
              <Item itemType='group' colCount={3}>
                <SimpleItem dataField='email' editorOptions={baseEditorOptions}>
                  <Label text='E-Mail' />
                </SimpleItem>
                <SimpleItem dataField='phone_one' editorOptions={baseEditorOptions}>
                  <Label text='Phone' />
                </SimpleItem>
              </Item>
              <Item itemType='group' colCount={3}>
                <SimpleItem dataField='homepage' editorOptions={baseEditorOptions}>
                  <Label text='Home Page' />
                </SimpleItem>
              </Item>
            </Form>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'contactpopbutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={50}
                text='Save'
                type='normal'
                stylingMode='contained'
                validationGroup='Partner_Edit_Data'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default PartnerEditPop;
