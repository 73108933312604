import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useHttpClient } from "../../hooks/http-hook";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import Button from "devextreme-react/button";
import "./GlobalDefinitions.css";

import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";

import DataGrid, { Scrolling, Column, FilterRow, MasterDetail } from "devextreme-react/data-grid";

import BankAccountDetailGrid from "../../detailgrids/BankAccountDetailGrid";

const BankaccountsTab = (props) => {
  const { user } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [bankAccountList, setBankAccountList] = useState([]);
  const [sysServiceID, setSysServiceID] = useState(-1);

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + "/routing/bankaccounts", "GET", null, {
          Authorization: "Bearer " + user.token,
        });
        setBankAccountList(dataArray);
      } catch (err) {}
    };
    fetchBankAccounts();
  }, [sendRequest]);

  const stBankData = new ArrayStore({
    key: "bankaccountid",
    data: bankAccountList,
  });

  const dcBankAccountData = new DataSource({
    store: stBankData,
  });

  const openBankAccountEdit = () => {
    user.bankaccount = {
      systemServiceId: sysServiceID,
      user: user,
      modeInsert: true,
    };
    history.push({
      pathname: "/bankaccountedit",
    });
  };

  const openBankAccountInsert = () => {
    user.bankaccount = {
      systemServiceId: -1,
      user: user,
      modeInsert: true,
    };
    history.push({
      pathname: "/bankaccountedit",
    });
  };

  return (
    <div className="sd-header item">
      <div className="dx-fieldset-header">Bank Accounts</div>

      <ResponsiveBox>
        <Col ratio={1} screen="md lg sm xs" />
        <Col ratio={10} />
        <Row />

        <Item>
          <Location screen="md lg sm" row={0} col={0} />
          <div id="hm-btn-section-0-1">
            <Button
              width={100}
              height={36}
              text="Add"
              type="default"
              stylingMode="outlined"
              onClick={openBankAccountInsert}
            />
          </div>
          <div id="hm-btn-section-0-11">
            <Button
              width={100}
              height={36}
              text="Open"
              type="success"
              stylingMode="outlined"
              onClick={openBankAccountEdit}
            />
          </div>
        </Item>

        <Item>
          <Location screen="md lg sm" row={0} col={1} />
          <Location screen="xs" row={0} col={0} />
          <div>
            <DataGrid
              height={460}
              dataSource={dcBankAccountData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  setSysServiceID(e.row.key);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField="bankaccountid" visible={false}></Column>
              <Column dataField="accountname" visible={true} caption="Account Name" width={270}></Column>
              <Column dataField="account_type_txt" caption="Account Type" width={180} />
              <Column dataField="rec_name" caption="Account Owner" width={200} />
              <Column dataField="bankname" visible={true} caption="Bank" width={200}></Column>

              <Column dataField="currency" caption="Currency" width={80} />
              <Column dataField="cur_amount" caption="Current Amount" width={110} />
              <MasterDetail enabled={true} component={BankAccountDetailGrid} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

export default BankaccountsTab;
