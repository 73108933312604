import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './ServicesData.css';

import { useHttpClient } from '../../hooks/http-hook';

import ArrayStore from 'devextreme/data/array_store';

const ServicesDataProvider = (props) => {
  const history = useHistory();

  const { selProviderId, user, modeInsert } = props;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const dataFormRef = useRef(null);
  const [providerData, setProviderData] = useState({});
  const [companyContact, setCompanyContact] = useState({});
  const [lpCategories, setLpCategories] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const fetchServiceProvider = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/provider/serviceprov/${selProviderId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProviderData(dataArray[0]);
        let anpersonid = -1;
        if (dataArray[0].personid) {
          anpersonid = dataArray[0].personid;
        }
        const ContactdataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/contacts/company/${dataArray[0].personid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCompanyContact(ContactdataArray[0]);
      } catch (err) {}
    };

    if (selProviderId) {
      fetchServiceProvider();
    }
  }, [sendRequest, selProviderId]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/service/servicecat', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setLpCategories(dataArray);
      } catch (err) {}
    };

    fetchCategories();
  }, [sendRequest]);

  const stLpCategoriesData = new ArrayStore({
    key: 'category_id',
    data: lpCategories,
  });

  const providerFormData = {
    providerid: (providerData && providerData.providerid) || -1,
    personid: (providerData && providerData.personid) || -1,
    categoryid: (providerData && providerData.categoryid) || -1,
    providername: (providerData && providerData.providername) || '',
    category: (providerData && providerData.category) || '',

    contactid: companyContact.contactid,
    lastname: companyContact.lastname,
    street: companyContact.street,
    city_code: companyContact.city_code,
    city: companyContact.city,
    phone_one: companyContact.phone_one,
    email: companyContact.email,
    homepage: companyContact.homepage,
    addressfield: companyContact.addressfield,
    nationality: companyContact.nationality,
    res_cntry: companyContact.res_cntry,
    contact_cat: companyContact.contact_cat,
  };

  function navigateToHome() {
    history.goBack();
  }

  const validateForm = async (dataFormRef) => {
    const result = dataFormRef.validationGroup.validate();
    // console.log(result);
    if (result.isValid) {
      saveNameAddress();
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  const saveNameAddress = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/provider/serviceprovider',
        'POST',
        JSON.stringify({
          operation: modeInsert ? 1 : 2,
          p_providerid: providerFormData.providerid,
          p_personid: providerFormData.personid || -1,
          p_categoryid: providerFormData.categoryid || -1,
          p_providername: providerFormData.providername || '',
          c_contactid: providerFormData.contactid || -1,
          c_lastname: providerFormData.providername || '',
          c_addressfield: providerFormData.addressfield || '',
          c_email: providerFormData.email || '',
          c_phone_one: providerFormData.phone_one || '',
          c_homepage: providerFormData.homepage || '',
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );
      // setContactsChanged(true);
      // setEmployeesChanged(!employeesChanged);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='sd-header item'>
      <div className='dx-fieldset-header'>Provider</div>

      <div className='note-details-container-small'>
        <div className='note-details-wrapper'>
          <div className='note-details-personally'>
            <div className='note-details-personally-none'>
              <div className='note-details-personally-block'>
                <div className='note-details-personally-block-infoText'>Name:</div>
                <div className='note-details-personally-block-outputText'>
                  {providerData && providerData.providername}
                </div>
              </div>

              <div className='note-details-personally-block'>
                <div className='note-details-personally-block-infoText'>Category:</div>
                <div className='note-details-personally-block-outputText'>{providerData && providerData.category}</div>
              </div>
            </div>
          </div>
        </div>

        <div className='note-details-wrapper'>
          <div className='note-details-personally'>
            <div className='note-details-personally-none'>
              <div className='note-details-personally-block'>
                <div className='note-details-personally-block-infoText'>Address:</div>
                <div className='note-details-personally-block-outputText'>{providerFormData.addressfield}</div>
              </div>

              <div className='note-details-personally-block'>
                <div className='note-details-personally-block-infoText'>E-Mail:</div>
                <div className='note-details-personally-block-outputText'>
                  {providerFormData && providerFormData.email}
                </div>
              </div>

              <div className='note-details-personally-block'>
                <div className='note-details-personally-block-infoText'>Phone:</div>
                <div className='note-details-personally-block-outputText'>
                  {providerFormData && providerFormData.phone_one}
                </div>
              </div>
              <div className='note-details-personally-block'>
                <div className='note-details-personally-block-infoText'>Homepage:</div>
                <div className='note-details-personally-block-outputText'>
                  {providerFormData && providerFormData.homepage}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ServicesDataProvider;
