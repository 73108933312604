import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';

import CasesNotesPop from './CasesNotesPop';

const CasesNotesTab = props => {
  const { tabVisibleIndex, caseDetails, user, getReload } = props;
  const fkey = caseDetails && caseDetails.base_lfdnr;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [notesDataList, setNotesDatalist] = useState([]);
  const [notesGridData, setNotesGridData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [openedType, setOpenedType] = useState(1);
  const [reloadContacts, setReloadContacts] = useState(false);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        /*
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/cases/one/${fkey}`
        );
        setNotesDatalist(dataArray);
        */
      } catch (err) {}
    };
    if (fkey) {
      fetchCases();
    }
  }, [sendRequest, fkey]);

  const navigatetoCaseNotePage = () => {
    setOpenedType(2);
    setShowPopup(true);
  };

  const navigatetoAddCaseNote = () => {
    setOpenedType(1);
    setShowPopup(true);
  };

  const hidePopup = useCallback(doContactReload => {
    setShowPopup(false);
    if (doContactReload) {
      setReloadContacts(!reloadContacts);
      getReload(true);
    }
  });

  return (
    <React.Fragment>
      <CasesNotesPop
        showPopup={showPopup}
        hidePopup={hidePopup}
        user={user}
        selLfdNr={notesGridData.LfdNr}
        openedToEdit={openedType}
        caseNumber={caseDetails && caseDetails.base_lfdnr}
        caseContact={caseDetails && caseDetails.contact}
        caseSubject={caseDetails && caseDetails.subject}
      />
      <ResponsiveBox>
        <Col ratio={0.3} screen='md lg sm' />
        <Col ratio={2} />
        <Row />

        <Item>
          <Location screen='md lg sm' row={0} col={0} />
          <div id='hm-btn-section-11'>
            <Button
              width={120}
              height={50}
              text='Add'
              type='normal'
              stylingMode='outlined'
              onClick={navigatetoAddCaseNote}
            />
          </div>
          <div id='hm-btn-section-2'>
            <Button
              width={120}
              height={50}
              text='Open'
              type='normal'
              stylingMode='outlined'
              onClick={navigatetoCaseNotePage}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div>
            <DataGrid
              id='casesNotesGrid'
              dataSource={notesDataList}
              keyExpr={['LfdNr', 'Base_LfdNr']}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={false}
              wordWrapEnabled={true}
              showRowLines={false}
              showColumnHeaders={false}
              onFocusedRowChanged={e => {
                setNotesGridData(e.row.data);
              }}
              onRowDblClick={() => {
                navigatetoCaseNotePage();
              }}
            >
              <FilterRow visible={true} />
              <Column dataField='LfdNr' visible={false}></Column>
              <Column dataField='Base_LfdNr' visible={false}></Column>
              <Column dataField='datenote' caption='Date' dataType='date' width={90} visible={false}></Column>
              <Column dataField='newsdata' caption='Notes' encodeHtml={false} dataType='string'></Column>
              <Column dataField='Nachname' caption='User' width={110} visible={false}></Column>
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default CasesNotesTab;
