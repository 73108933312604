import { useState, useCallback, useRef, useEffect } from 'react';
import { useHttpClient } from './http-hook';

export const useClientData = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const callClientData = useCallback(async (user, clientid) => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/client/clientsignupdata/${clientid}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + user.token }
      );
      return dataArray[0];
    } catch (err) {
      console.log(err);
    }
  }, []);

  const clientUsesForex = useCallback(async (user, clientid) => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/forex/clientuesesforex/${clientid}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + user.token }
      );
      if (dataArray[0] && dataArray[0].category_ident === 'frx') {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return {
    callClientData: callClientData,
    clientUsesForex: clientUsesForex,
  };
};
