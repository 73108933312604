import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';

import './ServiceProviderData.css';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import { Button } from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow, MasterDetail } from 'devextreme-react/data-grid';

import { Tabs, MultiView } from 'devextreme-react';

const ServiceProviderServices = (props) => {
  const { selProviderId, user } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [provServiceData, setProvServiceData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [selServiceId, setSelServiceId] = useState(-1);
  const [selServiceRow, setSelServiceRow] = useState({});

  useEffect(() => {
    const fetchProviderServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/provider/providerservices/${selProviderId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProvServiceData(dataArray);
      } catch (err) {}
    };

    if (selProviderId) {
      fetchProviderServices();
    }
  }, [sendRequest, selProviderId]);

  const stprovServiceData = new ArrayStore({
    key: 'serviceid',
    data: provServiceData,
  });

  const dcprovServiceData = new DataSource({
    store: stprovServiceData,
  });

  const openServicesData = () => {
    user.service = {
      serviceId: selServiceId,
      providerId: selServiceRow.providerid,
      categoryId: selServiceRow.categoryid,
      serviceName: selServiceRow.servicename,
      account_Liquidity_Id: -1,
      account_Service_Provider_Id: -1,
      user: user,
      modeInsert: false,
    };
    history.push({
      pathname: '/servicesdata',
    });
  };


  return (
    <div className='sd-header item'>
      <div className='dx-fieldset-header'>Services</div>
      <ResponsiveBox>
        <Row />
        <Col ratio={2} />
        <Col ratio={10} />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-0-1'>
            <Button
              width={100}
              height={36}
              text='Open'
              type='default'
              stylingMode='outlined'
               onClick={openServicesData}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <DataGrid
            height={430}
            dataSource={dcprovServiceData}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            allowColumnReordering={false}
            allowColumnResizing={false}
            focusedRowEnabled={true}
            showBorders={true}
            showRowLines={true}
            showColumnLines={false}
            autoNavigateToFocusedRow={true}
            onFocusedRowChanged={(e) => {
              setSelServiceId(e.row.key);
              setSelServiceRow(e.row.data);
            }}
          >
            <Column dataField='serviceid' visible={false} />
            <Column dataField='categoryid' visible={false} />
            <Column dataField='providerid' visible={false} />
            <Column dataField='servicename' caption='Services' width='250px' />
            <Column dataField='category_name' caption='Category' width='150px' />
            <Column dataField='currency' caption='Currency' width='80px' />
          </DataGrid>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServiceProviderServices;
