import HTTP from "../api/HTTP";

class ClientHelper {
  getClientsInSignup = (providerId, params) => {
    return HTTP.get(`/client/clientsprovidersignup/${providerId}`, {
      params
    }).then(response => response.data);
  };

  getActiveClients = (providerId, params) => {
    return HTTP.get(`/client/clientsofprovider/${providerId}`, {
      params
    }).then(response => response.data);
  };

  getClientData = (clientId) => {
    return HTTP.get(`/client/getclientdata/${clientId}`).then(
      response => response.data
    );
  };

  getClientSignUpData = (clientUid) => {
    return HTTP.get(`/client/clientsignupdata/${clientUid}`).then(
      response => response.data
    );
  };

  getServiceContacts = (personId) => {
    return HTTP.get(`/contacts/person/${personId}`).then(
      response => response.data
    );
  };

  findClient = (clientName) => {    
    return HTTP.get(`/client/clients`, {
      params: {
        search: clientName
      }
    }).then(response => response.data);
  };
}

export default new ClientHelper();
