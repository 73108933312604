import React, { useState, useEffect } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

const ServiceAccount = props => {
  const { selServiceId, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [serviceAccounts, setServiceAccounts] = useState([]);

  useEffect(() => {
    const fetchServiceAccounts = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/servicebankaccounts/${selServiceId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setServiceAccounts(dataArray);
      } catch (err) {}
    };

    if (selServiceId) {
      fetchServiceAccounts();
    }
  }, [sendRequest, selServiceId]);

  return (
    <ResponsiveBox>
      <Col />
      <Row />
      <Item>
        <Location screen='md lg sm xs' row={0} col={0} />
        <div>
          <DataGrid
            id='service-SystemServiceAccountsGrid'
            // ref={userDataGrid}
            keyExpr={'accountid'}
            dataSource={serviceAccounts}
            showColumnHeaders={true}
            showBorders={false}
            showRowLines={true}
            showColumnLines={false}
            columnAutoWidth={true}
            // filterValue={['usefeature', '=', true]}
            //  defaultFocusedRowIndex={0}
            // focusedRowEnabled={true}
            // onFocusedRowChanged={e => {
            //   setCmpGrid(e.row.data);
            // }}
          >
            <Column dataField='serviceid' visible={false}></Column>
            <Column dataField='providerid' visible={false}></Column>
            <Column dataField='servicename' caption='Service' width='150' visible={false}></Column>
            <Column dataField='accountid' visible={false}></Column>
            <Column dataField='account_type' visible={false}></Column>
            <Column dataField='accountname' caption='Account' width='250'></Column>
            <Column dataField='bankcountry' caption='Country' width='170'></Column>
            <Column dataField='min_amount' caption='Min Amount' width='130'></Column>
            <Column dataField='max_amount' caption='Max Amount' width='130'></Column>
            <Column dataField='cur_amount' caption='Current Amount' width='130'></Column>
          </DataGrid>
        </div>
      </Item>
    </ResponsiveBox>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServiceAccount;
