import React, { useRef, useState, useEffect } from 'react';
import './GlobalDefinitions.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column, Editing, Lookup, Selection } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';

import { Form, SimpleItem, Label, ButtonItem } from 'devextreme-react/form';
import HTTP from '../../api/HTTP';
import { Toast } from 'devextreme-react';

const AutomatedSituations = (props) => {
  const { user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [portalsData, setPortalsData] = useState([]);
  const [opsTemplatesData, setOpsTemplatesData] = useState([]);
  const [risklevels, setRisklevels] = useState([]);

  const [toastHint, setToastHint] = useState({ showtoast: false, by: -1 });

  const [selPortalid, setSelPortalid] = useState(-1);
  const [templateid, setTemplateid] = useState(-1);
  const [risklevelid, setRisklevelid] = useState(-1);

  const [selAutoSituation, setSelAutoSituation] = useState(-1);

  const [autoTemplatesData, setAutoTemplatesData] = useState([]);
  const [reloadTemplates, setReloadTemplates] = useState(false);

  const [autoTemplateId, setAutoTemplateId] = useState(-1);

  const fetchPortals = async () => {
    try {
      const dataArray = await HTTP.get('/management/portals');
      setPortalsData(dataArray.data);
    } catch (err) {}
  };

  const fetchOpsTemplates = async () => {
    try {
      const dataArray = await HTTP.get(`/cases/autotemplates`);

      setOpsTemplatesData(dataArray.data);
    } catch (err) {}
  };

  const fetchRisklevels = async () => {
    try {
      const dataArray = await HTTP.get('/base/getlookupvalues/12');
      setRisklevels(dataArray.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchPortals();
    fetchOpsTemplates();
    fetchRisklevels();
  }, []);

  const fetchDefinedAutoTemplates = async () => {
    try {
      const dataArray = await HTTP.get('/cases/defined-autotemplates');
      setAutoTemplatesData(dataArray.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchDefinedAutoTemplates();
  }, [reloadTemplates]);

  const stPortalsData = new ArrayStore({
    key: 'portalid',
    data: portalsData,
  });

  const stOpsTemplatesData = new ArrayStore({
    key: 'case_situation',
    data: opsTemplatesData,
  });

  const stRisklevels = new ArrayStore({
    key: 'lp_id',
    data: risklevels,
  });

  const saveAutomationSet = async (anOperation, anTemplateId) => {
    try {
      await HTTP.post(
        '/cases/save-defined-autotemplate',
        JSON.stringify({
          operation: anOperation,
          template_atm_id: anTemplateId,
          case_situation: templateid,
          portalid: selPortalid,
          risklevelid: risklevelid,
        })
      );
      setReloadTemplates(!reloadTemplates);
    } catch (err) {
      console.log(err);
    }
  };

  const stAutoTemplatesData = new ArrayStore({
    key: ['case_situation', 'portalid', 'risklevelid'],
    data: autoTemplatesData,
  });

  return (
    <React.Fragment>
      <Toast
        visible={toastHint.showtoast}
        message={
          toastHint.by === 1
            ? 'Please select a Portal, Situation and Risklevel before trying to add'
            : 'Please select an Automated Situation before trying to remove one'
        }
        type='error'
        displayTime={1500}
        closeOnClick={true}
        onHiding={() => {
          setToastHint(false);
        }}
      />
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Automated Situations / Flows</div>

        <ResponsiveBox>
          <Row />
          <Col ratio={1} />
          <Col ratio={0.35} />
          <Col ratio={1} />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <ResponsiveBox>
              <Row ratio={1} />
              <Row ratio={1} />
              <Row ratio={1} />
              <Col />
              <Item>
                <Location screen='md lg sm xs' row={0} col={0} />
                <div id='global-auto-grids'>
                  <DataGrid
                    height={300}
                    dataSource={stPortalsData}
                    repaintChangesOnly={true}
                    onSelectionChanged={(e) => {
                      setSelPortalid(e.selectedRowsData[0].portalid);
                    }}
                  >
                    <Selection mode='single' />
                    <Column dataField='portalid' visible={false} />
                    <Column dataField='portalname' caption='Portal' />
                    <Column dataField='partnername' caption='Merchant' />
                    <Column dataField='providername' caption='Provider' />
                  </DataGrid>
                </div>
              </Item>
              <Item>
                <Location screen='md lg sm xs' row={1} col={0} />
                <div>
                  <DataGrid
                    height={200}
                    dataSource={stOpsTemplatesData}
                    repaintChangesOnly={true}
                    onSelectionChanged={(e) => {
                      setTemplateid(e.selectedRowsData[0].case_situation);
                    }}
                  >
                    <Selection mode='single' />
                    <Column dataField='case_situation' visible={false} />
                    <Column dataField='templatename' caption='Situation' />
                  </DataGrid>
                </div>
              </Item>
              <Item>
                <Location screen='md lg sm xs' row={2} col={0} />
                <div>
                  <DataGrid
                    height={300}
                    dataSource={stRisklevels}
                    repaintChangesOnly={true}
                    onSelectionChanged={(e) => {
                      setRisklevelid(e.selectedRowsData[0].lp_id);
                    }}
                  >
                    <Selection mode='single' />
                    <Column dataField='lp_id' visible={false} />
                    <Column dataField='lp_value' caption='Risk Level' />
                  </DataGrid>
                </div>
              </Item>
            </ResponsiveBox>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <div id='global-auto-btn-section'>
              <Button
                width={120}
                height={36}
                text='Add'
                onClick={() => {
                  if (selPortalid === -1 || templateid === -1 || risklevelid === -1) {
                    setToastHint({ showtoast: true, by: 1 });
                  } else {
                    saveAutomationSet(1, -1);
                  }
                }}
              />
              <Button
                id='global-auto-btn-vert-dist'
                width={120}
                height={36}
                text='Remove'
                onClick={() => {
                  if (autoTemplateId === -1) {
                    setToastHint({ showtoast: true, by: 2 });
                  } else {
                    saveAutomationSet(3, autoTemplateId);
                  }
                }}
              />
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={0} col={2} />
            <div>
              <DataGrid
                height={830}
                dataSource={stAutoTemplatesData}
                repaintChangesOnly={true}
                onSelectionChanged={(e) => {
                  if (e.selectedRowsData[0]) {
                    setAutoTemplateId(e.selectedRowsData[0].template_atm_id);
                  } else {
                    setAutoTemplateId(-1);
                  }
                }}
              >
                <Selection mode='single' />
                <Column dataField='template_atm_id' visible={false} />
                <Column dataField='case_situation' visible={false} />
                <Column dataField='portalid' visible={false} />
                <Column dataField='risklevelid' visible={false} />
                <Column dataField='portalname' caption='Automate Situation of' />
                <Column dataField='situation' caption='for' />
                <Column dataField='risklevel' caption='On Risklevel' />
              </DataGrid>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default AutomatedSituations;
