import React, { useEffect, useState } from "react";
import { MultiView, Tabs } from "devextreme-react";
import { Item } from "devextreme-react/responsive-box";
import MailingsList from "./components/MailingsList";
import TemplatesList from "./components/TemplatesList";
import AccountsList from "./components/AccountsList";
import { withRouter } from "react-router-dom";

function MailingPage(props) {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (props.history.location.state !== undefined) {
      setTabIndex(Number(props.history.location.state));
    } else {
      setTabIndex(0);
    }
  }, []);

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Client Mailing</h2>
      <div className={"content-block"}>
        <div className={"dx-card wide-card p-1"}>
          <Tabs
            selectedIndex={tabIndex}
            visible={true}
            onItemClick={e => {
              setTabIndex(e.itemIndex);
            }}
          >
            <Item text="Emails" />
            <Item text="Templates" />
            <Item text="Accounts" />
          </Tabs>
          <MultiView
            swipeEnabled={false}
            animationEnabled={false}
            selectedIndex={tabIndex}
          >
            <Item>
              <MailingsList />
            </Item>
            <Item>
              <TemplatesList />
            </Item>
            <Item>
              <AccountsList />
            </Item>
          </MultiView>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(MailingPage);
