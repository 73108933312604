import React, { useState, useEffect } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import './ServicesData.css';

import Tabs from 'devextreme-react/tabs';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import { Form, SimpleItem, Label } from 'devextreme-react/form';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, MasterDetail } from 'devextreme-react/data-grid';

import ServiceFeeGridDetails from '../../detailgrids/ServiceFeeGridDetails';
import MessagePop from '../../ui_elements/MessagePop';

const ServicesDataCostRates = (props) => {
  const { user } = useAuth();
  const { selServiceId, selCostEntryid } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [costRateHeaderList, setCostRateHeaderList] = useState({});
  const [costRiskEntriesList, setCostRiskEntriesList] = useState([]);
  const [reloadServiceFeatures, setReloadServiceFeatures] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);

  const FormatDateString = (anDateString) => {
    const anDate = anDateString
      .slice(0, 10)
      // .replace(/-/g, "/")
      .replace('T', ' ');
    return anDate;
  };

  useEffect(() => {
    const fetchCostRatesHeader = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/singleservicecostrateheader/${selServiceId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCostRateHeaderList(dataArray[0]);

        let anCostEntryId = -1;

        if (dataArray[0].costentryid) {
          anCostEntryId = dataArray[0].costentryid;
        }

        const costRiskDataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/viewcostriskentries/${anCostEntryId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );

        setCostRiskEntriesList(costRiskDataArray);
        setReloadServiceFeatures(false);
      } catch (err) {}
    };

    if ((selServiceId && selServiceId > -1) || reloadServiceFeatures) {
      fetchCostRatesHeader();
    }
  }, [sendRequest, selServiceId, reloadServiceFeatures]);

  const costRateHeader = {
    serviceid: (costRateHeaderList && costRateHeaderList.serviceid) || -1,
    costentryid: (costRateHeaderList && costRateHeaderList.costentryid) || -1,
    generated: costRateHeaderList && costRateHeaderList.generated,
    valid_on: costRateHeaderList && costRateHeaderList.valid_on,
    valid_until: costRateHeaderList && costRateHeaderList.valid_until,
    currency: (costRateHeaderList && costRateHeaderList.currency) || '',
    entrynumber: (costRateHeaderList && costRateHeaderList.entrynumber) || '',
    comment: (costRateHeaderList && costRateHeaderList.comment) || '',
    userid: (costRateHeaderList && costRateHeaderList.userid) || -1,
    active: (costRateHeaderList && costRateHeaderList.active) || false,
    active_date: costRateHeaderList && costRateHeaderList.active_date,
  };

  const stCostRiskEntriesData = new ArrayStore({
    key: ['riskid'],
    data: costRiskEntriesList,
  });

  const dcCostRiskEntriesData = new DataSource({
    store: stCostRiskEntriesData,
  });

  function navigateToHome() {
    history.goBack();
  }

  const openServiceFeeEdit = async () => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/service/costrateworkcopy/${costRateHeader.serviceid}/${costRateHeader.costentryid}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + user.token }
      );

      user.costrates = {
        selServiceid: costRateHeader.serviceid,
        selCostEntryid: costRateHeader.costentryid,
        workId: dataArray[0].work_copy_costrates,
        originid: 2,
      };

      history.push({
        pathname: '/servicefees_edit',
      });
    } catch (err) {}
  };

  const initServiceCostRates = async () => {
    // console.log(selServiceId);
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/service/initservicecostrates',
        'POST',
        JSON.stringify({
          serviceid: selServiceId,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setReloadServiceFeatures(true);
    } catch (err) {
      console.log(err);
    }
  };

  const initializeServiceCostRates = () => {
    setShowMessagePop(true);
  };

  const hideMessagePop = async (doContinue) => {
    setShowMessagePop(false);
    if (doContinue) {
      // console.log('run remove');
      await initServiceCostRates();
      setReloadServiceFeatures(true);
    }
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Initialization will delete existing Service Cost Rates. Do you want to continue?'
      />
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Cost Rates</div>
        <ResponsiveBox>
          <Col ratio={1} screen='md lg sm xs' />
          <Col ratio={10} />
          <Row />

          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div id='hm-btn-section-1'>
              <Button
                width={120}
                height={36}
                text='Edit'
                type='default'
                stylingMode='outlined'
                onClick={openServiceFeeEdit}
              />
            </div>
            <div id='hm-btn-section-2'>
              <Button
                width={120}
                height={36}
                text='Init'
                type='normal'
                stylingMode='outlined'
                onClick={initializeServiceCostRates}
              />
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <Form
              formData={costRateHeader}
              labelLocation={'left'}
              colCountByScreen={colCountByScreen}
              scrollingEnabled={true}
            >
              <Item>
                <table>
                  <tbody>
                    <tr>
                      <td width='60'>Currency</td>
                      <td width='140'>
                        <b>{costRateHeader.currency}</b>
                      </td>
                      <td width='60'>Valid on </td>
                      <td width='140'>
                        <b>{costRateHeader.valid_on && FormatDateString(costRateHeader.valid_on)}</b>
                      </td>
                      <td width='80'>Valid until </td>
                      <td width='140'>
                        <b>{costRateHeader.valid_until && FormatDateString(costRateHeader.valid_until)}</b>
                      </td>
                      <td width='60'>Active </td>
                      <td width='140'>
                        <b>{costRateHeader.active && costRateHeader.active === true ? 'yes' : 'no'}</b>
                      </td>
                      <td width='60'>Since </td>
                      <td width='140'>
                        <b>{costRateHeader.active_date && FormatDateString(costRateHeader.valid_until)}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Item>

              <Item>
                <DataGrid
                  height={500}
                  dataSource={dcCostRiskEntriesData}
                  defaultFocusedRowIndex={0}
                  columnAutoWidth={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  focusedRowEnabled={true}
                  showBorders={true}
                  showRowLines={true}
                  showColumnLines={true}
                  autoNavigateToFocusedRow={true}
                  // onFocusedRowChanged={e => {
                  //   setRiskGridData(e.row.data);
                  //   setSelRiskid(e.row.data.riskid);
                  // }}
                >
                  <Column dataField='serviceid' visible={false} />
                  <Column dataField='costentryid' visible={false} />
                  <Column dataField='riskid' visible={false} />
                  <Column dataField='risklevel' caption='Risk Level' width='250px' allowEditing={false} />
                  <Column dataField='rates_set' caption='Rates Set' width='90px' visible={false} />
                  <Column dataField='not_applicable' caption='Not Applicable' width='120px' />
                  <Column dataField='setupfee' caption='Setup Fee' dataType='number' width='150' />
                  <Column dataField='recurringfee' caption='Service Fee' dataType='number' width='150' />
                  <Column dataField='recperiod' caption='Recurring' width='200'></Column>
                  <MasterDetail enabled={true} component={ServiceFeeGridDetails} />
                </DataGrid>
              </Item>
            </Form>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ServicesDataCostRates;
