import React, { useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule } from 'devextreme-react/form';

import { useAuth } from '../contexts/auth';

const NotesPopup = props => {
  const { showPopup, hidePopup, selsubj_type, selsubj_uuid, selsubj_int, initsubject } = props;
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const noteFormRef = useRef(null);

  const notedata = {
    notesubject: initsubject,
    notetext: '',
  };

  const saveNote = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/base/createnote',
        'POST',
        JSON.stringify({
          operation: 1,
          subj_type: selsubj_type,
          subj_uuid: selsubj_uuid,
          subj_int: selsubj_int,
          userid: user.userId,
          companyid: user.portalid,
          privacylevel: 1,
          subject: notedata.notesubject,
          content: notedata.notetext,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async noteFormRef => {
    const result = noteFormRef.validationGroup.validate();
    if (result.isValid) {
      saveNote();
      hidePopup(true);
    } else {
      alert('Please complete all required fields');
    }
  };

  return (
    <Popup
      title={'Note'}
      width={800}
      height={600}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup();
      }}
    >
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.1} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <Form
              id={'form'}
              ref={noteFormRef}
              formData={notedata}
              labelLocation={'left'}
              colCountByScreen={colCountByScreen}
              validationGroup='NoteData'
            >
              <Item itemType='group' colCount={1}>
                <SimpleItem dataField='notesubject' isRequired={true}>
                  <Label text='Subject' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={1}>
                <SimpleItem
                  dataField='notetext'
                  editorType='dxTextArea'
                  isRequired={true}
                  editorOptions={{ height: 350 }}
                >
                  <Label text='Content' />
                </SimpleItem>
              </Item>
            </Form>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'closebutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={40}
                text='Save'
                type='normal'
                stylingMode='contained'
                validationGroup='NoteData'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
              <Button
                width={120}
                height={40}
                text='close'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  hidePopup();
                }}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default NotesPopup;
