import React, { useRef, useState, useEffect } from 'react';
import './GlobalDefinitions.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';

import Box from 'devextreme-react/box';
import { Form, SimpleItem, Label, ButtonItem } from 'devextreme-react/form';

const OpsSettingsTab = (props) => {
  const { user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [opsAssetsData, setOpsAssetsData] = useState([]);
  const [lpSystemServices, setLpSystemServices] = useState([]);
  const [lpAssets, setLpAssets] = useState([]);
  const [lpCurrencies, setLpCurrencies] = useState([]);

  useEffect(() => {
    const fetchServiceAssets = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/ops/serviceassets', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setOpsAssetsData(dataArray);
      } catch (err) {}
    };

    const fetchSystemServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/service/systemservices',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpSystemServices(dataArray);
      } catch (err) {}
    };

    const fetchAssets = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/9',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpAssets(dataArray);
      } catch (err) {}
    };

    const fetchCurrencies = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/21',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpCurrencies(dataArray);
      } catch (err) {}
    };

    fetchSystemServices();
    fetchAssets();
    fetchCurrencies();
    fetchServiceAssets();
  }, [sendRequest]);

  const stOpsAssetsData = new ArrayStore({
    key: ['serviceid', 'assetentryid'],
    data: opsAssetsData,
  });

  const dcOpsAssetsData = new DataSource({
    store: stOpsAssetsData,
  });

  const stLpSystemServices = new ArrayStore({
    key: 'serviceid',
    data: lpSystemServices,
  });

  const stlpAssets = new ArrayStore({
    key: 'lp_id',
    data: lpAssets,
  });

  const stlpCurrencies = new ArrayStore({
    key: 'lp_id',
    data: lpCurrencies,
  });

  const saveServiceAssetEntry = async (assetEntryEntryData, onOps) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/ops/serviceassets',
        'POST',
        JSON.stringify({
          operation: onOps,
          serviceid: assetEntryEntryData.serviceid,
          assetentryid: assetEntryEntryData.assetentryid,
          assetid: assetEntryEntryData.assetid,
          buy_currency: assetEntryEntryData.buy_currency,
          buy_rate: assetEntryEntryData.buy_rate,
          sell_rate: assetEntryEntryData.sell_rate,
          size_value: assetEntryEntryData.size_value,
          size_spec: assetEntryEntryData.size_spec,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='sd-header item'>
      <div className='dx-fieldset-header'>Test Settings</div>

      <ResponsiveBox>
        <Row />
        <Col />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <DataGrid
              height={560}
              dataSource={dcOpsAssetsData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onSaved={(e) => {
                if (e.changes[0]) {
                  saveServiceAssetEntry(e.changes[0].data, e.changes[0].type);
                }
              }}
            >
              <Column dataField='assetentryid' visible={false} />

              <Column dataField='serviceid' caption='Service' width={250} visible={true}>
                <Lookup dataSource={stLpSystemServices} displayExpr='servicename' valueExpr='serviceid' />
              </Column>

              <Column dataField='assetid' caption='Asset' width={200} visible={true}>
                <Lookup dataSource={stlpAssets} displayExpr='lp_value' valueExpr='lp_id' />
              </Column>

              <Column dataField='asset_shortid' caption='Short ID' />

              <Column dataField='buy_currency' caption='Currency' width={100} visible={true}>
                <Lookup dataSource={stlpCurrencies} displayExpr='lp_value' valueExpr='lp_value' />
              </Column>
              <Column dataField='buy_rate' />
              <Column dataField='sell_rate' />
              <Column dataField='size_value' caption='Measurement' />
              <Column dataField='size_spec' caption='Unit' />

              <Editing mode='row' allowAdding allowDeleting allowUpdating={true} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

export default OpsSettingsTab;
