import { withNavigationWatcher } from './contexts/navigation';
import {
  HomePage,
  SystemSectionsPage,
  ServiceProviderPage,
  ServicesPage,
  RoutingPage,
  PartnerPage,
  ProductsPage,
  ReportingPage,
  PartnerProductEditPage,
  BankAccountsEdit,
  CostRatesBasePage,
  ServiceFeeEdit,
  PartnerEditServicesEditPage,
  PartnerServiceFeesEdit,
  ProductServiceFeesEdit,
  TransactionsPage,
  ClientsApprovedPage,
  CommunicationPage,
  ComplianceMatrixPage,
  ComplianceKYCPage,
  CompReqDoc,
  OperationsPage,
  InquiryPage,
  OmCaseNotePage,
  GlobalDefinitionsPage,
  ServiceProviderDataPage,
  ClientPage,
  PartnerDataPage,
  NetworkDataPage,
  ServicesDataPage,
  AdminDash,
  ExtensionsPage,
} from './pages';

import MailingPage from '../src/pages/mailing/MailingPage';
import AccountFormPage from '../src/pages/mailing/AccountFormPage';
import CalendarPage from '../src/pages/calendar/CalendarPage';
import EmailFormPage from '../src/pages/mailing/EmailFormPage';
import MessagingSystemPage from '../src/pages/messages/MessagingSystemPage';
import MessagingThreadPage from '../src/pages/messages/MessagingThreadPage';
import ReviewEmailPage from '../src/pages/mailing/ReviewEmailPage';
import TemplateFormPage from '../src/pages/mailing/TemplateFormPage';

const routes = [
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/systemsections',
    component: SystemSectionsPage,
  },
  {
    path: '/serviceprovider',
    component: ServiceProviderPage,
  },
  {
    path: '/services',
    component: ServicesPage,
  },
  {
    path: '/networks',
    component: RoutingPage,
  },

  {
    path: '/partner',
    component: PartnerPage,
  },
  {
    path: '/products',
    component: ProductsPage,
  },
  {
    path: '/reporting',
    component: ReportingPage,
  },
  {
    path: '/partnerproductedit',
    component: PartnerProductEditPage,
  },
  {
    path: '/bankaccountedit',
    component: BankAccountsEdit,
  },
  {
    path: '/costratesbase',
    component: CostRatesBasePage,
  },
  {
    path: '/servicefees_edit',
    component: ServiceFeeEdit,
  },
  {
    path: '/partnereditservices_edit',
    component: PartnerEditServicesEditPage,
  },
  {
    path: '/partnerservicefees_edit',
    component: PartnerServiceFeesEdit,
  },
  {
    path: '/productservicefees_edit',
    component: ProductServiceFeesEdit,
  },
  {
    path: '/transactions',
    component: TransactionsPage,
  },
  {
    path: '/clients',
    component: ClientsApprovedPage,
  },
  {
    path: '/communication',
    component: CommunicationPage,
  },
  {
    path: '/compliancematrix',
    component: ComplianceMatrixPage,
  },
  {
    path: '/kyc',
    component: ComplianceKYCPage,
  },
  {
    path: '/mailing',
    component: MailingPage,
  },
  {
    path: '/mailing/email',
    component: EmailFormPage,
  },
  {
    path: '/mailing/email/:emailId',
    component: EmailFormPage,
  },
  {
    path: '/mailing/review-email/:emailId',
    component: ReviewEmailPage,
  },
  {
    path: '/mailing/account',
    component: AccountFormPage,
  },
  {
    path: '/mailing/account/:accountId(\\d+)',
    component: AccountFormPage,
  },
  {
    path: '/mailing/template',
    component: TemplateFormPage,
  },
  {
    path: '/mailing/template/:templateId',
    component: TemplateFormPage,
  },
  {
    path: '/calendar',
    component: CalendarPage,
  },
  {
    path: '/messages',
    component: MessagingSystemPage,
  },
  {
    path: '/messages/thread/:threadId',
    component: MessagingThreadPage,
  },
  {
    path: '/compreqdoc',
    component: CompReqDoc,
  },
  { path: '/operations', component: OperationsPage },
  { path: '/inquiry', component: InquiryPage },
  { path: '/casenote', component: OmCaseNotePage },
  { path: '/globaldefinitions', component: GlobalDefinitionsPage },
  { path: '/serviceproviderdata', component: ServiceProviderDataPage },
  { path: '/clientdata', component: ClientPage },
  { path: '/partnerdata', component: PartnerDataPage },
  { path: '/networkdata', component: NetworkDataPage },
  { path: '/servicesdata', component: ServicesDataPage },
  { path: '/admindash', component: AdminDash},
  { path: '/extensions', component: ExtensionsPage}
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
