import React, { useRef, useState, useEffect } from 'react';
import './GlobalDefinitions.css';

import { useHttpClient } from '../../hooks/http-hook';

import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';

import { Form, SimpleItem, Label, ButtonItem } from 'devextreme-react/form';

const SystemSectionTab = (props) => {
  const { user } = props;
  const departmentDataGrid = useRef(null);

  const [departmentDatalist, setDepartmentDatalist] = useState([]);
  const [departmentGridData, setDepartmentGridData] = useState();
  const [departmentDataMod, setDeparmentDataMod] = useState(false);
  const [lpCategoryType, setLpCategoryType] = useState([]);
  const [lpCategoryOps, setLpCategoryOps] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const departmentData = {
    category_name: departmentGridData && departmentGridData.category_name,
    category_id: departmentGridData && departmentGridData.category_id,
    category_type: departmentGridData && departmentGridData.category_type,
    category_ops: departmentGridData && departmentGridData.category_ops,
    category_ident: departmentGridData && departmentGridData.category_ident,
    spec_settings: departmentGridData && departmentGridData.spec_settings,
  };

  useEffect(() => {
    const fetchServiceCategories = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/service/servicecat', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setDepartmentDatalist(dataArray);
      } catch (err) {}
    };

    const fetchCategoryType = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/14',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpCategoryType(dataArray);
      } catch (err) {}
    };

    const fetchCategoryOps = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/17',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpCategoryOps(dataArray);
      } catch (err) {}
    };

    fetchServiceCategories();
    fetchCategoryType();
    fetchCategoryOps();
  }, [sendRequest, departmentDataMod]);

  const stDepartmentData = new ArrayStore({
    key: 'category_id',
    data: departmentDatalist,
  });

  const stCategoryTypeData = new ArrayStore({
    key: 'lp_id',
    data: lpCategoryType,
  });

  const stCategoryOps = new ArrayStore({
    key: 'lp_id',
    data: lpCategoryOps,
  });

  const modServiceCat = async (modify, servicedata) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/service/servicecat',
        'POST',
        JSON.stringify({
          category_id: servicedata.category_id,
          category_name: servicedata.category_name,
          category_type: servicedata.category_type,
          category_ops: servicedata.category_ops,
          category_ident: servicedata.category_ident,
          spec_settings: servicedata.spec_settings,
          operation: modify,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setDeparmentDataMod(!departmentDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='sd-header item'>
      <div className='dx-fieldset-header'>System Sections</div>

      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={4} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} colspan={3} />
          <div id='companytab-box'>
            <Form id={'form'} formData={departmentData} labelLocation={'left'}>
              <Item itemType='group' colCount={12}>
                <SimpleItem dataField='category_name' colSpan={3}>
                  <Label location='left' text='Section' />
                </SimpleItem>
                <SimpleItem dataField='category_ident' colSpan={2}>
                  <Label location='left' text='ID' />
                </SimpleItem>
                <SimpleItem
                  dataField='category_type'
                  colSpan={2}
                  editorType='dxSelectBox'
                  name='type_listbox'
                  editorOptions={{
                    dataSource: stCategoryTypeData,
                    valueExpr: 'lp_id',
                    displayExpr: 'lp_value',
                    searchEnabled: false,
                  }}
                >
                  <Label text='Type' />
                </SimpleItem>
                <SimpleItem
                  dataField='category_ops'
                  colSpan={3}
                  editorType='dxSelectBox'
                  name='ops_listbox'
                  editorOptions={{
                    dataSource: stCategoryOps,
                    valueExpr: 'lp_id',
                    displayExpr: 'lp_value',
                    searchEnabled: false,
                  }}
                >
                  <Label text='Ops' />
                </SimpleItem>
                <SimpleItem dataField='spec_settings' editorType='dxCheckBox' colSpan={1}>
                  <Label location='left' text='Sp. Set' />
                </SimpleItem>
              </Item>
              <Item itemType='group' colCount={18}>
                <ButtonItem
                  id='companytab-button'
                  colSpan={2}
                  horizontalAlignment="left"
                  buttonOptions={{
                    height: 36,
                    width: 120,
                    text: 'Add',
                    type: 'default',
                    stylingMode: 'outlined',
                    onClick: () => {
                      modServiceCat(1, departmentData);
                    },
                  }}
                />
                <ButtonItem
                  id='companytab-button'
                  colSpan={2}
                  horizontalAlignment="left"
                  buttonOptions={{
                    width: 120,
                    height: 36,
                    text: 'Mod',
                    type: 'success',
                    stylingMode: 'outlined',
                    onClick: () => {
                      modServiceCat(2, departmentData);
                    },
                  }}
                />
                <ButtonItem
                  id='companytab-button'
                  colSpan={2}
                  horizontalAlignment="left"
                  buttonOptions={{
                    width: 120,
                    height: 36,
                    text: 'Rem',
                    type: 'danger',
                    stylingMode: 'outlined',
                    onClick: () => {
                      modServiceCat(3, departmentData);
                    },
                  }}
                />
              </Item>
            </Form>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <DataGrid
            id='global-defs-sys-sect-grid'
            height={380}
            width={740}
            ref={departmentDataGrid}
            dataSource={stDepartmentData}
            showRowLines={true}
            showBorders={true}
            showColumnLines={true}
            columnAutoWidth={true}
            defaultFocusedRowIndex={0}
            focusedRowEnabled={true}
            showColumnHeaders={true}
            onFocusedRowChanged={(e) => {
              setDepartmentGridData(e.row.data);
            }}
            onSaved={(e) => {
              if (e.changes[0]) {
                modServiceCat(2, e.changes[0].data);
              }
            }}
          >
            <Column dataField='category_id' visible={false}></Column>
            <Column dataField='category_name' caption='Service Category'></Column>
            <Column dataField='category_ident' caption='ID' width='50'></Column>
            <Column dataField='category_type' caption='Category Type' width='120'>
              <Lookup dataSource={stCategoryTypeData} displayExpr='lp_value' valueExpr='lp_id' />
            </Column>
            <Column dataField='category_ops' caption='Category Operations' width='150'>
              <Lookup dataSource={stCategoryOps} displayExpr='lp_value' valueExpr='lp_id' />
            </Column>
            <Column dataField='spec_settings' caption='Specific Section' dataType='boolean' width='120'></Column>
            <Editing mode='row' allowUpdating={true} />
          </DataGrid>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

export default SystemSectionTab;
