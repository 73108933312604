import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import './Networks.css';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { Form, SimpleItem, EmptyItem, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Tabs, MultiView, SelectBox } from 'devextreme-react';
import { useAuth } from '../../contexts/auth';
import { Label } from 'devextreme-react/data-grid';

import RoutingTab from './Networkstab';
import RoutingServices from './NetworksServices';
import RoutingPartner from './NetworksPartner';
import RoutingNotes from './NetworksNotes';

const RoutingPage = () => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [contactPersonid, setContactPersonid] = useState();
  const [userCompanies, setUserCompanies] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const getsavedselcompany = () => {
    const result = -1;
    let loaded = JSON.parse(localStorage.getItem('storedCompany'));
    if (loaded && loaded !== null) {
      return loaded.cid;
    } else {
      return result;
    }
  };

  const [selectedCompany, setSelectedCompany] = useState(getsavedselcompany);

  const baseformData = {
    selCompanyid: selectedCompany,
    selLfdNr: -1,
    selBase_LfdNr: -1,
  };

  const getPersonid = fPersonid => {
    setContactPersonid(fPersonid);
  };

  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Cases</h2> */}
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={2} />
            <Row ratio={0.7} />
            <Col />

            <Item>
              <Location screen='md lg' row={0} col={0} colspan={2} />
              <Location screen='sm' row={0} col={0} colspan={1} />
              <Location screen='xs' row={0} col={0} />
              <div className='rb-header item'>
                <ResponsiveBox>
                  <Row />
                  <Col ratio={3} />
                  <Col ration={1} />
                  <Item>
                    <Location screen='md lg sm' row={0} col={0} />
                    <div className='dx-fieldset-header' id='cap_item'>
                      Financial Networks
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={1} />
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg sm' row={1} col={0} />
              <Location screen='xs' row={1} col={0} />
              <div className='rb-content item'>
                <ResponsiveBox>
                  <Row />
                  <Col />
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={0} />
                    <RoutingTab passToParent={getPersonid} user={user} selCompanyID={selectedCompany} />
                    <div>
                    <ResponsiveBox>
                      <Col ratio={0.33} screen='md lg sm' />
                      <Col ratio={3} />
                      <Row />
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={0} />
                      </Item>
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={1} />
                        <Tabs
                          selectedIndex={tabIndex}
                          width='99%'
                          // height='20px'
                          visible={true}
                          onItemClick={e => {
                            setTabIndex(e.itemIndex);
                          }}
                        >
                          <Item text='Services' />
                          <Item text='Products' />
                        </Tabs>
                        <MultiView height={370} animationEnabled={false} selectedIndex={tabIndex}>
                          <Item title='Asociated Services'>
                            <RoutingServices selTabIndex={tabIndex} selRoutingID={contactPersonid} user={user} />
                          </Item>
                          <Item title='Partners'>
                            <RoutingPartner tabVisibleIndex={tabIndex} selRoutingID={contactPersonid} user={user} />
                          </Item>
                        </MultiView>
                      </Item>
                    </ResponsiveBox>
                    </div>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg' row={2} col={0} colspan={2} />
              <Location screen='sm' row={2} col={0} colspan={1} />
              <Location screen='xs' row={2} col={0} />
              <div className='rb-footer item' id='cap_item'></div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RoutingPage;
