import React, { useEffect, useRef, useState } from 'react';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import MultiView from 'devextreme-react/multi-view';
import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import './costrateservices.css';
import { SelectBox } from 'devextreme-react/select-box';
import Box from 'devextreme-react/box';
import Tabs from 'devextreme-react/tabs';
import ArrayStore from 'devextreme/data/array_store';

const ServicesEditPop = props => {
  const { showPopup, hidePopup, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const [lpServices, setLpServices] = useState([]);
  const [selServiceId, setSelServiceId] = useState(-1);

  useEffect(() => {
    const fetchServicesWoCostRates = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/service/serviceswocostrates',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpServices(dataArray);
      } catch (err) {}
    };
    if (showPopup) {
      fetchServicesWoCostRates();
    }
  }, [sendRequest, showPopup]);

  const CreateServiceCostRates = async () => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/service/servicecreatecostrates/${selServiceId}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + user.token }
      );
      hidePopup(true);
    } catch (err) {
      console.log(err);
    }
  };

  const stServices = new ArrayStore({
    key: 'serviceid',
    data: lpServices,
  });

  const navigateToHome = () => {
    hidePopup();
  };

  return (
    <Popup
      title={'Services'}
      width={380}
      height={260}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        setSelServiceId(-1);
        // hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div className='dx-field'>
            <div className='dx-field-label'>Service</div>
            <div className='dx-field-value'>
              <SelectBox
                name='service_sel_box'
                dataSource={stServices}
                displayExpr='servicename'
                valueExpr='serviceid'
                value={selServiceId}
                onValueChanged={e => {
                  setSelServiceId(e.value);
                }}
              />
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'contactpopbutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={50}
                text='Create'
                type='normal'
                stylingMode='contained'
                onClick={CreateServiceCostRates}
              />
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServicesEditPop;
