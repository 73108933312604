import React from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import MultiView from 'devextreme-react/multi-view';
import { Item } from 'devextreme-react/responsive-box';

import ServiceSectionTab from './SystemSectionTab';
import { useAuth } from '../../contexts/auth';

const SystemSectionsPage = () => {
  const { user } = useAuth();
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Service Sections</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <div className={'dx-card wide-card'}>
          <MultiView height={650} loop={false}>
            <Item title='System Sections'>
              <ServiceSectionTab user={user} />
            </Item>
          </MultiView>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SystemSectionsPage;
