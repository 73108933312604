import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { useAuth } from '../contexts/auth';
import './detailgrids.css';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

const ProvServiceDetailGrid = props => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const risklevel = props.data.data;
  const [costRateDetailList, setCostRateDetailList] = useState([]);

  useEffect(() => {
    const fetchCostRateEntries = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/viewcostrateentries/${risklevel.serviceid}/${risklevel.riskid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCostRateDetailList(dataArray);
      } catch (err) {}
    };
    fetchCostRateEntries();
  }, [sendRequest, risklevel.serviceid, risklevel.riskid]);

  const stCostDetailsData = new ArrayStore({
    key: ['serviceid', 'feature_group_assetid', 'featureid'],
    data: costRateDetailList,
  });

  const dcCostDetailsData = new DataSource({
    store: stCostDetailsData,
  });

  return (
    <div>
      {/*  <p>
        {risklevel.risklevel +
          ' - ' +
          risklevel.costentryid +
          ' - ' +
          risklevel.riskid}
      </p>
      */}
      <DataGrid
        id='service-fees-detailgrid'
        dataSource={dcCostDetailsData}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        focusedRowEnabled={true}
        showBorders={true}
        showRowLines={true}
        showColumnLines={true}
        autoNavigateToFocusedRow={true}
      >
        <FilterRow visible={false} />
        <Column dataField='serviceid' visible={false} />
        <Column dataField='costentryid' visible={false} />
        <Column dataField='riskid' visible={false} />
        <Column dataField='featureid' visible={false} />
        <Column dataField='regional_limit_id' visible={false} />
        <Column dataField='feature' caption='Service Feature' width={200} />
        <Column dataField='feature_group_asset' caption='Group' width={100} />
        <Column dataField='costtype' caption='Cost Type' width={80} visible={false}></Column>
        <Column dataField='costvalue' caption='Fixed Fee' dataType='number' width={80} />
        <Column dataField='costperc' caption='Percent of Value' dataType='number' width={120} />
        <Column dataField='costperc_minvalue' caption='Min Value' dataType='number' width={80} />
        <Column dataField='costperc_maxvalue' caption='Max Value' dataType='number' width={80} />
        <Column dataField='flattype' caption='Flat Type' width={90}></Column>
        <Column dataField='flatvalue' dataType='number' caption='Flat Value' width={90} />
        <Column dataField='flatperiod' caption='Flat Period' width={90}></Column>
      </DataGrid>
    </div>
  );
};
export default ProvServiceDetailGrid;
