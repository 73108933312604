import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Toast } from 'devextreme-react/toast';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule } from 'devextreme-react/form';

import { useAuth } from '../contexts/auth';

import MessageThreadPage from '../pages/messages/MessageThreadPage';
import ScrollView from 'devextreme-react/scroll-view';
import InquiryDocRequest from '../modules/inquiries/InquiryDocRequest';
import ArrayStore from 'devextreme/data/array_store';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { CheckBox } from 'devextreme-react/check-box';
import { SelectBox } from 'devextreme-react';
import AddCustomFile from '../modules/inquiries/AddCustomFile';
import MessagePop from './MessagePop';
import { ConstantLine } from 'devextreme-react/chart';

const InquiryEditPopup = props => {
  const { showPopup, hidePopup, selNotesKey, selInquiryId, selPartnerid, selClientid, selAccountType } = props;
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const noteFormRef = useRef(null);
  const [noteData, setNoteData] = useState({});
  const [operationId, setOperationId] = useState(10);
  const [reloadDocData, setReloadDocData] = useState(false);
  const [documentsArray, setDocumentsArray] = useState([]);
  const [requiredDocData, setRequiredDocData] = useState();
  const [downloadFileRow, setDownloadFileRow] = useState();
  const [selectedClientAccount, setSelectedClientAccount] = useState();
  const [showAddCustomFilePopup, setShowAddCustomFilePopup] = useState(false);
  const [selectedAddFile, setSelectedAddFile] = useState();
  const [threadAttachedDocument, setThreadAttachedDocument] = useState();
  const [clientUploadedDocuments, setClientUploadedDocuments] = useState();
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [documentStatus, setDocumentStatus] = useState([]);
  const [toastConfigVisible, setToastConfigVisible] = useState(false);

  useEffect(() => {
    const fetchClientUploadedDocuments = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/inquiries/getUploadedDocuments/${selInquiryId}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setClientUploadedDocuments(dataArray);
      } catch (err) {}
    };

    const fetchDocumentStatus = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/document/getDocumentStatus/`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setDocumentStatus(dataArray);
      } catch (err) {}
    };

    if (selInquiryId) {
      fetchClientUploadedDocuments();
      fetchDocumentStatus();
    }
  }, [sendRequest, selInquiryId, showPopup]);

  useEffect(() => {
    const fetchNote = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/inquiries/getInquiry/${selInquiryId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );

        console.log(1234, dataArray[0]);
        console.log(5678, selNotesKey, selInquiryId, selPartnerid, selClientid, selAccountType);
        setNoteData(dataArray[0]);
      } catch (err) {
        console.log(err);
      }
    };
    if (selInquiryId) {
      fetchNote();
    }
  }, [sendRequest, selNotesKey, showPopup]);

  useEffect(() => {
    const fetchReqDocs = async () => {
      if (selAccountType === 'Person') {
        setOperationId(10);
      }
      if (selAccountType === 'Company') {
        setOperationId(20);
      }

      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/document/getAllSignupDocuments',
          'POST',
          JSON.stringify({
            operationId: operationId,
            selPartnerId: selPartnerid,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
          }
        );
        setDocumentsArray(dataArray);
      } catch (err) {
        console.log(err);
      }
    };
    fetchReqDocs();
  }, [sendRequest, user.token, reloadDocData, selNotesKey, selInquiryId, selAccountType]);

  const stReqDocData = new ArrayStore({
    key: 'id',
    data: documentsArray,
  });

  const stReqDocUpload = new ArrayStore({
    key: 'id',
    data: clientUploadedDocuments,
  });

  const notedata = {
    notesubject: noteData && noteData.subject,
    notetext: noteData && noteData.description,
    chatId: noteData && noteData.chatthreadid,
  };

  const updateInquiry = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/inquiries/updateInquiry',
        'POST',
        JSON.stringify({
          notedata,
          selInquiryId,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );
      setReloadDocData(!reloadDocData);
      hidePopup();
    } catch (err) {
      console.log(err);
    }
  };

  const hideAddCustomFilePopup = () => {
    setShowAddCustomFilePopup(false);
    setReloadDocData(!reloadDocData);
  };

  const AddCustomFilePopup = () => {
    setShowAddCustomFilePopup(true);
  };

  useEffect(() => {
    const fetchDocumentsThread = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/inquiries/getDocumentsThread/${selInquiryId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setThreadAttachedDocument(dataArray);
      } catch (err) {
        console.log(err);
      }
    };
    if (selInquiryId) {
      fetchDocumentsThread();
    }
  }, [sendRequest, user.token, reloadDocData, selNotesKey, selInquiryId, selAccountType]);

  const saveDocumentThread = async () => {
    if (typeof selectedAddFile !== 'undefined') {
      try {
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/inquiries/saveDocumentThread',
          'POST',
          JSON.stringify({
            inquiryId: selInquiryId,
            selectedAddFile: selectedAddFile,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
          }
        );
        setReloadDocData(!reloadDocData);
      } catch (err) {
        console.log(err);
      }
    } else {
      // Popup to choose file before being able to add
      setToastConfigVisible(true);
    }
  };

  const removeDocumentThread = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/inquiries/removeDocumentThread',
        'POST',
        JSON.stringify({
          inquiryId: selInquiryId,
          selectedRemoveFile: requiredDocData.documentid,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );
      setReloadDocData(!reloadDocData);
    } catch (err) {
      console.log(err);
    }
  };

  const threadAttachedDocumentData = new ArrayStore({
    key: 'documentid',
    data: threadAttachedDocument,
  });

  const removeReqDoc = () => {
    setReloadDocData(reloadDocData => !reloadDocData);
    setShowMessagePop(true);
  };

  const hideMessagePop = useCallback(doRemove => {
    setShowMessagePop(false);
    if (doRemove) {
      removeDocumentThread().then(r => setReloadDocData(reloadDocData => !reloadDocData));
    }
  });

  const cellRenderDocumentStatus = data => {
    for (let i = 0; i < documentStatus.length; i++) {
      if (documentStatus[i].id === data.value) {
        return documentStatus[i].label;
      }
    }
  };

  const downloadFile = async () => {
    try {
      await fetch(process.env.REACT_APP_BACKEND_URL + `/document/getDocumentDownload/${downloadFileRow.id}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then(response => response.blob())
        .then(function (b) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(b);
          fileReader.onloadend = async () => {
            if (fileReader.result) {
              const link = document.createElement('a');
              link.href = fileReader.result.toString();
              link.setAttribute('download', downloadFileRow.file_name);
              document.body.appendChild(link);
              link.click();
            }
          };
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Popup
      title={'Inquiry'}
      width={1000}
      height={820}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup();
      }}
    >
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected required Document?'
      />
      <AddCustomFile
        showAddCustomFilePopup={showAddCustomFilePopup}
        hideAddCustomFilePopup={hideAddCustomFilePopup}
        selInquiryId={selInquiryId}
        selPartnerId={selPartnerid}
      />
      <ResponsiveBox>
        <Row ratio={1} />
        <Col />
        <Row ratio={0.1} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <ResponsiveBox>
            <Row ratio={1} />
            <Col ratio={1} />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <div>
                <Form
                  ref={noteFormRef}
                  formData={notedata}
                  labelLocation={'left'}
                  colCountByScreen={colCountByScreen}
                  width={'480px'}
                >
                  <Item itemType='group' colCount={1}>
                    <SimpleItem dataField='notesubject'>
                      <Label text='Subject' />
                    </SimpleItem>
                  </Item>
                  <EmptyItem />
                  <Item itemType='group' colCount={1}>
                    <SimpleItem dataField='notetext' editorType='dxTextArea' editorOptions={{ height: 100 }}>
                      <Label text='Message' />
                    </SimpleItem>
                  </Item>
                </Form>
                <p>
                  <b>Document Templates</b>
                </p>
                <ResponsiveBox>
                  <Row ratio={0.1} />
                  <Col ratio={0.6} />
                  <Col ratio={0.2} />
                  <Col ratio={0.26} />
                  <Row ratio={0.1} />
                  <Row ratio={0.1} />
                  <Row ratio={0.1} />
                  <Row ratio={0.1} />
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={0} />
                    <div style={{ flexDirection: 'row' }}>
                      <div>
                        <SelectBox
                          dataSource={stReqDocData}
                          displayExpr={'usage_advice'}
                          valueExpr='id'
                          width={'85%'}
                          onSelectionChanged={e => {
                            setSelectedAddFile(e.selectedItem);
                          }}
                        />
                      </div>
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={1} />
                    <div>
                      <Button
                        width={75}
                        height={36}
                        text='Add'
                        type='success'
                        stylingMode='contained'
                        onClick={() => {
                          saveDocumentThread();
                        }}
                      />
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={2} />
                    <div>
                      <Button
                        width={110}
                        height={36}
                        text='Custom'
                        type='normal'
                        stylingMode='contained'
                        onClick={() => {
                          AddCustomFilePopup();
                        }}
                      />
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={1} col={0} />
                    <div>
                      <DataGrid
                        id='req-doc-Grid'
                        dataSource={threadAttachedDocumentData}
                        width='480px'
                        height={'135px'}
                        showBorders={true}
                        showRowLines={true}
                        showColumnLines={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        onFocusedRowChanged={e => {
                          setRequiredDocData(e.row.data);
                        }}
                      >
                        <Column dataField='id' visible={false} />
                        <Column dataField='usage_advice' caption='Document Title' />
                        <Column dataField='file_name' caption='Required Document' />
                      </DataGrid>
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={2} col={0} />
                    <div>
                      <Button
                        width={210}
                        height={36}
                        text='Remove Selected'
                        type='danger'
                        stylingMode='contained'
                        onClick={removeReqDoc}
                      />
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={3} col={0} />
                    <div>
                      <p>
                        <b>Client Uploaded Files</b>
                      </p>
                      <DataGrid
                        id='req-doc-up-Grid'
                        dataSource={stReqDocUpload}
                        width='480px'
                        height={'135px'}
                        showBorders={true}
                        showRowLines={true}
                        showColumnLines={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        onFocusedRowChanged={e => {
                          setDownloadFileRow(e.row.data);
                        }}
                      >
                        <Column dataField='id' visible={false} />
                        <Column dataField='file_name' caption='File Name' />
                        <Column
                          alignment='center'
                          caption='Document Status'
                          dataField='status_id'
                          width={120}
                          cellRender={cellRenderDocumentStatus}
                        />
                      </DataGrid>
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={4} col={0} />
                    <div>
                      <Button
                        width={210}
                        height={36}
                        text='Download Selected'
                        type='normal'
                        stylingMode='contained'
                        onClick={() => {
                          downloadFile();
                        }}
                      />
                    </div>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <ResponsiveBox>
            <Row ratio={1} />
            <Row ratio={1} />
            <Item>
              <Location screen='md lg sm xs' row={0} />
              <div
                style={{
                  textAlign: 'end',
                  fontSize: '18px',
                  marginRight: '20px',
                }}
              >
                <b>Messaging</b>
              </div>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={1} />

              <ScrollView height={'100%'} width='100%' direction='vertical' useNative={true} showScrollbar='always'>
                <div>{notedata.chatId && <MessageThreadPage messageid={notedata.chatId} />}</div>
              </ScrollView>
            </Item>
          </ResponsiveBox>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={1} col={1} />
          <div id={'closebutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                style={{ marginRight: '5px' }}
                width={100}
                height={36}
                text='Save'
                type='default'
                stylingMode='contained'
                onClick={updateInquiry}
              />
              <Button
                width={100}
                height={36}
                text='close'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  hidePopup();
                }}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
      <Toast
        visible={toastConfigVisible}
        message='Choose a document template first.'
        type='error'
        displayTime={4000}
        onHiding={() => {
          setToastConfigVisible(false);
        }}
      />
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default InquiryEditPopup;
