import React, { useEffect, useState } from 'react';
import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

const getScreenGridHeight = () => {
  const anValue = window.innerHeight - 220;
  return anValue;
};

const CasesNews = props => {
  const { user, selPartnerPortal, retrieveNewsKey, reloadNewsGrid, reloadNewsGridCondition } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [casesNewsData, setCasesNewsData] = useState([]);
  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    const fetchCasesNews = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/cases/casesnews/${selPartnerPortal}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        await setCasesNewsData(dataArray);

        await delay(50);

        if (reloadNewsGrid === true) {
          await reloadNewsGridCondition(false);
        }
      } catch (err) {}
    };
    if (selPartnerPortal) {
      fetchCasesNews();
    }
  }, [sendRequest, reloadNewsGrid, selPartnerPortal]);

  const stCasesNews = new ArrayStore({
    key: 'entryid',
    data: casesNewsData,
  });

  const dcCasesNews = new DataSource({
    store: stCasesNews,
  });

  return (
    <React.Fragment>
      <div>
        <DataGrid
          id='cases-news-grid'
          height={getScreenGridHeight}
          dataSource={dcCasesNews}
          defaultFocusedRowIndex={0}
          showBorders={true}
          focusedRowEnabled={true}
          showRowLines={true}
          wordWrapEnabled={true}
          onFocusedRowChanged={e => {
            if (e.row) {
              retrieveNewsKey(e.row.data.case_entryid, e.row.data.clientid);
            }
          }}
        >
          <Column dataField='entryid' visible={false}></Column>
          <Column dataField='case_entryid' visible={false}></Column>
          <Column dataField='newsdata' caption='Notes' encodeHtml={false} dataType='string'></Column>
        </DataGrid>
      </div>
    </React.Fragment>
  );
};

export default CasesNews;
