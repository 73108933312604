import React, { useRef, useState, useEffect } from 'react';
import './GlobalDefinitions.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Form, SimpleItem, Label, ButtonItem } from 'devextreme-react/form';

const CRMActivities = (props) => {
  const { user } = props;
  const departmentDataGrid = useRef(null);
  const [activitiesGridData, setActivitiesGridData] = useState();
  const [assetDataMod, setAssetDataMod] = useState(false);
  const [cRMActivitiesList, setCRMActivitiesList] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const activitiesData = {
    activityid: activitiesGridData && activitiesGridData.activityid,
    activityname: activitiesGridData && activitiesGridData.activityname,
    activitystate: activitiesGridData && activitiesGridData.activitystate,
    activityaction: activitiesGridData && activitiesGridData.activityaction,
  };

  useEffect(() => {
    const fetchCRMActivities = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/crm-activities', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setCRMActivitiesList(dataArray);
      } catch (err) {}
    };

    fetchCRMActivities();
  }, [sendRequest, assetDataMod]);

  const stCRMActivitiesData = new ArrayStore({
    key: 'activityid',
    data: cRMActivitiesList,
  });

  const modActivities = async (modify, activitydata) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/base/mod-crm-activities',
        'POST',
        JSON.stringify({
          activityid: activitydata.activityid,
          activityname: activitydata.activityname,
          activitystate: activitydata.activitystate,
          activityaction: activitydata.activityaction,
          operation: modify,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setAssetDataMod(!assetDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='sd-header item'>
      <div className='dx-fieldset-header'>CRM Activities</div>

      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={4} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} colspan={3} />
          <div>
            <Form id={'form'} formData={activitiesData} labelLocation={'left'}>
              <Item itemType='group' colCount={12}>
                <SimpleItem dataField='activityid' colSpan={1}>
                  <Label location='left' text='ID' />
                </SimpleItem>
                <SimpleItem dataField='activityname' colSpan={3}>
                  <Label location='left' text='Name' />
                </SimpleItem>
                <SimpleItem dataField='activitystate' colSpan={4}>
                  <Label location='left' text='State' />
                </SimpleItem>
                <SimpleItem dataField='activityaction' colSpan={4}>
                  <Label location='left' text='Action' />
                </SimpleItem>
              </Item>
              <Item itemType='group' colCount={18}>
                <ButtonItem
                  horizontalAlignment='left'
                  colSpan={2}
                  buttonOptions={{
                    text: 'Add',
                    width: 120,
                    height: 36,
                    type: 'default',
                    stylingMode: 'outlined',
                    onClick: () => {
                      modActivities(1, activitiesData);
                    },
                  }}
                />
                <ButtonItem
                  horizontalAlignment='left'
                  colSpan={2}
                  buttonOptions={{
                    text: 'Mod',
                    width: 120,
                    height: 36,
                    type: 'success',
                    stylingMode: 'outlined',
                    onClick: () => {
                      modActivities(2, activitiesData);
                    },
                  }}
                />
                <ButtonItem
                  horizontalAlignment='left'
                  colSpan={2}
                  buttonOptions={{
                    text: 'Rem',
                    width: 120,
                    height: 36,
                    type: 'danger',
                    stylingMode: 'outlined',
                    onClick: () => {
                      modActivities(3, activitiesData);
                    },
                  }}
                />
              </Item>
              <Item>
                <Location screen='md lg sm xs' row={1} col={0} />
                <DataGrid
                  height={400}
                  ref={departmentDataGrid}
                  dataSource={stCRMActivitiesData}
                  showRowLines={true}
                  showBorders={true}
                  showColumnLines={true}
                  columnAutoWidth={true}
                  defaultFocusedRowIndex={0}
                  focusedRowEnabled={true}
                  showColumnHeaders={true}
                  onFocusedRowChanged={(e) => {
                    setActivitiesGridData(e.row.data);
                  }}
                  onSaved={(e) => {
                    if (e.changes[0]) {
                      modActivities(2, e.changes[0].data);
                    }
                  }}
                >
                  <Column dataField='activityid' visible={true} caption='ID' width='50' />
                  <Column dataField='activityname' caption='Name' width='200' />
                  <Column dataField='activitystate' caption='State' width='300' />
                  <Column dataField='activityaction' caption='Action' width='300' />

                  <Editing mode='row' allowUpdating={true} />
                </DataGrid>
              </Item>
            </Form>
          </div>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

export default CRMActivities;
