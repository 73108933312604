import React, { useState, useEffect } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import './services.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

const ServiceFeatures = props => {
  const { selSystemServiceID, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    const fetchSystemService = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/systemservicefeatures/${selSystemServiceID}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setServiceData(dataArray);
      } catch (err) {}
    };

    console.log(selSystemServiceID);

    if (selSystemServiceID) {
      fetchSystemService();
    }
  }, [sendRequest, selSystemServiceID]);

  const stServiceFeaturesData = new ArrayStore({
    key: ['serviceid', 'group_assetid', 'featureid'],
    data: serviceData,
  });

  const dcServiceFeaturesData = new DataSource({
    store: stServiceFeaturesData,
  });

  return (
    <ResponsiveBox>
      <Col />
      <Row />
      <Item>
        <Location screen='md lg sm xs' row={0} col={0} />
        <div>
          <DataGrid
            id='service-SystemServiceFeaturesGrid'
            // ref={userDataGrid}
            height={360}
            dataSource={dcServiceFeaturesData}
            showColumnHeaders={true}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            columnAutoWidth={true}
            // filterValue={['usefeature', '=', true]}
            //  defaultFocusedRowIndex={0}
            // focusedRowEnabled={true}
            // onFocusedRowChanged={e => {
            //   setCmpGrid(e.row.data);
            // }}
          >
            <Column dataField='serviceid' visible={false}></Column>
            <Column dataField='featureid' visible={false}></Column>
            <Column dataField='feature' caption='Features' width='200'></Column>
            <Column dataField='groupasset' caption='Group' width='120'></Column>
            <Column dataField='buysellasset_txt' caption='Asset' width='80px' visible={true}></Column>
            <Column dataField='buysellasset' visible={false}></Column>
            <Column dataField='sendreceiveasset' visible={false}></Column>
            <Column dataField='main_feature' dataType='boolean' caption='Service' width='90px' visible={true}></Column>
            <Column
              dataField='account_feature'
              dataType='boolean'
              caption='Account'
              width='90px'
              visible={true}
            ></Column>
            <Column dataField='buy' dataType='boolean' caption='Buy' width='70px' visible={true}></Column>
            <Column dataField='sell' dataType='boolean' caption='Sell' width='70px' visible={true}></Column>
            <Column dataField='list' dataType='boolean' caption='List' width='70px' visible={true}></Column>
            <Column dataField='send' dataType='boolean' caption='Send' width='70px' visible={true}></Column>
            <Column dataField='receive' dataType='boolean' caption='Receive' width='70px' visible={true}></Column>
            <Column dataField='usefeature' dataType='boolean' caption='Use' width='80px' visible={false}></Column>
          </DataGrid>
        </div>
      </Item>
    </ResponsiveBox>
  );
};

export default ServiceFeatures;
