import React, { useRef, useState, useEffect } from 'react';
import './GlobalDefinitions.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import Button from 'devextreme-react/button';

import PortalPop from './PortalPop';
import MessagePop from '../../ui_elements/MessagePop';
import HTTP from '../../api/HTTP';

const PortalsTab = (props) => {
  const { user } = props;
  const portalDataGrid = useRef(null);
  const [portalsDatalist, setPortalsDatalist] = useState([]);

  const [portalGridData, setPortalGridData] = useState();

  const [showPortalPopup, setShowPortalPopup] = useState(false);
  const [addPortal, setAddPortal] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [reloadPortalData, setReloadPortalData] = useState(false);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const portalData = {
    portalid: portalGridData && portalGridData.portalid,
    portaltype: portalGridData && portalGridData.portaltype,
    portalname: portalGridData && portalGridData.portalname,
    partnerid: portalGridData && portalGridData.partnerid ? portalGridData.partnerid : -1,
    providerid: portalGridData && portalGridData.providerid ? portalGridData.providerid : -1,
    personal_accounts: portalGridData && portalGridData.personal_accounts ? portalGridData.personal_accounts : true,
    business_accounts: portalGridData && portalGridData.business_accounts ? portalGridData.business_accounts : true,
    multi_account_users:
      portalGridData && portalGridData.multi_account_users ? portalGridData.multi_account_users : false,
    account_user_security:
      portalGridData && portalGridData.account_user_security ? portalGridData.account_user_security : false,
  };

  useEffect(() => {
    const fetchPortals = async () => {
      try {
        const dataArray = await HTTP.get('/management/portals');
        setPortalsDatalist(dataArray.data);
      } catch (err) {}
    };

    fetchPortals();
  }, [sendRequest, reloadPortalData]);

  const stPortalData = new ArrayStore({
    key: 'portalid',
    data: portalsDatalist,
  });

  const delPortal = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/management/modportal',
        'POST',
        JSON.stringify({
          portalid: portalData.portalid,
          operation: 3,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const addNewPortal = () => {
    setAddPortal(true);
    setShowPortalPopup(true);
  };

  const editExistPortal = () => {
    setAddPortal(false);
    setShowPortalPopup(true);
  };

  const removePortal = () => {
    setShowMessagePop(true);
  };

  const hidePortalPopup = () => {
    setShowPortalPopup(false);
    setReloadPortalData(!reloadPortalData);
  };

  const hideMessagePop = (doRemove) => {
    setShowMessagePop(false);
    if (doRemove) {
      delPortal();
      setReloadPortalData(!reloadPortalData);
    }
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected Feature?'
      />
      <PortalPop
        showPopup={showPortalPopup}
        hidePopup={hidePortalPopup}
        portalRowData={portalGridData}
        addPortal={addPortal}
        user={user}
      />
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Portals</div>
        <ResponsiveBox>
          <Row />
          <Col ratio={2} />
          <Col ratio={8} />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div id='hm-btn-section-0-1'>
              <Button width={100} height={36} text='Add' type='default' stylingMode='outlined' onClick={addNewPortal} />
            </div>
            <div id='hm-btn-section-0-11'>
              <Button
                width={100}
                height={36}
                text='Edit'
                type='success'
                stylingMode='outlined'
                onClick={editExistPortal}
              />
            </div>
            <div id='hm-btn-section-0-11'>
              <Button width={100} height={36} text='Rem' type='danger' stylingMode='outlined' onClick={removePortal} />
            </div>
          </Item>

          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <DataGrid
              // id='portal-data-grid'
              ref={portalDataGrid}
              height={440}
              dataSource={stPortalData}
              showRowLines={true}
              showBorders={true}
              showColumnLines={true}
              columnAutoWidth={true}
              defaultFocusedRowIndex={0}
              focusedRowEnabled={true}
              showColumnHeaders={true}
              onFocusedRowChanged={(e) => {
                setPortalGridData(e.row.data);
              }}
            >
              <Column dataField='portalid' visible={true} caption='ID' width='50' />
              <Column dataField='partnerid' visible={false} />
              <Column dataField='providerid' visible={false} />
              <Column dataField='partnertype' visible={false} />
              <Column dataField='portalname' caption='Name' width='150' />
              <Column dataField='portaltypetxt' caption='Type' width='150' />
              <Column dataField='partnername' caption='Merchant' width='120' />
              <Column dataField='providername' caption='Provider' width='120' />
              <Column dataField='personal_accounts' caption='Personal Accounts' dataType='boolean' width='120' />
              <Column dataField='business_accounts' caption='Business Accounts' dataType='boolean' width='120' />
              <Column dataField='multi_account_users' caption='Multiple Acct. Users' dataType='boolean' width='120' />
              <Column
                dataField='account_user_security'
                caption='Account User Security'
                dataType='boolean'
                width='120'
              />
            </DataGrid>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default PortalsTab;
