import HTTP from "../../api/HTTP";

class CalendarHelper {
  getEvents = queryStringData => {
    return HTTP.get("/calendar/events", {
      params: queryStringData
    }).then(response => response.data);
  };

  saveEvent = eventData => {
    if (eventData.id === undefined) {
      return HTTP.post("calendar/events", eventData).then(
        response => response.data
      );
    } else {
      return HTTP.put(`calendar/events/${eventData.id}`, eventData).then(
        response => response.data
      );
    }
  };

  getEventDetails = eventId => {
    return HTTP.get(`calendar/events/${eventId}`).then(
      response => response.data
    );
  };

  deleteEvent = eventId => {
    return HTTP.delete(`calendar/events/${eventId}`).then(
      response => response.data
    );
  };

  getEventTypes = () => {
    return HTTP.get(`calendar/events/types`).then(response => response.data);
  };

  getEventMeetingUrl = () => {
    return HTTP.get(`calendar/events/meeting-url`).then(
      response => response.data
    );
  };

  getAttendees = queryStringData => {
    return HTTP.get(`calendar/events/possible-attendees`, {
      params: queryStringData
    }).then(response => response.data);
  };

  getEventTypeReminders = () => {
    return HTTP.get(`calendar/events/reminders`).then(
      response => response.data
    );
  };
}

export default new CalendarHelper();
