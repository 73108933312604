import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import './ComplianceKYC.css';
import 'devextreme/dist/css/dx.light.css';

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';
import DataGrid, { Column, Lookup, Editing } from 'devextreme-react/data-grid';

import { useAuth } from '../../contexts/auth';

import ComplianceIframe from './ComplianceIframe.js';

const ComplianceKYCPage = () => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const src = 'https://cockpit.sumsub.com/';

  useEffect(() => {}, [user]);

  return (
    <div className={'content-block'}>
      <div className={'dx-card wide-card p-4'}>
       {/* <ComplianceIframe source={src} /> */} 
      </div>
    </div>
  );
};

export default ComplianceKYCPage;
