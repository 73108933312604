import React, { useState, useEffect } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, MasterDetail } from 'devextreme-react/data-grid';

import { Form, SimpleItem, Label } from 'devextreme-react/form';

import ServiceFeeGridDetails from '../../detailgrids/ServiceFeeGridDetails';

const ServiceCostRates = props => {
  const { selServiceId, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [costRateHeaderList, setCostRateHeaderList] = useState({});
  const [costRiskEntriesList, setCostRiskEntriesList] = useState([]);

  const FormatDateString = anDateString => {
    const anDate = anDateString
      .slice(0, 10)
      // .replace(/-/g, "/")
      .replace('T', ' ');
    return anDate;
  };

  useEffect(() => {
    const fetchCostRatesHeader = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/singleservicecostrateheader/${selServiceId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCostRateHeaderList(dataArray[0]);

        let anCostEntryId = -1;

        if (dataArray[0].costentryid) {
          anCostEntryId = dataArray[0].costentryid;
        }

        const costRiskDataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/viewcostriskentries/${anCostEntryId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );

        setCostRiskEntriesList(costRiskDataArray);
      } catch (err) {}
    };

    if (selServiceId && selServiceId > -1) {
      fetchCostRatesHeader();
    }
  }, [sendRequest, selServiceId]);

  const costRateHeader = {
    serviceid: (costRateHeaderList && costRateHeaderList.serviceid) || -1,
    costentryid: (costRateHeaderList && costRateHeaderList.costentryid) || -1,
    generated: costRateHeaderList && costRateHeaderList.generated,
    valid_on: costRateHeaderList && costRateHeaderList.valid_on,
    valid_until: costRateHeaderList && costRateHeaderList.valid_until,
    currency: (costRateHeaderList && costRateHeaderList.currency) || '',
    entrynumber: (costRateHeaderList && costRateHeaderList.entrynumber) || '',
    comment: (costRateHeaderList && costRateHeaderList.comment) || '',
    userid: (costRateHeaderList && costRateHeaderList.userid) || -1,
    active: (costRateHeaderList && costRateHeaderList.active) || false,
    active_date: costRateHeaderList && costRateHeaderList.active_date,
  };

  const stCostRiskEntriesData = new ArrayStore({
    key: ['riskid'],
    data: costRiskEntriesList,
  });

  const dcCostRiskEntriesData = new DataSource({
    store: stCostRiskEntriesData,
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />

          <Form
            id='service-costrates'
            formData={costRateHeader}
            labelLocation={'left'}
            colCountByScreen={colCountByScreen}
            scrollingEnabled={true}
            readOnly={true}
          >
            <Item>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td width='60'>Currency</td>
                    <td width='140'>
                      <b>{costRateHeader.currency}</b>
                    </td>
                    <td width='60'>Valid on </td>
                    <td width='140'>
                      <b>{costRateHeader.valid_on && FormatDateString(costRateHeader.valid_on)}</b>
                    </td>
                    <td width='80'>Valid until </td>
                    <td width='140'>
                      <b>{costRateHeader.valid_until && FormatDateString(costRateHeader.valid_until)}</b>
                    </td>
                    <td width='60'>Active </td>
                    <td width='140'>
                      <b>{costRateHeader.active && costRateHeader.active === true ? 'yes' : 'no'}</b>
                    </td>
                    <td width='60'>Since </td>
                    <td width='140'>
                      <b>{costRateHeader.active_date && FormatDateString(costRateHeader.valid_until)}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Item>
            <Item>
              <DataGrid
                // id={'services-cost-rates-grid'}
                dataSource={dcCostRiskEntriesData}
                height={300}
                // defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                allowColumnReordering={false}
                allowColumnResizing={false}
                focusedRowEnabled={true}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                autoNavigateToFocusedRow={true}
                // onFocusedRowChanged={e => {
                //   setRiskGridData(e.row.data);
                //   setSelRiskid(e.row.data.riskid);
                // }}
              >
                <Column dataField='serviceid' visible={false} />
                <Column dataField='costentryid' visible={false} />
                <Column dataField='riskid' visible={false} />
                <Column dataField='risklevel' caption='Risk Level' width='250px' allowEditing={false} />
                <Column dataField='rates_set' caption='Rates Set' width='90px' visible={false} />
                <Column dataField='not_applicable' caption='Not Applicable' width='120px' />
                <Column dataField='setupfee' caption='Setup Fee' dataType='number' width='120' />
                <Column dataField='recurringfee' caption='Service Fee' dataType='number' width='120' />
                <Column dataField='recperiod' caption='Recurring' width='200'></Column>
                <MasterDetail enabled={true} component={ServiceFeeGridDetails} />
              </DataGrid>
            </Item>
          </Form>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServiceCostRates;
