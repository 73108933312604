import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import './ClientsApprovedPage.css';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';

import Button from 'devextreme-react/button';

import InquiryEditPopup from '../../ui_elements/InquiryEditPopup';
import { useHistory } from 'react-router-dom';

const ClientsApprovedInquiry = props => {
  const { selSignupClientid, user } = props;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [inquiriesDataList, setInquiriesDatalist] = useState([]);
  const [notesKey, setNotesKey] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [reloadInquiries, setReloadInquiries] = useState(false);
  const [showInquiryPopup, setShowInquiryPopup] = useState(false);
  const [selInquiryid, setSelInquiryid] = useState(-1);
  const history = useHistory();

  useEffect(() => {
    const fetchInquiries = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/getclientinquiries/${selSignupClientid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setInquiriesDatalist(dataArray);
      } catch (err) {}
    };
    if (selSignupClientid) {
      //   fetchInquiries();
    }
  }, [sendRequest, selSignupClientid, reloadInquiries]);

  const stInquiriesDataList = new ArrayStore({
    key: 'inquiryid',
    data: inquiriesDataList,
  });

  const dcInquiriesDataList = new DataSource({
    store: stInquiriesDataList,
  });

  const OpenInquiry = () => {
    setShowInquiryPopup(true);
  };

  const AddInquiry = () => {
    // setShowPopup(true);
    history.push({
      pathname: '/addinquire',
      state: { inquiry: selInquiryid },
    });
  };

  const hidePopup = () => {
    setShowPopup(false);
    setReloadInquiries(!reloadInquiries);
  };

  const hideInquiryPopup = () => {
    setShowInquiryPopup(false);
  };

  return (
    <React.Fragment>
      <InquiryEditPopup
        showPopup={showInquiryPopup}
        hidePopup={hideInquiryPopup}
        user={user}
        selInquiryid={selInquiryid}
      />
      <ResponsiveBox>
        <Col ratio={0.3} screen='md lg sm xs' />
        <Col ratio={2} />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <p>Inquiry</p>
          </div>
          <div id='hm-btn-section-1'>
            <Button width={120} height={36} text='Add' type='default' disabled='true' onClick={AddInquiry} />
          </div>
          <div id='hm-btn-section-11'>
            <Button width={120} height={36} text='Open' type='success' disabled='true' onClick={OpenInquiry} />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div>
            <DataGrid
              id='client-inquiry-Grid'
              dataSource={dcInquiriesDataList}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              wordWrapEnabled={true}
              showRowLines={false}
              showColumnHeaders={true}
              onFocusedRowChanged={e => {
                e && setSelInquiryid(e.row.key);
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='inquiryid' visible={true} width={100} caption='ID'></Column>
              <Column dataField='portalid' visible={false}></Column>
              <Column dataField='inquirytype' width={100} caption='Type' visible={true}></Column>
              <Column dataField='inquirystate' width={100} caption='State' visible={true}></Column>
              <Column dataField='inquirydate' caption='Date' dataType='datetime' width={120} visible={true}></Column>
              <Column dataField='subject' alignment='left' caption='Subject' width={300} visible={true}></Column>
              <Column dataField='inquirytext' encodeHtml={false} caption='Inquiry' dataType='string'></Column>
              <Column dataField='init_by_id' visible={true} caption='Initiated by' width={120} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientsApprovedInquiry;
