import React, { useEffect, useState } from 'react';
import 'devextreme/dist/css/dx.light.css';

import { useHttpClient } from '../../../hooks/http-hook';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';
import DataGrid, { Column, Lookup, Editing } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import { Form, SimpleItem, EmptyItem, Label, ButtonItem, ButtonOptions } from 'devextreme-react/form';

export default function MatrixData(props) {
  const { modelid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [lpRiskResults, setLpRiskResults] = useState([]);
  const [lpNeuronalDataTypes, setLpNeuronalDataTypes] = useState([]);

  const [matrixDataEntries, setMatrixDataEntries] = useState([]);
  const [reloadMatrixDataEntries, setReloadMatrixDataEntries] = useState(true);
  const [matrixDataEntriesEntryid, setMatrixDataEntriesEntryid] = useState(0);
  const [matrixDataEntryFocusedRow, setMatrixDataEntryFocusedRow] = useState();

  const [matrixDataFeatures, setMatrixDataFeatures] = useState([]);
  const [reloadMatrixDataFeatures, setReloadMatrixDataFeatures] = useState(false);

  const [lpMatrixFeatures, setLpMatrixFeatures] = useState([]);
  const [selFeatureId, setSelFeatureId] = useState();
  const [featureRowData, setFeatureRowData] = useState();

  useEffect(() => {
    const fetchRiskResults = async () => {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/33', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpRiskResults(dataArray);
    };

    const fetchNeuronalDataTypes = async () => {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/34', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpNeuronalDataTypes(dataArray);
    };

    fetchRiskResults();
    fetchNeuronalDataTypes();
  }, []);

  const stRiskResults = new ArrayStore({
    key: 'lp_id',
    data: lpRiskResults,
  });

  const stNeuronalDataTypes = new ArrayStore({
    key: 'lp_id',
    data: lpNeuronalDataTypes,
  });

  useEffect(() => {
    const fetchMatrixDataEntries = async () => {
      const response = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/ai/matrix-data/matrix-data-entries/${modelid}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + user.token,
        }
      );
      setMatrixDataEntries(response);
      setReloadMatrixDataEntries(false);
    };
    if (modelid || reloadMatrixDataEntries) {
      fetchMatrixDataEntries();
    }
  }, [reloadMatrixDataEntries, modelid]);

  useEffect(() => {
    const fetchMatrixDataFeatures = async () => {
      const response = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/ai/matrix-data/matrix-data-features/${modelid}/${matrixDataEntriesEntryid}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + user.token,
        }
      );
      setMatrixDataFeatures(response);
      setReloadMatrixDataFeatures(false);
    };
    if (modelid || matrixDataEntriesEntryid || reloadMatrixDataFeatures) {
      fetchMatrixDataFeatures();
    }
  }, [matrixDataEntriesEntryid, reloadMatrixDataFeatures, modelid]);

  useEffect(() => {
    const fetchMatrixFeatures = async () => {
      const response = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/ai/matrix-data/matrix-features/${modelid}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + user.token,
        }
      );
      setLpMatrixFeatures(response);
    };
    fetchMatrixFeatures();
  }, [modelid]);

  const stMatrixFeatures = new ArrayStore({
    key: 'group_entryid',
    data: lpMatrixFeatures,
  });

  const dcMatrixFeatures = new DataSource({
    store: stMatrixFeatures,
    filter: selFeatureId && selFeatureId ? ['risk_groupid', '=', selFeatureId] : null,
  });

  const martrixData = {
    entryid: matrixDataEntryFocusedRow && matrixDataEntryFocusedRow.entryid ? matrixDataEntryFocusedRow.entryid : -1,
    entryname:
      matrixDataEntryFocusedRow && matrixDataEntryFocusedRow.entryname ? matrixDataEntryFocusedRow.entryname : '',
    entryresultid:
      matrixDataEntryFocusedRow && matrixDataEntryFocusedRow.entryresultid
        ? matrixDataEntryFocusedRow.entryresultid
        : -1,
    entrydatatype:
      matrixDataEntryFocusedRow && matrixDataEntryFocusedRow.entrydatatype
        ? matrixDataEntryFocusedRow.entrydatatype
        : -1,
  };

  const martrixFeaturesData = {
    entryid: featureRowData && featureRowData.entryid ? featureRowData.entryid : -1,
    featureid: featureRowData && featureRowData.featureid ? featureRowData.featureid : -1,
    featureName: featureRowData && featureRowData.risk_name ? featureRowData.risk_name : '',
    featurevalueid: featureRowData && featureRowData.featurevalueid ? featureRowData.featurevalueid : -1,
  };

  const modMatrixDataEntry = async (modify, mdata) => {
    console.log(martrixData);
    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + '/ai/matrix-data/mod_matrix_data',
      'POST',
      JSON.stringify({
        operation: modify,
        modelid: modelid,
        entryid: mdata.entryid,
        entryname: mdata.entryname,
        entryresultid: mdata.entryresultid,
        entrydatatype: mdata.entrydatatype,
        clientid: '',
      }),
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    );
    console.log(response);
    setReloadMatrixDataEntries(!reloadMatrixDataEntries);
  };

  const modMatrixFeatureDataEntry = async () => {
    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + '/ai/matrix-data/mod_matrix_feature_data',
      'POST',
      JSON.stringify({
        modelid: modelid,
        entryid: martrixFeaturesData.entryid,
        featureid: martrixFeaturesData.featureid,
        featurevalueid: martrixFeaturesData.featurevalueid,
      }),
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    );
    console.log(response);
    setReloadMatrixDataFeatures(true);
  };

  return (
    <React.Fragment>
      <div>
        <h4 className='card-heading'>Matrix Data</h4>
      </div>
      <ResponsiveBox>
        <Row ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <div>
            <p>
              <span className='fw-bold'>Data Entry</span>
            </p>
          </div>
          <Form formData={martrixData} labelLocation={'left'}>
            <Item itemType='group' colCount={15}>
              <SimpleItem
                dataField='entryname'
                colSpan={3}
                editorOptions={{
                  
                }}
              >
                <Label location='left' text='Entry' />
              </SimpleItem>
              <SimpleItem
                dataField='entryresultid'
                editorType='dxSelectBox'
                colSpan={3}
                name='clienttype_listbox'
                editorOptions={{
                  dataSource: stRiskResults,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                  
                }}
              >
                <Label text='Result' />
              </SimpleItem>

              <SimpleItem
                dataField='entrydatatype'
                editorType='dxSelectBox'
                colSpan={3}
                name='clienttype_listbox'
                editorOptions={{
                  dataSource: stNeuronalDataTypes,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                  
                }}
              >
                <Label text='D-Type' />
              </SimpleItem>

              <ButtonItem colSpan={2} horizontalAlignment='left'>
                <ButtonOptions
                  width='150px'
                  height='100%'
                  type='default'
                  text='Add'
                  stylingMode='outlined'
                  onClick={() => {
                    modMatrixDataEntry(1, martrixData);
                  }}
                ></ButtonOptions>
              </ButtonItem>
              <ButtonItem colSpan={2} horizontalAlignment='left'>
                <ButtonOptions
                  width='150px'
                  height='100%'
                  type='success'
                  text='Save'
                  stylingMode='outlined'
                  onClick={() => {
                    modMatrixDataEntry(2, martrixData);
                  }}
                ></ButtonOptions>
              </ButtonItem>
              <ButtonItem colSpan={2} horizontalAlignment='left'>
                <ButtonOptions
                  width='150px'
                  height='100%'
                  type='danger'
                  text='REM'
                  stylingMode='outlined'
                  onClick={() => {
                    modMatrixDataEntry(3, martrixData);
                  }}
                ></ButtonOptions>
              </ButtonItem>
            </Item>
          </Form>
        </Item>
      </ResponsiveBox>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={9} />
        <Col ratio={1} />
        <Col ratio={1} />

        <Item>
          <Location screen='md lg xs sm' row={1} col={0} />
          <DataGrid
            id='Matrix-Data-Entry-Grid'
            dataSource={matrixDataEntries}
            keyExpr={'entryid'}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            columnAutoWidth={true}
            focusStateEnabled={true}
            defaultFocusedRowIndex={0}
            focusedRowKey={matrixDataEntriesEntryid}
            focusedRowEnabled={true}
            onSaved={e => {
              if (e.changes[0]) {
                modMatrixDataEntry(2, e.changes[0].data);
              }
            }}
            onFocusedRowChanged={e => {
              if (e.row) {
                setMatrixDataEntriesEntryid(e.row.key);
                setMatrixDataEntryFocusedRow(e.row.data);
              }
            }}
          >
            <Column dataField='entryid' visible={false} />
            <Column dataField='entryname' visible={true} caption='Data Entry' width='240px' />
            <Column dataField='entryresultid' visible={true} caption='Result' width='140px'>
              <Lookup dataSource={stRiskResults} displayExpr='lp_value' valueExpr='lp_id' />
            </Column>
            <Column dataField='entrydatatype' visible={true} caption='D-Type' width='140px'>
              <Lookup dataSource={stNeuronalDataTypes} displayExpr='lp_value' valueExpr='lp_id' />
            </Column>
            <Editing mode='row' allowUpdating={true} />
          </DataGrid>
        </Item>

        <Item>
          <Location screen='md lg xs sm' row={1} col={1} />
          <div id='ai-form-sub'>
            <div>
              <p>
                <span className='fw-bold'>Features</span>
              </p>
            </div>
            <Form formData={martrixFeaturesData} labelLocation={'left'}>
              <Item itemType='group' colCount={12}>
                <SimpleItem
                  dataField='featureName'
                  colSpan={4}
                  editorOptions={{
                    readOnly: true,
                    
                  }}
                >
                  <Label location='left' text='Entry' visible={false} />
                </SimpleItem>
                <SimpleItem
                  dataField='featurevalueid'
                  editorType='dxSelectBox'
                  colSpan={6}
                  name='clienttype_listbox'
                  editorOptions={{
                    dataSource: dcMatrixFeatures,
                    valueExpr: 'group_entryid',
                    displayExpr: 'group_entry',
                    searchEnabled: true,
                    
                  }}
                >
                  <Label text='Value' />
                </SimpleItem>

                <ButtonItem colSpan={2} horizontalAlignment='left'>
                  <ButtonOptions
                    width='150px'
                    height='100%'
                    type='success'
                    text='Save'
                    stylingMode='outlined'
                    onClick={modMatrixFeatureDataEntry}
                  ></ButtonOptions>
                </ButtonItem>
              </Item>
              <Item>
                <DataGrid
                  id='Matrix-Data-Feature-Grid'
                  dataSource={matrixDataFeatures}
                  keyExpr={'featureid'}
                  showBorders={true}
                  showRowLines={true}
                  showColumnLines={true}
                  columnAutoWidth={true}
                  focusStateEnabled={true}
                  defaultFocusedRowIndex={0}
                  focusedRowKey={selFeatureId}
                  focusedRowEnabled={true}
                  onFocusedRowChanged={e => {
                    if (e.row) {
                      setSelFeatureId(e.row.key);
                      setFeatureRowData(e.row.data);
                      console.log(e.row.data);
                    }
                  }}
                >
                  <Column dataField='entryid' visible={false} />
                  <Column dataField='featureid' visible={false} />
                  <Column dataField='featurevalueid' visible={false} />
                  <Column dataField='risk_name' visible={true} caption='Feature' width='280px' />
                  <Column dataField='group_entry' visible={true} caption='Value' width='200px'></Column>
                  <Column dataField='matrixvalue' visible={true} caption='Score' width='80px'></Column>
                </DataGrid>
              </Item>
            </Form>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
}
