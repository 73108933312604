import React, { useEffect, useRef, useState } from "react";
import { Button } from "devextreme-react";
import paginator from "../../../modules/paginator/Paginator";
import { Link, withRouter } from "react-router-dom";
import InternalMessagingHelper from "./InternalMessagingHelper";
import DataGrid, { Column, Pager } from "devextreme-react/data-grid";
import ThreadsFilter from "./ThreadsFilter";

function ThreadsList(props) {
  let searchTimeout = useRef();
  let dataGridRef = null;

  const initGrid = (clientId, queryStringData) => {
    return paginator(
      InternalMessagingHelper.getThreads,
      [clientId],
      "id",
      10,
      queryStringData
    );
  };

  const [searchText, setSearchText] = useState("");
  const [threadsDataList, setThreadsDataList] = useState();

  useEffect(() => {
    setThreadsDataList(initGrid(props.clientId));
  }, [props.clientId]);

  const goToThread = threadId => {
    props.history.push(`/messages/thread/${threadId}`);
  };

  const renderActionCell = cellData => {
    return (
      <>
        <Button
          text="Messages"
          type="normal"
          stylingMode="outlined"
          onClick={() => {
            goToThread(cellData.data.id);
          }}
        />
      </>
    );
  };

  const handleChangeSearchText = value => {
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setInterval(() => {
      setSearchText(value);
    }, 1000);
  };

  const renderClientName = data => {
    const persons = data.data.persons;
    let client;
    if (persons) {
      client = persons.find(person => person.customerType === "CLIENT");
    }

    if (client) {
      return (
        <Link
          to={`/clients/${client.customerId}`}
          title={"Go to Client's Threads"}
        >
          {`${client.firstName} ${client.lastName}`}
        </Link>
      );
    }
    return <></>;
  };

  const handleFilterAction = filterFormData => {
    if (filterFormData) {
      setThreadsDataList(
        initGrid(props.clientId || filterFormData.clientId, {
          startDate: filterFormData.startDate,
          endDate: filterFormData.endDate
        })
      );
    } else {
      setThreadsDataList(initGrid(props.clientId));
    }
  };

  return (
    <React.Fragment>
      <div className="content">
        <div className={"row-flex-space-between mt-4"}>
          <ThreadsFilter
            clientId={props.clientId}
            handleSubmitAction={handleFilterAction}
          />
        </div>
        <div className={"mt-4"}>
          <DataGrid
            ref={dataGridRef}
            id="rep-transactions-notes-grid"
            dataSource={threadsDataList}
            columnAutoWidth={true}
            allowColumnReordering={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            focusedRowEnabled={false}
            showBorders={true}
            wordWrapEnabled={true}
            showRowLines={true}
            showColumnHeaders={true}
            remoteOperations={true}
          >
            <Pager showPageSizeSelector={true} showInfo={true} />
            <Column dataField="id" />
            {props.history.location.pathname == "/messages" ? (
              <Column
                encodeHtml={false}
                caption="Client Name"
                dataType="string"
                width={"100%"}
                cellRender={renderClientName}
              />
            ) : null}
            <Column
              dataField="theme"
              encodeHtml={false}
              caption="Theme"
              dataType="string"
              width={"100%"}
            />
            <Column
              dataField="createdAt"
              encodeHtml={false}
              caption="Start at"
              dataType="date"
              width={"100%"}
              // format="dd.MM.yyyy"
            />
            <Column
              width={200}
              // caption="Action"
              cellRender={renderActionCell}
            />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(ThreadsList);
