import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "devextreme-react/button";

import DateBox from "devextreme-react/date-box";

import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form";

import { downloadFileFromBuffer } from "../../utils/attachesHelper";
import notify from "devextreme/ui/notify";
import { MailingGetAccount, MailingGetEmail } from "./components/MailingHelper";

function ReviewEmailPage(props) {
  const [emailId, setEmailId] = useState(props.match.params.emailId);
  const [formData, setFormData] = useState({
    id: null,
    mailingAccountId: null,
    recipients: [],
    subject: "",
    body: "",
    status: "",
    attaches: [],
    createdOn: "",
    updatedOn: ""
  });

  const [mailingAccountName, setMailingAccountName] = useState("");

  useEffect(() => {
    MailingGetEmail(emailId).then(emailData => {
      console.log("emailData", emailData);

      MailingGetAccount(emailData.mailingAccountId).then(accountData => {
        setFormData(emailData);
        setMailingAccountName(
          `${accountData.fromName}<${accountData.fromEmail}>`
        );
      });
    });
  }, []);

  const handleBackButton = () => {
    props.history.push("/mailing");
  };

  const handleDownloadFile = attachId => {
    const attach = formData.attaches.find(attach => attach.id === attachId);
    if (attach) {
      const fileBuffer = attach.content.data;
      downloadFileFromBuffer(fileBuffer, attach.fileName);
    } else {
      notify("An error occurred while downloading the file.", "error", 2000);
    }
  };

  const renderRecipients = () => {
    let ss = formData.recipients.map((fieldSet, index) => (
      <div className="dx-field" key={"recipient-" + index}>
        <div className="dx-field-label mailing-label">
          {fieldSet.type + ":"}
        </div>
        <div className="dx-field-value">
          <div className="row-flex-space-between">{fieldSet.address}</div>
        </div>
      </div>
    ));
    console.log(ss);
    return ss;
  };

  return (
    <>
      <h2 className={"content-block"}>Review Email</h2>
      <div className={"content-block"}>
        <div className="dx-card wide-card p-4">
          <Button
            width={180}
            height={35}
            icon={"back"}
            type={"default"}
            className={"dx-shape-standard mb-4"}
            text={"Back to Emails"}
            stylingMode="outlined"
            onClick={handleBackButton}
          />

          <Form
            className={"mt-4"}
            formData={formData}
            labelLocation={"left"}
            validationGroup={"formValidationGroup"}
          >
            <GroupItem colCount={2}>
              <GroupItem>
                <GroupItem colCount={8}>
                  <div className="dx-field">
                    <div className="dx-field-label mailing-label">ACCOUNT</div>
                    <div className="dx-field-value">{mailingAccountName}</div>
                  </div>
                </GroupItem>
                <GroupItem colCount={10}>
                  <div>{renderRecipients()}</div>
                </GroupItem>
                <GroupItem colCount={10}>
                  <div className="dx-field">
                    <div className="dx-field-label mailing-label">SUBJECT</div>
                    <div className="dx-field-value">{formData.subject}</div>
                  </div>
                </GroupItem>
              </GroupItem>

              <GroupItem>
                <GroupItem>
                  <div
                    className="dx-field"
                    style={{
                      justifyContent: "flex-end",
                      paddingBottom: "10px"
                    }}
                  >
                    <div className="dx-field-label mailing-label">DATE:</div>
                    <div className="dx-field-value">
                      <DateBox
                        value={formData.createdOn}
                        readOnly={true}
                        displayFormat="dd.MM.yyyy HH.mm"
                      />
                    </div>
                  </div>
                </GroupItem>
              </GroupItem>
            </GroupItem>

            <SimpleItem
              cssClass={"mt-4"}
              dataField={"body"}
              editorType={"dxHtmlEditor"}
              editorOptions={{
                height: 400,
                readOnly: true
              }}
            >
              <Label text="BODY" location="top" />
            </SimpleItem>

            <GroupItem>
              <div className="dx-field">
                <div className="dx-field-label mailing-label">Attachments:</div>
                <div
                  className="dx-field-value"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {formData?.attaches?.map((file, index) => (
                    <a
                      className={"p-1"}
                      onClick={e => {
                        e.preventDefault();
                        handleDownloadFile(file.id);
                      }}
                      key={"download" + index}
                      href="#"
                      download
                    >
                      {file.fileName}
                    </a>
                  ))}
                </div>
              </div>
            </GroupItem>
          </Form>
        </div>
      </div>
    </>
  );
}

export default withRouter(ReviewEmailPage);
