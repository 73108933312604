import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import { RequiredRule } from "devextreme-react/validator";
import {
  ButtonItem,
  ButtonOptions,
  Form,
  Label,
  SimpleItem
} from "devextreme-react/form";
import { htmlEditorToolbarConfig } from "../../utils/htmlEditorToolbarConfig";
import LoadIndicator from "devextreme-react/load-indicator";
import {
  MailingGetTemplate,
  MailingSaveTemplate
} from "./components/MailingHelper";
import {useAuth} from '../../contexts/auth';

function TemplateFormPage(props) {
  const { user: currentUser } = useAuth();
  const [template, setTemplate] = useState({
    id: null,
    providerId: undefined,
    title: "",
    body: ""
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.match.params.templateId !== undefined) {
      MailingGetTemplate(props.match.params.templateId).then(data => {
        setTemplate(data);
      });
    }
  }, []);

  const handleBackButton = () => {
    props.history.push({ pathname: "/mailing", state: "1" });
  };

  const handleSubmit = data => {
    const validate = data.validationGroup.validate();
    if (validate.isValid) {
      setLoading(true);

      const formData = {
        id: template.id,
        providerId: template.providerId || currentUser.providerId,
        title: template.title,
        body: template.body
      };

      MailingSaveTemplate(formData)
        .then(data => {
          setTemplate(data);
          notify("The template is created", "success", 2000);
        })
        .catch(() => {
          notify("An error occurred while sending the data.", "error", 2000);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notify("Please complete all required fields", "error", 2000);
    }
  };

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Mailing Template</h2>
      <div className={"content-block"}>
        <div className="dx-card wide-card p-4 ">
          <Button
            width={200}
            height={35}
            icon={"back"}
            type={"default"}
            className={"dx-shape-standard mb-4"}
            text={"Back to templates"}
            stylingMode="outlined"
            onClick={handleBackButton}
          />

          <Form className={"mt-4"} formData={template} labelLocation={"left"}>
            <SimpleItem dataField={"title"}>
              <RequiredRule message="Required field" />
            </SimpleItem>
            <SimpleItem
              dataField={"body"}
              editorType={"dxHtmlEditor"}
              editorOptions={{
                height: 400,
                toolbar: htmlEditorToolbarConfig
              }}
            >
              <RequiredRule message="Required field" />
              <Label text="TEMPLATE" location="top" />
            </SimpleItem>
            <ButtonItem cssClass={"mt-4"}>
              <ButtonOptions
                disabled={loading}
                onClick={handleSubmit}
                width={180}
                height={35}
                type={"success"}
                stylingMode="contained"
              >
                <span className="dx-button-text">
                  {loading ? (
                    <LoadIndicator
                      width={"20px"}
                      height={"20px"}
                      visible={true}
                    />
                  ) : (
                    "Save"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(TemplateFormPage);
