import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import './GlobalDefinitions.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';
import PortalsTab from './PortalsTab';
import PortalsUserTab from './PortalUserTab';
import RegionsTab from './RegionsTab';
import BankaccountsTab from './BankaccountsTab';
import SystemSectionTab from './SystemSectionTab';
import ServiceFeaturesPage from './ServiceFeaturesPage';
import ServiceAssetsTab from './ServiceAssetsTab';
import LookupTab from './LookupTab';
import MailTemplatesTab from './MailTemplatesTab';
import OpTemplatesTab from './OpTemplatesTab';
import OpsSettingsTab from './TestSettings';
import CRMActivities from './CRMActivities';
import AutomatedSituations from './AutomatedSituations';

const GlobalDefinitionsPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line1-left'>
                <PortalsTab user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line1-right'>
                <PortalsUserTab user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={1} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line2'>
                <RegionsTab user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line3'>
                <BankaccountsTab user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={3} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line4'>
                <SystemSectionTab user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={4} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line5'>
                <ServiceFeaturesPage user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={5} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line5'>
                <ServiceAssetsTab user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={6} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line5'>
                <LookupTab user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={7} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line5'>
                <CRMActivities user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={8} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line8'>
                <OpTemplatesTab user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={9} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line8'>
                <AutomatedSituations user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={10} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line8'>
                <MailTemplatesTab user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={11} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='global-defs-box-line5'>
                <OpsSettingsTab user={user} />
              </div>
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default GlobalDefinitionsPage;
