import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { ArgumentAxis, Chart, Series, ValueAxis } from 'devextreme-react/chart';

const DashProducts = props => {
  const { selStartDate, selEndDate, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [dashProductData, setDashProductData] = useState([]);

  useEffect(() => {
    const fetchDashRev = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/rep/dashproducts/${selStartDate}/${selEndDate}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setDashProductData(dataArray);
      } catch (err) {}
    };
    fetchDashRev();
  }, [sendRequest]);

  const stDashProductData = new ArrayStore({
    key: 'productname',
    data: dashProductData,
  });

  const dcDashProductData = new DataSource({
    store: stDashProductData,
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />

        <Item></Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='dash-area-product'>
            <Chart id='dash-product-chart' dataSource={dcDashProductData} rotated={true}>
              <Series valueField='revenue' argumentField='productname' name='Transactions' type='bar' color='#aa181f' />
              <ArgumentAxis title='Products' />
              <ValueAxis title='EUR' />
            </Chart>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default DashProducts;
