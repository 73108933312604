import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import './PartnerPage.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

const PartnerServices = props => {
  const { user, selPartnerId } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [servicesList, setServicesList] = useState([]);
  const [sysServiceID, setSysServiceID] = useState(-1);
  const [selServiceData, setSelServiceData] = useState({});

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/partnersystemservices/${selPartnerId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setServicesList(dataArray);
      } catch (err) {}
    };
    if (selPartnerId) {
      fetchServices();
    }
  }, [sendRequest, selPartnerId]);

  const stServicesData = new ArrayStore({
    key: 'serviceid',
    data: servicesList,
  });

  const dcServicesData = new DataSource({
    store: stServicesData,
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col ratio={0.3} screen='md lg sm' />
        <Col ratio={3} />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} colspan={2} />
          <div>
            <DataGrid
              id='partner-services-tab-grid'
              dataSource={dcServicesData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={e => {
                if (e.row) {
                  setSysServiceID(e.row.key);
                  setSelServiceData(e.row.data);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='serviceid' visible={false}></Column>
              <Column dataField='categoryid' visible={false}></Column>
              <Column dataField='providerid' visible={false}></Column>
              <Column dataField='servicename' caption='Service' width={200} />
              <Column dataField='category' caption='Category' width={120} />
              <Column dataField='providername' caption='Provider' width={180} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default PartnerServices;
