import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useHttpClient } from "../../hooks/http-hook";
import { useAuth } from "../../contexts/auth";

import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import Button from "devextreme-react/button";
import "./costrateservices.css";

import "devextreme-react/switch";

import Tabs from "devextreme-react/tabs";

import {
  Form,
  SimpleItem,
  EmptyItem,
  Label,
  RequiredRule,
  ButtonItem,
} from "devextreme-react/form";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

import DataGrid, {
  Scrolling,
  Column,
  FilterRow,
  Editing,
  Lookup,
} from "devextreme-react/data-grid";

import MessagePop from "../../ui_elements/MessagePop";
import ProductRiskLevelCopyPop from "./ProductRiskLevelCopyPop";
import ProductServiceRiskLevelCopyPop from "./ProductServiceRiskLevelCopyPop";

const ProductServiceFeesEdit = () => {
  const { user } = useAuth();
  const history = useHistory();

  const selPartnerId = user.productfees.selPartnerId;
  const selProductId = user.productfees.selProductId;
  const selSellrateId = user.productfees.selSellrateId;
  const selWorkId = user.productfees.workId;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [costRateEntryList, setCostRateEntryList] = useState([]);
  const [lpFlatRateType, setLpFlatRateType] = useState([]);
  const [costRateHeaderList, setCostRateHeaderList] = useState({});
  const [lpCostType, setLpCostType] = useState([]);
  const [lpFlatRatePeriod, setLpFlatRatePeriod] = useState([]);
  const [lpRiskLevel, setLpRiskLevel] = useState([]);
  const [lpCustomerType, setLpCustomerType] = useState([]);
  const [lpRegionalLimitType, setLpRegionalLimitType] = useState([]);
  const [selRiskid, setSelRiskid] = useState(1);
  const [costRiskEntriesList, setCostRiskEntriesList] = useState([]);
  const [riskGridData, setRiskGridData] = useState({});
  const [lpRegions, setLpRegions] = useState([]);
  const [productServicesList, setProductServicesList] = useState([]);
  const [selServiceId, setSelServiceId] = useState(-1);
  const [serviceCostRiskEntriesList, setServiceCostRiskEntriesList] = useState(
    {}
  );
  const [reloadData, setReloadData] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showProdServicePopup, setShowProdServicePopup] = useState(false);

  useEffect(() => {
    const fetchCostRatesHeader = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/work/singleproductcostrateheader/${selWorkId}/${selProductId}/${selPartnerId}/${selSellrateId}`,
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setCostRateHeaderList(dataArray[0]);
      } catch (err) {}
    };

    if (selSellrateId && selSellrateId > -1) {
      fetchCostRatesHeader();
    }
  }, [sendRequest, selSellrateId, selWorkId, reloadData]);

  const selCostRateHeaderData = {
    workid: selWorkId,
    productid: selProductId,
    partnerid: selPartnerId,
    sellrateid: selSellrateId,
    servicename: costRateHeaderList.servicename,
    generated: costRateHeaderList.generated,
    valid_on: costRateHeaderList.valid_on,
    valid_until: costRateHeaderList.valid_until,
    currency: costRateHeaderList.currency,
    entrynumber: costRateHeaderList.entrynumber,
    userid: costRateHeaderList.userid,
    active: costRateHeaderList.active,
    active_date: costRateHeaderList.active_date,
  };

  useEffect(() => {
    const fetchCostRiskEntries = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/work/productcostriskentries/${selWorkId}/${selProductId}/${selPartnerId}/${selSellrateId}`,
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setCostRiskEntriesList(dataArray);
      } catch (err) {}
    };
    if (selSellrateId && selSellrateId > -1) {
      fetchCostRiskEntries();
    }
  }, [sendRequest, selSellrateId, reloadData]);

  const stCostRiskEntriesData = new ArrayStore({
    key: ["riskid"],
    data: costRiskEntriesList,
  });

  const dcCostRiskEntriesData = new DataSource({
    store: stCostRiskEntriesData,
  });

  useEffect(() => {
    const fetchProductServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/product/productservices/${selProductId}`,
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setProductServicesList(dataArray);
      } catch (err) {}
    };
    if (selProductId) {
      fetchProductServices();
    }
  }, [sendRequest, selProductId, reloadData]);

  const stProductServicesList = new ArrayStore({
    key: ["serviceid"],
    data: productServicesList,
  });

  const dcProductServicesList = new DataSource({
    store: stProductServicesList,
  });

  useEffect(() => {
    const fetchCustomerType = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/3",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpCustomerType(dataArray);
      } catch (err) {}
    };

    const fetchRiskLevel = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/4",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpRiskLevel(dataArray);
      } catch (err) {}
    };

    const fetchCostType = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/6",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpCostType(dataArray);
      } catch (err) {}
    };

    const fetchFlatRateType = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/5",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpFlatRateType(dataArray);
      } catch (err) {}
    };

    const fetchFlatRatePeriod = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/2",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpFlatRatePeriod(dataArray);
      } catch (err) {}
    };

    const fetchRegionalLimitType = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/13",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpRegionalLimitType(dataArray);
      } catch (err) {}
    };

    const fetchRegions = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/service/regions",
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setLpRegions(dataArray);
      } catch (err) {}
    };

    fetchCustomerType();
    fetchRiskLevel();
    fetchCostType();
    fetchFlatRateType();
    fetchFlatRatePeriod();
    fetchRegionalLimitType();
    fetchRegions();
  }, [sendRequest]);

  const stCostTypeData = new ArrayStore({
    key: "lp_id",
    data: lpCostType,
  });

  const stFlatRateTypeData = new ArrayStore({
    key: "lp_id",
    data: lpFlatRateType,
  });

  const stFlatRatePeriod = new ArrayStore({
    key: "lp_id",
    data: lpFlatRatePeriod,
  });

  const stRiskLevel = new ArrayStore({
    key: "lp_id",
    data: lpRiskLevel,
  });

  const stCustomerType = new ArrayStore({
    key: "lp_id",
    data: lpCustomerType,
  });

  const stRegionalLimitType = new ArrayStore({
    key: "lp_id",
    data: lpRegionalLimitType,
  });

  const stRegions = new ArrayStore({
    key: "regionid",
    data: lpRegions,
  });

  useEffect(() => {
    const fetchCostRateEntries = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/work/productcostrateentries/${selWorkId}/${selProductId}/${selPartnerId}/${selSellrateId}/${selRiskid}/${selServiceId}`,
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setCostRateEntryList(dataArray);
      } catch (err) {}
    };
    fetchCostRateEntries();
  }, [sendRequest, selSellrateId, selRiskid, selServiceId, reloadData]);

  const stCostRateEntriesData = new ArrayStore({
    key: ["costentryid", "feature_group_assetid", "featureid"],
    data: costRateEntryList,
  });

  const dcCostRateEntriesData = new DataSource({
    store: stCostRateEntriesData,
  });

  useEffect(() => {
    const fetchServiceCostRiskEntries = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/work/productserviceriskentries/${selWorkId}/${selProductId}/${selPartnerId}/${selSellrateId}/${selRiskid}/${selServiceId}`,
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setServiceCostRiskEntriesList(dataArray[0]);
      } catch (err) {}
    };
    if (selRiskid && selServiceId > -1) {
      fetchServiceCostRiskEntries();
    }
  }, [sendRequest, selSellrateId, selRiskid, selServiceId, reloadData]);

  const selServiceRiskEntry = {
    workid: selWorkId,
    productid: selProductId,
    partnerid: selPartnerId,
    sellrateid: selSellrateId,
    serviceid: selServiceId,
    costentryid: serviceCostRiskEntriesList.costentryid,
    riskid: selRiskid,
    setupfee: serviceCostRiskEntriesList.setupfee,
    recurringfee: serviceCostRiskEntriesList.recurringfee,
    recurringperiodid: serviceCostRiskEntriesList.recurringperiodid,
    not_applicable: serviceCostRiskEntriesList.not_applicable,
    regional_limittypeid: serviceCostRiskEntriesList.regional_limittypeid,
    regional_limit_id: serviceCostRiskEntriesList.regional_limit_id,
  };

  const saveRateHeader = async (ancostRateEntryData) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/work/productrateheader",
        "POST",
        JSON.stringify({
          workid: selWorkId,
          productid: ancostRateEntryData.productid,
          partnerid: ancostRateEntryData.partnerid,
          sellrateid: ancostRateEntryData.sellrateid,
          valid_on: ancostRateEntryData.valid_on,
          valid_until: ancostRateEntryData.valid_until,
          currency: ancostRateEntryData.currency,
          entrynumber: ancostRateEntryData.entrynumber,
          comment: ancostRateEntryData.comment,
          userid: ancostRateEntryData.userid,
          active: ancostRateEntryData.active,
          active_date: ancostRateEntryData.active_date,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const saveRiskentry = async (ancostRateEntryData) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/work/productriskentry",
        "POST",
        JSON.stringify({
          workid: selWorkId,
          productid: ancostRateEntryData.productid,
          partnerid: ancostRateEntryData.partnerid,
          sellrateid: ancostRateEntryData.sellrateid,
          riskid: ancostRateEntryData.riskid,
          featureid: ancostRateEntryData.featureid,
          setupfee: ancostRateEntryData.setupfee,
          recurringfee: ancostRateEntryData.recurringfee,
          recurringperiodid: ancostRateEntryData.recurringperiodid,
          not_applicable: ancostRateEntryData.not_applicable,
          regional_limittypeid: ancostRateEntryData.regional_limittypeid,
          regional_limit_id: ancostRateEntryData.regional_limit_id,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const saveServerCostEntry = async (ancostRateEntryData) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/work/productservicecostentry",
        "POST",
        JSON.stringify({
          workid: selWorkId,
          productid: ancostRateEntryData.productid,
          partnerid: ancostRateEntryData.partnerid,
          sellrateid: ancostRateEntryData.sellrateid,
          serviceid: ancostRateEntryData.serviceid,
          costentryid: ancostRateEntryData.costentryid,
          riskid: ancostRateEntryData.riskid,
          featureid: ancostRateEntryData.featureid,
          costvalue: ancostRateEntryData.costvalue,
          costperc: ancostRateEntryData.costperc,
          costperc_minvalue: ancostRateEntryData.costperc_minvalue,
          costperc_maxvalue: ancostRateEntryData.costperc_maxvalue,
          costtype: ancostRateEntryData.costtype,
          flattypeid: ancostRateEntryData.flattypeid,
          flatperiodid: ancostRateEntryData.flatperiodid,
          flatvalue: ancostRateEntryData.flatvalue,
          feature_group_assetid: ancostRateEntryData.feature_group_assetid,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const saveProductServiceCostEntry = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/work/productsaveservicecostentry",
        "POST",
        JSON.stringify({
          workid: selWorkId,
          productid: selServiceRiskEntry.productid,
          partnerid: selServiceRiskEntry.partnerid,
          sellrateid: selServiceRiskEntry.sellrateid,
          serviceid: selServiceRiskEntry.serviceid,
          costentryid: selServiceRiskEntry.costentryid,
          riskid: selServiceRiskEntry.riskid,
          setupfee: selServiceRiskEntry.setupfee,
          recurringfee: selServiceRiskEntry.recurringfee,
          recurringperiodid: selServiceRiskEntry.recurringperiodid,
          not_applicable: selServiceRiskEntry.not_applicable,
          regional_limittypeid: selServiceRiskEntry.regional_limittypeid,
          regional_limit_id: selServiceRiskEntry.regional_limit_id,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const saveWorktoMain = async (an_Operation) => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/work/saveworkproductcost/${an_Operation}/${selWorkId}`,
        "GET",
        null,
        { Authorization: "Bearer " + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const importCostRates = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/work/productimportcostrates",
        "POST",
        JSON.stringify({
          workid: selWorkId,
          productid: selProductId,
          partnerid: selPartnerId,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    history.goBack();
  }

  const SaveWork = async () => {
    await saveRateHeader(selCostRateHeaderData);
    await saveProductServiceCostEntry();
    await saveWorktoMain(1);
    navigateToHome();
  };

  const CancelSaveWork = async () => {
    await saveWorktoMain(3);
    navigateToHome();
  };

  const importPartnerCostRates = () => {
    setShowMessagePop(true);
  };

  const hideMessagePop = useCallback((doAdopt) => {
    setShowMessagePop(false);
    if (doAdopt) {
      importCostRates();
      setReloadData(!reloadData);
    }
  });

  const hidePopup = () => {
    setShowPopup(false);
    setReloadData(!reloadData);
  };

  const showRiskLevelCopyPopup = () => {
    setShowPopup(true);
  };

  const hideProdServicePopup = () => {
    setShowProdServicePopup(false);
    setReloadData(!reloadData);
  };

  const showServiceRiskLevelCopyPopup = () => {
    setShowProdServicePopup(true);
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle="Confirmation"
        popMessage="Import Cost Rates? - Please note that this will overwrite any changes made"
      />
      <ProductRiskLevelCopyPop
        showPopup={showPopup}
        hidePopup={hidePopup}
        selWorkId={selWorkId}
        selSellRateId={selSellrateId}
        selPartnerId={selPartnerId}
        selProductId={selProductId}
        selRiskid={selRiskid}
        user={user}
      />
      <ProductServiceRiskLevelCopyPop
        showPopup={showProdServicePopup}
        hidePopup={hideProdServicePopup}
        selWorkId={selWorkId}
        selSellRateId={selSellrateId}
        selPartnerId={selPartnerId}
        selProductId={selProductId}
        selServiceId={selServiceRiskEntry.serviceid}
        selRiskid={selRiskid}
        selCostEntryid={selServiceRiskEntry.costentryid}
        sellSellRateid={selServiceRiskEntry.sellrateid}
        user={user}
      />
      <p className={"content-block"}>Product - Customer Fees</p>
      <div className={"content-block"}>
        <div className={"dx-card"}>
          <ResponsiveBox>
            <Row ratio={10} />
            <Row ratio={0.2} />
            <Col ratio={0.7} />
            <Col ratio={5} />
            <Item>
              <Location screen="md lg sm xs" row={0} col={0} />
              <div id="product-edit-support-buttons">
                <div
                  className="dx-fieldset-header"
                  id="product-service-edit-btn-margin-1"
                >
                  Customer Fees
                </div>

                <div className="dx-fieldset-header">Risk Level</div>
                <div id="product-service-edit-btn-section-2">
                  <Button
                    width={130}
                    height={50}
                    text="Copy"
                    type="normal"
                    stylingMode="outlined"
                    onClick={showRiskLevelCopyPopup}
                  />
                </div>
                <div className="dx-fieldset-header">Service Risk Level</div>
                <div id="product-service-edit-btn-section-3">
                  <Button
                    width={130}
                    height={50}
                    text="Copy"
                    type="normal"
                    stylingMode="outlined"
                    onClick={showServiceRiskLevelCopyPopup}
                  />
                </div>
              </div>
            </Item>
            <Item>
              <Location screen="md lg sm xs" row={0} col={1} />

              <Form
                id="service-fee-edit-form"
                // ref={dataFormRef}
                formData={selCostRateHeaderData}
                labelLocation={"left"}
                colCountByScreen={colCountByScreen}
                // validationGroup='financial_Routing_Create_Data'
              >
                <Item itemType="group" colCount={5}>
                  <SimpleItem dataField="currency">
                    <Label text="Currency" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="valid_on"
                    dataType="date"
                    editorType="dxDateBox"
                  >
                    <Label text="Valid on" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="valid_until"
                    dataType="date"
                    editorType="dxDateBox"
                  >
                    <Label text="Valid on" />
                  </SimpleItem>
                </Item>
                <Item itemType="group" colCount={6}>
                  <SimpleItem
                    dataField="active"
                    dataType="boolean"
                    editorType="dxCheckBox"
                  >
                    <Label text="Active" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="active_date"
                    dataType="date"
                    editorType="dxDateBox"
                    editorOptions={{ readOnly: true }}
                  >
                    <Label text="Active on" />
                  </SimpleItem>
                </Item>
                <Item>
                  <DataGrid
                    id="service-cost-rates-risk-grid-base"
                    dataSource={dcCostRiskEntriesData}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    focusedRowEnabled={true}
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={true}
                    autoNavigateToFocusedRow={true}
                    onFocusedRowChanged={(e) => {
                      if (e.row) {
                        setRiskGridData(e.row.data);
                        setSelRiskid(e.row.data.riskid);
                      }
                    }}
                    onSaved={(e) => {
                      if (e.changes[0]) {
                        saveRiskentry(e.changes[0].data);
                      }
                    }}
                  >
                    <Column dataField="workid" visible={false} />
                    <Column dataField="productid" visible={false} />
                    <Column dataField="partnerid" visible={false} />
                    <Column dataField="sellrateid" visible={false} />
                    <Column dataField="riskid" visible={false} />
                    <Column
                      dataField="risklevel"
                      caption="Risk Level"
                      width="150px"
                      allowEditing={false}
                    />
                    <Column
                      dataField="rates_set"
                      caption="Rates Set"
                      width="90px"
                      visible={false}
                    />
                    <Column
                      dataField="not_applicable"
                      caption="Not Applicable"
                      width="120px"
                    />
                    <Column
                      dataField="setupfee"
                      caption="Setup Fee"
                      dataType="number"
                      width="110"
                    />
                    <Column
                      dataField="recurringfee"
                      caption="Service Fee"
                      dataType="number"
                      width="110"
                    />
                    <Column
                      dataField="recurringperiodid"
                      caption="Recurring"
                      width="100"
                    >
                      <Lookup
                        dataSource={stFlatRatePeriod}
                        displayExpr="lp_value"
                        valueExpr="lp_id"
                      />
                    </Column>
                    <Column
                      dataField="regional_limittypeid"
                      caption="Limited to"
                      width="140"
                    >
                      <Lookup
                        dataSource={stRegionalLimitType}
                        displayExpr="lp_value"
                        valueExpr="lp_id"
                      />
                    </Column>
                    <Column
                      dataField="regional_limit_id"
                      caption="Countries"
                      width="180"
                    >
                      <Lookup
                        dataSource={stRegions}
                        displayExpr="regionname"
                        valueExpr="regionid"
                      />
                    </Column>
                    <Editing mode="row" allowUpdating={true} />
                  </DataGrid>
                </Item>

                <Item>
                  <ResponsiveBox>
                    <Row />
                    <Col ratio={1.2} />
                    <Col ratio={4} />
                    <Item>
                      <Location screen="md lg sm xs" row={0} col={0} />
                      <DataGrid
                        dataSource={dcProductServicesList}
                        defaultFocusedRowIndex={0}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        showBorders={true}
                        showRowLines={true}
                        showColumnLines={true}
                        autoNavigateToFocusedRow={true}
                        onFocusedRowChanged={(e) => {
                          setSelServiceId(e.row.data.serviceid);
                        }}
                      >
                        <FilterRow visible={false} />
                        <Column dataField="serviceid" visible={false} />
                        <Column
                          dataField="servicename"
                          caption="Service"
                          width={230}
                        />
                      </DataGrid>
                    </Item>
                    <Item>
                      <Location screen="md lg sm xs" row={0} col={1} />
                      <Form
                        formData={selServiceRiskEntry}
                        labelLocation={"left"}
                        colCountByScreen={colCountByScreen}
                      >
                        <Item itemType="group" colCount={6}>
                          <SimpleItem
                            dataField="not_applicable"
                            dataType="boolean"
                            editorType="dxCheckBox"
                          >
                            <Label text="No applicable" />
                          </SimpleItem>
                          <SimpleItem
                            dataField="setupfee"
                            dataType="number"
                            editorType="dxNumberBox"
                          >
                            <Label text="Setup Fee" />
                          </SimpleItem>
                          <SimpleItem
                            dataField="recurringfee"
                            dataType="number"
                            editorType="dxNumberBox"
                          >
                            <Label text="Service Fee" />
                          </SimpleItem>
                          <SimpleItem
                            dataField="recurringperiodid"
                            editorType="dxSelectBox"
                            colSpan={2}
                            name="period_listbox"
                            editorOptions={{
                              dataSource: stFlatRatePeriod,
                              valueExpr: "lp_id",
                              displayExpr: "lp_value",
                              searchEnabled: true,
                            }}
                          >
                            <Label text="Rec. Period" />
                          </SimpleItem>
                        </Item>
                        <Item>
                          <DataGrid
                            id="product-service-cost-rates-entries-base-edit-grid"
                            dataSource={dcCostRateEntriesData}
                            defaultFocusedRowIndex={0}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            focusedRowEnabled={true}
                            showBorders={true}
                            showRowLines={true}
                            showColumnLines={true}
                            autoNavigateToFocusedRow={true}
                            onSaved={(e) => {
                              if (e.changes[0]) {
                                saveServerCostEntry(e.changes[0].data);
                              }
                            }}
                          >
                            <FilterRow visible={false} />
                            <Column dataField="workid" visible={false} />
                            <Column dataField="productid" visible={false} />
                            <Column dataField="partnerid" visible={false} />
                            <Column dataField="sellrateid" visible={false} />
                            <Column dataField="serviceid" visible={false} />
                            <Column dataField="costentryid" visible={false} />
                            <Column dataField="riskid" visible={false} />
                            <Column dataField="featureid" visible={false} />
                            <Column
                              dataField="feature_group_assetid"
                              visible={false}
                            />
                            <Column
                              dataField="regional_limit_id"
                              visible={false}
                            />
                            <Column
                              dataField="feature"
                              caption="Service Feature"
                              width={190}
                              allowEditing={false}
                            />
                            <Column
                              dataField="feature_group_asset"
                              caption="Group"
                              width={100}
                              allowEditing={false}
                            />
                            <Column
                              dataField="costtype"
                              caption="Cost Type"
                              width={80}
                              visible={false}
                            >
                              <Lookup
                                dataSource={stCostTypeData}
                                displayExpr="lp_value"
                                valueExpr="lp_id"
                              />
                            </Column>
                            <Column
                              dataField="costvalue"
                              caption="Fixed Fee"
                              width={70}
                            />
                            <Column
                              dataField="costperc"
                              caption="Perc of Value"
                              dataType="number"
                              width={90}
                            />
                            <Column
                              dataField="costperc_minvalue"
                              caption="Min Value"
                              dataType="number"
                              width={75}
                            />
                            <Column
                              dataField="costperc_maxvalue"
                              caption="Max Value"
                              dataType="number"
                              width={75}
                            />
                            <Column
                              dataField="flattypeid"
                              caption="Flat Type"
                              width={80}
                            >
                              <Lookup
                                dataSource={stFlatRateTypeData}
                                displayExpr="lp_value"
                                valueExpr="lp_id"
                              />
                            </Column>
                            <Column
                              dataField="flatvalue"
                              dataType="number"
                              caption="Flat Value"
                              width={70}
                            />
                            <Column
                              dataField="flatperiodid"
                              caption="Flat Period"
                              width={80}
                            >
                              <Lookup
                                dataSource={stFlatRatePeriod}
                                displayExpr="lp_value"
                                valueExpr="lp_id"
                              />
                            </Column>

                            <Editing mode="row" allowUpdating={true} />
                          </DataGrid>
                        </Item>
                      </Form>
                    </Item>
                  </ResponsiveBox>
                </Item>
              </Form>
            </Item>
            <Item>
              <Location screen="md lg sm xs" row={1} col={1} />
              <div id={"services-edit-button"}>
                <div style={{ flexDirection: "row" }}>
                  <Button
                    id="savebutton"
                    width={120}
                    height={50}
                    text="Save"
                    type="default"
                    onClick={SaveWork}
                  />
                  <Button
                    id="services-edit_close-button"
                    width={120}
                    height={50}
                    text="Close"
                    type="normal"
                    stylingMode="contained"
                    onClick={CancelSaveWork}
                  />
                </div>
              </div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ProductServiceFeesEdit;
