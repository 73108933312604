import React, { useState, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import './RepTransactions.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Tabs, MultiView } from 'devextreme-react';
import { useAuth } from '../../contexts/auth';

import TransactionsOneTab from './TransactionsOneTab';
import TransactionsNotesTab from './TransactionsNotesTab';
import TransactionsClientDetails from './TransactionsClientDetails';

const TransactionsPage = props => {
  const { selProviderId } = props;
  const { user } = useAuth();
  const [contactPersonid, setContactPersonid] = useState();
  const [tabIndex, setTabIndex] = useState(0);

  const getPersonid = useCallback(fPersonid => {
    setContactPersonid(fPersonid);
  });

  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Cases</h2> */}
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={2} />
            <Row ratio={0.7} />
            <Col />

            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <div id='provider-clients-signup-label'>
                <p>
                  <b>Transactions</b>
                </p>
              </div>
            </Item>

            <Item>
              <Location screen='md lg sm xs' row={1} col={0} />

              <div className='rb-content item'>
                <ResponsiveBox>
                  <Row ratio={1} />
                  <Row ratio={1} />
                  <Col ratio={1} />
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={0} />
                    <div>
                      <MultiView height={400} animationEnabled={false}>
                        <Item title='Contacts'>
                          <TransactionsOneTab passToParent={getPersonid} user={user} selProviderId={selProviderId} />
                        </Item>
                      </MultiView>
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={1} col={0} />
                    <ResponsiveBox>
                      <Col ratio={0.38} screen='md lg sm' />
                      <Col ratio={3} />
                      <Row />
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={0} />
                      </Item>
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={1} />
                        <Tabs
                          selectedIndex={tabIndex}
                          width='99%'
                          // height='20px'
                          visible={true}
                          onItemClick={e => {
                            setTabIndex(e.itemIndex);
                          }}
                        >
                          <Item text='Notes' />
                          <Item text='Client Details' />
                          <Item text='Transaction Details' />
                        </Tabs>
                        <MultiView height={340} animationEnabled={false} selectedIndex={tabIndex}>
                          <Item title='Customer Notes'>
                            <TransactionsNotesTab selectedKey={contactPersonid} selNotesType={3} user={user} />
                          </Item>
                          <Item title='Client Details'>
                            <TransactionsClientDetails selContactPersonid={contactPersonid} user={user} />
                          </Item>
                        </MultiView>
                      </Item>
                    </ResponsiveBox>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg sm xs' row={2} col={0} />
              <div className='rb-footer item' id='cap_item'></div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TransactionsPage;
