import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import './services.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import ProductsEditPop from './ProductsEditPop';

const ProductsTab = props => {
  const { passToParent, user, partnerid } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [productsList, setProductsList] = useState([]);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [popupTabsVisible, setPopupTabsVisible] = useState(false);
  const [popModeInsert, setpopModeInsert] = useState(false);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [selectedProductid, setSelectedProductid] = useState();
  const [selectedRoutingid, setSelectedRoutingid] = useState();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/product/products/${partnerid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProductsList(dataArray);
      } catch (err) {}
    };

    if (partnerid) {
      fetchProducts();
    }
  }, [sendRequest, reloadContacts, partnerid]);

  const stProductData = new ArrayStore({
    key: 'productid',
    data: productsList,
  });

  const dcProductData = new DataSource({
    store: stProductData,
  });

  const openContactEdit = () => {
    setpopModeInsert(false);
    setPopupTabsVisible(false);
    setShowContactPopup(true);
  };

  const openContactAdd = () => {
    setSelectedProductid(-1);
    setpopModeInsert(true);
    setPopupTabsVisible(true);
    setShowContactPopup(true);
  };

  const openProductEdit = () => {
    history.push({
      pathname: '/partnerproductedit',
      product: {
        partnerid: partnerid || -1,
        productid: selectedProductid || -1,
        routingid: selectedRoutingid || -1,
        modeInsert: false,
      },
    });
    // setpopModeInsert(false);
    // setPopupTabsVisible(false);
    // setShowContactPopup(true);
  };

  const hideContactPopup = useCallback(doContactReload => {
    setShowContactPopup(false);
    if (doContactReload) {
      setReloadContacts(!reloadContacts);
    }
  });

  return (
    <React.Fragment>
      <ProductsEditPop
        showPopup={showContactPopup}
        hidePopup={hideContactPopup}
        selProductid={selectedProductid}
        selRoutingid={user.routingid}
        user={user}
        showTabs={popupTabsVisible}
        modeInsert={popModeInsert}
      />
      <ResponsiveBox>
        <Col ratio={0.3} screen='md lg sm' />
        <Col ratio={3} />
        <Row />

        <Item>
          <Location screen='md lg sm' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <Button width={120} height={50} text='Add' type='normal' stylingMode='outlined' onClick={openContactAdd} />
          </div>
          <div id='hm-btn-section-2'>
            <Button
              width={120}
              height={50}
              text='Open'
              type='normal'
              stylingMode='outlined'
              onClick={openProductEdit}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div>
            <DataGrid
              id='routingsGrid'
              dataSource={dcProductData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={e => {
                if (e.row) {
                  passToParent(e.row.key);
                  setSelectedProductid(e.row.key);
                  setSelectedRoutingid(e.row.data.routingid);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='productid' visible={false}></Column>
              <Column dataField='partnerid' visible={false}></Column>
              <Column dataField='routingid' visible={false}></Column>
              <Column dataField='productname' caption='Product Name' width={300}></Column>
              <Column dataField='productactive' caption='Active' width={90}></Column>
              <Column dataField='startdate' dataType='date' caption='Start Date' width={130} />
              <Column dataField='enddate' dataType='date' caption='End Date' width={130} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ProductsTab;
