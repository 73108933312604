import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

import './ServicesData.css';

import { Button } from 'devextreme-react/button';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import ServicesDataSetup from './ServicesDataSetup';
import ServicesDataProvider from './ServicesDataProvider';
import ServicesDataFeatures from './ServicesDataFeatures';
import ServicesDataCostRates from './ServicesDataCostRates';
import ServicesDataAccounts from './ServicesDataAccounts';

const ServicesDataPage = () => {
  const { user } = useAuth();
  const history = useHistory();

  const systemServiceId = user.service && user.service.serviceId;
  const selProviderId = user.service && user.service.providerId;
  const selCategoryId = user.service && user.service.categoryId;
  const selServiceName = user.service && user.service.serviceName;
  const modeInsert = user.service && user.service.modeInsert;

  useEffect(() => {
    if (!user.service) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='serviceprovider-data-box'>
                <ServicesDataSetup systemServiceId={systemServiceId} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='serviceprovider-data-box'>
                <ServicesDataProvider selProviderId={selProviderId} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={1} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='services-data-line-500'>
                <ServicesDataFeatures
                  selServiceId={systemServiceId}
                  selCategoryId={selCategoryId}
                  modeInsert={modeInsert}
                  user={user}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='services-data-line-600'>
                <ServicesDataCostRates selServiceId={systemServiceId} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>

        <Item>
          <Location screen='md lg xs sm' row={3} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='serviceprovider-data-box'>
                <ServicesDataAccounts
                  selServiceId={systemServiceId}
                  selCategoryId={selCategoryId}
                  modeInsert={modeInsert}
                  user={user}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={5} col={1} />
          <div id={'datapage-close-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ServicesDataPage;
