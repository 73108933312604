import axios from "axios";

import HTTP from "../api/HTTP";

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const REFRESH_STATUS = "refresh_status";

const REFRESH_TOKEN_RENEWAL_INTERVAL = 3000 * 1000; // msec

class TokenService {
  config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "/auth/refresh",
    headers: {
      // 'Authorization': authHeader,
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: {}
  };

  refreshTimerId;

  removeAll = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(REFRESH_STATUS);
  };

  getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN);
  };

  getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN);
  };

  getRefreshStatus = () => {
    return !!localStorage.getItem(REFRESH_STATUS);
  };

  setRefreshStatus = () => {
    localStorage.setItem(REFRESH_STATUS, "true");
  };

  setAccessAndRefreshToken = (accessToken, refreshToken) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    localStorage.removeItem(REFRESH_STATUS);
  };
  setAccessToken = accessToken => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.removeItem(REFRESH_STATUS);
  };

  parseAccessToken = () => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    if (!token) {
      return null;
    }

    return this.parse(token);
  };

  parse = token => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  startRefreshTokenRenewal = () => {
    if (typeof this.refreshTimerId != "undefined") {
      this.stopRefreshTokenRenewal();
    }

    this.refreshTimerId = setInterval(
      this.refreshToken,
      REFRESH_TOKEN_RENEWAL_INTERVAL
    );
  };

  startRefreshTokenRenewalIfNotStarted = () => {
    if (typeof this.refreshTimerId == "undefined") {
      this.refreshTimerId = setInterval(
        this.refreshToken,
        REFRESH_TOKEN_RENEWAL_INTERVAL
      );
    }
  };

  stopRefreshTokenRenewal = () =>
    (this.refreshTimerId = clearInterval(this.refreshTimerId));

  refreshToken = () => {
    let refreshToken = this.getRefreshToken();

    // token is not present, need to login again
    if (!refreshToken) {
      return new Promise(resolve => {
        resolve({});
      });
    }

    return axios
      .get(process.env.REACT_APP_API_URL + "/auth/refresh", {
        headers: { refresh_token: refreshToken.toString() }
      })
      .then(({ data }) => {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + data.accessToken;
        this.setAccessToken(data.accessToken);
        return Promise.resolve(data);
      });
  };

  retrieveTokenData = (username, password) => {
    this.stopRefreshTokenRenewal();

    let bodyFormData = new FormData();
    bodyFormData.set("username", username.toString());
    bodyFormData.set("password", password.toString());
    bodyFormData.set("grant_type", "password");

    this.config.data = bodyFormData;

    return HTTP(this.config).then(({ data }) => {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + data.access_token;
      this.setAccessAndRefreshToken(data.access_token, data.refresh_token);
      this.startRefreshTokenRenewal();
      return Promise.resolve(data);
    });
  };
}


export default new TokenService();