import React, { useState } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import MultiView from 'devextreme-react/multi-view';
import Tabs from 'devextreme-react/tabs';

import CasesNotesTab from './CasesNotesTab';
import ClientsInquiry from './ClientInquiries';
import MessageThreadPage from '../../messages/MessageThreadPage';
import ClientDocuments from './ClientDocuments';

const ClientsComms = (props) => {
  const { user, selClientId, clientChatid, clientPortalid } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [tabIndex, setTabIndex] = useState(0);
  const [selPartnerPortal, setSelPartnerPortal] = useState(user.portalid);

  return (
    <div id='client-comms-box'>
      <Tabs
        selectedIndex={tabIndex}
        width='100%'
        visible={true}
        onItemClick={(e) => {
          setTabIndex(e.itemIndex);
        }}
      >
        <Item text='Notes' />
        <Item text='Chat' />
        <Item text='Doc. Requests' />
        <Item text='Documents' />
      </Tabs>
      <MultiView height={740} animationEnabled={false} selectedIndex={tabIndex}>
        <Item title='Notes'>
          <CasesNotesTab
            tabVisibleIndex={tabIndex}
            selClientId={selClientId}
            selPartnerPortal={selPartnerPortal}
            user={user}
            // getReload={getReloadCondition}
          />
        </Item>

        <Item title='Chat'>
          {clientChatid && clientPortalid && (
            <MessageThreadPage messageid={parseInt(clientChatid)} openedBy={parseInt(clientPortalid)} />
          )}{' '}
        </Item>

        <Item title='Doc. Requests'>
          <ClientsInquiry
            selectedKey={selClientId}
            selClientid={selClientId}
            selAccountType={'Person'}
            selPartnerid={user.partnerid}
            user={user}
          />
        </Item>
        <Item title='Documents'>
          <ClientDocuments user={user} selClientId={selClientId} />
        </Item>
      </MultiView>
    </div>
  );
};

export default ClientsComms;
