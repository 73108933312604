import React, { useEffect, useRef, useState } from 'react';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import MultiView from 'devextreme-react/multi-view';
import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import './Networks.css';
import { SelectBox } from 'devextreme-react/select-box';
import Box from 'devextreme-react/box';
import Tabs from 'devextreme-react/tabs';
import ArrayStore from 'devextreme/data/array_store';

const RoutingEditPop = props => {
  const { showPopup, hidePopup, selRoutingid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const dataFormRef = useRef(null);
  const [lpBankAccounts, setLpBankAccounts] = useState([]);
  const [routingData, setRoutingData] = useState({});

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/bankaccountslp', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setLpBankAccounts(dataArray);
      } catch (err) {}
    };

    if (showPopup) {
      fetchBankAccounts();
    }
  }, [sendRequest, showPopup]);

  useEffect(() => {
    const fetchSingleRouting = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/routing/financialrouting/${selRoutingid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setRoutingData(dataArray[0]);
      } catch (err) {}
    };
    if (selRoutingid && showPopup) {
      fetchSingleRouting();
    }
  }, [sendRequest, selRoutingid, showPopup]);

  const stlpBankAccountsData = new ArrayStore({
    key: 'bankaccountid',
    data: lpBankAccounts,
  });

  const fnce_Routing_FormData = {
    routingid: (routingData && routingData.routingid) || -1,
    routingname: (routingData && routingData.routingname) || '',
    ops_accountid: (routingData && routingData.ops_accountid) || -1,
    qua_accountid: (routingData && routingData.qua_accountid) || -1,
    mrg_accountid: (routingData && routingData.mrg_accountid) || -1,
  };

  const ModifyRouting = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/routing/modfinancialrouting',
        'POST',
        JSON.stringify({
          operation: 1,
          routingid: fnce_Routing_FormData.routingid,
          routingname: fnce_Routing_FormData.routingname,
          ops_accountid: fnce_Routing_FormData.ops_accountid,
          qua_accountid: fnce_Routing_FormData.qua_accountid,
          mrg_accountid: fnce_Routing_FormData.mrg_accountid,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    hidePopup(true);
  }

  const validateForm = async dataFormRef => {
    const result = dataFormRef.validationGroup.validate();
    if (result.isValid) {
      await ModifyRouting();
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  return (
    <Popup
      title={'Add Financial Network'}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      width='800'
      height='450'
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      //    onShowing={() => {
      //      setContactsChanged(false);
      //    }}
    >
      <ResponsiveBox>
        <Row ratio={2} />
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form
            id={'form'}
            ref={dataFormRef}
            formData={fnce_Routing_FormData}
            labelLocation={'top'}
            colCountByScreen={colCountByScreen}
            validationGroup='financial_Routing_Create_Data'
          >
            <Item itemType='group' colCount={2}>
              <SimpleItem dataField='routingname' isRequired={true} editorOptions={baseEditorOptions}>
                <RequiredRule message='Please enter a Routing Name' />
                <Label text='Name' />
              </SimpleItem>
            </Item>
            <EmptyItem />

            <Item itemType='group' colCount={2}>
              <SimpleItem
                dataField='ops_accountid'
                editorType='dxSelectBox'
                colSpan={1}
                name='opsaccount_listbox'
                isRequired={true}
                editorOptions={{
                  dataSource: stlpBankAccountsData,
                  valueExpr: 'bankaccountid',
                  displayExpr: 'accountname',
                  searchEnabled: true,
                  
                }}
              >
                <RequiredRule message='Please select the Operational Account' />
                <Label text='Operational Account' />
              </SimpleItem>
            </Item>
            <EmptyItem />

            <Item itemType='group' colCount={2}>
              <SimpleItem
                dataField='qua_accountid'
                editorType='dxSelectBox'
                colSpan={1}
                name='quaaccount_listbox'
                isRequired={true}
                editorOptions={{
                  dataSource: stlpBankAccountsData,
                  valueExpr: 'bankaccountid',
                  displayExpr: 'accountname',
                  searchEnabled: true,
                  
                }}
              >
                <RequiredRule message='Please select the Quarantine Account' />
                <Label text='Quarantine Account' />
              </SimpleItem>

              <SimpleItem
                dataField='mrg_accountid'
                editorType='dxSelectBox'
                colSpan={1}
                name='mrgaccount_listbox'
                isRequired={true}
                editorOptions={{
                  dataSource: stlpBankAccountsData,
                  valueExpr: 'bankaccountid',
                  displayExpr: 'accountname',
                  searchEnabled: true,
                  
                }}
              >
                <RequiredRule message='Please select the Margin Account' />
                <Label text='Margin Account' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={2} col={0} />
          <div id={'contactpopbutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={50}
                text='Save'
                type='normal'
                stylingMode='contained'
                validationGroup='financial_Routing_Create_Data'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default RoutingEditPop;
