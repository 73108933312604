import React, {useEffect, useRef, useState, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useHttpClient} from '../../hooks/http-hook';

import CompReqDocPop from './CompReqDocPop';
import {useAuth} from "../../contexts/auth";

import ArrayStore from 'devextreme/data/array_store';
import {Button} from "devextreme-react/button";

import './compReqDoc.scss'
import DataGrid, {Column} from "devextreme-react/data-grid";
import MessagePop from "../../ui_elements/MessagePop";

const CompReqDoc = (props) => {

    const history = useHistory();
    const {user} = useAuth();

    const {isLoading, error, sendRequest, clearError} = useHttpClient();
    const [serviceReqDocData, setServiceReqDocData] = useState([]);
    const [requiredDocId, setRequiredDocId] = useState(-1);
    const [showPopup, setShowPopup] = useState(false);
    const [popInsert, setPopInsert] = useState(0);
    const [showMessagePop, setShowMessagePop] = useState(false);
    const [reloadDocData, setReloadDocData] = useState(false);
    const [documentsArray, setDocumentsArray] = useState([]);

    useEffect(() => {
        const fetchReqDocs = async () => {
            try {
                const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/document/getAllGlobalDocuments', 'GET', null, {
                    Authorization: 'Bearer ' + user.token,
                });
                setDocumentsArray(dataArray);
            } catch (err) {
            }
        };
        fetchReqDocs();
    }, [sendRequest, user.token, reloadDocData]);

    const removeTouDoc = async () => {
        try {
            await sendRequest(
                process.env.REACT_APP_BACKEND_URL + '/document/removeDocument',
                'POST',
                JSON.stringify({
                    id: requiredDocId
                }),
                {'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token}
            );
        } catch (err) {
            console.log(err);
        }
    };

    const stReqDocData = new ArrayStore({
        key: 'id',
        data: documentsArray,
    });

    const addRequiredCustomerDoc = () => {
        setReloadDocData(reloadDocData => !reloadDocData);
        setPopInsert(2);
        setShowPopup(true);
    };

    const hidePopup = () => {
        setShowPopup(false);
        setReloadDocData(reloadDocData => !reloadDocData);
    };

    const hideMessagePop = useCallback(doRemove => {
        setShowMessagePop(false);
        if (doRemove) {
            removeTouDoc();
            setReloadDocData(reloadDocData => !reloadDocData);
        }
    });

    const removeReqDoc = () => {
        setReloadDocData(reloadDocData => !reloadDocData);
        setShowMessagePop(true);
    };

    const cellRenderActionButtons = () => {
        return (
            <>
                <Button className={'btn-action-download'} height={35} hint="Edit" icon="edit"/>
                <Button className={'btn-action-danger'} height={35} hint="Remove" icon="remove" onClick={removeReqDoc}/>
            </>
        );
    }

    return (
        <>
            <MessagePop
                showPopup={showMessagePop}
                hidePopup={hideMessagePop}
                popTitle='Confirmation'
                popMessage='Delete the selected required Document?'
            />
            <CompReqDocPop
                showPopup={showPopup}
                hidePopup={hidePopup}
                popInsert={popInsert}
                user={user}
            />

            <div className={"content-block"}>
                <div className={"dx-card wide-card"}>
                    <div className={"grid-compreqdoc-wrapper"}>

                        <div className={'grid-compreqdoc-row-1-5'}></div>
                        <div className={'grid-compreqdoc-row-6-6'}>
                            <Button
                                className={'btn-add-document'}
                                width={100}
                                height={35}
                                text='Add'
                                type='normal'
                                stylingMode='contained'
                                onClick={addRequiredCustomerDoc}
                            />
                        </div>
                        <div className={'grid-compreqdoc-row-7'}></div>
                        <div className={'grid-compreqdoc-col-2-row-1'}></div>
                        <div className={'grid-compreqdoc-col-2-row-2-6'}>
                            <DataGrid
                                id='req-doc-Grid'
                                dataSource={stReqDocData}
                                showBorders={true}
                                showRowLines={true}
                                showColumnLines={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                onFocusedRowChanged={e => {
                                    setRequiredDocId(e.row.key);
                                }}
                            >
                                <Column dataField='id' visible={false}></Column>
                                <Column dataField='usage_advice' caption='Title'></Column>
                                <Column dataField='file_name' caption='Required Document'></Column>
                                <Column dataField='created_on' width={200} caption='Uploaded'
                                        allowSorting={false}></Column>
                                <Column dataField='content_type' width={200} caption='File Type'
                                        allowSorting={false}></Column>
                                <Column dataField='file_size' width={100} caption='Filesize'
                                        allowSorting={false}></Column>
                                <Column dataField='comment' caption='Discription' allowSorting={false}></Column>
                                <Column dataField='action' width={120} caption='' cellRender={cellRenderActionButtons}
                                        allowSorting={false} alignment={'center'}></Column>
                            </DataGrid>
                        </div>
                        <div className={'grid-compreqdoc-col-2-row-7'}></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CompReqDoc;