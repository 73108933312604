import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './NetworkData.css';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';

import DataGrid, { Column, Editing } from 'devextreme-react/data-grid';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

const NetworkDataConfig = (props) => {
  const { selRoutingid, user } = props;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const [routingAccountsData, setRoutingAccountsData] = useState([]);

  useEffect(() => {
    const fetchRoutingAccounts = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/routing/getroutingaccounts/${selRoutingid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setRoutingAccountsData(dataArray);
      } catch (err) {}
    };
    if (selRoutingid) {
      fetchRoutingAccounts();
    }
  }, [sendRequest, selRoutingid]);

  const stRoutingAccountsData = new ArrayStore({
    key: ['accountid'],
    data: routingAccountsData,
  });

  const dcRoutingAccountsData = new DataSource({
    store: stRoutingAccountsData,
  });

  const saveAccountLimits = async (anAccountData) => {
    try {
      sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/routing/modroutingaccountlimits',
        'POST',
        JSON.stringify({
          routingid: anAccountData.routingid,
          accountid: anAccountData.accountid,
          funds_min: anAccountData.funds_min,
          funds_max: anAccountData.funds_max,
          funds_idle: anAccountData.funds_idle,
          funds: anAccountData.funds,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    history.goBack();
  }

  return (
    <div className='sd-header item'>
      <div className='dx-fieldset-header'>Network Account Configuration</div>
      <ResponsiveBox>
        <Row />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />

          <Form labelLocation={'left'} colCountByScreen={colCountByScreen} scrollingEnabled={true}>
            <Item>
              <DataGrid
                height={300}
                dataSource={dcRoutingAccountsData}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                onSaved={(e) => {
                  if (e.changes[0]) {
                    saveAccountLimits(e.changes[0].data);
                  }
                }}
              >
                <Column dataField='routingid' visible={false}></Column>
                <Column dataField='accountid' visible={false}></Column>
                <Column dataField='serviceid' visible={false}></Column>
                <Column dataField='bankaccountid' visible={false}></Column>
                <Column dataField='accounttype' visible={false}></Column>
                <Column dataField='servicename' caption='Service' width='250' allowEditing={false}></Column>
                <Column dataField='accountname' caption='Account Type' width='150' allowEditing={false}></Column>
                <Column dataField='category_name' caption='Category' width='150' allowEditing={false}></Column>
                <Column dataField='funds_idle' dataType='number' caption='Funds Ideal' width='100'></Column>
                <Column dataField='funds_min' dataType='number' caption='Funds Min' width='100'></Column>
                <Column dataField='funds_max' dataType='number' caption='Funds Max' width='100'></Column>
                <Column
                  dataField='funds'
                  dataType='number'
                  caption='Current Funds'
                  width='100'
                  allowEditing={true}
                ></Column>
                <Column dataField='imbalance' dataType='number' caption='Imbalance' width='100'></Column>
                <Editing mode='row' allowUpdating={true} />
              </DataGrid>
            </Item>
            <EmptyItem />
            <Item itemType='group' colCount={5} caption='Balance Accounts'>
              <SimpleItem dataField='active' dataType='boolean' editorType='dxCheckBox'>
                <Label text='Run Automatically' />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={5}>
              <ButtonItem
                horizontalAlignment='left'
                colSpan={2}
                buttonOptions={{
                  text: 'Run Now',
                  width: 250,
                  height: 36,
                  type: 'default',
                  disabled: true,
                  stylingMode: 'outlined',
                  onClick: () => {
                    // saveEmployee(1);
                  },
                }}
              />
            </Item>
          </Form>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default NetworkDataConfig;
