import React, { useRef, useState, useEffect } from "react";
import "./GlobalDefinitions.css";

import { useHistory, useLocation } from "react-router-dom";
import { useHttpClient } from "../../hooks/http-hook";
import DataGrid, { Column } from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";
import Button from "devextreme-react/button";

import Box from "devextreme-react/box";
import { Form, SimpleItem, Label, ButtonItem } from "devextreme-react/form";

const RegionsTab = (props) => {
  const { user } = props;
  const [regionsDatalist, setRegionsDatalist] = useState([]);
  const [regionGridData, setRegionGridData] = useState({});
  const [departmentDataMod, setDeparmentDataMod] = useState(false);
  const [regionCountriesDatalist, setRegionCountriesDatalist] = useState([]);
  const [countryGridData, setCountryGridData] = useState({});
  const [lookupCountriesDatalist, setLookupCountriesDatalist] = useState([]);
  const [regionCountryDataMod, setRegionCountryDataMod] = useState(false);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const regionData = {
    regionname: regionGridData && regionGridData.regionname,
    regionid: regionGridData && regionGridData.regionid,
  };

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + "/service/getallregions", "GET", null, {
          Authorization: "Bearer " + user.token,
        });
        setRegionsDatalist(dataArray);
      } catch (err) {}
    };
    fetchRegions();
  }, [sendRequest, departmentDataMod]);

  const stRegionsData = new ArrayStore({
    key: "regionid",
    data: regionsDatalist,
  });

  useEffect(() => {
    const fetchLookupCountries = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + "/base/countries", "GET", null, {
          Authorization: "Bearer " + user.token,
        });
        setLookupCountriesDatalist(dataArray);
      } catch (err) {}
    };
    fetchLookupCountries();
  }, [sendRequest, departmentDataMod]);

  const stLookupCountriesData = new ArrayStore({
    key: "countryid",
    data: lookupCountriesDatalist,
  });

  const modServiceCat = async (modify) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/service/regions",
        "POST",
        JSON.stringify({
          regionid: regionData.regionid,
          partnerid: -1,
          providerid: -1,
          regionname: regionData.regionname,
          operation: modify,
        }),
        { "Content-Type": "application/json", Authorization: "Bearer " + user.token }
      );
      setDeparmentDataMod(!departmentDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchRegionCountries = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/regioncountries/${regionData.regionid}`,
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setRegionCountriesDatalist(dataArray);
      } catch (err) {}
    };
    if (regionData.regionid) {
      fetchRegionCountries();
    } else setRegionCountriesDatalist([]);
  }, [sendRequest, regionData.regionid, regionCountryDataMod]);

  const stRegionCountriesData = new ArrayStore({
    key: "countryid",
    data: regionCountriesDatalist,
  });

  const regionCountryData = {
    regionid: regionGridData && regionGridData.regionid,
    countryid: countryGridData && countryGridData.countryid,
    countryname: countryGridData && countryGridData.countryname,
  };

  const modRegionCountries = async (modify) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/service/regioncountries",
        "POST",
        JSON.stringify({
          regionid: regionCountryData.regionid,
          countryid: regionCountryData.countryid,
          operation: modify,
        }),
        { "Content-Type": "application/json", Authorization: "Bearer " + user.token }
      );
      setRegionCountryDataMod(!regionCountryDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="sd-header item">
      <div className="dx-fieldset-header">Regions</div>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={4} />
        <Item>
          <Location screen="md lg sm xs" row={0} col={0} />

          <Form id={"form"} formData={regionData} labelLocation={"left"}>
            <Item itemType="group" colCount={18}>
              <SimpleItem dataField="regionname" colSpan={3}>
                <Label location="left" text="Region" />
              </SimpleItem>
              <SimpleItem dataField="portal" colSpan={3}>
                <Label location="left" text="Portal" />
              </SimpleItem>

              <ButtonItem
                horizontalAlignment="left"
                colSpan={2}
                buttonOptions={{
                  text: "Add",
                  width: 120,
                  height: 36,
                  type: "default",
                  stylingMode: "outlined",
                  onClick: () => {
                    modServiceCat(1);
                  },
                }}
              />
              <ButtonItem
                horizontalAlignment="left"
                colSpan={2}
                buttonOptions={{
                  text: "Mod",
                  width: 120,
                  height: 36,
                  type: "success",
                  stylingMode: "outlined",
                  onClick: () => {
                    modServiceCat(2);
                  },
                }}
              />
              <ButtonItem
                horizontalAlignment="left"
                colSpan={2}
                buttonOptions={{
                  text: "Rem",
                  width: 120,
                  height: 36,
                  type: "danger",
                  stylingMode: "outlined",
                  onClick: () => {
                    modServiceCat(3);
                  },
                }}
              />
            </Item>
          </Form>
        </Item>
        <Item>
          <Location screen="md lg sm xs" row={1} col={0} />
          <ResponsiveBox>
            <Row />
            <Col ratio={2} />
            <Col ratio={3} />
            <Item>
              <Location screen="md lg sm xs" row={0} col={0} />
              <div id="global-defs-region-grid">
                <DataGrid
                  height={370}
                  dataSource={stRegionsData}
                  showRowLines={true}
                  showBorders={true}
                  showColumnLines={true}
                  columnAutoWidth={true}
                  defaultFocusedRowIndex={0}
                  focusedRowEnabled={true}
                  showColumnHeaders={false}
                  onFocusedRowChanged={(e) => {
                    setRegionGridData(e.row.data);
                  }}
                >
                  <Column dataField="regionid" visible={false} />
                  <Column dataField="regionname" caption="Region Name" width={200} />
                  <Column dataField="partnername" caption="Partner" width={120} />
                  <Column dataField="providername" caption="Provider" width={120} />
                </DataGrid>
              </div>
            </Item>
            <Item>
              <Location screen="md lg sm xs" row={0} col={1} />
              <Form id="global-defs-tab-countries" formData={regionCountryData} labelLocation={"left"}>
                <Item itemType="group" colCount={12}>
                  <SimpleItem
                    dataField="countryid"
                    editorType="dxSelectBox"
                    colSpan={6}
                    name="country_listbox"
                    editorOptions={{
                      dataSource: stLookupCountriesData,
                      valueExpr: "countryid",
                      displayExpr: "name_en",
                      searchEnabled: true,
                    }}
                  >
                    <Label text="Country" />
                  </SimpleItem>

                  <ButtonItem
                    horizontalAlignment="left"
                    colSpan={2}
                    buttonOptions={{
                      text: "Add",
                      width: 100,
                      height: 36,
                      type: "default",
                      stylingMode: "outlined",
                      onClick: () => {
                        modRegionCountries(1);
                      },
                    }}
                  />
                  <ButtonItem
                    horizontalAlignment="left"
                    colSpan={2}
                    buttonOptions={{
                      text: "Rem",
                      width: 100,
                      height: 36,
                      type: "danger",
                      stylingMode: "outlined",
                      onClick: () => {
                        modRegionCountries(3);
                      },
                    }}
                  />
                </Item>

                <Item>
                  <DataGrid
                    height={300}
                    width={400}
                    dataSource={stRegionCountriesData}
                    showRowLines={true}
                    showBorders={true}
                    showColumnLines={true}
                    columnAutoWidth={true}
                    defaultFocusedRowIndex={0}
                    focusedRowEnabled={true}
                    showColumnHeaders={false}
                    onFocusedRowChanged={(e) => {
                      setCountryGridData(e.row.data);
                    }}
                  >
                    <Column dataField="regionid" visible={false} />
                    <Column dataField="countryid" visible={false} />
                    <Column dataField="countryname" visible={true} />
                  </DataGrid>
                </Item>
              </Form>
            </Item>
          </ResponsiveBox>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

export default RegionsTab;
