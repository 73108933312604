import React, { useState, useEffect } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

const ServiceProvContact = props => {
  const { selContactid, user, selCompanyID } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [companyContact, setCompanyContact] = useState({});

  useEffect(() => {
    const fetchCompanyContact = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/contacts/company/${selContactid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCompanyContact(dataArray[0]);
      } catch (err) {}
    };
    if (selContactid) {
      fetchCompanyContact();
    }
  }, [sendRequest, selContactid]);

  const provContactData = {
    contactid: companyContact.contactid,
    lastname: companyContact.lastname,
    street: companyContact.street,
    city_code: companyContact.city_code,
    city: companyContact.city,
    phone_one: companyContact.phone_one,
    email: companyContact.email,
    homepage: companyContact.homepage,
    addressfield: companyContact.addressfield,
    nationality: companyContact.nationality,
    res_cntry: companyContact.res_cntry,
    contact_cat: companyContact.contact_cat,
  };

  return (
    <ResponsiveBox>
      <Col />
      <Row />
      <Item>
        <Location screen='md lg sm xs' row={0} col={0} />
        <div className="note-details-container-small">
            <div className="note-details-wrapper">
              <div className="note-details-personally">
                <div className="note-details-personally-none">
                  <div className="note-details-personally-block">
                    <div className="note-details-personally-block-infoText">
                      {"Address: "}
                    </div>
                    <div className="note-details-personally-block-outputText">
                    {provContactData.addressfield}
                    </div>
                  </div>

                  <div className="note-details-personally-block">
                    <div className="note-details-personally-block-infoText">
                      {"E-Mail: "}
                    </div>
                    <div className="note-details-personally-block-outputText">
                    {provContactData.email}
                    </div>
                  </div>

                  <div className="note-details-personally-block">
                    <div className="note-details-personally-block-infoText">
                      {"Phone: "}
                    </div>
                    <div className="note-details-personally-block-outputText">
                    {provContactData.phone_one}
                    </div>
                  </div>

                  <div className="note-details-personally-block">
                    <div className="note-details-personally-block-infoText">
                      {"Hompage: "}
                    </div>
                    <div className="note-details-personally-block-outputText">
                    {provContactData.homepage}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </Item>
    </ResponsiveBox>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ServiceProvContact;
