import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { Form, SimpleItem, EmptyItem, Label, ButtonItem, RequiredRule, ButtonOptions } from 'devextreme-react/form';

import { useInquiry } from '../../hooks/inquiry-hook';

import CasesNotesToReceivePop from './CasesNotesToReceivePop';
import CasesNotesToSelReceivePop from './CasesNotesToSelReceivePop';

const CasesNotesToPop = props => {
  const {
    showPopup,
    hidePopup,
    user,
    entryid,
    caseEntryid,
    caseSituation,
    contactPortalid,
    contactClient,
    contactClientid,
    caseSituationAction,
    caseNoteSubject,
    caseNoteContent,
  } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { createInquiry } = useInquiry();
  const popupRef = useRef(null);
  const sendCaseNotetoFormRef = useRef(null);

  const [showReceveivePopup, setShowReceveivePopup] = useState(false);

  const [selToType, setSelToType] = useState(1);
  const [receiverDetails, setReceiverDetails] = useState({});
  const [toReceiverId, setToReceiverId] = useState(-1);
  const [ccReceiverId, setCcReceiverId] = useState(-1);
  const [toReceiverPortal, setToReceiverPortal] = useState(-1);
  const [ccReceiverPortal, setCcReceiverPortal] = useState(-1);
  const [toReceiver, setToReceiver] = useState('');
  const [ccReceiver, setCcReceiver] = useState('');
  const [opsTemplateData, setOpsTemplateData] = useState({});

  const [showSelReceveivePopup, setShowSelReceveivePopup] = useState(false);

  const [receiverClient, setToReceiverClient] = useState('');

  const [clientData, setClientData] = useState({});
  const [contactClientMail, setContactClientMail] = useState('');
  const [noteInquiryId, setNoteInquiryId] = useState(-1);

  useEffect(() => {
    const fetchCaseSituation = async () => {
      try {
        /* Client Data */
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/clientsignupdata/${contactClientid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        await setClientData(dataArray[0]);

        /* Case Situation Template */
        const opsdataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/cases/opstemplate/${caseSituation}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        await setOpsTemplateData(opsdataArray[0]);

        if (opsdataArray[0].adm_to === 1) {
          setReceiverToClient(contactClientid, dataArray[0].cl_email);
          setContactClientMail(dataArray[0].cl_email);
        } else {
          await setReceiver(opsdataArray[0].adm_to);

          if (contactPortalid > -1) {
            setReceiverTo(contactPortalid);
          } else {
            if (contactPortalid === -10) {
              openSelReceiverPopup(1);
            }
          }
        }
      } catch (err) {}
    };

    if (entryid && showPopup && caseSituation) {
      fetchCaseSituation();
    }
  }, [sendRequest, entryid, showPopup, caseSituation]);

  const getPortalName = async anPortalid => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/management/portalname/${anPortalid}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + user.token,
        }
      );
      return dataArray[0].portalname;
    } catch (err) {
      console.log(err);
    }
  };

  const replaceSubject = () => {
    let anSubject = '';

    const anClient =
      'Client: ' +
      clientData.cl_lastname +
      ' ' +
      clientData.cl_firstname +
      ' ' +
      clientData.cl_salutation +
      ' - ' +
      clientData.cl_email;

    if (contactClient) {
      anSubject = opsTemplateData.adm_subject.replace('<Subject>', `Your - ${clientData.partnername} Account`);
    }

    if (!contactClient) {
      anSubject = opsTemplateData.adm_subject.replace('<Subject>', anClient);
    }

    anSubject = anSubject.replace('<Portal>', clientData.partnername);

    if (caseSituation === 30 || caseSituation === 40) {
      anSubject = anSubject.replace('<forward>', caseNoteSubject);
    }

    return anSubject;
  };

  const replaceContent = () => {
    let anContent = '';

    const anClientName = clientData.cl_salutation + ' ' + clientData.cl_lastname;

    if (contactClient || opsTemplateData.adm_to === 1) {
      anContent = opsTemplateData.adm_content.replace('<Client>', anClientName);
      anContent = anContent.replace('<Portal>', clientData.partnername);
      anContent = anContent.replace('<Portal>', clientData.partnername);
    } else {
      if (!contactClient) {
        anContent = opsTemplateData.adm_content.replace('<User>', user.fullname);
        anContent = anContent.replace('<Portal>', user.portalname + ' Portal');
      }
    }

    if (caseSituation === 30 || caseSituation === 40) {
      anContent = anContent.replace('<forward>', caseNoteContent);
    }

    return anContent;
  };

  const caseNote = {
    entryid: -1,
    case_entryid: caseEntryid,
    userid: user.id,
    portalid: user.portalid,
    notedate: new Date(),
    subject: opsTemplateData.adm_subject && replaceSubject(),
    case_content: opsTemplateData.adm_content && replaceContent(),
    portaluser: user.lastname,
    case_situation: caseSituation,
  };

  const caseNoteTo = {
    toReceiverId: toReceiverId,
    ccReceiverId: ccReceiverId,
    toReceiverPortal: toReceiverPortal,
    ccReceiverPortal: ccReceiverPortal,
    sendNoteTo: toReceiver,
    sendNoteCC: ccReceiver,
  };

  const setReceiver = toValue => {
    setToReceiverId(toValue);
    setToReceiverPortal(-1);
    switch (toValue) {
      case 1:
        setToReceiver('<Client>');
        break;
      case 3:
        setToReceiver('<All Client Provider>');
        break;
      case 4:
        setToReceiver(`<P-Select>`);
        break;
    }
  };

  const setReceiverTo = async anReceiverid => {
    console.log(7332, anReceiverid);
    const anPortalName = await getPortalName(anReceiverid);
    setToReceiverId(4);
    setToReceiverPortal(anReceiverid);
    setToReceiver(`<portal@${anPortalName}>`);
  };

  const setReceiverToClient = async (anClientid, anClientMail) => {
    setToReceiverId(1);
    setToReceiverClient(anClientid);
    setToReceiver(`<client:${anClientMail}>`);
  };

  const getReceiver = useCallback(async (receiveType, selPortalid, toType, selPortalName) => {
    console.log(6050, {
      receiveType: receiveType,
      selPortalid: selPortalid,
      selPortalName: selPortalName,
      toType: toType,
    });

    await setReceiverDetails({
      receiveType: receiveType,
      selPortalid: selPortalid,
      selPortalName: selPortalName,
      toType: toType,
    });

    if (toType === 1) {
      setToReceiverId(receiveType);
      setToReceiverPortal(selPortalid);
      switch (receiveType) {
        case 1:
          setToReceiver('<Client>');
          break;
        case 3:
          setToReceiver('<All Client Provider>');
          break;
        case 4:
          setToReceiver(`<portal@${selPortalName}>`);
          break;
      }
    } else {
      setCcReceiverId(receiveType);
      setCcReceiverPortal(selPortalid);
      switch (receiveType) {
        case 1:
          setCcReceiver('<Client>');
          break;
        case 3:
          setCcReceiver('<All Client Provider>');
          break;
        case 4:
          setCcReceiver(`<portal@${selPortalName}>`);
          break;
      }
    }
  });

  const createNewInquiry = async () => {
    const response = await createInquiry(user, contactClientid, caseNote.subject, caseNote.case_content);
    if (response.success) {
      return response.inquiryid;
    } else {
      return -1;
    }
  };

  const SendCaseNote = async () => {
    let anInquiryId = -1;
    try {
      if (contactClient) {
        anInquiryId = await createNewInquiry();
        await setNoteInquiryId(anInquiryId);
      }
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/cases/sendcasenote',
        'POST',
        JSON.stringify({
          entryid: caseNote.entryid,
          case_entryid: caseNote.case_entryid,
          userid: user.id,
          portalid: user.portalid,
          clientid: contactClientid,
          notedate: caseNote.notedate,
          subject: caseNote.subject,
          case_content: caseNote.case_content,
          caseSituation: caseSituation,
          sendnoteto: toReceiverId,
          sendnotecc: ccReceiverId,
          sendnoteto_portalid: toReceiverPortal,
          sendnotecc_portalid: ccReceiverPortal,
          inquiryid: anInquiryId,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async () => {
    SendCaseNote();
    hidePopup(noteInquiryId);
  };

  const hideReceveivePopup = () => {
    setShowReceveivePopup(false);
  };

  const hideSelReceveivePopup = () => {
    setShowSelReceveivePopup(false);
  };

  const openReceiverPopup = async antotype => {
    await setSelToType(antotype);
    setShowReceveivePopup(true);
  };

  const openSelReceiverPopup = async antotype => {
    await setSelToType(antotype);
    setShowSelReceveivePopup(true);
  };

  const onHideClearValues = () => {
    setSelToType(-1);
    setReceiverDetails({});
    setToReceiverId(-1);
    setCcReceiverId(-1);
    setToReceiverPortal(-1);
    setCcReceiverPortal(-1);
    setToReceiver('');
    setCcReceiver('');
    setOpsTemplateData({});
  };

  return (
    <Popup
      title={'Case-Note'}
      width={800}
      height={600}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onShown={() => {
        if (!caseSituationAction) {
          if (contactPortalid === -10) {
            openSelReceiverPopup(1);
          }
        }
      }}
      onHiding={() => {
        onHideClearValues();
        if (noteInquiryId === -1) {
          hidePopup(noteInquiryId);
        }
      }}
    >
      <CasesNotesToReceivePop
        popTitle={'To'}
        showPopup={showReceveivePopup}
        hidePopup={hideReceveivePopup}
        user={user}
        entryid={entryid}
        toType={selToType}
        getReceiver={getReceiver}
      />
      <CasesNotesToSelReceivePop
        popTitle={'To'}
        showPopup={showSelReceveivePopup}
        hidePopup={hideSelReceveivePopup}
        user={user}
        entryid={entryid}
        toType={selToType}
        getReceiver={getReceiver}
        clientId={contactClientid}
      />
      <ResponsiveBox>
        <Row ratio={0.1} />
        <Row ratio={20} />
        <Row ratio={1} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <ResponsiveBox>
            <Row ratio={1} />
            <Col ratio={1} />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <Form
                id={'notes-to-pop'}
                formData={caseNoteTo}
                labelLocation={'left'}
                colCountByScreen={colCountByScreen}
              >
                <Item itemType='group' colCount={5}>
                  <ButtonItem horizontalAlignment='left'>
                    <ButtonOptions
                      text='to'
                      width={'100%'}
                      height={'36px'}
                      type='default'
                      onClick={() => {
                        openReceiverPopup(1);
                      }}
                    />
                  </ButtonItem>

                  <SimpleItem dataField='sendNoteTo' colSpan={4}>
                    <Label visible={false} text='to' />
                  </SimpleItem>
                </Item>
                {/*                <Item itemType='group' colCount={5}>
                 <ButtonItem horizontalAlignment='left'>
                    <ButtonOptions
                      text='CC'
                      width={'100%'}
                      height={'36px'}
                      type='success'
                      onClick={() => {
                        openReceiverPopup(2);
                      }}
                    />
                  </ButtonItem>

                  <SimpleItem dataField='sendNoteCC' colSpan={4}>
                    <Label visible={false} text='cc' />
                  </SimpleItem>

                  
                </Item>
*/}
              </Form>
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id='notes-to-subj-cont'>
            <Form
              id={'notes-to-pop'}
              ref={sendCaseNotetoFormRef}
              formData={caseNote}
              labelLocation={'left'}
              colCountByScreen={colCountByScreen}
              //  validationGroup='sendCaseNoteOps'
            >
              <Item itemType='group' colCount={1}>
                <SimpleItem dataField='subject'>
                  <Label text='Subject' />
                </SimpleItem>
              </Item>

              <Item itemType='group' colCount={1}>
                <SimpleItem dataField='case_content' editorType='dxTextArea' editorOptions={{ height: 310 }}>
                  <Label text='Content' />
                </SimpleItem>
              </Item>
            </Form>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={2} col={0} />
          <div id={'notes-to-closebutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                // id='savebutton-right'
                width={200}
                height={50}
                text='Send'
                type='default'
                stylingMode='contained'
                // validationGroup='sendCaseNoteOps'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default CasesNotesToPop;
