import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import Box, { Item } from 'devextreme-react/box';
import { useHttpClient } from '../../hooks/http-hook';

import { Form, SimpleItem, Label } from 'devextreme-react/form';

import ArrayStore from 'devextreme/data/array_store';

const ServiceFeaturePop = props => {
  const { showPopup, hidePopup, serviceId, serviceCategoryId, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [featureChanged, setFeatureChanged] = useState(false);
  const srvDataFormRef = useRef(null);
  const [lpAssets, setLpAssets] = useState([]);
  const [lpTradingAssets, setLpTradingAssets] = useState([]);
  const [featureGroupsList, setFeatureGroupsList] = useState([]);

  useEffect(() => {
    const fetchFeatureGroups = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/featuregroups/${serviceCategoryId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setFeatureGroupsList(dataArray);
      } catch (err) {}
    };
    const fetchAssets = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/serviceassetsofcategory/${serviceCategoryId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpAssets(dataArray);
      } catch (err) {}
    };

    const fetchTradingAssets = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/9',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpTradingAssets(dataArray);
      } catch (err) {}
    };

    if (showPopup && serviceCategoryId) {
      fetchAssets();
      fetchFeatureGroups();
      fetchTradingAssets();
    }
  }, [sendRequest, showPopup]);

  const stAssetsData = new ArrayStore({
    key: 'assetid',
    data: lpAssets,
  });

  const stFeatureGroupsList = new ArrayStore({
    key: 'group_id',
    data: featureGroupsList,
  });

  const stTradingAssets = new ArrayStore({
    key: 'lp_id',
    data: lpTradingAssets,
  });

  const srvFeatureData = {
    area_id: -1,
    group_id: -1,
    asset_id: -1,
  };

  const modSystemServiceGroup = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/service/modsystemservicegroup',
        'POST',
        JSON.stringify({
          operation: 1,
          category_id: serviceCategoryId,
          serviceid: serviceId,
          area_id: srvFeatureData.area_id,
          group_id: srvFeatureData.group_id,
          assetid: srvFeatureData.asset_id,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      hidePopup(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Popup
      title={'Service Feature'}
      width={600}
      height={240}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      onShowing={() => {
        setFeatureChanged(false);
      }}
    >
      <Box id='servicefeatureform' direction='col' height='100%'>
        <Item ratio={4} width='100%'>
          <Form id={'form'} ref={srvDataFormRef} formData={srvFeatureData} labelLocation={'top'}>
            <Item itemType='group' colCount={6}>
              <SimpleItem
                dataField='area_id'
                editorType='dxSelectBox'
                colSpan={2}
                name='featuregroup_listbox'
                editorOptions={{
                  dataSource: stFeatureGroupsList,
                  valueExpr: 'group_id',
                  displayExpr: 'feature',
                  searchEnabled: false,
                  
                }}
              >
                <Label text='Section' />
              </SimpleItem>
              <SimpleItem
                dataField='group_id'
                editorType='dxSelectBox'
                colSpan={2}
                name='featureasset_listbox'
                editorOptions={{
                  dataSource: stAssetsData,
                  valueExpr: 'assetid',
                  displayExpr: 'assetname',
                  searchEnabled: true,
                  
                }}
              >
                <Label text='Group' />
              </SimpleItem>

              <SimpleItem
                dataField='asset_id'
                editorType='dxSelectBox'
                colSpan={2}
                name='buysell_listbox'
                editorOptions={{
                  dataSource: stTradingAssets,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                  
                }}
              >
                <Label text='Asset' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item ratio={2}>
          <div id={'closebutton-pop'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton-right'
                width={120}
                height={50}
                text='Add'
                type='normal'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={modSystemServiceGroup}
              />
              <Button width={120} height={50} text='Close' type='normal' stylingMode='contained' onClick={hidePopup} />
            </div>
          </div>
        </Item>
      </Box>
    </Popup>
  );
};

export default ServiceFeaturePop;
