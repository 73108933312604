import React, { useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import MultiView from 'devextreme-react/multi-view';

import DataGrid, { Column } from 'devextreme-react/data-grid';
import './services.css';
import { SelectBox } from 'devextreme-react/select-box';
import Box from 'devextreme-react/box';
import Tabs from 'devextreme-react/tabs';
import ArrayStore from 'devextreme/data/array_store';

const ProductsEditPop = props => {
  const { showPopup, hidePopup, selProductid, selRoutingid, user, selcontactid, selucompany, showTabs, modeInsert } =
    props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const dataFormRef = useRef(null);
  const [lpRecPeriod, setLpRecPeriod] = useState([]);
  const [lpIdentityType, setLpIdentityType] = useState([]);
  const [lpRiskLevel, setLpRiskLevel] = useState([]);
  const [productData, setProductData] = useState({});
  const [productServicesData, setProductServicesData] = useState([]);
  const [routingServicesData, setRoutingServicesData] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [productServiceDataMod, setProductServiceDataMod] = useState();

  useEffect(() => {
    const fetchRecPeriod = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/2',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpRecPeriod(dataArray);
      } catch (err) {}
    };

    const fetchIdentityType = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/3',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpIdentityType(dataArray);
      } catch (err) {}
    };

    const fetchRiskLevel = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/4',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpRiskLevel(dataArray);
      } catch (err) {}
    };

    if (showPopup) {
      fetchRecPeriod();
      fetchIdentityType();
      fetchRiskLevel();
    }
  }, [sendRequest, showPopup]);

  useEffect(() => {
    const fetchSingleProduct = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/product/singleproduct/${selProductid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProductData(dataArray[0]);
      } catch (err) {}
    };
    if (selProductid && showPopup) {
      fetchSingleProduct();
    }
  }, [sendRequest, selProductid, showPopup]);

  useEffect(() => {
    const fetchProductServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/product/systemservices/${selProductid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProductServicesData(dataArray);
      } catch (err) {}
    };
    if (selProductid && showPopup) {
      fetchProductServices();
    }
  }, [sendRequest, selProductid, showPopup, productServiceDataMod]);

  useEffect(() => {
    const fetchRoutingServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/routing/routingsystemservices/${selRoutingid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setRoutingServicesData(dataArray);
      } catch (err) {}
    };
    if (selRoutingid && showPopup) {
      fetchRoutingServices();
    }
  }, [sendRequest, selRoutingid, showPopup]);

  const stProductServicesData = new ArrayStore({
    key: 'serviceid',
    data: productServicesData,
  });

  const stRoutingServicesData = new ArrayStore({
    key: 'serviceid',
    data: routingServicesData,
  });

  const stlpRecPeriodData = new ArrayStore({
    key: 'lp_id',
    data: lpRecPeriod,
  });

  const stlpIdentityType = new ArrayStore({
    key: 'lp_id',
    data: lpIdentityType,
  });

  const stRiskLevel = new ArrayStore({
    key: 'lp_id',
    data: lpRiskLevel,
  });

  const product_FormData = {
    productid: (productData && productData.productid) || -1,
    partnerid: (productData && productData.partnerid) || user.managedpartner,
    productname: (productData && productData.productname) || '',
    startdate: productData && productData.startdate,
    enddate: productData && productData.enddate,
    setupfee: productData && productData.setupfee,
    recurring_fee: productData && productData.recurring_fee,
    recurring_period: (productData && productData.recurring_period) || -1,
    blacklist_id: (productData && productData.blacklist_id) || -1,
    whitelist_id: (productData && productData.whitelist_id) || -1,
    identitytype: (productData && productData.identitytype) || -1,
    risklevel: (productData && productData.risklevel) || -1,
    autoorder: (productData && productData.autoorder) || false,
    userid: (productData && productData.userid) || user.userId,
    currency: (productData && productData.currency) || '',
    productactive: (productData && productData.productactive) || false,
  };

  const service_FormData = {
    serviceid: (selectedService && selectedService.serviceid) || -1,
  };

  const ModifyProduct = async () => {
    let OpType = 1;
    if (modeInsert) {
      OpType = 1;
    } else {
      OpType = 2;
    }
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/product/modproduct',
        'POST',
        JSON.stringify({
          operation: OpType,
          productid: product_FormData.productid,
          partnerid: product_FormData.partnerid,
          productname: product_FormData.productname,
          startdate: product_FormData.startdate,
          enddate: product_FormData.enddate,
          setupfee: product_FormData.setupfee,
          recurring_fee: product_FormData.recurring_fee,
          recurring_period: product_FormData.recurring_period,
          blacklist_id: product_FormData.blacklist_id,
          whitelist_id: product_FormData.whitelist_id,
          identitytype: product_FormData.identitytype,
          risklevel: product_FormData.risklevel,
          autoorder: product_FormData.autoorder,
          userid: product_FormData.userid,
          currency: product_FormData.currency,
          productactive: product_FormData.productactive,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    hidePopup(true);
  }

  const validateForm = async dataFormRef => {
    const result = dataFormRef.validationGroup.validate();
    if (result.isValid) {
      await ModifyProduct();
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  const modProductService = async modify => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/product/modproductservices',
        'POST',
        JSON.stringify({
          productid: product_FormData.productid,
          system_service_id: service_FormData.serviceid,
          operation: modify,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setProductServiceDataMod(!productServiceDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Popup
      title={'Product'}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      //    onShowing={() => {
      //      setContactsChanged(false);
      //    }}
    >
      <ResponsiveBox>
        <Row ratio={2} />
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form
            id={'form'}
            ref={dataFormRef}
            formData={product_FormData}
            labelLocation={'top'}
            colCountByScreen={colCountByScreen}
            validationGroup='product_create_data'
          >
            <Item itemType='group' colCount={4}>
              <SimpleItem dataField='productname' isRequired={true} editorOptions={baseEditorOptions}>
                <RequiredRule message='Please enter a Product Name' />
                <Label text='Name' />
              </SimpleItem>
              <SimpleItem dataField='productactive' editorType='dxCheckBox'>
                <Label text='Active' />
              </SimpleItem>
              <SimpleItem dataField='startdate' editorType='dxDateBox' editorOptions={{ type: 'date' }}>
                <Label text='Start Date' />
              </SimpleItem>
            </Item>

            <Item itemType='group' colCount={3}>
              <SimpleItem dataField='setupfee' editorOptions={baseEditorOptions}>
                <Label text='Setup Fee' />
              </SimpleItem>
              <SimpleItem dataField='recurring_fee' editorOptions={baseEditorOptions}>
                <Label text='Recurring Fee' />
              </SimpleItem>

              <SimpleItem
                dataField='recurring_period'
                editorType='dxSelectBox'
                colSpan={1}
                name='recurring_listbox'
                isRequired={true}
                editorOptions={{
                  dataSource: stlpRecPeriodData,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                  
                }}
              >
                <Label text='Recurring Period' />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={4}>
              <SimpleItem
                dataField='identitytype'
                editorType='dxSelectBox'
                name='identitytype_listbox'
                editorOptions={{
                  dataSource: stlpIdentityType,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                  
                }}
              >
                <Label text='Person/Company' />
              </SimpleItem>

              <SimpleItem
                dataField='risklevel'
                editorType='dxSelectBox'
                name='risklevel_listbox'
                editorOptions={{
                  dataSource: stRiskLevel,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                  
                }}
              >
                <Label text='Risk Level' />
              </SimpleItem>
              <SimpleItem dataField='currency' editorOptions={baseEditorOptions}>
                <Label text='Currency' />
              </SimpleItem>
              <SimpleItem dataField='autoorder' editorType='dxCheckBox'>
                <Label text='Single Services Ordering' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <ResponsiveBox>
            <Row ratio={1} />
            <Row ratio={4} />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} colspan={3} />
              <div id='margin-services-edit-box'>
                <Box direction='row' height={40} width='60%'>
                  <Item ratio={3}>
                    <Form id={'form'} formData={service_FormData} labelLocation={'left'}>
                      <SimpleItem
                        dataField='serviceid'
                        editorType='dxSelectBox'
                        colSpan={1}
                        name='service_listbox'
                        editorOptions={{
                          dataSource: stRoutingServicesData,
                          valueExpr: 'serviceid',
                          displayExpr: 'servicename',
                          searchEnabled: true,
                          
                        }}
                      >
                        <Label location='left' text='Service' />
                      </SimpleItem>
                    </Form>
                  </Item>
                  <Item ratio={1}>
                    <Button
                      id='companytab-button'
                      width={130}
                      height={'36px'}
                      text='Add'
                      type='normal'
                      onClick={() => {
                        modProductService(1);
                      }}
                    />
                  </Item>
                  <Item ratio={1}>
                    <Button
                      id='companytab-button'
                      width={130}
                      height={'36px'}
                      text='Remove'
                      type='normal'
                      onClick={() => {
                        modProductService(3);
                      }}
                    />
                  </Item>
                </Box>
              </div>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={1} col={0} />
              <DataGrid
                id='product-services-edit-grid'
                // ref={departmentDataGrid}
                dataSource={stProductServicesData}
                showRowLines={true}
                showBorders={true}
                showColumnLines={true}
                columnAutoWidth={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                showColumnHeaders={true}
                onFocusedRowChanged={e => {
                  setSelectedService(e.row.data);
                }}
              >
                <Column dataField='serviceid' visible={false}></Column>
                <Column dataField='servicename' caption='Product Services'></Column>
              </DataGrid>
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={2} col={0} />
          <div id={'contactpopbutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={50}
                text='Save'
                type='normal'
                stylingMode='contained'
                validationGroup='product_create_data'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ProductsEditPop;
