import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './ServiceProviderData.css';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import { useHttpClient } from '../../hooks/http-hook';

import ArrayStore from 'devextreme/data/array_store';

const ServicesEditProviderName = (props) => {
  const history = useHistory();

  const { svProviderId, svPersonId, modeInsert, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const dataFormRef = useRef(null);
  const [providerData, setProviderData] = useState({});
  const [companyContact, setCompanyContact] = useState({});
  const [lpCategories, setLpCategories] = useState([]);

  useEffect(() => {
    const fetchServiceProvider = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/provider/serviceprov/${svProviderId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProviderData(dataArray[0]);
      } catch (err) {}
    };

    const fetchCompanyContact = async (an_contactid) => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/contacts/company/${an_contactid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCompanyContact(dataArray[0]);
      } catch (err) {}
    };

    if (svProviderId) {
      fetchServiceProvider();

      if (svPersonId) {
        fetchCompanyContact(svPersonId);
      } else {
        fetchCompanyContact(-1);
      }
    }
  }, [sendRequest, svProviderId]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/service/servicecat', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setLpCategories(dataArray);
      } catch (err) {}
    };

    fetchCategories();
  }, [sendRequest]);

  const stLpCategoriesData = new ArrayStore({
    key: 'category_id',
    data: lpCategories,
  });

  const providerFormData = {
    providerid: (providerData && providerData.providerid) || -1,
    personid: (providerData && providerData.personid) || -1,
    categoryid: (providerData && providerData.categoryid) || -1,
    providername: (providerData && providerData.providername) || '',

    contactid: companyContact.contactid,
    lastname: companyContact.lastname,
    street: companyContact.street,
    city_code: companyContact.city_code,
    city: companyContact.city,
    phone_one: companyContact.phone_one,
    email: companyContact.email,
    homepage: companyContact.homepage,
    addressfield: companyContact.addressfield,
    nationality: companyContact.nationality,
    res_cntry: companyContact.res_cntry,
    contact_cat: companyContact.contact_cat,
  };

  function navigateToHome() {
    history.goBack();
  }

  const validateForm = async (dataFormRef) => {
    const result = dataFormRef.validationGroup.validate();
    // console.log(result);
    if (result.isValid) {
      saveNameAddress();
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  const saveNameAddress = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/provider/serviceprovider',
        'POST',
        JSON.stringify({
          operation: modeInsert ? 1 : 2,
          p_providerid: providerFormData.providerid,
          p_personid: providerFormData.personid || -1,
          p_categoryid: providerFormData.categoryid || -1,
          p_providername: providerFormData.providername || '',
          c_contactid: providerFormData.contactid || -1,
          c_lastname: providerFormData.providername || '',
          c_addressfield: providerFormData.addressfield || '',
          c_email: providerFormData.email || '',
          c_phone_one: providerFormData.phone_one || '',
          c_homepage: providerFormData.homepage || '',
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      // setContactsChanged(true);
      // setEmployeesChanged(!employeesChanged);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Provider</div>
        <ResponsiveBox>
          <Row />
          <Col />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <Form
              ref={dataFormRef}
              formData={providerFormData}
              labelLocation={'left'}
              colCountByScreen={colCountByScreen}
              validationGroup='ServProvData'
            >
              <Item itemType='group' colCount={12}>
                <SimpleItem dataField='providername' colSpan={6} isRequired={true} editorOptions={baseEditorOptions}>
                  <RequiredRule message='Please enter a Service Provider Name' />
                  <Label text='Name' />
                </SimpleItem>
                <SimpleItem
                  dataField='categoryid'
                  editorType='dxSelectBox'
                  colSpan={6}
                  name='category_listbox'
                  editorOptions={{
                    dataSource: stLpCategoriesData,
                    valueExpr: 'category_id',
                    displayExpr: 'category_name',
                    searchEnabled: true,
                  }}
                >
                  <Label text='Category' />
                </SimpleItem>
              </Item>
              <EmptyItem />

              <Item itemType='group' colCount={12}>
                <SimpleItem
                  dataField='addressfield'
                  colSpan={8}
                  editorType='dxTextArea'
                  editorOptions={{ height: 120 }}
                >
                  <Label text='Address' visible={false} />
                </SimpleItem>
              </Item>
              <Item itemType='group' colCount={12}>
                <SimpleItem dataField='email' colSpan={6} editorOptions={baseEditorOptions}>
                  <Label text='E-Mail' />
                </SimpleItem>
                <SimpleItem dataField='phone_one' colSpan={6} editorOptions={baseEditorOptions}>
                  <Label text='Phone' />
                </SimpleItem>
              </Item>
              <Item itemType='group' colCount={12}>
                <SimpleItem dataField='homepage' colSpan={8} editorOptions={baseEditorOptions}>
                  <Label text='Home Page' />
                </SimpleItem>
              </Item>
            </Form>
            <div id={'serviceprovider-edit-button'}>
              <div style={{ flexDirection: 'row' }}>
                <Button
                  id='savebutton'
                  width={120}
                  height={36}
                  text='Save'
                  type='default'
                  stylingMode='outlined'
                  validationGroup='ServProvData'
                  useSubmitBehavior={true}
                  onClick={validateForm}
                />
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ServicesEditProviderName;
