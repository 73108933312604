import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import './PartnerPage.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

const PartnerProducts = props => {
  const { user, selPartnerId } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [productsList, setProductsList] = useState([]);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [popupTabsVisible, setPopupTabsVisible] = useState(false);
  const [popModeInsert, setpopModeInsert] = useState(false);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [selectedProductid, setSelectedProductid] = useState();
  const [selectedRoutingid, setSelectedRoutingid] = useState();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/product/products/${selPartnerId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProductsList(dataArray);
      } catch (err) {}
    };
    if (selPartnerId) {
      fetchProducts();
    }
  }, [sendRequest, selPartnerId]);

  const stProductData = new ArrayStore({
    key: 'productid',
    data: productsList,
  });

  const dcProductData = new DataSource({
    store: stProductData,
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />

        <Item>
          <Location screen='md lg sm, xs' row={0} col={0} />
          <div>
            <DataGrid
              id='partner-products-tab-grid'
              dataSource={dcProductData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={e => {
                if (e.row) {
                  setSelectedProductid(e.row.key);
                  setSelectedRoutingid(e.row.data.routingid);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='productid' visible={false}></Column>
              <Column dataField='partnerid' visible={false}></Column>
              <Column dataField='routingid' visible={false}></Column>
              <Column dataField='productname' caption='Product' width={250}></Column>
              <Column dataField='productactive' caption='Active' width={60}></Column>
              <Column dataField='startdate' dataType='date' caption='Start Date' width={100} />
              <Column dataField='enddate' dataType='date' caption='End Date' width={100} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default PartnerProducts;
