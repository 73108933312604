import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Box, { Item } from 'devextreme-react/box';
import { TextBox } from 'devextreme-react/text-box';
import { useHttpClient } from '../../hooks/http-hook';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ArrayStore from 'devextreme/data/array_store';

const ServiceFeaturePop = props => {
  const { showPopup, hidePopup, srvceCatID, srvceRowData, servceaddFeature, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [featureChanged, setFeatureChanged] = useState(false);
  const srvDataFormRef = useRef(null);
  const [lpAssets, setLpAssets] = useState([]);
  const [lpCurrencies, setLpCurrencies] = useState([]);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/9',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpAssets(dataArray);
      } catch (err) {}
    };

    const fetchCurrencies = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/10',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpCurrencies(dataArray);
      } catch (err) {}
    };

    if (showPopup) {
      fetchAssets();
      fetchCurrencies();
    }
  }, [sendRequest, showPopup]);

  const stAssetsData = new ArrayStore({
    key: 'lp_id',
    data: lpAssets,
  });

  const stCurrenciesData = new ArrayStore({
    key: 'lp_id',
    data: lpCurrencies,
  });

  const srvFeatureData = {
    servicefeature_id: srvceRowData && !servceaddFeature ? srvceRowData.servicefeature_id : -1,
    category_id: srvceCatID,
    feature: srvceRowData && !servceaddFeature ? srvceRowData.feature : '',
    account_feature: srvceRowData && !servceaddFeature ? srvceRowData.account_feature : false,
    main_feature: srvceRowData && !servceaddFeature ? srvceRowData.main_feature : false,
    account_operation: srvceRowData && !servceaddFeature ? srvceRowData.account_operation : false,
    asset_operation: srvceRowData && !servceaddFeature ? srvceRowData.asset_operation : false,
    group_id: srvceRowData && !servceaddFeature ? srvceRowData.group_id : -1,
    group_position: srvceRowData && !servceaddFeature ? srvceRowData.group_position : -1,
    buy: srvceRowData && !servceaddFeature ? srvceRowData.buy : false,
    sell: srvceRowData && !servceaddFeature ? srvceRowData.sell : false,
    list: srvceRowData && !servceaddFeature ? srvceRowData.list : false,
    send: srvceRowData && !servceaddFeature ? srvceRowData.send : false,
    receive: srvceRowData && !servceaddFeature ? srvceRowData.receive : false,
    buysellasset: srvceRowData && !servceaddFeature ? srvceRowData.buysellasset : -1,
    sendreceiveasset: srvceRowData && !servceaddFeature ? srvceRowData.sendreceiveasset : -1,
  };

  const modServiceFeature = async modify => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/service/servicefeature',
        'POST',
        JSON.stringify({
          servicefeature_id: srvFeatureData.servicefeature_id ? srvceRowData.servicefeature_id : -1,
          category_id: srvceCatID,
          feature_name: srvFeatureData.feature ? srvFeatureData.feature : '',
          account_feature: srvFeatureData.account_feature ? srvFeatureData.account_feature : false,
          main_feature: srvFeatureData.main_feature ? srvFeatureData.main_feature : false,
          account_operation: srvFeatureData.account_operation ? srvFeatureData.account_operation : false,
          asset_operation: srvFeatureData.asset_operation ? srvFeatureData.asset_operation : false,
          group_id: srvFeatureData.group_id ? srvFeatureData.group_id : -1,
          group_position: srvFeatureData.group_position ? srvFeatureData.group_position : -1,
          buy: srvFeatureData.buy ? srvFeatureData.buy : false,
          sell: srvFeatureData.sell ? srvFeatureData.sell : false,
          list: srvFeatureData.list ? srvFeatureData.list : false,
          send: srvFeatureData.send ? srvFeatureData.send : false,
          receive: srvFeatureData.receive ? srvFeatureData.receive : false,
          buysellasset: srvFeatureData.buysellasset ? srvFeatureData.buysellasset : -1,
          sendreceiveasset: srvFeatureData.sendreceiveasset ? srvFeatureData.sendreceiveasset : -1,
          operation: modify,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const validatePopup = async srvDataFormRef => {
    const result = srvDataFormRef.validationGroup.validate();
    // console.log(result);
    if (result.isValid) {
      setFeatureChanged(true);
      if (servceaddFeature) {
        await modServiceFeature(1);
      } else {
        await modServiceFeature(2);
      }

      hidePopup(true);
    } else {
      alert('Please complete all required fields');
    }
  };

  return (
    <Popup
      title={'Service Feature'}
      width={800}
      height={640}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      onShowing={() => {
        setFeatureChanged(false);
      }}
    >
      <Box id='employeeform' direction='col' height='100%'>
        <Item ratio={4} width='100%'>
          <Form
            id={'form'}
            ref={srvDataFormRef}
            formData={srvFeatureData}
            validationGroup='serviceFeatureValGrp'
            labelLocation={'top'}
          >
            <Item itemType='group' colCount={12}>
              <SimpleItem dataField='feature' colSpan={4}>
                <Label text='Feature' />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={12}>
              <SimpleItem dataField='main_feature' colSpan={3} editorType='dxCheckBox'>
                <Label text='Service Account' />
              </SimpleItem>
              <SimpleItem dataField='account_feature' colSpan={3} editorType='dxCheckBox'>
                <Label text='Asset Account' />
              </SimpleItem>
              <SimpleItem dataField='account_operation' colSpan={3} editorType='dxCheckBox'>
                <Label text='Account Operation' />
              </SimpleItem>
              <SimpleItem dataField='asset_operation' colSpan={3} editorType='dxCheckBox'>
                <Label text='Asset Operation' />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={10}>
              <SimpleItem dataField='group_id' colSpan={2} editorType='dxNumberBox'>
                <Label text='Group ID' />
              </SimpleItem>
              <SimpleItem dataField='group_position' colSpan={2} editorType='dxNumberBox'>
                <Label text='Group Position' />
              </SimpleItem>
            </Item>
            <EmptyItem />
            <Item itemType='group' colCount={10} caption='Operation'>
              <SimpleItem dataField='buy' editorType='dxCheckBox'>
                <Label text='Buy' />
              </SimpleItem>
              <SimpleItem dataField='sell' editorType='dxCheckBox'>
                <Label text='Sell' />
              </SimpleItem>
              <SimpleItem dataField='list' editorType='dxCheckBox'>
                <Label text='List' />
              </SimpleItem>
              <SimpleItem dataField='send' editorType='dxCheckBox'>
                <Label text='Send' />
              </SimpleItem>
              <SimpleItem dataField='receive' editorType='dxCheckBox'>
                <Label text='Receive' />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={4} caption='Asset'>
              <SimpleItem
                dataField='buysellasset'
                editorType='dxSelectBox'
                colSpan={2}
                name='buysell_listbox'
                editorOptions={{
                  dataSource: stAssetsData,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                  
                }}
              >
                <Label text='Buy/Sell' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item ratio={1}>
          <div id={'closebutton-2'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton-right'
                width={120}
                height={50}
                text='Save'
                type='default'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={validatePopup}
              />
              <Button width={120} height={50} text='Close' type='normal' stylingMode='contained' onClick={hidePopup} />
            </div>
          </div>
        </Item>
      </Box>
    </Popup>
  );
};

export default ServiceFeaturePop;
