import React, { useState, useCallback } from "react";
import "./services.css";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

import { Tabs, MultiView } from "devextreme-react";
import { useAuth } from "../../contexts/auth";

import ServicesOneTab from "./ServicesOneTab";
import ServiceFeatures from "./ServiceFeatures";
import ServiceCostRates from "./ServiceCostRates";
import ServiceProvider from "./ServiceProvider";
import ServiceStatus from "./ServiceStatus";
import ServiceAccount from "./ServiceAccount";
import ServicesNotes from "./ServicesNotes";

const ServicesPage = () => {
  const { user } = useAuth();
  const [selectedServiceId, setSelectedServiceId] = useState();
  const [tabIndex, setTabIndex] = useState(0);

  const getServiceid = useCallback((fServiceId) => {
    setSelectedServiceId(fServiceId);
  });

  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Cases</h2> */}
      <div className={"content-block"}>
        <div className={"dx-card wide-card"}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={2} />
            <Col />

            <Item>
              <Location screen="md lg" row={0} col={0} colspan={2} />
              <Location screen="sm" row={0} col={0} colspan={1} />
              <Location screen="xs" row={0} col={0} />
              <div className="rb-header item">
                <ResponsiveBox>
                  <Row />
                  <Col />
                  <Item>
                    <Location screen="md lg sm" row={0} col={0} />
                    <div className="dx-fieldset-header" id="cap_item">
                      System Services
                    </div>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen="md lg sm" row={1} col={0} />
              <Location screen="xs" row={1} col={0} />
              <div className="rb-content item">
                <ResponsiveBox>
                  <Row />
                  <Col />
                  <Item>
                    <Location screen="md lg sm xs" row={0} col={0} />
                    
                    <ServicesOneTab passToParent={getServiceid} user={user} />
                      <div>
                      <ResponsiveBox>
                      <Row  />
                      <Col ratio={0.3} />
                      <Col ratio={3} />
                      <Item>
                        <Location screen="md lg sm xs" row={0} col={0} />
                      </Item>
                      <Item>
                        <Location screen="md lg sm xs" row={0} col={1} />
                      <Tabs
                        selectedIndex={tabIndex}
                        width="99%"
                        // height='20px'
                        visible={true}
                        onItemClick={(e) => {
                          setTabIndex(e.itemIndex);
                        }}
                      >
                        <Item text="Features" />
                        <Item text="Cost Rates" />
                        <Item text="Service Provider" />
                        <Item text="Bank Accounts" />
                      </Tabs>
                      <MultiView
                        height={400}
                        animationEnabled={false}
                        selectedIndex={tabIndex}
                      >
                        <Item title="Assinged Services">
                          <ServiceFeatures
                            selSystemServiceID={selectedServiceId}
                            selTabIndex={tabIndex}
                            user={user}
                          />
                        </Item>
                        <Item title="Cost Rates">
                          <ServiceCostRates
                            selServiceId={selectedServiceId}
                            user={user}
                          />
                        </Item>
                        <Item title="Compliance">
                          <ServiceProvider
                            selServiceId={selectedServiceId}
                            user={user}
                          />
                        </Item>
                        <Item title="Accounts">
                          <ServiceAccount
                            selServiceId={selectedServiceId}
                            user={user}
                          />
                        </Item>
                      </MultiView>
                      </Item>
                      </ResponsiveBox>
                      </div>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ServicesPage;
