import React, { useEffect, useState, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';

import './Extensions.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';
import ExtensionAccounts from './ExtensionAccounts';
import ForexSetup from './ForexSetup';
import ForexTrades from './ForexTrades';

const ExtensionsPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();

  const extAccountId = 1;

  useEffect(() => {
    if (!user) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='fxtrading-box'>
                <ExtensionAccounts user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='fxtrading-box'>
                <ForexSetup user={user} extAccountId={extAccountId} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={1} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='fxtrading-line'><ForexTrades user={user} extAccountId={extAccountId} /></div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={3} col={1} />
          <div id={'client-close-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ExtensionsPage;
