import React, { useState, useEffect } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow, MasterDetail } from 'devextreme-react/data-grid';

import ProvServiceDetailGrid from '../../detailgrids/ProvServiceDetailGrid';

const ServiceProviderServices = props => {
  const { selProviderId, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [provServiceData, setProvServiceData] = useState([]);

  useEffect(() => {
    const fetchProviderServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/provider/providerservices/${selProviderId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProvServiceData(dataArray);
      } catch (err) {}
    };

    if (selProviderId) {
      fetchProviderServices();
    }
  }, [sendRequest, selProviderId]);

  const stprovServiceData = new ArrayStore({
    key: ['serviceid'],
    data: provServiceData,
  });

  const dcprovServiceData = new DataSource({
    store: stprovServiceData,
  });

  return (
    <ResponsiveBox>
      <Col />
      <Row />
      <Item>
        <Location screen='md lg sm xs' row={0} col={0} />
        <div>
          <DataGrid
            id='serviceprovider-services-tab-grid'
            dataSource={dcprovServiceData}
            // defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            allowColumnReordering={false}
            allowColumnResizing={false}
            focusedRowEnabled={true}
            showBorders={true}
            showRowLines={true}
            showColumnLines={false}
            autoNavigateToFocusedRow={true}
            // onFocusedRowChanged={e => {
            //   setRiskGridData(e.row.data);
            //   setSelRiskid(e.row.data.riskid);
            // }}
          >
            <Column dataField='serviceid' visible={false} />
            <Column dataField='categoryid' visible={false} />
            <Column dataField='providerid' visible={false} />
            <Column dataField='servicename' caption='Service' width='250px' />
            <Column dataField='category_name' caption='Category' width='200px' />
            <Column dataField='currency' caption='Currency' width='80px' />
            <Column dataField='active' caption='Active' dataType='boolean' width='180' />
            <Column dataField='active_date' caption='Active on' dataType='date' width='200' />
            <MasterDetail enabled={true} component={ProvServiceDetailGrid} />
          </DataGrid>
        </div>
      </Item>
    </ResponsiveBox>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServiceProviderServices;
