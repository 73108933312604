import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import './partnerEditTOU.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';

import ArrayStore from 'devextreme/data/array_store';

import PartnerEditTOUPop from './PartnerEditTOUPop';
import MessagePop from '../../ui_elements/MessagePop';

const PartnerDataTOU = (props) => {
  const { user } = props;
  const history = useHistory();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [partnerTouData, setPartnerTouData] = useState([]);
  const [partnerTouId, setPartnerTouId] = useState(-1);
  const [showPopup, setShowPopup] = useState(false);
  const [popInsert, setPopInsert] = useState(0);
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [reloadTouData, setReloadTouData] = useState(false);

  useEffect(() => {
    const fetchServiceTou = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/partner/partnerdoctou/${user.partnerid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setPartnerTouData(dataArray);
      } catch (err) {}
    };

    fetchServiceTou();
  }, [sendRequest, reloadTouData]);

  const stPartnerTouData = new ArrayStore({
    key: 'partnertouid',
    data: partnerTouData,
  });

  const removeTouDoc = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/partner/partnerdoctou',
        'POST',
        JSON.stringify({
          operation: 3,
          partnerid: user.partnerid,
          partnertouid: partnerTouId,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const addTermsofUse = () => {
    setPopInsert(1);
    setShowPopup(true);
  };

  const openTermsofUse = () => {
    setPopInsert(2);
    setShowPopup(true);
  };

  const hidePopup = () => {
    setShowPopup(false);
    setReloadTouData(!reloadTouData);
  };

  function navigateToHome() {
    history.goBack();
  }

  const hideMessagePop = useCallback((doRemove) => {
    setShowMessagePop(false);
    if (doRemove) {
      removeTouDoc();
      setReloadTouData(!reloadTouData);
    }
  });

  const removeTOU = () => {
    setShowMessagePop(true);
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected Terms of Use?'
      />
      <PartnerEditTOUPop
        showPopup={showPopup}
        hidePopup={hidePopup}
        selPartnerId={user.partnerid}
        selTermsofUseId={partnerTouId}
        popInsert={popInsert}
        user={user}
      />
      <div id='settings-tou-box'>
        <div className='rb-header item'>
          <div className='dx-fieldset-header'>Terms of Use</div>
        </div>

        <ResponsiveBox>
          <Row ratio={2} />
          <Col ratio={2} />
          <Col ratio={10} />

          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div id='hm-btn-section-0-1'>
              <Button
                width={100}
                height={36}
                text='Add'
                type='default'
                disabled={true}
                stylingMode='outlined'
                onClick={addTermsofUse}
              />
            </div>
            <div id='hm-btn-section-0-11'>
              <Button
                width={100}
                height={36}
                text='Edit'
                type='success'
                disabled={true}
                stylingMode='outlined'
                onClick={openTermsofUse}
              />
            </div>
            <div id='hm-btn-section-0-11'>
              <Button
                width={100}
                height={36}
                text='Rem'
                type='danger'
                disabled={true}
                stylingMode='outlined'
                onClick={removeTOU}
              />
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <div>
              <DataGrid
                height={400}
                dataSource={stPartnerTouData}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                columnAutoWidth={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                onFocusedRowChanged={(e) => {
                  setPartnerTouId(e.row.key);
                }}
              >
                <Column dataField='partnerid' visible={false}></Column>
                <Column dataField='partnertouid' visible={false}></Column>
                <Column dataField='doc_title' caption='Terms of Use' width='250px'></Column>
                <Column dataField='validon' dataType='date' width='90px'></Column>
                <Column dataField='validuntil' dataType='date' width='90px'></Column>
              </DataGrid>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default PartnerDataTOU;
