import React, { useRef, useState } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { Popup } from 'devextreme-react/popup';
import ResponsiveBox, { Col, Item, Location, Row } from 'devextreme-react/responsive-box';
import { Form, Item as FormItem, Label, SimpleItem } from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { CheckBox } from 'devextreme-react/check-box';
import { DocumentRequestFormData } from '../../modules/docexchange/forms';

const PartnerEditReqDocEditPopup = props => {
  const { showEditPopup, hideEditPopup, user, requiredDocData } = props;
  const { sendRequest } = useHttpClient();
  const popupRef = useRef(requiredDocData);

  const docTemplateData = {
    doc_title: requiredDocData?.usage_advice || '',
    docComment: requiredDocData?.comment || '',
    docId: requiredDocData?.id,
    signup_person: requiredDocData?.signup_person || false,
    signup_company: requiredDocData?.signup_company || false,
  };

  console.log(docTemplateData);

  const updateRequiredDoc = async () => {
    // console.log(docTemplateData);

    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/document/updateDocument',
        'POST',
        JSON.stringify({
          docTemplateData,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );
    } catch (err) {
      console.log(err);
    }
    notify('Document was successfully updated', 'success', 2000);
    hideEditPopup(true);
  };

  return (
    <Popup
      title='Edit Template Definitions'
      ref={popupRef}
      visible={showEditPopup}
      height={'auto'}
      width={'650px'}
      closeOnOutsideClick={true}
      showCloseButton={true}
      resizeEnabled={true}
      onHiding={() => {
        hideEditPopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={5} />
        <Row ratio={2} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form id='doc-add-dialog' ref={popupRef} formData={docTemplateData} labelLocation={'top'}>
            <SimpleItem dataField='doc_title' colSpan={2}>
              <Label text='Title' />
            </SimpleItem>
            <FormItem itemType='group' colCount={1}>
              <SimpleItem dataField='docComment' editorType='dxTextArea' editorOptions={{ height: 200 }}>
                <Label text='Description' />
              </SimpleItem>
            </FormItem>
            <FormItem itemType='group' colCount={1}>
              <Button
                id='btn-popup-dlg'
                text='Close'
                type='normal'
                height={40}
                width={120}
                onClick={() => hideEditPopup(true)}
              />
              <Button
                className='btn-popup'
                text='Save'
                type='default'
                height={40}
                width={120}
                onClick={updateRequiredDoc}
              />
            </FormItem>
          </Form>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default PartnerEditReqDocEditPopup;
