import React, { useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import ResponsiveBox, { Col, Item, Location, Row } from 'devextreme-react/responsive-box';
import { Form, Item as FormItem, Label, RequiredRule, SimpleItem } from 'devextreme-react/form';
import FileUploader from 'devextreme-react/file-uploader';
import { Validator } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import DocExchangeHelper from '../../modules/docexchange/DocExchangeHelper';
import Button from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { DocumentRequestFormData } from '../../modules/docexchange/forms';
import { Toast } from 'devextreme-react/toast';

const PartnerEditReqDocPopup = props => {
  const { showPopup, hidePopup, selPartnerId, selPersonId, partnername, operationId } = props;
  const noteFormRef = useRef(null);
  const popupRef = useRef(null);
  const uploadFileRef = useRef(null);
  const [docDetails, setDocDetails] = useState({});
  const [docDataTitle, setDocDataTitle] = useState();
  const [docDataDesc, setDocDataDesc] = useState();
  const [checkBoxPerson, setCheckBoxPerson] = useState(false);
  const [checkBoxCompany, setCheckBoxCompany] = useState(false);
  const [saveFile, setSaveFile] = useState(null);
  const [toastConfigVisible, setToastConfigVisible] = useState(false);

  const docTemplateData = {
    doc_title: docDataTitle || '',
    docComment: docDataDesc || '',
    file: null,
    signupPerson: false,
    signupCompany: false,
  };

  const saveRequiredDoc = async () => {
    console.log(checkBoxPerson);

    if (
      (docTemplateData.doc_title && docTemplateData.docComment && saveFile) ||
      (docTemplateData.doc_title && docTemplateData.docComment && saveFile)
    ) {
      try {
        const docData = new FormData();

        docData.append('comment', docTemplateData.docComment);
        docData.append('usage_advice', docTemplateData.doc_title);
        docData.append('signupPerson', docTemplateData.signupPerson);
        docData.append('signupCompany', docTemplateData.signupCompany);
        docData.append('template_doc', true);
        docData.append('selPartnerId', selPartnerId);
        docData.append('selPersonId', selPersonId);
        docData.append('partnername', partnername);
        docData.append('file', saveFile);

        await DocExchangeHelper.uploadDocumentGlobal(docData);

        notify('New document was successfully added', 'success', 2000);
        resetValues();
        hidePopup(true);
      } catch (err) {
        notify('Error sending data', 'error', 2000);
      }
    } else {
      setToastConfigVisible(true);
    }
  };

  const resetValues = () => {
    noteFormRef.current.instance.resetValues();
    uploadFileRef.current.instance.reset();
    setSaveFile(null);
    hidePopup(true);
  };

  const renderFileUploader = () => {
    return (
      <div className='fileuploader-container'>
        <FileUploader
          id={'requiredDocFileUploader'}
          selectButtonText='Select'
          labelText='or Drop a file here'
          uploadMode='instantly'
          uploadFile={file => {
            setSaveFile(file);
          }}
          ref={uploadFileRef}
        >
          {/*<Validator validationGroup="RequiredDocData">*/}
          {/*    <RequiredRule message="Select a file"/>*/}
          {/*</Validator>*/}
        </FileUploader>
      </div>
    );
  };

  const handleCheckboxPerson = e => {
    setCheckBoxPerson(e.value);
  };

  const handleCheckboxCompany = e => {
    setCheckBoxCompany(e.value);
  };

  const handleUpload = async () => {
    await saveRequiredDoc();
    resetValues();
    hidePopup(true);
  };

  return (
    <Popup
      title='Document Template'
      ref={popupRef}
      visible={showPopup}
      height={'auto'}
      width={600}
      closeOnOutsideClick={true}
      showCloseButton={true}
      resizeEnabled={true}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={7} />
        <Row ratio={2} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form id='doc-add-dialog' formData={docTemplateData} ref={noteFormRef} labelLocation={'top'}>
            <SimpleItem
              dataField='doc_title'
              colSpan={2}
              editorType='dxTextBox'
              editorOptions={{
                onValueChanged: e => {
                  setDocDataTitle(e.value);
                },
              }}
            >
              <Label text='Title' />
            </SimpleItem>
            <FormItem itemType='group' colCount={1}>
              <SimpleItem
                dataField='docComment'
                editorType='dxTextArea'
                editorOptions={{
                  height: 200,
                  onValueChanged: e => {
                    setDocDataDesc(e.value);
                  },
                }}
              >
                <Label text='Description' />
              </SimpleItem>
            </FormItem>
            <FormItem itemType='group' colCount={1}>
              <SimpleItem dataField='file' render={renderFileUploader}>
                <Label text='Upload document' />
              </SimpleItem>
            </FormItem>
            <FormItem colCount={1}>
              <Button
                id='btn-popup-dlg'
                text='Close'
                type='normal'
                height={40}
                width={120}
                onClick={() => {
                  resetValues();
                }}
              />
              <Button
                className='btn-popup'
                text='Save'
                type='default'
                onClick={saveRequiredDoc}
                height={40}
                width={120}
                useSubmitBehavior={false}
              />
            </FormItem>
          </Form>
        </Item>
      </ResponsiveBox>
      <Toast
        visible={toastConfigVisible}
        message='Please complete title, description and upload a document to save it as Template'
        type='error'
        displayTime={4000}
        onHiding={() => {
          setToastConfigVisible(false);
        }}
      />
    </Popup>
  );
};

export default PartnerEditReqDocPopup;
