import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import './services.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import ServicesCreatePop from './ServicesCreatePop';

const getScreenGridHeight = () => {
  const anValue = window.innerHeight - 650;
  // console.log(5678, anValue);
  return anValue;
};

const ServicesOneTab = props => {
  const { passToParent, user } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [servicesList, setServicesList] = useState([]);
  const [popModeInsert, setpopModeInsert] = useState(false);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [sysServiceID, setSysServiceID] = useState(-1);
  const [selServiceData, setSelServiceData] = useState({});
  const [showServiceCreatePopup, setShowServiceCreatePopup] = useState(false);
  const [popupTabsVisible, setPopupTabsVisible] = useState(false);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/service/systemservices',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setServicesList(dataArray);
      } catch (err) {}
    };
    fetchServices();
  }, [sendRequest, reloadContacts]);

  const stServicesData = new ArrayStore({
    key: 'serviceid',
    data: servicesList,
  });

  const dcServicesData = new DataSource({
    store: stServicesData,
  });

  const openServicesData = () => {
    user.service = {
      serviceId: sysServiceID,
      providerId: selServiceData.providerid,
      categoryId: selServiceData.categoryid,
      serviceName: selServiceData.servicename,
      account_Liquidity_Id: selServiceData.account_liquidity_id,
      account_Service_Provider_Id: selServiceData.account_service_provider_id,
      user: user,
      modeInsert: false,
    };
    history.push({
      pathname: '/servicesdata',
    });
  };

  const openServicesInsert = () => {
    user.service = {
      systemServiceId: -1,
      providerId: -1,
      categoryid: -1,
      serviceName: '',
      account_liquidity_id: -1,
      account_service_provider_id: -1,
      user: user,
      modeInsert: true,
    };
    history.push({
      pathname: '/servicesedit',
    });
  };

  const hideServiceCreatePopup = useCallback(doContactReload => {
    setShowServiceCreatePopup(false);
    if (doContactReload) {
      setReloadContacts(!reloadContacts);
    }
  });

  const openServiceAdd = () => {
    setpopModeInsert(true);
    setPopupTabsVisible(true);
    setShowServiceCreatePopup(true);
  };

  return (
    <React.Fragment>
      <ServicesCreatePop
        showPopup={showServiceCreatePopup}
        hidePopup={hideServiceCreatePopup}
        systemServiceId={sysServiceID}
        user={user}
        selucompany={5}
        showTabs={popupTabsVisible}
        modeInsert={popModeInsert}
      />

      <ResponsiveBox>
        <Col ratio={0.3} screen='md lg sm' />
        <Col ratio={3} />
        <Row />

        <Item>
          <Location screen='md lg sm' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <Button width={120} height={36} text='Add' type='default' stylingMode='outlined' onClick={openServiceAdd} />
          </div>
          <div id='hm-btn-section-11'>
            <Button width={120} height={36} text='Open' type='success' stylingMode='outlined' onClick={openServicesData} />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div>
            <DataGrid
              id='service-serviceone-tab-grid'
              height={getScreenGridHeight}
              dataSource={dcServicesData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={e => {
                if (e.row) {
                  setSysServiceID(e.row.key);
                  passToParent(e.row.key);
                  setSelServiceData(e.row.data);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='serviceid' visible={false}></Column>
              <Column dataField='categoryid' visible={false}></Column>
              <Column dataField='providerid' visible={false}></Column>
              <Column dataField='servicename' caption='Service Name' width={280} />
              <Column dataField='category' caption='Category' width={200} />
              <Column dataField='providername' caption='Provider' width={200} />
              <Column dataField='lq_account' caption='Liq. Account' width={200} visible={false} />
              <Column dataField='sv_account' caption='Srv. Account' width={200} visible={false} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ServicesOneTab;
