import React, { useEffect, useState, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';

import './AdminDash.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';
import AdminDashPartner from './AdminDashPartner';
import AdminDashNetworks from './AdminDashNetworks';
import AdminDashProvider from './AdminDashProvider';

const AdminDash = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='admindash-data-line'>
               <AdminDashPartner user={user}/>
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={1} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='admindash-data-box'>
              <AdminDashNetworks user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={0} colspan={2}/>
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='admindash-data-line'>
              <AdminDashProvider user={user} />
              </div>
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default AdminDash;
