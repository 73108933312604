import React, { useRef } from 'react';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';

const ProductsInfoRemovePopup = props => {
  const { showPopup, hidePopup, selectedProduct } = props;
  const popupRef = useRef(null);

  return (
    <React.Fragment>
      <Popup
        title={selectedProduct}
        width={400}
        height={250}
        ref={popupRef}
        visible={showPopup}
        showCloseButton={true}
        onHiding={() => {
          hidePopup(false);
        }}
      >
        <ResponsiveBox>
          <Row ratio={10} />
          <Row ratio={0.1} />
          <Col />

          <Item>
            <Location row={0} col={0} colspan={3} screen='lg md sm xs'></Location>
            <div className='partner-info'>
              <p>Active Products cannot be deleted</p>
            </div>
          </Item>

          <Item>
            <Location screen='md lg sm xs' row={1} col={0} />
            <div id={'closebutton'}>
              <div style={{ flexDirection: 'row' }}>
                {/* <Button
                                    id='deletebutton'
                                    width={120}
                                    height={40}
                                    text='Remove'
                                    type='normal'
                                    stylingMode='contained'
                                    onClick={delProduct}
                                /> */}
                <Button
                  width={120}
                  height={40}
                  text='close'
                  type='normal'
                  stylingMode='contained'
                  onClick={() => {
                    hidePopup(false);
                  }}
                />
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      </Popup>
    </React.Fragment>
  );
};

export default ProductsInfoRemovePopup;
