import React  from "react";
import { withRouter } from "react-router-dom";
import ThreadsList from "./internalMessaging/ThreadsList";

function MessagingSystemPage(props) {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Messaging System</h2>
      <div className={"content-block"}>
        <div className={"dx-card wide-card"}>
          <div>
            <div className="dx-fieldset">
              <ThreadsList clientId={""} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(MessagingSystemPage);
