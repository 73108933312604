import React, { useEffect, useRef, useState, useCallback } from "react";
import { useAuth } from "../../contexts/auth";
import "./servicesedit.css";
import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";
import MultiView from "devextreme-react/multi-view";
import Tabs from "devextreme-react/tabs";

import ServicesEditFeatures from "./ServicesEditFeatures";
import ServicesEditProviderName from "./ServicesEditProviderName";
import ServicesEditCustomerDocs from "./ServicesEditCustomerDocs";
import ServicesEditTermsofUse from "./ServicesEditTermsofUse";
import ServicesEditCostRates from "./ServicesEditCostRates";
import ServicesEditPartnerSellRates from "./ServicesEditPartnerSellRates";

const PartnerEditServicesEditPage = (props) => {
  const { user } = useAuth();
  const systemServiceId = user.service.serviceId;
  const partnerName = user.service.partnerName;
  const serviceName = user.service.serviceName;
  const selProviderId = user.service.providerId;
  const selCategoryId = user.service.categoryId;
  const selPartnerId = user.service.partnerId;
  const selRoutingId = user.service.routingId;
  const modeInsert = user.service.modeInsert;
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card wide-card"}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={10} />
            <Row ratio={0.2} />
            <Col />
            <Item>
              <Location screen="md lg sm xs" row={0} col={0} />
              <div className="rb-header item">
                <div className="dx-fieldset-header" id="cap_item">
                  {partnerName} / Service: {serviceName}
                </div>
              </div>
            </Item>
            <Item>
              <Location screen="md lg sm xs" row={1} col={0} />

              <Tabs
                id="routing-edit-tab"
                selectedIndex={tabIndex}
                width="98%"
                visible={true}
                onItemClick={(e) => {
                  setTabIndex(e.itemIndex);
                }}
              >
                <Item text="Features" />
                <Item text="Cost Rates" />
                <Item text="Sell Rates" />
                <Item text="Required Cust. Docs" />
                <Item text="Terms of Use" />
                <Item text="Service Provider" />
              </Tabs>

              <MultiView height={745} animationEnabled={false} selectedIndex={tabIndex}>
                <Item title="Features">
                  <ServicesEditFeatures
                    selServiceId={systemServiceId}
                    selCategoryId={selCategoryId}
                    modeInsert={modeInsert}
                    user={user}
                  />
                </Item>
                <Item title="Service Cost Rates">
                  <ServicesEditCostRates selServiceId={systemServiceId} user={user} />
                </Item>
                <Item title="Partner Sell Rates">
                  <ServicesEditPartnerSellRates
                    selServiceId={systemServiceId}
                    selPartnerId={selPartnerId}
                    selRoutingId={selRoutingId}
                    modeInsert={modeInsert}
                    user={user}
                  />
                </Item>
                <Item title="Customer Documents">
                  <ServicesEditCustomerDocs systemServiceId={systemServiceId} modeInsert={modeInsert} user={user} />
                </Item>
                <Item title="Terms of Use">
                  <ServicesEditTermsofUse systemServiceId={systemServiceId} modeInsert={modeInsert} user={user} />
                </Item>
                <Item title="Service Provider">
                  <ServicesEditProviderName selProviderId={selProviderId} modeInsert={modeInsert} user={user} />
                </Item>
              </MultiView>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: "unterlined" };

export default PartnerEditServicesEditPage;
