import React, { useEffect, useRef, useState } from 'react';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import { useHttpClient } from '../../hooks/http-hook';
import './costrateservices.css';
import { SelectBox } from 'devextreme-react/select-box';
import ArrayStore from 'devextreme/data/array_store';

const ProductRiskLevelCopyPop = props => {
  const { showPopup, hidePopup, selWorkId, selPartnerId, selProductId, selRiskid, selSellRateId, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const [lpRiskLevels, setLpRiskLevels] = useState([]);

  useEffect(() => {
    const fetchRisklevels = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/12',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpRiskLevels(dataArray);
      } catch (err) {}
    };
    fetchRisklevels();
  }, [sendRequest]);

  const stLpRiskLevels = new ArrayStore({
    key: 'lp_id',
    data: lpRiskLevels,
  });

  const navigateToHome = () => {
    hidePopup();
  };

  const copyformdata = {
    workid: selWorkId,
    partnerid: selPartnerId,
    productid: selProductId,
    from_riskid: selRiskid,
    to_riskid: -1,
    to_all: false,
    cpy_risklevel: true,
    cpy_rateentries: true,
  };

  const copyRiskLevel = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/work/productrisklevelcopy',
        'POST',
        JSON.stringify({
          workid: copyformdata.workid,
          sellrateid: selSellRateId,
          partnerid: copyformdata.partnerid,
          productid: copyformdata.productid,
          from_riskid: copyformdata.from_riskid,
          to_riskid: copyformdata.to_riskid,
          to_all: copyformdata.to_all,
          cpy_risklevel: copyformdata.cpy_risklevel,
          cpy_rateentries: copyformdata.cpy_rateentries,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
    hidePopup(true);
  };

  return (
    <Popup
      title={'Copy Risk Level'}
      width={800}
      height={280}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form id='risk-level-copy-form' formData={copyformdata} labelLocation={'top'}>
            <Item itemType='group' colCount={7}>
              <SimpleItem
                dataField='from_riskid'
                colSpan={2}
                editorType='dxSelectBox'
                name='from_riskid_listbox'
                editorOptions={{
                  dataSource: stLpRiskLevels,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                }}
              >
                <Label text='From Risk-Level' />
              </SimpleItem>

              <SimpleItem dataField='to_all' editorType='dxCheckBox'>
                <Label text='To All' />
              </SimpleItem>

              <SimpleItem dataField='cpy_risklevel' editorType='dxCheckBox'>
                <Label text='Risk Section' />
              </SimpleItem>

              <SimpleItem dataField='cpy_rateentries' editorType='dxCheckBox'>
                <Label text='Feature Rates' />
              </SimpleItem>

              <SimpleItem
                dataField='to_riskid'
                colSpan={2}
                editorType='dxSelectBox'
                name='from_riskid_listbox'
                editorOptions={{
                  dataSource: stLpRiskLevels,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                }}
              >
                <Label text='of Risk-Level' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'contactpopbutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={50}
                text='Execute'
                type='normal'
                stylingMode='contained'
                onClick={copyRiskLevel}
              />
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ProductRiskLevelCopyPop;
