import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import CasesNotesToPop from '../operations/CasesNotesToPop';
import ClientAccessPop from './ClientAccessPop';
import ClientApprovalPop from './ClientApprovalPop';

const ClientsApprovedAccess = props => {
  const { tabVisibleIndex, selSignupClientid, user, reloadNewsGridCondition } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [signupClientDatalist, setSignupClientDatalist] = useState({});

  const [showCasesNotesTo, setShowCasesNotesTo] = useState(false);
  const [noteEntryId, setNoteEntryId] = useState(-1);
  const [noteCaseEntryid, setNoteCaseEntryid] = useState(-1);
  const [noteCaseSituation, setNoteCaseSituation] = useState(-1);
  const [contactPortalid, setContactPortalid] = useState(-1);
  const [contactClient, setContactClient] = useState(false);
  const [contactClientid, setContactClientid] = useState(-1);
  const [contactClientMail, setContactClientMail] = useState('');
  const [clientApprovalStateData, setClientApprovalStateData] = useState([]);
  const [showClientAccessPop, setShowClientAccessPop] = useState(false);
  const [showClientApprovalPop, setShowClientApprovalPop] = useState(false);
  const [caseSituationAction, setCaseSituationAction] = useState(false);

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/clientsignupdata/${selSignupClientid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setSignupClientDatalist(dataArray[0]);

        const dataArrayTwo = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/getclientapprovalstate/${selSignupClientid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setClientApprovalStateData(dataArrayTwo);
      } catch (err) {}
    };
    if (selSignupClientid) {
      fetchSignupClientData();
    }
  }, [sendRequest, selSignupClientid]);

  const stclientApprovalStateData = new ArrayStore({
    key: 'assetaccountid',
    data: clientApprovalStateData,
  });

  const dcClientApprovalStateData = new DataSource({
    store: stclientApprovalStateData,
  });

  const contactSelectedClient = async () => {
    setContactClientid(signupClientDatalist.signupid);
    setCaseSituationAction(false);
    setNoteCaseSituation(16);
    setContactPortalid(signupClientDatalist.portalid);
    setContactClient(true);
    setShowCasesNotesTo(true);
  };

  const contactClientMerchant = () => {
    setContactClient(false);
    setContactClientid(signupClientDatalist.clientid);
    setContactPortalid(signupClientDatalist.portalid);
    setNoteCaseSituation(17);
    setShowCasesNotesTo(true);
  };

  const contactClientProvider = async () => {
    setContactClient(false);
    setContactClientid(signupClientDatalist.signupid);
    setContactPortalid(-10);
    setNoteCaseSituation(18);
    setShowCasesNotesTo(true);
  };

  const hideCasesNotesTo = async () => {
    await setShowCasesNotesTo(false);
    await setNoteEntryId(-1);
    await setNoteCaseEntryid(-1);
    await setContactPortalid(-1);
    await setContactClient(false);
    await setContactClientid(-1);
    await setContactPortalid(-1);
    await setNoteCaseSituation(-1);
    await reloadNewsGridCondition(true);
  };

  const showClientAccessDialog = () => {
    setShowClientAccessPop(true);
  };

  const hideClientAccessPop = () => {
    setShowClientAccessPop(false);
  };

  const showClientApprovalDialog = () => {
    setShowClientApprovalPop(true);
  };

  const hideClientApprovalPop = async () => {
    await setShowClientApprovalPop(false);
    await reloadNewsGridCondition(true);
  };

  return (
    <React.Fragment>
      <CasesNotesToPop
        showPopup={showCasesNotesTo}
        hidePopup={hideCasesNotesTo}
        user={user}
        entryid={noteEntryId}
        caseEntryid={noteCaseEntryid}
        caseSituation={noteCaseSituation}
        contactPortalid={contactPortalid}
        contactClient={contactClient}
        contactClientid={contactClientid}
        contactClientMail={contactClientMail}
        clientData={signupClientDatalist}
        caseSituationAction={caseSituationAction}
      />
      <ClientAccessPop showPopup={showClientAccessPop} hidePopup={hideClientAccessPop} user={user} />
      <ClientApprovalPop
        showPopup={showClientApprovalPop}
        hidePopup={hideClientApprovalPop}
        user={user}
        clientApprovalStateData={clientApprovalStateData}
        selSignupClientid={selSignupClientid}
      />
      <ResponsiveBox>
        <Row ratio={1} />
        <Col ratio={1.8} />
        <Col ratio={9} />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <p>Account</p>
          </div>
          <div id='hm-btn-section-1'>
            <Button
              width={120}
              height={36}
              text='Approval'
              type='default'
              disabled={true}
              onClick={showClientApprovalDialog}
            />
          </div>
          <div id='hm-btn-section-11'>
            <Button
              width={120}
              height={36}
              text='Access'
              type='success'
              disabled={true}
              onClick={showClientAccessDialog}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div id='client-access-info'>
            <table>
              <tbody>
                <tr>
                  <td width='100px'>Client Access:</td>
                  <td width='100px'>Active</td>
                  <td width='60px'>Since:</td>
                  <td width='100px'>01.01.2022</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <p>Accounts:</p>
          </div>
          <DataGrid
            id='client-access-grid'
            dataSource={dcClientApprovalStateData}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            focusedRowEnabled={true}
            showBorders={true}
            showColumnLines={true}
            showRowLines={true}
            autoNavigateToFocusedRow={true}
            // focusedRowKey={selectedClientid}
            onFocusedRowChanged={e => {
              if (e.row) {
                //    setSelectedClientid(e.row.key.clientid);
                //    passToParent(e.row.key.clientid);
              }
            }}
          >
            <Column dataField='assetaccountid' visible={false}></Column>
            <Column dataField='providerid' visible={false}></Column>
            <Column dataField='productname' caption='Product' width={150} />
            <Column dataField='servicename' caption='Service' width={150} />
            <Column dataField='providername' caption='Provider' width={120} />
            <Column dataField='provider_approved' caption='Approved' width={80} />
            <Column dataField='access_state_txt' caption='Access State' width={120} />
            <Column dataField='access_state_time' caption='on' dataType='datetime' width={120} />
          </DataGrid>
          <div id='client-access-onboarding'>
            <table>
              <tbody>
                <tr>
                  <td width='130px'>Onboarding Fee:</td>
                  <td width='100px'>Paid</td>
                  <td width='60px'>01.01.2022</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientsApprovedAccess;
