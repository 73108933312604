import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { useHttpClient } from '../../hooks/http-hook';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import DataGrid, { Column } from 'devextreme-react/data-grid';

import ArrayStore from 'devextreme/data/array_store';

const PortalPop = (props) => {
  const { showPopup, hidePopup, portalRowData, addPortal, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [featureChanged, setFeatureChanged] = useState(false);
  const srvDataFormRef = useRef(null);
  const [lpPortalTypes, setLpPortalTypes] = useState([]);
  const [partnerDatalist, setPartnerDatalist] = useState([]);
  const [providerDatalist, setProviderDatalist] = useState([]);
  const [sectionData, setSectionData] = useState({});

  const [lpSections, setLpSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState();
  const [connectedSectionsData, setConnectedSectionsData] = useState([]);
  const [portalSectionDataMod, setPortalSectionDataMod] = useState(false);

  const fetchSections = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/53', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpSections(dataArray);
    } catch (err) {}
  };

  const fetchPortalTypes = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/35', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpPortalTypes(dataArray);
    } catch (err) {}
  };

  const fetchfreePartner = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/management/freepartner', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setPartnerDatalist(dataArray);
    } catch (err) {}
  };

  const fetchfreeProvider = async () => {
    try {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/management/freeprovider', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setProviderDatalist(dataArray);
    } catch (err) {}
  };

  const fetchConnectedSections = async () => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/management/portalsections/${portalRowData.portalid}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + user.token,
        }
      );
      setConnectedSectionsData(dataArray);
    } catch (err) {}
  };

  useEffect(() => {
    if (showPopup) {
      fetchPortalTypes();
      fetchfreePartner();
      fetchfreeProvider();
      fetchSections();
      fetchConnectedSections();
    }
  }, [sendRequest, showPopup]);

  useEffect(() => {
    if (showPopup) {
      fetchConnectedSections();
    }
  }, [showPopup, portalSectionDataMod]);

  const stPortalTypes = new ArrayStore({
    key: 'lp_id',
    data: lpPortalTypes,
  });

  const stPartnerDatalist = new ArrayStore({
    key: 'partnerid',
    data: partnerDatalist,
  });

  const stProviderDatalist = new ArrayStore({
    key: 'providerid',
    data: providerDatalist,
  });

  const stSections = new ArrayStore({
    key: 'lp_id',
    data: lpSections,
  });

  const stConnectedSectionsData = new ArrayStore({
    key: 'sectionid',
    data: connectedSectionsData,
  });

  const portalData = {
    portalid: addPortal === true ? -1 : portalRowData && portalRowData.portalid,
    portaltype: addPortal === true ? -1 : portalRowData && portalRowData.portaltype,
    portalname: addPortal === true ? '' : portalRowData && portalRowData.portalname,
    partnerid: addPortal === true ? -1 : portalRowData && portalRowData.partnerid ? portalRowData.partnerid : -1,
    providerid: addPortal === true ? -1 : portalRowData && portalRowData.providerid ? portalRowData.providerid : -1,
    personal_accounts:
      addPortal === true
        ? true
        : portalRowData && portalRowData.personal_accounts
        ? portalRowData.personal_accounts
        : true,
    business_accounts:
      addPortal === true
        ? true
        : portalRowData && portalRowData.business_accounts
        ? portalRowData.business_accounts
        : true,
    multi_account_users:
      addPortal === true
        ? false
        : portalRowData && portalRowData.multi_account_users
        ? portalRowData.multi_account_users
        : false,
    account_user_security:
      addPortal === true
        ? false
        : portalRowData && portalRowData.account_user_security
        ? portalRowData.account_user_security
        : false,
  };

  const sectionFormData = {
    portal_sectionid: (selectedSection && selectedSection.sectionid) || -1,
  };

  const modifyPortal = async (modify) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/management/modportal',
        'POST',
        JSON.stringify({
          portalid: portalData.portalid,
          portaltype: portalData.portaltype,
          portalname: portalData.portalname,
          partnerid: portalData.partnerid,
          providerid: portalData.providerid,
          personal_accounts: portalData.personal_accounts,
          business_accounts: portalData.business_accounts,
          multi_account_users: portalData.multi_account_users,
          account_user_security: portalData.account_user_security,
          operation: modify,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const modPortalSections = async (modify) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/management/modportsections',
        'POST',
        JSON.stringify({
          portalid: portalData.portalid,
          portal_sectionid: sectionFormData.portal_sectionid,
          operation: modify,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setPortalSectionDataMod(!portalSectionDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  const savePopup = async () => {
    if (addPortal) {
      await modifyPortal(1);
    } else {
      await modifyPortal(2);
    }
    await hidePopup(true);
  };

  return (
    <Popup
      title={'Portal'}
      width={1000}
      height={800}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      onShowing={() => {
        setFeatureChanged(false);
      }}
    >
      <ResponsiveBox>
        <Row ratio={9} />
        <Row ratio={1} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form id={'form'} ref={srvDataFormRef} formData={portalData} labelLocation='left'>
            <Item itemType='group' colCount={6}>
              <SimpleItem dataField='portalname' colSpan={3}>
                <Label location='left' text='Name' />
              </SimpleItem>
              <SimpleItem
                dataField='portaltype'
                editorType='dxSelectBox'
                name='portaltype_listbox'
                colSpan={3}
                editorOptions={{
                  dataSource: stPortalTypes,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: false,
                }}
              >
                <Label text='Type' />
              </SimpleItem>
            </Item>
            <EmptyItem />
            <Item itemType='group' colCount={6}>
              <SimpleItem
                dataField='partnerid'
                editorType='dxSelectBox'
                name='partnerid_listbox'
                colSpan={3}
                editorOptions={{
                  dataSource: stPartnerDatalist,
                  valueExpr: 'partnerid',
                  displayExpr: 'partnername',
                  searchEnabled: true,
                }}
              >
                <Label text='Merchant' />
              </SimpleItem>
              <SimpleItem
                dataField='providerid'
                editorType='dxSelectBox'
                name='providerid_listbox'
                colSpan={3}
                editorOptions={{
                  dataSource: stProviderDatalist,
                  valueExpr: 'providerid',
                  displayExpr: 'providername',
                  searchEnabled: true,
                }}
              >
                <Label text='Provider' />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={12} caption='End Customer Options'>
              <SimpleItem dataField='personal_accounts' editorType='dxCheckBox' colSpan={3}>
                <Label text='Pers. Accounts' />
              </SimpleItem>
              <SimpleItem dataField='business_accounts' editorType='dxCheckBox' colSpan={3}>
                <Label text='Business Accounts' />
              </SimpleItem>
              <SimpleItem dataField='multi_account_users' editorType='dxCheckBox' colSpan={3}>
                <Label text='Multiple Account Users' />
              </SimpleItem>
              <SimpleItem dataField='account_user_security' editorType='dxCheckBox' colSpan={3}>
                <Label text='Account User Security' />
              </SimpleItem>
            </Item>
          </Form>

          <div id='portal-pop-sections-area'>
            <Form formData={sectionFormData} labelLocation={'left'}>
              <Item itemType='group' colCount={7}>
                <SimpleItem
                  dataField='portal_sectionid'
                  editorType='dxSelectBox'
                  colSpan={3}
                  name='sections_listbox'
                  editorOptions={{
                    dataSource: stSections,
                    valueExpr: 'lp_id',
                    displayExpr: 'lp_value',
                  }}
                >
                  <Label location='left' text='Extension' />
                </SimpleItem>
                <ButtonItem
                  colSpan={1}
                  buttonOptions={{
                    width: 120,
                    height: 36,
                    text: 'Add',
                    type: 'default',
                    stylingMode: 'outlined',
                    onClick: () => {
                      modPortalSections(1);
                    },
                  }}
                />

                <ButtonItem
                  colSpan={1}
                  buttonOptions={{
                    width: 120,
                    height: 36,
                    text: 'Rem',
                    type: 'danger',
                    stylingMode: 'outlined',
                    onClick: () => {
                      modPortalSections(3);
                    },
                  }}
                />
              </Item>
            </Form>
          </div>

          <DataGrid
            id='portal-pop-extension-grid'
            height={240}
            width={260}
            dataSource={stConnectedSectionsData}
            showRowLines={true}
            showBorders={true}
            showColumnLines={true}
            columnAutoWidth={true}
            defaultFocusedRowIndex={0}
            focusedRowEnabled={true}
            showColumnHeaders={true}
            onFocusedRowChanged={(e) => {
              setSelectedSection(e.row.data);
            }}
          >
            <Column dataField='portalid' visible={false} />
            <Column dataField='sectionid' visible={false} />
            <Column dataField='sectionname' caption='Extension' width={150} />
            <Column dataField='sectionshortid' caption='Short ID' width={70} />
          </DataGrid>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'closebutton-2'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton-right'
                width={120}
                height={50}
                text='Save'
                type='default'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={savePopup}
              />
              <Button width={120} height={50} text='Close' type='normal' stylingMode='contained' onClick={hidePopup} />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default PortalPop;
