import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useAuth } from '../../contexts/auth';
import { useHistory } from 'react-router-dom';
import './ProductEditPage.css';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Tabs, MultiView, SelectBox } from 'devextreme-react';

import ProductSetup from './ProductSetup';
import ProductPricing from './ProductPricing';

const PartnerProductEditPage = () => {
  const { user } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);

  const partnerBaseData = {
    partnerid: user.product.partnerid,
    productid: user.product.productid,
    routingid: user.product.routingid,
  };

  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Cases</h2> */}
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={2} />
            <Row ratio={0.7} />
            <Col />

            <Item>
              <Location screen='md lg' row={0} col={0} colspan={2} />
              <Location screen='sm' row={0} col={0} colspan={1} />
              <Location screen='xs' row={0} col={0} />
              <div className='rb-header item'>
                <ResponsiveBox>
                  <Row />
                  <Col ratio={3} />
                  <Col ration={1} />
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={0} />
                    <div className='dx-fieldset-header' id='cap_item'>
                      Customer Product
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={1} />
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg sm' row={1} col={0} />
              <Location screen='xs' row={1} col={0} />
              <div className='rb-content item'>
                <Tabs
                  id='tab-product-edit-page'
                  selectedIndex={tabIndex}
                  width='98%'
                  visible={true}
                  onItemClick={e => {
                    setTabIndex(e.itemIndex);
                  }}
                >
                  <Item text='Product' />
                  <Item text='Pricing' />
                </Tabs>
                <ResponsiveBox>
                  <Row />
                  <Col />
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={0} />
                    <div>
                      <MultiView height={710} animationEnabled={false} selectedIndex={tabIndex}>
                        <Item title='Product Setup'>
                          <ProductSetup
                            selPartnerId={partnerBaseData.partnerid}
                            selProductId={partnerBaseData.productid}
                            selRoutingId={partnerBaseData.routingid}
                            user={user}
                            modeInsert={false}
                          />
                        </Item>
                        <Item title='Product Pricing'>
                          <ProductPricing
                            selPartnerId={partnerBaseData.partnerid}
                            selProductId={partnerBaseData.productid}
                            selRoutingId={partnerBaseData.routingid}
                            user={user}
                          />
                        </Item>
                      </MultiView>
                    </div>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg' row={2} col={0} colspan={2} />
              <Location screen='sm' row={2} col={0} colspan={1} />
              <Location screen='xs' row={2} col={0} />
              <div className='rb-footer item' id='cap_item'></div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PartnerProductEditPage;
