import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Box, { Item } from 'devextreme-react/box';
import { useHttpClient } from '../../hooks/http-hook';

import ArrayStore from 'devextreme/data/array_store';
import { SelectBox } from 'devextreme-react';

const MailTemplatePop = props => {
  const { showPopup, hidePopup, stPortalData, fromPortalid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [toPortalid, setToPortalid] = useState(-1);
  const srvDataFormRef = useRef(null);
  const [selPortalid, setSelPortalid] = useState(-1);

  const copyTemplatesToPortal = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/management/modportal',
        'POST',
        JSON.stringify({
          fromPortalid: fromPortalid,
          toPortalid: toPortalid,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const savePopup = async () => {
    copyTemplatesToPortal(1);
    await hidePopup(true);
  };

  return (
    <Popup
      title={'Copy To Portal'}
      width={350}
      height={200}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      // onShowing={() => {
      //    setFeatureChanged(false);
      // }}
    >
      <Box id='employeeform' direction='col' height='100%'>
        <Item ratio={0.8} width='100%'>
          <SelectBox
            width={'200px'}
            dataSource={stPortalData}
            valueExpr={'portalid'}
            displayExpr={'portalname'}
            searchEnabled={true}
            defaultValue={selPortalid}
            onValueChanged={e => {
              e && setSelPortalid(e.value);
            }}
          />
        </Item>
        <Item ratio={2}>
          <div id={'closebutton-2'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton-right'
                width={120}
                height={50}
                text='Save'
                type='default'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={savePopup}
              />
              <Button width={120} height={50} text='Close' type='normal' stylingMode='contained' onClick={hidePopup} />
            </div>
          </div>
        </Item>
      </Box>
    </Popup>
  );
};

export default MailTemplatePop;
