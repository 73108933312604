import { useState, useCallback, useRef, useEffect } from 'react';
import { useHttpClient } from './http-hook';

export const useCaseNote = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const sendCaseNoteSilent = useCallback(async (user, clientid, caseSituation, notecomment) => {
    console.log(1009, user, clientid, caseSituation, notecomment);
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/cases/sendcasenotesilent',
        'POST',
        JSON.stringify({
          userid: user.id,
          clientid: clientid,
          caseSituation: caseSituation,
          portalid: user.portalid,
          notecomment: notecomment,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  return {
    sendCaseNoteSilent: sendCaseNoteSilent,
  };
};
