import React, { useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule } from 'devextreme-react/form';

import { useAuth } from '../contexts/auth';

const NoteEditPopup = props => {
  const { showPopup, hidePopup, selNotesKey } = props;
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const noteFormRef = useRef(null);
  const [noteData, setNoteData] = useState({});

  useEffect(() => {
    const fetchNote = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/base/getselnote/${selNotesKey}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setNoteData(dataArray[0]);
      } catch (err) {}
    };
    if (selNotesKey && showPopup) {
      fetchNote();
    }
  }, [sendRequest, selNotesKey, showPopup]);

  const notedata = {
    notesubject: noteData && noteData.subject,
    notetext: noteData && noteData.content,
  };

  return (
    <Popup
      title={'Note'}
      width={800}
      height={600}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup();
      }}
    >
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.1} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <Form
              id={'form'}
              ref={noteFormRef}
              formData={notedata}
              labelLocation={'left'}
              colCountByScreen={colCountByScreen}
            >
              <Item itemType='group' colCount={1}>
                <SimpleItem dataField='notesubject'>
                  <Label text='Subject' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={1}>
                <SimpleItem dataField='notetext' editorType='dxTextArea' editorOptions={{ height: 350 }}>
                  <Label text='Content' />
                </SimpleItem>
              </Item>
            </Form>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'closebutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={120}
                height={40}
                text='close'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  hidePopup();
                }}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default NoteEditPopup;
