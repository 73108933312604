import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import MultiView, {Item} from "devextreme-react/multi-view";
import ListView from "./components/ListView";
import CalendarView from "./components/CalendarView";
import {ButtonGroup} from "devextreme-react/button-group";

import Button from "devextreme-react/button";
import {Popup} from "devextreme-react/popup";

import AppointmentForm from "./AppointmentForm";
import {CalendarProvider, useCalendar} from "../../contexts/calendar";

function CalendarPage(props) {
    const eventListDataGridRef = React.useRef();
    const eventCalendarSchedulerRef = React.useRef();

    const [tabIndex, setTabIndex] = useState(0);

    const {
        toggleShowEventPopup,
        showEventPopup,
        clearAppointmentData
    } = useCalendar();

    const onHidingPopup = () => {
        handleHidePopup();
    };

    const handleHidePopup = refresh => {
        toggleShowEventPopup();
        clearAppointmentData();
        if (refresh && eventListDataGridRef.current) {
            eventListDataGridRef.current.instance.refresh();
        }
        if (refresh && eventCalendarSchedulerRef.current) {
            eventCalendarSchedulerRef.current.instance._reloadDataSource();
        }
    };

    return (
        <>
            <h2 className={"content-block"}>Client Video Conferencing</h2>
            <div className={"content-block"}>
                <div className={"dx-card wide-card p-4"}>
                    <div className={"row-flex-space-between"}>
                        <div className="buttongroups-container mb-4">
                            <span className={"mr-4"}>Display type</span>
                            <ButtonGroup
                                defaultSelectedItemKeys={"List"}
                                items={[
                                    {text: "List", type: "normal", "height": "35px"},
                                    {text: "Calendar", type: "normal", "height": "35px"}
                                ]}
                                keyExpr="text"
                                stylingMode="outlined"
                                onItemClick={e => {
                                    setTabIndex(e.itemIndex);
                                }}
                            />
                        </div>

                        <Button
                            text={"New event"}
                            onClick={toggleShowEventPopup}
                            width={200}
                            height={35}
                            style={{ "margin-bottom": "10px" }}
                        />
                    </div>

                    <MultiView
                        swipeEnabled={false}
                        animationEnabled={false}
                        selectedIndex={tabIndex}
                    >
                        <Item>
                            <ListView forwardEventListRef={eventListDataGridRef}/>
                        </Item>
                        <Item>
                            <CalendarView
                                forwardEventCalendarRef={eventCalendarSchedulerRef}
                            />
                        </Item>
                    </MultiView>
                </div>
            </div>

            {showEventPopup && (
                <Popup
                    visible={showEventPopup}
                    onHiding={onHidingPopup}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showCloseButton={true}
                    showTitle={true}
                    title="Event Information"
                    container=".dx-viewport"
                    height={"auto"}
                    width={700}
                >
                    <AppointmentForm
                        hideEventPopup={(refresh = false) => handleHidePopup(refresh)}
                    />
                </Popup>
            )}
        </>
    );
}

const CalendarPageWithContext = withRouter(CalendarPage);
export default function () {
    return (
        <CalendarProvider>
            <CalendarPageWithContext/>
        </CalendarProvider>
    );
}
