import React, { useEffect, useRef, useState } from 'react';
import './ServicesData.css';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import { useHttpClient } from '../../hooks/http-hook';

import { SelectBox } from 'devextreme-react/select-box';
import ArrayStore from 'devextreme/data/array_store';

const ServiceProviderEditPop = (props) => {
  const { showPopup, hidePopup, svProviderId, svPersonId, user } = props;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const dataFormRef = useRef(null);
  const [providerData, setProviderData] = useState({});
  const [companyContact, setCompanyContact] = useState({});
  const [lpCategories, setLpCategories] = useState([]);

  useEffect(() => {
    const fetchServiceProvider = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/provider/serviceprov/${svProviderId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setProviderData(dataArray[0]);
      } catch (err) {}
    };

    const fetchCompanyContact = async (an_contactid) => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/contacts/company/${an_contactid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCompanyContact(dataArray[0]);
      } catch (err) {}
    };

    if (svProviderId && showPopup) {
      fetchServiceProvider();

      if (svPersonId) {
        fetchCompanyContact(svPersonId);
      } else {
        fetchCompanyContact(-1);
      }
    }
  }, [sendRequest, showPopup, svProviderId]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/service/servicecat', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setLpCategories(dataArray);
      } catch (err) {}
    };
    if (showPopup) {
      fetchCategories();
    }
  }, [sendRequest, showPopup]);

  const stLpCategoriesData = new ArrayStore({
    key: 'category_id',
    data: lpCategories,
  });

  const providerFormData = {
    providerid: (providerData && providerData.providerid) || -1,
    personid: (providerData && providerData.personid) || -1,
    categoryid: (providerData && providerData.categoryid) || -1,
    providername: (providerData && providerData.providername) || '',

    contactid: companyContact.contactid,
    lastname: companyContact.lastname,
    street: companyContact.street,
    city_code: companyContact.city_code,
    city: companyContact.city,
    phone_one: companyContact.phone_one,
    email: companyContact.email,
    homepage: companyContact.homepage,
    addressfield: companyContact.addressfield,
    nationality: companyContact.nationality,
    res_cntry: companyContact.res_cntry,
    contact_cat: companyContact.contact_cat,
  };

  function navigateToHome() {
    hidePopup(true);
  }

  const validateForm = async (dataFormRef) => {
    const result = dataFormRef.validationGroup.validate();
    // console.log(result);
    if (result.isValid) {
      saveNameAddress(1);
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  const saveNameAddress = async (addressOperation) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/provider/serviceprovider',
        'POST',
        JSON.stringify({
          operation: addressOperation,
          p_providerid: providerFormData.providerid,
          p_personid: providerFormData.personid || -1,
          p_categoryid: providerFormData.categoryid || -1,
          p_providername: providerFormData.providername || '',
          c_contactid: providerFormData.contactid || -1,
          c_lastname: providerFormData.providername || '',
          c_addressfield: providerFormData.addressfield || '',
          c_email: providerFormData.email || '',
          c_phone_one: providerFormData.phone_one || '',
          c_homepage: providerFormData.homepage || '',
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      // setContactsChanged(true);
      // setEmployeesChanged(!employeesChanged);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Popup
      title={'Add Service Provider'}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.5} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='form-edit'>
            <Form
              id={'form'}
              ref={dataFormRef}
              formData={providerFormData}
              labelLocation={'top'}
              colCountByScreen={colCountByScreen}
              validationGroup='ServProvData'
            >
              <Item itemType='group' colCount={3}>
                <SimpleItem dataField='providername' isRequired={true} editorOptions={baseEditorOptions}>
                  <RequiredRule message='Please enter a Service Provider Name' />
                  <Label text='Name' />
                </SimpleItem>
                <SimpleItem
                  dataField='categoryid'
                  editorType='dxSelectBox'
                  colSpan={1}
                  name='category_listbox'
                  editorOptions={{
                    dataSource: stLpCategoriesData,
                    valueExpr: 'category_id',
                    displayExpr: 'category_name',
                    searchEnabled: true,
                  }}
                >
                  <Label text='Category' />
                </SimpleItem>
              </Item>
              <EmptyItem />

              <Item itemType='group' colCount={3} caption='Address'>
                <SimpleItem dataField='addressfield' editorType='dxTextArea' editorOptions={{ height: 120 }}>
                  <Label text='Address' visible={false} />
                </SimpleItem>
              </Item>
              <Item itemType='group' colCount={3}>
                <SimpleItem dataField='email' editorOptions={baseEditorOptions}>
                  <Label text='E-Mail' />
                </SimpleItem>
                <SimpleItem dataField='phone_one' editorOptions={baseEditorOptions}>
                  <Label text='Phone' />
                </SimpleItem>
              </Item>
              <Item itemType='group' colCount={3}>
                <SimpleItem dataField='homepage' editorOptions={baseEditorOptions}>
                  <Label text='Home Page' />
                </SimpleItem>
              </Item>
            </Form>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'contactpopbutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={50}
                text='Save'
                type='normal'
                stylingMode='contained'
                validationGroup='ServProvData'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ServiceProviderEditPop;
