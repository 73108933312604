import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';

import ServiceFeaturePop from './ServiceFeaturePop';
import MessagePop from '../../ui_elements/MessagePop';

import './GlobalDefinitions.css';

const ServiceSectionTab = props => {
  const { serviceSectionID, user } = props;

  const [serviceFeatureDataList, setServiceFeatureDataList] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showFeaturePopup, setShowFeaturePopup] = useState(false);
  const [reloadFeatures, setReloadFeatures] = useState(false);
  const [focusedDataRow, setFocusedDataRow] = useState({});
  const [addFeature, setAddFeature] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);

  useEffect(() => {
    const fetchServiceFeatures = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/service/servicefeatures/${serviceSectionID}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setServiceFeatureDataList(dataArray);
        setReloadFeatures(false);
      } catch (err) {}
    };
    if (serviceSectionID || reloadFeatures) {
      fetchServiceFeatures();
    }
  }, [sendRequest, serviceSectionID, reloadFeatures]);

  const delServiceFeature = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/service/servicefeature',
        'POST',
        JSON.stringify({
          servicefeature_id: focusedDataRow.servicefeature_id ? focusedDataRow.servicefeature_id : -1,
          category_id: serviceSectionID,
          feature_name: focusedDataRow.feature ? focusedDataRow.feature : '',
          operation: 3,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateSystemServiceFeatures = async () => {
    try {
      const dataArray = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/service/updsystemservicefeatures/${serviceSectionID}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {}
  };

  const addServiceFeature = () => {
    setAddFeature(true);
    setShowFeaturePopup(true);
  };

  const editServiceFeature = () => {
    setAddFeature(false);
    setShowFeaturePopup(true);
  };

  const removeServiceFeature = () => {
    setShowMessagePop(true);
    // delServiceFeature();
    // setReloadFeatures(!reloadFeatures);
  };

  const updateServicesfeature = () => {
    UpdateSystemServiceFeatures();
  };

  const hideFeaturePopup = doServiceReload => {
    setShowFeaturePopup(false);
    // if (doServiceReload) {
    setReloadFeatures(true);
    // }
  };

  const hideMessagePop = doRemove => {
    setShowMessagePop(false);
    // console.log(focusedDataRow);
    if (doRemove) {
      delServiceFeature();
      setReloadFeatures(true);
    }
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected Feature?'
      />
      <ServiceFeaturePop
        showPopup={showFeaturePopup}
        hidePopup={hideFeaturePopup}
        srvceCatID={serviceSectionID}
        srvceRowData={focusedDataRow}
        servceaddFeature={addFeature}
        user={user}
      />
      <ResponsiveBox>
        <Row />
        <Col ratio={1} />
        <Col ratio={10} />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id="hm-btn-section-0-11">
           <p>Features</p> 
          </div>
          <div id="hm-btn-section-0-1">
            <Button
              width={100}
              height={36}
              text='Add'
              type='default'
              stylingMode='outlined'
              onClick={addServiceFeature}
            />
            </div>
            <div id="hm-btn-section-0-11">
              <Button
                width={100}
                height={36}
                text='Edit'
                type='success'
                stylingMode='outlined'
                onClick={editServiceFeature}
              />
            </div>
            <div id="hm-btn-section-0-11">
              <Button
                width={100}
                height={36}
                text='Rem'
                type='danger'
                stylingMode='outlined'
                onClick={removeServiceFeature}
              />
            </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <DataGrid
            id="global-defs-features-grid"
            height={400}
            dataSource={serviceFeatureDataList}
            keyExpr={'servicefeature_id'}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            columnAutoWidth={true}
            defaultFocusedRowIndex={0}
            focusedRowEnabled={true}
            onFocusedRowChanged={e => {
              setFocusedDataRow(e.row.data);
            }}
          >
            <Column dataField='servicefeature_id' visible={false} />
            <Column dataField='category_id' visible={false} />
            <Column dataField='buysellasset' visible={false} />
            <Column dataField='sendreceiveasset' visible={false} />
            <Column dataField='feature' caption='Feature' width='230px' />
            <Column dataField='group_id' visible={true} caption='Group' width='55px' />
            <Column dataField='group_position' visible={true} caption='GrpPos' width='65px' />
            <Column dataField='main_feature' dataType='boolean' caption='Service' width='70px' />
            <Column dataField='account_feature' dataType='boolean' caption='Account' width='80px' />
            <Column dataField='account_operation' dataType='boolean' caption='Account Ops' width='90px' />
            <Column dataField='asset_operation' dataType='boolean' caption='Asset Ops' width='90px' />
            <Column dataField='buy' dataType='boolean' caption='Buy' width='50px' />
            <Column dataField='sell' dataType='boolean' caption='Sell' width='50px' />
            <Column dataField='list' dataType='boolean' caption='List' width='50px' />
            <Column dataField='send' dataType='boolean' caption='Send' width='50px' />
            <Column dataField='receive' dataType='boolean' caption='Receive' width='70px' />
          </DataGrid>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ServiceSectionTab;
