import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import Box, { Item } from 'devextreme-react/box';

import { Form, SimpleItem, Label, EmptyItem } from 'devextreme-react/form';

import { useHttpClient } from '../../../hooks/http-hook';

import ArrayStore from 'devextreme/data/array_store';

const ComplianceMatrixPop = props => {
  const { showPopup, hidePopup, modelid, modeldatarow, addAiModel, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [featureChanged, setFeatureChanged] = useState(false);

  const nwModel = {
    modelid: modeldatarow && !addAiModel ? modelid : -1,
    modelname: modeldatarow && !addAiModel ? modeldatarow.modelname : '',
    modelident: modeldatarow && !addAiModel ? modeldatarow.modelident : '',
  };

  const modnwModelEntry = async modify => {
    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + '/ai/modmodels',
      'POST',
      JSON.stringify({
        operation: modify,
        modelid: nwModel.modelid,
        modelname: nwModel.modelname,
        modelident: nwModel.modelident,
      }),
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    );
    console.log(response);
  };

  const SaveInput = async () => {
    if (addAiModel) {
      await modnwModelEntry(1);
    } else {
      await modnwModelEntry(2);
    }
    hidePopup(true);
  };

  return (
    <Popup
      title={'Risk Matrix Entries'}
      width={500}
      height={240}
      visible={showPopup}
      resizeEnabled={false}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      onShowing={() => {
        setFeatureChanged(false);
      }}
    >
      <Box id='employeeform' direction='col' height='100%'>
        <Item ratio={4} width='100%'>
          <Form id={'form'} labelLocation={'top'} formData={nwModel}>
            <Item itemType='group' colCount={12}>
              <SimpleItem
                dataField='modelname'
                colSpan={9}
                editorOptions={{
                  
                }}
              >
                <Label text='Model Name' />
              </SimpleItem>
              <SimpleItem
                dataField='modelident'
                colSpan={3}
                editorOptions={{
                  
                }}
              >
                <Label text='Identifier' />
              </SimpleItem>
            </Item>
            <EmptyItem />
          </Form>
          <div id={'closebutton-2'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton-right'
                width={120}
                height={50}
                text='Save'
                type='default'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={SaveInput}
              />
              <Button width={120} height={50} text='Close' type='normal' stylingMode='contained' onClick={hidePopup} />
            </div>
          </div>
        </Item>
      </Box>
    </Popup>
  );
};

export default ComplianceMatrixPop;
