import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useHttpClient } from "../../hooks/http-hook";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { CheckBox } from "devextreme-react/check-box";

import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";

import DataGrid, { Scrolling, Column, FilterRow, SearchPanel } from "devextreme-react/data-grid";
import Form, { SimpleItem, GroupItem, Label, ButtonItem } from "devextreme-react/form";

import { useClientData } from '../../hooks/cientdata-hook';

const getscreenheight = () => {
  const anValue = window.innerHeight - 630;
  // console.log(5678, anValue);
  return anValue;
};

const ClientsApprovedOneTab = (props) => {
  const { passToParent, user } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { callClientData, clientUsesForex } = useClientData();
  const [partnerClientDataList, setPartnerClientDatalist] = useState([]);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [selectedClientid, setSelectedClientid] = useState(-1);
  const [selClientRow, setSelClientRow] = useState({});
  const [inApprovalCheckedId, setInApprovalCheckedId] = useState(0);
  const [clientSearchText, setClientSearchText] = useState("");
  const [inSignupCheckedId, setInSignupCheckedId] = useState(0);
  const [disableApproval, setDisableApproval] = useState(false);
  const [selClientGrp, setSelClientGrp] = useState(1);

  const clientGroups = [
    { grpid: 1, grpname: "All" },
    { grpid: 2, grpname: "Signup" },
    { grpid: 3, grpname: "Pending Approval" },
    { grpid: 4, grpname: "Approved" },
  ];

  const stAccessType = new ArrayStore({
    key: "grpid",
    data: clientGroups,
  });

  const doSearch = (anSearchText) => {
    let field1 = anSearchText.split(" ")[0] ? anSearchText.split(" ")[0] : "";
    let field2 = anSearchText.split(" ")[1] ? anSearchText.split(" ")[1] : "";
    let field3 = anSearchText.split(" ")[2] ? anSearchText.split(" ")[2] : "";
    let field4 = anSearchText.split(" ")[3] ? anSearchText.split(" ")[3] : "";
    let field5 = anSearchText.split(" ")[4] ? anSearchText.split(" ")[4] : "";
    let field6 = anSearchText.split(" ")[5] ? anSearchText.split(" ")[5] : "";

    field1 = cleanfield(field1);
    field2 = cleanfield(field2);
    field3 = cleanfield(field3);
    field4 = cleanfield(field4);
    field5 = cleanfield(field5);
    field6 = cleanfield(field6);

    if (inSignupCheckedId === 0) {
      fetchSearchClients(field1, field2, field3, field4, field5, field6);
    } else {
      fetchSearchClientSignup(field1, field2, field3, field4, field5, field6);
    }
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/clientsall/${inApprovalCheckedId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setPartnerClientDatalist(dataArray);
      } catch (err) {}
    };

    const fetchClientsinSignup = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/client/clientspartnersignup/${user.partnerid}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setPartnerClientDatalist(dataArray);
      } catch (err) {}
    };

    if (inSignupCheckedId === 1) {
      fetchClientsinSignup();
    } else {
      if (clientSearchText === "") {
        fetchCustomers();
      } else {
        doSearch(clientSearchText);
      }
    }
  }, [sendRequest, reloadContacts, inApprovalCheckedId, inSignupCheckedId]);

  const stPartnerCustomerData = new ArrayStore({
    key: "clientid",
    data: partnerClientDataList,
  });

  const dcPartnerCustomerData = new DataSource({
    store: stPartnerCustomerData,
  });

  const buttonAttributes = {
    text: "Search",
    type: "normal",
    icon: "search",
    height: 36,
    useSubmitBehavior: true,
  };

  const fetchSearchClients = async (field1, field2, field3, field4, field5, field6) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/client/clientsallsearch",
        "POST",
        JSON.stringify({
          field1: "%" + field1 + "%",
          field2: "%" + field2 + "%",
          field3: "%" + field3 + "%",
          field4: "%" + field4 + "%",
          field5: "%" + field5 + "%",
          field6: "%" + field6 + "%",
          wclid: 0,
          partnerid: -1,
          providerid: -1,
          inapprovalid: inApprovalCheckedId,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      ).then((data) => {
        setPartnerClientDatalist(data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSearchClientSignup = async (field1, field2, field3, field4, field5, field6) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/client/clientsignupsearch",
        "POST",
        JSON.stringify({
          field1: "%" + field1 + "%",
          field2: "%" + field2 + "%",
          field3: "%" + field3 + "%",
          field4: "%" + field4 + "%",
          field5: "%" + field5 + "%",
          field6: "%" + field6 + "%",
          wclid: 1,
          partnerid: user.partnerid,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      ).then((data) => {
        setPartnerClientDatalist(data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const cleanfield = (anfield) => {
    let resultfield = anfield.replace("+", "");
    resultfield = resultfield.replace("-", "");
    resultfield = resultfield.replace("(", "");
    resultfield = resultfield.replace(")", "");
    resultfield = resultfield.replace(";", "");
    resultfield = resultfield.replace(",", "");
    return resultfield;
  };

  const handleSubmit = (e) => {
    const searchtext = e.target.elements.searchtext.value;
    setClientSearchText(searchtext);
    doSearch(searchtext);
    e.preventDefault();
  };

  const clientOpenClick =async() => {
 
    const clientData = await callClientData(user, selClientRow.clientid); 
    const clientUsesFx = await clientUsesForex(user, selClientRow.clientid);
 
     user.client = {
       selClientid: selClientRow.clientid,
       selClientData: clientData,
       selClientUsesForex: clientUsesFx,
     };

    history.push({
      pathname: "/clientdata",
    });
  };

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col ratio={1} />
        <Row ratio={1} />

        <Item>
          <Location screen="md lg sm xs" row={0} col={0} />
          <form
            action="search"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <ResponsiveBox>
              <Row ratio={1} />
              <Col ratio={1} />
              <Item>
                <Location screen="md lg sm xs" row={0} col={0} />
                <Form id="client-search-section" labelLocation="left">
                  <GroupItem cssClass="first-group" colCount={8}>
                    <SimpleItem
                      dataField="clienttype"
                      colSpan={2}
                      editorType="dxSelectBox"
                      editorOptions={{
                        value: selClientGrp,
                        dataSource: stAccessType,
                        valueExpr: "grpid",
                        displayExpr: "grpname",
                        stylingMode: "outlined",
                        onValueChanged: (e) => {
                          setSelClientGrp(e.value);
                          // console.log(1234, e.value);
                        },
                      }}
                    >
                      <Label text="Clients" />
                    </SimpleItem>
                    <SimpleItem dataField="searchtext" colSpan={4}>
                      <Label text="find" />
                    </SimpleItem>
                    <ButtonItem horizontalAlignment="left" colSpan={1} buttonOptions={buttonAttributes} />
                    <ButtonItem
                      horizontalAlignment="left"
                      colSpan={1}
                      buttonOptions={{
                        text: "Open",
                        type: "normal",
                        icon: "user",
                        height: 36,
                        onClick: () => {clientOpenClick()}
                      }}
                    />
                  </GroupItem>
                </Form>
              </Item>

              {/*  
              <Item>
                <Location screen="md lg sm xs" row={0} col={1} />
                <CheckBox
                  id="clients-section-cbx"
                  text="Clients in Sign Up"
                  onValueChanged={(e) => {
                    if (e.value === true) {
                      setInSignupCheckedId(1);
                      setDisableApproval(true);
                    } else {
                      setInSignupCheckedId(0);
                      setDisableApproval(false);
                    }
                  }}
                />
              </Item>
              <Item>
                <Location screen="md lg sm xs" row={0} col={2} />
                <CheckBox
                  id="clients-section-cbx"
                  text="Clients in Approval"
                  disabled={disableApproval}
                  onValueChanged={(e) => {
                    if (e.value === true) {
                      setInApprovalCheckedId(1);
                    } else {
                      setInApprovalCheckedId(0);
                    }
                  }}
                />
              </Item>
            */}
            </ResponsiveBox>
          </form>
          <div>
            <DataGrid
              id="client-section-grids"
              height={getscreenheight}
              dataSource={dcPartnerCustomerData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              autoNavigateToFocusedRow={true}
              focusedRowKey={selectedClientid}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  setSelectedClientid(e.row.key);
                  setSelClientRow(e.row.data);
                  passToParent(e.row.key, e.row.data.partnerid, e.row.data.productid);
                }
              }}
            >
              <FilterRow visible={true} />
              <Column dataField="clientid" visible={false}></Column>
              <Column dataField="partnerid" visible={false}></Column>
              <Column dataField="productid" visible={false}></Column>
              <Column dataField="signupdate" caption="SignUp Date" dataType="datetime" width={140} />
              <Column dataField="partnername" caption="Merchant" width={120} />
              <Column dataField="clienttype" caption="Client Type" width={100} visible={false} />
              <Column dataField="risklevel" caption="Risklevel" width={120} />
              <Column dataField="cl_lastname" caption="Name" width={150} />
              <Column dataField="cl_firstname" caption="First Name" width={100} />
              <Column dataField="cl_salutation" caption="Sal." width={50} />
              <Column dataField="cl_nationality" caption="Nationality" width={100} />
              <Column dataField="cl_countryofresidence" caption="Living in" width={100} />
              <Column dataField="cl_email" caption="E-Mail" width={150} />
              <Column dataField="cl_mobilephone" caption="Mobile Phone" width={130} />
              <Column dataField="cy_name" caption="Company" width={120} />
              <Column dataField="cy_country" caption="in" width={100} />
              <Column dataField="productname" caption="Product" width={120}></Column>
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientsApprovedOneTab;
