import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import './AdminDash.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';


const AdminDashPartner = (props) => {
  const {  user } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [partnerList, setPartnerList] = useState([]);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [popupTabsVisible, setPopupTabsVisible] = useState(false);
  const [popModeInsert, setpopModeInsert] = useState(false);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [serviceProviderId, setServiceProviderId] = useState(-1);
  const [serviceProvContactId, setServiceProvContactId] = useState(-1);
  const [partnerRecord, setPartnerRecord] = useState({});

  useEffect(() => {
    const fetchPartner = async () => {
  
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/partner/partners', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });

        setPartnerList(dataArray);
      } catch (err) {}
    };
    fetchPartner();
  }, [sendRequest]);

  const stPartnerData = new ArrayStore({
    key: 'partnerid',
    data: partnerList,
  });

  const dcPartnerData = new DataSource({
    store: stPartnerData,
  });


  const openPartnerData = () => {
    user.partner = {
      partnerid: partnerRecord.partnerid || -1,
      routingid: partnerRecord.routingid || -1,
      partnername: partnerRecord.partnername || '',
      personid: partnerRecord.personid || -1,
      routingname: partnerRecord.routingname || '',
    };
    history.push({
      pathname: '/partnerdata',
    });
  };

  const renderGridCell = (cellData) => {
    return <div style={{ color: 'blue' }}>{cellData.text}</div>;
  };

  const hideContactPopup = useCallback((doContactReload) => {
    setShowContactPopup(false);
    if (doContactReload) {
      setReloadContacts(!reloadContacts);
    }
  });

  return (
    <div className='sd-header item'>
      <div className='dx-fieldset-header'>Merchants, Partner</div>
      <ResponsiveBox>
        <Col ratio={0.3} screen='md lg sm xs' />
        <Col ratio={3} />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-11'>
            <Button
              width={120}
              height={36}
              text='Open'
              type='success'
              stylingMode='outlined'
              onClick={openPartnerData}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div>
            <DataGrid
              id='partner-grid'
              height={350}
              key='partnerid'
              dataSource={dcPartnerData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  setServiceProviderId(e.row.key);
                  setServiceProvContactId(e.row.data.personid);
                  setPartnerRecord(e.row.data);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='partnerid' visible={false}></Column>
              <Column dataField='routingid' visible={false}></Column>
              <Column dataField='partnername' caption='Merchant' width={200}></Column>
              <Column dataField='personid' visible={false}></Column>
              <Column dataField='routingname' caption='Financial Network' width={300} />
              <Column dataField='activedate' dataType='date' caption='Active since' width={130} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

export default AdminDashPartner;
