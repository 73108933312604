import React, { useEffect, useRef, useState } from 'react';
import './ServicesData.css';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import { useHttpClient } from '../../hooks/http-hook';

import ArrayStore from 'devextreme/data/array_store';

const BankaccountCreatePop = (props) => {
  const { showPopup, hidePopup, systemServiceId, user, selcontactid, selucompany, showTabs, modeInsert } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const dataFormRef = useRef(null);
  const [lpAccounttypes, setLpAccounttypes] = useState([]);

  useEffect(() => {
    const fetchAccounttypes = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/1',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpAccounttypes(dataArray);
      } catch (err) {}
    };

    if (showPopup) {
      fetchAccounttypes();
    }
  }, [sendRequest, showPopup]);

  const stLpAccountData = new ArrayStore({
    key: 'lp_id',
    data: lpAccounttypes,
  });

  const bankaccount_FormData = {
    bankaccountid: -1,
    bankname: '',
    bankstreet: '',
    bankcity: '',
    bankcountry: '',
    ibankto: '',
    bicswift: '',
    currency: '',
    rec_name: '',
    rec_street: '',
    rec_city: '',
    rec_country: '',
    accountname: '',
    account_type: -1,
  };

  const CreateBankAccount = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/routing/modbankaccount',
        'POST',
        JSON.stringify({
          operation: 1,
          bankaccountid: -1,
          bankname: bankaccount_FormData.bankname,
          bankstreet: bankaccount_FormData.bankstreet,
          bankcity: bankaccount_FormData.bankcity,
          bankcountry: bankaccount_FormData.bankcountry,
          ibankto: bankaccount_FormData.ibankto,
          bicswift: bankaccount_FormData.bicswift,
          currency: bankaccount_FormData.currency,
          rec_name: bankaccount_FormData.rec_name,
          rec_street: bankaccount_FormData.rec_street,
          rec_city: bankaccount_FormData.rec_city,
          rec_country: bankaccount_FormData.rec_country,
          accountname: bankaccount_FormData.accountname,
          account_type: bankaccount_FormData.account_type,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async (dataFormRef) => {
    const result = dataFormRef.validationGroup.validate();
    if (result.isValid) {
      await CreateBankAccount();
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  function navigateToHome() {
    hidePopup(true);
  }

  return (
    <Popup
      title={'Add Bank Account'}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
      //    onShowing={() => {
      //      setContactsChanged(false);
      //    }}
    >
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='form-edit'>
            <Form
              id={'form'}
              ref={dataFormRef}
              formData={bankaccount_FormData}
              labelLocation={'top'}
              colCountByScreen={colCountByScreen}
              validationGroup='Bankaccount_Create_Data'
            >
              <Item itemType='group' colCount={3}>
                <SimpleItem dataField='accountname' isRequired={true} editorOptions={baseEditorOptions}>
                  <RequiredRule message='Please enter an Account Name' />
                  <Label text='Account Name' />
                </SimpleItem>
                <SimpleItem
                  dataField='account_type'
                  editorType='dxSelectBox'
                  colSpan={1}
                  name='accounttype_listbox'
                  isRequired={true}
                  editorOptions={{
                    dataSource: stLpAccountData,
                    valueExpr: 'lp_id',
                    displayExpr: 'lp_value',
                    searchEnabled: true,
                  }}
                >
                  <RequiredRule message='Please select the Account Type' />
                  <Label text='Account Type' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={4}>
                <SimpleItem dataField='rec_name' editorOptions={baseEditorOptions}>
                  <Label text='Receipient Name' />
                </SimpleItem>

                <SimpleItem dataField='rec_street' editorOptions={baseEditorOptions}>
                  <Label text='Street' />
                </SimpleItem>

                <SimpleItem dataField='rec_city' editorOptions={baseEditorOptions}>
                  <Label text='City' />
                </SimpleItem>

                <SimpleItem dataField='rec_country' editorOptions={baseEditorOptions}>
                  <Label text='Country' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={4}>
                <SimpleItem dataField='bankname' editorOptions={baseEditorOptions}>
                  <Label text='Bank Name' />
                </SimpleItem>

                <SimpleItem dataField='bankstreet' editorOptions={baseEditorOptions}>
                  <Label text='Street' />
                </SimpleItem>

                <SimpleItem dataField='bankcity' editorOptions={baseEditorOptions}>
                  <Label text='City' />
                </SimpleItem>

                <SimpleItem dataField='bankcountry' editorOptions={baseEditorOptions}>
                  <Label text='Country' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={3}>
                <SimpleItem dataField='ibankto' colSpan={2} editorOptions={baseEditorOptions}>
                  <Label text='IBAN/Account No' />
                </SimpleItem>

                <SimpleItem dataField='bicswift' editorOptions={baseEditorOptions}>
                  <Label text='BIC (SWIFT-Code)' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={6}>
                <SimpleItem dataField='currency' editorOptions={baseEditorOptions}>
                  <Label text='Currency' />
                </SimpleItem>
              </Item>
            </Form>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'contactpopbutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={50}
                text='Save'
                type='normal'
                stylingMode='contained'
                validationGroup='Bankaccount_Create_Data'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default BankaccountCreatePop;
