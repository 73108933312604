import React, { useEffect, useState } from 'react';
import 'devextreme/dist/css/dx.light.css';
import '../ComplianceMatrix.css';

import { useHttpClient } from '../../../hooks/http-hook';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Form, Container, Card } from 'devextreme-react/form';
import DataGrid, { Column, Lookup, Editing } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';

import ComplianceMatrixPop from '../matrix-dialogs/ComplianceMatrixPop';
import MessagePop from '../matrix-dialogs/MessagePop';
import ComplianceRiskGroupPop from '../matrix-dialogs/ComplianceRiskGroupPop';

export default function MatrixModel(props) {
  const { modelid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [matrixData, setMatrixData] = useState([]);
  const [showRiskMatrixPopup, setShowRiskMatrixPopup] = useState(false);
  const [focusedDataRow, setFocusedDataRow] = useState({});
  const [addNewMatrixEntry, setAddNewMatrixEntry] = useState(false);
  const [selRiskMatrixGroupId, setSelRiskMatrixGroupId] = useState(-1);
  const [reloadFeatures, setReloadFeatures] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [featureGroupData, setFeatureGroupData] = useState([]);
  const [showRiskGroupPopup, setShowRiskGroupPopup] = useState(false);
  const [reloadGroupFeatures, setReloadGroupFeatures] = useState(false);

  useEffect(() => {
    const fetchMatrix = async () => {
      const response = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/ai/feature-matrix/matrix/${modelid}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + user.token,
        }
      );
      setMatrixData(response);
    };
    if (modelid) {
      fetchMatrix();
    }
  }, [modelid, reloadFeatures]);

  useEffect(() => {
    const fetchFeatureValues = async () => {
      const response = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/ai/feature-matrix/feature-group-values/${modelid}/${selRiskMatrixGroupId}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + user.token,
        }
      );
      // console.log(response);
      setFeatureGroupData(response);
      setReloadGroupFeatures(false);
    };
    if ((modelid, selRiskMatrixGroupId)) {
      fetchFeatureValues();
    }
  }, [modelid, selRiskMatrixGroupId, reloadGroupFeatures]);

  const modRiskMatrixEntry = async modify => {
    const response = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + '/ai/feature-matrix/mod_matrix_entry',
      'POST',
      JSON.stringify({
        operation: modify,
        modelid: 1,
        entryid: focusedDataRow.entryid,
      }),
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    );
    console.log(response);
  };

  const addMatrixEntry = () => {
    setAddNewMatrixEntry(true);
    setShowRiskMatrixPopup(true);
  };

  const editMatrixEntry = () => {
    setAddNewMatrixEntry(false);
    setShowRiskMatrixPopup(true);
  };

  const hideRiskMatrixPopup = () => {
    setShowRiskMatrixPopup(false);
    setReloadFeatures(!reloadFeatures);
  };

  const hideMessagePop = async doRemove => {
    setShowMessagePop(false);
    if (doRemove) {
      await modRiskMatrixEntry(3);
      setReloadFeatures(!reloadFeatures);
    }
  };

  const removeMatrixEntry = () => {
    setShowMessagePop(true);
  };

  const defineRiskGroups = () => {
    setShowRiskGroupPopup(true);
  };

  const hideRiskGroupPopup = () => {
    setShowRiskGroupPopup(false);
    setReloadGroupFeatures(true);
    // }
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected Entry?'
      />
      <ComplianceMatrixPop
        showPopup={showRiskMatrixPopup}
        hidePopup={hideRiskMatrixPopup}
        rskEntryID={focusedDataRow.entryid}
        rskRowData={focusedDataRow}
        rskEntryadd={addNewMatrixEntry}
        modelid={modelid}
        user={user}
      />

      <ComplianceRiskGroupPop
        showPopup={showRiskGroupPopup}
        hidePopup={hideRiskGroupPopup}
        riskGroupid={focusedDataRow.entryid}
        modelid={modelid}
        user={user}
      />

      <ResponsiveBox>
        <Row ratio={1} />
        <Col ratio={7} />
        <Col ratio={5} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <h4 className='card-heading'>AI - Model Matrix</h4>
          <ResponsiveBox>
            <Row ratio={1} />
            <Col ratio={3} />
            <Col ratio={12} />
            <Item>
              <Location screen='md lg xs sm' row={0} col={0} />
              <div id='matrix-btn-1'>
                <Button width={120} height={36} text='Add' type='default' stylingMode='outlined' onClick={addMatrixEntry} />
              </div>
              <div id='matrix-btn-11'>
                <Button width={120} height={36} text='Modify' type='success' stylingMode='outlined' onClick={editMatrixEntry} />
              </div>
              <div id='matrix-btn-2'>
                <Button width={120} height={36} text='Remove' type='danger' stylingMode='outlined' onClick={removeMatrixEntry} />
              </div>
            </Item>
            <Item>
              <Location screen='md lg xs sm' row={0} col={1} />
              <DataGrid
                id='MatrixEntryGrid'
                dataSource={matrixData}
                keyExpr={['entryid', 'group_entryid']}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                columnAutoWidth={true}
                focusStateEnabled={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                onFocusedRowChanged={e => {
                  if (e) {
                    setFocusedDataRow(e.row.data);
                    setSelRiskMatrixGroupId(e.row.key.entryid);
                  }
                }}
              >
                <Column dataField='entryid' visible={false} />
                <Column dataField='group_entryid' visible={false} />
                <Column dataField='clienttype' visible={false} />
                <Column dataField='risk_name' visible={true} caption='Risk Name' width='260px' />
                <Column dataField='clienttypevalue' visible={true} caption='Affects' width='100px' />
                <Column dataField='riskareavalue' visible={true} caption='Risk Area' width='100px' alignment='left' />
                <Column dataField='risk_groupid' visible={false} width='100px' />
                <Column dataField='client_input' width='100px' />
                <Column dataField='entryident' caption='Identifier' width='80px' />
              </DataGrid>
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} />
          <div id='ai-form-sub'>
            <h4 className='card-heading'>Feature Values</h4>
            <ResponsiveBox>
              <Row ratio={1} />
              <Col ratio={4} />
              <Col ratio={12} />
              <Item>
                <Location screen='md lg xs sm' row={0} col={0} />
                <div id='matrix-btn-1'>
                  <Button width={120} height={36} text='Define' type='default' stylingMode='outlined' onClick={defineRiskGroups} />
                </div>
              </Item>
              <Item>
                <Location screen='md lg xs sm' row={0} col={1} />
                <DataGrid
                  id='MatrixEntryGrid'
                  dataSource={featureGroupData}
                  keyExpr={'group_entryid'}
                  showRowLines={true}
                  showBorders={true}
                  showColumnLines={true}
                  columnAutoWidth={true}
                  focusStateEnabled={true}
                  defaultFocusedRowIndex={0}
                  focusedRowEnabled={true}
                  showColumnHeaders={true}
                >
                  <Column dataField='risk_groupid' visible={false} />
                  <Column dataField='group_entryid' caption='Entryid' width='60' visible={false} />
                  <Column dataField='group_entry' caption='Entry' />
                  <Column dataField='group_entry_score' caption='Score' width='60' />
                </DataGrid>
              </Item>
            </ResponsiveBox>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
}
