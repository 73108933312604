import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import './serviceProvider.css';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Tabs, MultiView, SelectBox } from 'devextreme-react';
import { useAuth } from '../../contexts/auth';
import { Label } from 'devextreme-react/data-grid';

import ServiceProviderTab from './ServiceProviderTab';
import ServiceProvServices from './ServiceProvServices';
import ServiceProvClientsSignup from './ServiceProvClientsSignup';
import ServiceProvNotes from './ServiceProvNotes';
import ServiceProvContact from './ServiceProvContact';
import ServiceProvClientsApproved from './ServiceProvClientsApproved';
import ServiceProvTransactions from './ServiceProvTransactions';

const ServiceProviderPage = () => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [contactPersonid, setContactPersonid] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedProviderid, setSelectedProviderid] = useState();

  const getPersonid = useCallback((fProviderid, fPersonid) => {
    setSelectedProviderid(fProviderid);
    setContactPersonid(fPersonid);
  });

  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Cases</h2> */}
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={2} />
            <Row ratio={0.7} />
            <Col />

            <Item>
              <Location screen='md lg' row={0} col={0} colspan={2} />
              <Location screen='sm' row={0} col={0} colspan={1} />
              <Location screen='xs' row={0} col={0} />
              <div className='rb-header item'>
                <ResponsiveBox>
                  <Row />
                  <Col ratio={3} />
                  <Col ration={1} />
                  <Item>
                    <Location screen='md lg sm' row={0} col={0} />
                    <div className='dx-fieldset-header' id='cap_item'>
                      Service Provider
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={1} />
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg sm' row={1} col={0} />
              <Location screen='xs' row={1} col={0} />
              <div className='rb-content item'>
                <ResponsiveBox>
                  <Row ratio={1} />
                  <Row ratio={1} />
                  <Col ratio={1} />
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={0} />
                    <ServiceProviderTab passToParent={getPersonid} user={user} />
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={1} col={0} />
                    <ResponsiveBox>
                      <Col ratio={0.33} screen='md lg sm' />
                      <Col ratio={3} />
                      <Row />
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={0} />
                      </Item>
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={1} />
                        <Tabs
                          selectedIndex={tabIndex}
                          width='99%'
                          // height='20px'
                          visible={true}
                          onItemClick={e => {
                            setTabIndex(e.itemIndex);
                          }}
                        >
                          <Item text='Contact Details' />
                          <Item text='Services' />
                          <Item text='Clients in Approval' />
                          <Item text='Approved Clients' />
                          <Item text='Transactions' />
                        </Tabs>
                        <MultiView height={400} animationEnabled={false} selectedIndex={tabIndex}>
                          <Item title='Contact Details'>
                            <ServiceProvContact selContactid={contactPersonid} user={user} />
                          </Item>
                          <Item title='Assinged Services'>
                            <ServiceProvServices selProviderId={selectedProviderid} user={user} />
                          </Item>

                          <Item title='Clients in Signup'>
                            <ServiceProvClientsSignup selProviderId={selectedProviderid} user={user} />
                          </Item>

                          <Item title='Active Clients'>
                            <ServiceProvClientsApproved selProviderId={selectedProviderid} user={user} />
                          </Item>

                          <Item title='Service Provider Transactions'>
                            <ServiceProvTransactions selProviderId={selectedProviderid} user={user} />
                          </Item>
                        </MultiView>
                      </Item>
                    </ResponsiveBox>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg' row={2} col={0} colspan={2} />
              <Location screen='sm' row={2} col={0} colspan={1} />
              <Location screen='xs' row={2} col={0} />
              <div className='rb-footer item' id='cap_item'></div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ServiceProviderPage;
